
import { MessageHandlerContext } from 'components/Contexts/MessageHandler';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';


interface MessageHandlerAppContainerProps{

}
const MessageHandlerAppContainer: React.FunctionComponent<MessageHandlerAppContainerProps> =({
    children
})=> {
    const messageHandler = useContext(MessageHandlerContext);

return (
<>
{
     messageHandler.wholeAppOverlay?
     messageHandler.wholeAppOverlay: <></>
   }
   {/* <NonBlockingMessageDisplay/> */}
{children}
</>
);
}
export default MessageHandlerAppContainer;
