import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import { HorizFlex, HorizFlexMin, MinimalDiv, VertFlex, VertFlexMin } from "components/MainMenu";
import Button from "components/Reusables/Button";
import NumberInput from "components/Reusables/NumberInput";
import Popup from "components/Reusables/Popup";
import YearlessDatePicker, {
  months,
  Months,
} from "components/Reusables/YearlessDatePicker";
import { DemoInputs, getGTPResult } from "lib/api";
import { createBlockingLoadingMessage } from "lib/ErrorAndMessageHandling/MessageHandler";
import { invalidInputsMessage } from "lib/gtpInputValidation";
import { getAllowedUnitsFromBaseUnit } from "lib/units";
import { usePrevious } from "lib/usePrevious";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import DateInput from "./DateInput";
import GTPResult from "./GTPResult";
import LocationInput from "./LocationInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: var(--s-23); */
  background: var(--c-gray);
  overflow: auto;
  max-height: 99%;
  padding-top: var(--s-10);
  padding-bottom: var(--s-15);
`;
const BottomCenterButton = styled(Button)`
  font-size: var(--fs-6);
  width:80%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background: var(--c-gray-dark);
  padding: 0;
  color: var(--c-white);
`;
const SectionTitle = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-green);
  color: var(--c-white);
  width: 100%;
  margin: 0;
  border-radius: var(--def-rounding);
  box-sizing: border-box;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: var(--s-10);
`;
const BottomBar = styled.div`
  position: absolute;
  bottom: -10%;
  left: 15%;
  /* border-radius: 0; */
  /* padding: 0; */
  /* margin: 0; */
  color: var(--c-black);
  width: 70%;
  box-sizing: border-box;
  background: var(--c-gray-very-light);
  /* left: 30%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow-1-surround);
`;
const GTPLogo = styled.img`
  width: 20%;
`;
const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;
const DirectInputSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const VertDivider = styled.div`
  border-left: solid var(--c-green) var(--s-3);
  padding: 0;
  min-height: var(--s-15);
  width: 0px;
  max-width: 0;
  border-radius: 0;
  /* margin: 0; */
`;
const InstructionText = styled.div`
  width: 100%;
`;
const InstructionPhotoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const InstructionPhoto = styled.img`
width: 50%;
`;
const InstructionSideText = styled.div``;


type numUn = number | undefined;

interface GTPInputsProps {
  depthBelowTopOfPile: number;
  setResult: (result: any) => void;
  closeCallback: any;
}
const GTPInputs: React.FunctionComponent<GTPInputsProps> = ({
  closeCallback,
  depthBelowTopOfPile,
  setResult,
}) => {
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);
  const [monthTextual, setMonthTextual] = useState<Months>("January");
  const prevMonthT = usePrevious(monthTextual);
  const [localLat, setLocalLat] = useState<any>(); //to allow for negative input
  const [localLong, setLocalLong] = useState<any>();
  const prevLocLat = usePrevious(localLat);
  const prevLocLong = usePrevious(localLong);

  //keep textual month in sync with numerical month
  useEffect(() => {
    if (!api) return;
    const monthConverted = months.indexOf(monthTextual) + 1;
    if (monthConverted == api.gtpMonth) return;
    if (monthTextual === prevMonthT && api.gtpMonth != undefined) {
      setMonthTextual(months[api.gtpMonth - 1]);
    } else {
      api.setGTPMonth(monthConverted);
    }
  }, [api, monthTextual, prevMonthT]);

  function handleGetPredictionClick(e: any) {
    //TODO
    //validate input
    //send off request
    //trigger loading animation
    //on success close and autofill

   //console.log("get GTP prediction clicked");
    // if (!allowedToGetResults) return;
    ////console.log("allowed to get results");
    if (!api) return;
    const lat = api.gtpLat;
    const long = api.gtpLong;
    const depth = (api.gtpPileDepth || 0) + depthBelowTopOfPile;
    const day = api.gtpDay;
    const month = months[(api.gtpMonth || 1) - 1];
    const invalidMessage = invalidInputsMessage(lat, long, depth, day, month);
    if (invalidMessage) {
      window.alert(invalidMessage);
      return;
    }
   //console.log("inputs valid");
    const body: DemoInputs = {
      //  @ts-expect-error
      latitude: parseFloat(lat),
      // @ts-expect-error
      longitude: parseFloat(long),
      //@ts-expect-error
      depth: parseFloat(depth),
      //@ts-expect-error
      day: parseInt(day),
      month: months.indexOf(month) + 1,
    };
    async function asyncWrapper() {
      if (!body) return;
      let res = undefined;
      gc?.setPopup(undefined);
      const gtpRequestMessageId = messageHandler.addMessage!(createBlockingLoadingMessage(
        'GTP Predictions', 'Retrieving your automatic ground temperature estimates...',''));
      res = await getGTPResult(body);
     //console.log("got this response from api: ", res);
      if (typeof res === "string") {
        // TODO HANDLE THIS ERROR, display info to user
        // gc?.addErrorMessage(res);
        messageHandler.resolveMessage!(gtpRequestMessageId);
        messageHandler.addMessage!(createNonFatalHTTPRequestErrorMessage(
          'Retrieve Predictions from GTP', 500, JSON.stringify(res)
        ));
        setResult(undefined);
      } else {
        const result = res.toFixed(2);
        setResult(result);
        messageHandler.resolveMessage!(gtpRequestMessageId);
        // gc?.setSuccessState(true);
        //TODO show quick popup with result.
        gc?.setPopup(
          <GTPResult onClose={() => gc.setPopup(undefined)} result={result} />
        );
      }
    }
    asyncWrapper();
  }

  useEffect(() => {
    //detect if change is coming from outside?
    if (!api) return;
    if (localLat == prevLocLat) {
      //change coming from outside
      setLocalLat(api.gtpLat);
    } else {
      // change coming from number input
      if (parseFloat(localLat) !== api.gtpLat && parseFloat(localLat)) {
        api.setGTPLat(localLat);
      }
    }
  }, [localLat, api, prevLocLat]);
  useEffect(() => {
    if (!api) return;
    //detect if change is coming from outside?
    if (localLong == prevLocLong) {
      //change coming from outside
      setLocalLong(api.gtpLong);
    } else {
      // change coming from number input
      if (parseFloat(localLong) !== api.gtpLong && parseFloat(localLong)) {
        api.setGTPLong(localLong);
      }
    }
  }, [localLong, api, prevLocLong]);

  return (
    <Popup
      closeCallback={closeCallback}
      title="Inputs Required For Ground Temperature Prediction"
    >
      <Container>
        <Section>
          <SectionTitle>1. Pile Depth</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Enter the buried depth of the pile: the distance between the top
              surface of the ground and the top of the geo-heat-exchanging
              section of the GEOPile:
            </InstructionText>
            <VertDivider></VertDivider>

            <NumberInput
              label="Pile Top Depth"
              value={api?.gtpPileDepth}
              setValue={api?.setGTPPileDepth}
              withUnitConversions
              allowedUnits={getAllowedUnitsFromBaseUnit("m")}
              outputUnit="m"
              labelWidth="30%"
            />
          </DirectInputSection>
          <InstructionPhotoSection>
          <InstructionPhoto src="BuriedDepth.svg"></InstructionPhoto>
          <InstructionSideText>
          The buried depth can be thought of as the start of the vertical section of the pile that is in direct (uninterrupted) contact with the ground, without the presence of grout or insulation. 
            <br/><br/>
            If there are multiple buried depths within an array, enter the average distance.
          </InstructionSideText>
          </InstructionPhotoSection>
        </Section>
        <Section>
          <SectionTitle>2. Location</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Select the location of the desired ground temperature on the map,
              or manually enter the latitude and longitude:
            </InstructionText>
            <VertDivider></VertDivider>
            <VertFlex>
              <HorizFlex>
                <label>Latitude</label>
                <input
                  type="number"
                  step="0.00001"
                  min="-90"
                  max="90"
                  onChange={(e) => setLocalLat(e.target.value)}
                  value={localLat}
                ></input>
              </HorizFlex>
              <HorizFlex>
                <label>Longitude</label>
                <input
                  type="number"
                  step="0.00001"
                  min="-180"
                  max="180"
                  onChange={(e) => setLocalLong(e.target.value)}
                  value={localLong}
                ></input>
              </HorizFlex>
            </VertFlex>
          </DirectInputSection>
          <LocationInput
            lat={api?.gtpLat || 0}
            long={api?.gtpLong || 0}
            setLong={api?.setGTPLong}
            setLat={api?.setGTPLat}
          />
        </Section>
        <Section>
          <SectionTitle>3. Date</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Enter the day and month of the year of your ground temperature
              prediction by entering either the numerical value, or selecting it
              from the calendar:
            </InstructionText>
            <VertDivider></VertDivider>
            <div>
              <HorizFlex>
                <label>Day</label>
                <input
                  value={api?.gtpDay}
                  type="number"
                  onChange={(e) => api?.setGTPDay(e.target.valueAsNumber)}
                ></input>
              </HorizFlex>
              <div>
                <label>Month</label>
                <select
                  value={months[(api?.gtpMonth || 1) - 1]}
                  onChange={(e) =>
                    api?.setGTPMonth(
                      months.indexOf(e.target.value as Months) + 1
                    )
                  }
                >
                  {months.map((month) => {
                    return (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </DirectInputSection>
          <DateInput
            day={api?.gtpDay}
            month={monthTextual}
            setDay={api?.setGTPDay}
            setMonth={setMonthTextual}
          />
        </Section>
      </Container>
      <BottomBar>
        <LogoSection>
          <GTPLogo src="GTPLogo.svg"/>
          <VertFlexMin>
            <div>Predicted with Umny AI through the GTP Application.</div>
            <HorizFlexMin>
              <Button custCss="background-color: var(--c-gray-dark);" onClick={()=>window.open('https://groundtemperatures.com/accuracy')}>Accuracy</Button>
              <Button  custCss="background-color: var(--c-gray-dark);"  onClick={()=>window.open('https://secureservercdn.net/104.238.68.196/c65.c15.myftpupload.com/wp-content/uploads/2021/09/GTP-Technical-Brochure-2021.pdf')}>Info</Button>
            </HorizFlexMin>
          </VertFlexMin>
        </LogoSection>
        <BottomCenterButton onClick={handleGetPredictionClick}>
          Get Prediction
        </BottomCenterButton>
      </BottomBar>
    </Popup>
  );
};
export default GTPInputs;
function createNonFatalHTTPRequestErrorMessage(arg0: string, arg1: number, arg2: string): import("lib/ErrorAndMessageHandling/MessageHandler").Message {
  throw new Error("Function not implemented.");
}

