import { OfficialVariables, readableVariable } from "lib/types";
import { BaseModel, IBaseModel } from "./base-model";
import { PossibleCategoryNames } from "./possible-category-names";

export interface IUiInputVariableInt extends IBaseModel {
  inputValue?: number;

  calculatedValue?: number;

  category?: PossibleCategoryNames;

  minAllowed?: number;

  maxAllowed?: number;


  unit?: string | null;

  canSolveFor?: boolean;
  specialButtonText?: string;

  specialButtonOnClick?: ()=>void
}
export interface IUiInputVariableIntOut {
  inputValue?: number;
}

export class UiInputVariableInt extends BaseModel implements IUiInputVariableInt{
  public constructor(type: OfficialVariables){
    super();
    this.name = readableVariable[type]
  }
  public inputValue?: number;

  public calculatedValue?: number;

  public category?: PossibleCategoryNames;

  public minAllowed?: number;

  public maxAllowed?: number;

  public solveFor?: boolean = false;

  public unit?: string | null;

  public canSolveFor?: boolean;
}
export function convertUiInputIntToDto(base?: null | IUiInputVariableInt): IUiInputVariableIntOut | undefined{
  return base?{inputValue: base.inputValue}:undefined;
}