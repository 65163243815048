import { createHandledPromiseRejectionEventMessage, createUnhandledPromiseRejectionEventMessage, ErrorHandler, ErrorHandlers } from "lib/ErrorAndMessageHandling/ErrorHandler";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

import { MessageHandlerContext } from "./MessageHandler";

//! customize me
const customErrorHandlingRules: ErrorHandlers = {
  UnhandledPromiseRejections: {
    matchCallbacks: [
      (e) =>
        e instanceof PromiseRejectionEvent && e.type == "unhandledrejection",
    ],
    response: (e: PromiseRejectionEvent) => {
      return createUnhandledPromiseRejectionEventMessage(e);
    },
  },
  HandledPromiseRejections: {
    matchCallbacks: [
      (e) =>
        e instanceof PromiseRejectionEvent && e.type != "unhandledrejection",
    ],
    response: (e: PromiseRejectionEvent) =>
      createHandledPromiseRejectionEventMessage(e),
  },
};

//define types here
interface ErrorHandlerContextProps {
  errorHandler: ErrorHandler;
}

//initialize state structure here
export const ErrorHandlerContext = React.createContext<
  Partial<ErrorHandlerContextProps>
>({});

const ErrorHandlerProvider: React.FunctionComponent = ({ children }) => {
  const messageHandler = useContext(MessageHandlerContext);
  //credit to andrew.fox on https://stackoverflow.com/questions/31111771/can-you-catch-all-errors-of-a-react-js-app-with-a-try-catch-block
  const [errorHandler, setErrorHandler] = useState<ErrorHandler>();
  //initial setup on mount
  useEffect(() => {
    if (
      !messageHandler ||
      !messageHandler.addMessage
    )
      return;
    const thisErrorHandler = new ErrorHandler(
      messageHandler.addMessage,
      customErrorHandlingRules
    );
    setErrorHandler(thisErrorHandler);
    registerHandlersToCatchAllApplicationErrors(thisErrorHandler);
  }, [messageHandler]);

  function registerHandlersToCatchAllApplicationErrors(
    errorHandler: ErrorHandler
  ) {
    window.addEventListener("error", (event) => {
      console.warn("Error event going to ERrorHandler = ", event);
      errorHandler.handleAllErrors(event.error);
    });

    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        console.warn('Unhandled promise rejection going to ErrorHandler = ',event);
        errorHandler.handleAllErrors(event);
      }
    );
  }
  return (
    <ErrorHandlerContext.Provider
      value={{
        errorHandler: errorHandler,
      }}
    >
      {children}
    </ErrorHandlerContext.Provider>
  );
};
export default ErrorHandlerProvider;
