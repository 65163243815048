
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import RoomIcon from '@material-ui/icons/Room';
import styles from 'styles/InputSection.module.scss'


interface LocationPinProps{
  lat: any
  lng: any
}
const LocationPin: React.FunctionComponent<LocationPinProps> =({lat, lng})=> {

return (
 <div className={styles.pin}>
    <RoomIcon  
    fontSize='inherit'
    className={styles.pinIcon}></RoomIcon>
    {/* <p className="pin-text">{text}</p> */}
  </div>
);
}
export default LocationPin;
