import { GlobalContext } from "components/Contexts/Global";
import InfoPopup from "components/Popups/InfoPopup";
import Select from "components/Reusables/Select";
import { readableVariable } from "lib/types";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
} from "react";
import { Option } from "sheldons-components";
import SectionContainer from "./SectionContainer";
import { BasicCols,  InfoButton, InnerTitleCont, InnerTitleText, InputInstr, LittleDivider, LittleInstr } from "./Styles";

interface BulkStudyConfigSectionProps {
    withHeatPump: boolean |undefined
    setWithHeatPump: React.Dispatch<SetStateAction<boolean|undefined>>
    withKnownInFluidEachRow: boolean | undefined
    setWithKnownInFluidEachRow: React.Dispatch<SetStateAction<boolean|undefined>>
}
const BulkStudyConfigSection: React.FunctionComponent<BulkStudyConfigSectionProps> =
  ({
      withHeatPump,setWithHeatPump, withKnownInFluidEachRow, setWithKnownInFluidEachRow
  }) => {

    const hpOptions= {
        with: {
            content: 'With Heat Pump',
            id: 'whp',
            value: 'whp'
        },
        without: {
            content: 'Without Heat Pump',
            id: 'nohp',
            value: 'nohp'
        }
    }
    const inFluidOptions = {
        known: {
          content: 'Manual',
          id: 'known',
          value: 'known'
        },
        unknown: {
          content: 'Automatic',
          id: 'unknown',
          value: 'unknown',
        }
    }

    const gc = useContext(GlobalContext);
    return (
      <SectionContainer title="3. Configure Study">
          <BasicCols>
            <InputInstr>
              Define if the study will be run with or without a heat pump:
            </InputInstr>
            <Select
                type="styled"
                selected={
                    withHeatPump?
                    hpOptions.with:
                    hpOptions.without
                }
                onChange={
                    (newOption)=>{
                        if (newOption?.id == 'whp'){
                            setWithHeatPump(true);
                        }
                        else {
                            setWithHeatPump(false);
                        }
                    }
                }
                options={[hpOptions.with, hpOptions.without]}
            />
            
            <InfoButton
              onClick={() =>
                gc?.setPopup(<InfoPopup imageLink={"With or Without Heat Pump INFO.jpg"} />)
              }
            ></InfoButton>
          </BasicCols>
          <LittleInstr>
          When "Without Heat Pump" is selected, the thermal capacity is
            calculated as the heat exchange rate by the individual pile or array
            of piles (if there are more than one piles entered). "With Heat
            Pump" will calculate the thermal capacity of the piles through a
            user-defined heat pump along with the COP.
            
          </LittleInstr>
          <LittleDivider></LittleDivider>
          <BasicCols>
            <InputInstr>
              Automatic or Manual Incoming Fluid Temperature
            </InputInstr>
            <Select
                type="styled"
                selected={
                    withKnownInFluidEachRow?
                    inFluidOptions.known:
                    inFluidOptions.unknown
                }
                onChange={
                    (newOption)=>{
                        if (newOption?.id == 'known'){
                          setWithKnownInFluidEachRow(true)
                        }
                        else {
                          setWithKnownInFluidEachRow(false);
                        }
                    }
                }
                options={[inFluidOptions.known, inFluidOptions.unknown]}
            />
            
            <InfoButton
              onClick={() =>
                gc?.setPopup(<InfoPopup imageLink={"Bulk Tin Input INFO.jpg"} />)
              }
            ></InfoButton>
          </BasicCols>
          <LittleInstr>
          Define if the study will be run with known {readableVariable['heatPumpOutletFluidTemperature']}/{readableVariable['pileInletFluidTemperature']}, or if these should be calculated automatically.
          If you have selected "With Heat Pump" then {readableVariable['heatPumpOutletFluidTemperature']} is used.
            If you have selected "Without Heat Pump" then {readableVariable['pileInletFluidTemperature']} is used.
            You must always set the value for this fluid temperature in the first row of your csv, even if you select 
            "Automatic". However if you select "Automatic" then
            every timestep after the first one will have its incoming fluid temperature calculated based on the results
            from the previous timestep.
          </LittleInstr>
          <LittleDivider></LittleDivider>
         
      </SectionContainer>
    );
  };
export default BulkStudyConfigSection;
