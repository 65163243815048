import { baseURL, GlobalContext } from "components/Contexts/Global";
import { HorizFlex } from "components/MainMenu";
import Button from "components/Reusables/Button";
import GeneralInput from "components/Reusables/GeneralInput";
import Popup from "components/Reusables/Popup";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";

const Container = styled.div`
  background: var(--c-white);
  overflow: scroll;
  height: 100%;
  color: var(--c-black);
`;
const Header = styled.h4`
    color: var(--c-green);
`;
const Underline = styled.div`
text-decoration: underline;
`;
const UnderlineBold = styled.div`
text-decoration: underline;
font-weight: 600;
`;
interface TOSProps {}
const TOS: React.FunctionComponent<TOSProps> = ({}) => {
  const gc = useContext(GlobalContext);
    //console.log('tos rendering');
  return (
    <Popup
      title="Terms of Service ('Terms')"
      closeCallback={() => gc?.setPopup(undefined)}
    >
      <Container>
        <div>These Terms of Service were last updated in June, 2021.</div>
        <Underline>
          The following Terms of Service govern all access to and use of the
          Geo-Pile Predictive Software (GPPS) web application, and all content
          and reports generated through this software application.
        </Underline>
        <Underline>
          Please read these Terms of Service (“Terms”, “Terms of Service”)
          carefully, and ensure you agree with them, before accessing or using
          the GPPS, owned by Innovia GEO Corp (“us”, “we”, or
          “our”).
        </Underline>
        <UnderlineBold>
          By accessing or using any part of the GPPS,
          you agree to become bound by these Terms. If you disagree with any
          part of the Terms of Service of the Agreement, then you may not access
          or use the GPPS service.
        </UnderlineBold>
        <Header>About our Terms of Service</Header>
        <div>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural:
        </div>
        <ul>
          <li>
            “Company” (referred to as either “the Company”, “We”, “Us” or “Our”
            in this Disclaimer) refers to Innovia GEO Corp, 64 North Forster Park Drive, Oakville, Ontario L6K1Z3.
          </li>
          <li>
            “Service” refers to the Geo-Pile Predictive Software Application.
          </li>
          <li>
            “You” (referred to as either “You” or “Your”) refers to the
            individual accessing the Service, or the company, or other legal
            entity on behalf of which such individual is accessing or using the
            Service, as applicable.
          </li>
          <li>
            “Application” refers to the software program provided by the
            Company, accessed by You on any electronic device, named Geo-Pile
            Prediction Software.
          </li>
        </ul>
        <Header>Termination</Header>
        <div>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if You breach the Terms.
        </div>
        <div>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </div>
        <Header>Content</Header>
        <div>
          Our Service allows You to store and download certain information,
          text, graphics, or other material ("Content"). All content from this
          software is the intellectual property of the Company. Also the Company
          will not be liable for any use of Content that comes from this
          Service.
        </div>
        <Header>Disclaimer Summary</Header>
        <div>
          The information contained on the Service is for general information
          purposes only.
        </div>
        <div>
          The Company assumes no responsibility for errors or omissions in the
          contents of the Service.
        </div>
        <div>
          Reports or predictions generated by this software are for general
          information purposes only and may not be used as an engineering
          document without the express consent, validation, and seal of a
          professional engineer licensed under the governing body of the region
          of use.
        </div>
        <div>
          In no event shall the Company be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The Company reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </div>
        <div>
          The Company does not warrant that the Service is free of viruses or
          other harmful components.
        </div>
        <Header>External Links Disclaimer</Header>
        <div>
          The Service may contain links to external websites that are not
          provided or maintained by or in any way affiliated with the Company.
        </div>
        <div>
          Please note that the Company does not guarantee the accuracy,
          relevance, timeliness, or completeness of any information on these
          external websites.
        </div>
        <Header>Errors and Omissions Disclaimer</Header>
        <div>
          The information given by the Service is for general guidance on
          matters of interest only. Even if the Company takes every precaution
          to insure that the content of the Service is both current and
          accurate, errors can occur. Plus, given the changing nature of laws,
          rules and regulations, there may be delays, omissions or inaccuracies
          in the information contained on the Service.
        </div>
        <div>
          The Company is not responsible for any errors or omissions, or for
          losses associated with the results obtained from the use of this
          information.
        </div>
        <Header>No Responsibility Disclaimer</Header>
        <div>
          The information on the Service is provided with the understanding that
          the Company is not herein engaged in rendering engineering or other
          professional advice and services. As such, it should not be used as a
          substitute for consultation with a professional engineer or other
          competent advisers.
        </div>
        <div>
          In no event shall the Company or its suppliers be liable for any
          special, incidental, indirect, or consequential damages whatsoever
          arising out of or in connection with your access or use or inability
          to access or use the Service.
        </div>
        <Header>"Use at Your Own Risk" Disclaimer</Header>
        <div>
          All information in the Service is provided “as is”, with no guarantee
          of completeness, accuracy, timeliness or of the results obtained from
          the use of this information, and without warranty of any kind, express
          or implied, including, but not limited to warranties of performance,
          merchantability and fitness for a particular purpose.
        </div>
        <div>
          The Company will not be liable to You or anyone else for any decision
          made or action taken in reliance on the information given by the
          Service or for any consequential, special or similar damages, even if
          advised of the possibility of such damages.
        </div>
        <Header>Intellectual Property and Copyrights</Header>
        <div>
         ]GPPS is solely owned by Innovie GEO Corp., all intellectual property and copyrights are
          assigned to Innovia GEO Corp., except for previously disclosed
          Background IP. Use of this Application must follow the copyright and
          intellectual property agreements of Innovia GEO Corp and Umny Inc.
        </div>
        <div>
          Mainly, the use of Content created while using the Application may not
          be repurposed for similar or competing use. The Content must be
          appropriately credited and referenced. All images and graphics created
          for the User Interface of the Application remain in the original
          copyright of their designer – and may not be altered, shared without
          credit, or used for other commercial purposes.
        </div>
        <Header>Ground Temperature Predictions Terms of Service</Header>
        <div>
          Parts of GPPS utilize the external service "Ground Temperature Predictor" (GTP) owned by Umny Inc.
          By utilizing the parts of GPPS reliant on GTP the user is fully agreeing to the GTP Terms of Service and Conditions,
          which can be found here: https://groundtemperatures.com/interactive-demo (as of Sept, 2021, contact us if the link does not work).
        </div>
        {/* <Header>Contact Us</Header>
        <div>
          If You have any questions about this agreement, You can contact us
        </div>
        <div>By email: EMAIL HERE</div> */}
      </Container>
    </Popup>
  );
};
export default TOS;
