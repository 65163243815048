import { GlobalContext } from "components/Contexts/Global";
import HeatPumpCapacityResults from "components/ResultsViews/HeatPumpCapacity";
import NumberOfPilesResults from "components/ResultsViews/NumberOfPiles";
import PileLengthResults from "components/ResultsViews/PileLength";
import GroundTemperatureResults from "components/ResultsViews/GroundTemperature";
import Popup from "components/Reusables/Popup";

import { ICalculator, OutputVariables } from "lib/models";
import {
  IHeatPumpCapacityResults,
  INumberOfPilesResults,
  IPileLengthDynamicReturnType,
  IPileLengthResults,
  IGroundTemperatureResults,
} from "lib/models/calculation-results";
import { gcd } from "mathjs";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import BulkPredictionResults from "components/ResultsViews/BulkPredictionResults";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";

const TitleContainer = styled.div`
  background: var(--c-black);
  width: fit-content;
  position: absolute;
  top: -4vw;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
  z-index: 999;
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;
const ScrollContainer = styled.div`
  margin: 0;
  padding: 0;
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
`;

interface ResultsPopupProps {
  heatPumpCapacityData?: IHeatPumpCapacityResults;
  groundTemperatureData?: IGroundTemperatureResults;
  pileLengthData?: IPileLengthDynamicReturnType;
  numberOfActivePilesInTheArrayData?: INumberOfPilesResults;
  bulkPredictionData?: BulkHeatPumpCapacityResult;
}
const ResultsPopup: React.FunctionComponent<ResultsPopupProps> = ({
  heatPumpCapacityData,
  groundTemperatureData,
  pileLengthData,
  numberOfActivePilesInTheArrayData,
  bulkPredictionData,
}) => {
  const gc = useContext(GlobalContext);
  const [data, setData] = useState<any>();
  //! DELETE ME
 //console.log('starting Results Popup, with ',bulkPredictionData);
  //update data type with gc output variable
  useEffect(() => {
    //console.log('gc output variable change');
    if (!gc || !gc.outputVariable) return;
    const newData =
      gc?.outputVariable === OutputVariables.NumberOfPiles
        ? numberOfActivePilesInTheArrayData
        : gc?.outputVariable === OutputVariables.PileHeatPumpCapacity
        ? heatPumpCapacityData
        : gc.outputVariable === OutputVariables.PileLength
        ? pileLengthData
        : gc.outputVariable === OutputVariables.GroundTemperature
        ? groundTemperatureData
        :gc.outputVariable === OutputVariables.BulkPredictions
        ? bulkPredictionData
        : undefined;
    setData(newData);
  }, [gc?.outputVariable]);
  
  //debugging
  // useEffect(()=>{
  //   //console.log('data in results popup changed');
  // },[data]);

  if (!data) return <></>;
  return (
    <Popup
      title={`${gc?.outputVariable} Results`}
      closeCallback={() => gc?.setPopup(undefined)}
      overwriteContainerStyles={css`
      background: linear-gradient(var(--c-black), var(--c-gray-dark), var(--c-black));
      `}
    >
      <ScrollContainer>
        {gc?.outputVariable == OutputVariables.PileHeatPumpCapacity ? (
          <HeatPumpCapacityResults data={data} />
        ) : gc?.outputVariable == OutputVariables.PileLength ? (
          <PileLengthResults data={data} />
        ) : gc?.outputVariable == OutputVariables.NumberOfPiles ? (
          <NumberOfPilesResults data={data} />
        ) : gc?.outputVariable == OutputVariables.GroundTemperature ? (
          <GroundTemperatureResults data={data} />
        ) : gc?.outputVariable == OutputVariables.BulkPredictions ? (
          <BulkPredictionResults data={data}/>
        )
        :
        (
          <></>
        )}
      </ScrollContainer>
    </Popup>
  );
};
export default ResultsPopup;
