import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import Popup from "components/Reusables/Popup";

import { createEmpty } from "lib/helper-functions";
import {
    IPlasticPipeNominalSize,
  PlasticPipeNominalSize,
  plasticPipeNominalSizeToDto,
} from "lib/models/plastic-pipe-nominal-size";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { getAllowedUnitsFromBaseUnit } from "lib/units";
import NumberInput from "components/Reusables/NumberInput";
import { Option } from "sheldons-components";
import _ from 'lodash';
import styled, {css} from 'styled-components';
import Select from "components/Reusables/Select";
import Button from "components/Reusables/Button";
import GeneralInput from "components/Reusables/GeneralInput";

const Container = styled.div`
  background: var(--c-green-very-light);
  position: relative;
  padding: var(--s-7);
`;
const TitleContainer = styled.div`
  background: var(--c-black);
  width: fit-content;
  position: absolute;
  top: -4vw;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
  z-index: 999;
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--c-black);
  color: var(--c-background-white);
`;
const ACRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
`;
const ACRow1 = styled.div`
  ${ACRow}
  background: var(--c-gray);
`;
const SelectLabel = styled.div`
  color: var(--c-green-very-light);
  font-weight: bold;
`;
const ACRow2 = styled.div`
  ${ACRow}
`;



interface PlasticPipeNominalSizeCrudProps {}
const PlasticPipeNominalSizeCrud: React.FunctionComponent<PlasticPipeNominalSizeCrudProps> = (
  props
) => {
  const gc = useContext(GlobalContext);
  const api = useContext(BackendAPIContext);
  const [selectedObject, setSelectedObject] = useState<
    IPlasticPipeNominalSize | undefined
  >();
  const [impossibleShapeWarning, setImpossibleShapeWarning] = useState<boolean>();

  //keep impossibleShapeWarning up to date
  useEffect(()=>{
    if (!api || !api.plasticPipeNominalSize || api.plasticPipeNominalSize.innerDiameter === undefined 
      || api.plasticPipeNominalSize.innerDiameter === null || api.plasticPipeNominalSize.outerDiameter === null || api.plasticPipeNominalSize.outerDiameter === undefined) 
    {
      setImpossibleShapeWarning(false);
      return;}
    if (api?.plasticPipeNominalSize?.innerDiameter > api?.plasticPipeNominalSize?.outerDiameter){
      setImpossibleShapeWarning(true);
    }else {
      setImpossibleShapeWarning(false);
    }
  },[api?.plasticPipeNominalSize]);
 
    const emptyObject = createEmpty.plasticPipeNominalSize();
  function savedObjectsToOptions(): Option[] | undefined {
    if (!api?.plasticPipeNominalSizes || api?.plasticPipeNominalSizes.length < 1) return undefined;
    // //console.log(api.plasticPipeNominalSizes);
    const savedOptions = api?.plasticPipeNominalSizes.map((savedObject) => {
      return crudableObjectToOption(savedObject);
    });
    return savedOptions?.filter((item: Option | undefined) => item) as Option[];
  }
  function crudableObjectToOption(
    obj: IPlasticPipeNominalSize | undefined
  ): Option | undefined {
    if (!obj || obj.id === null || obj.id === undefined) return undefined;
    return { id: obj.id, value: obj.id, content: obj.name };
  }
  function getSavedObjectFromOption(option: Option | undefined) {
    if (!api?.plasticPipeNominalSizes || !option) return undefined;
    return api.plasticPipeNominalSizes.find((savedObject) => savedObject.id === option.id);
  }
  async function handleOverwriteClick() {
    if (!api || !api?.plasticPipeNominalSize || !api?.saveModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    if (_.isEqual(api?.plasticPipeNominalSize, emptyObject )) return;
    if (impossibleShapeWarning) {
      window.alert('Error: The Inner Diameter must be smaller than the Outer Diameter');
      return;
    }
    await api?.updateModel('plasticpipenominalsizes',api?.plasticPipeNominalSize,gc?.apiToken);
    await api?.refreshModelsLists(gc?.apiToken);
    refreshSelectedObject();
  
  }
  function refreshSelectedObject(){
    //console.log('refreshing selected object');
    const id = selectedObject?.id;
    const newSelected = api?.plasticPipeNominalSizes?.find(object => object.id === id);
    //console.log('found this new selected object', newSelected);
    setSelectedObject(newSelected);
  }
  function handleSaveAsNewClick(){
    
    if (!api?.plasticPipeNominalSize || !api?.saveModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    
    if (_.isEqual(api?.plasticPipeNominalSize, emptyObject)) return;
    if (impossibleShapeWarning) {
      window.alert('Error: The Inner Diameter must be smaller than the Outer Diameter');
      return;
    }
    api?.saveModel('plasticpipenominalsizes',{...api?.plasticPipeNominalSize, id: undefined}, gc.apiToken, api.plasticPipeNominalSizes);
  }
  async function handleDeleteClick() {
    if (!api?.plasticPipeNominalSize || !api.plasticPipeNominalSize.name || !api?.deleteModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    await api?.deleteModel('plasticpipenominalsizes',api?.plasticPipeNominalSize.name,gc.apiToken);
    await api?.refreshModelsLists(gc.apiToken);
    setSelectedObject(undefined); 
   
  }
  function handleLoadClick() {
    if (!selectedObject) return;
    api?.setPlasticPipeNominalSize(selectedObject);
  }


 

  return (
    <Popup 
    title="Plastic Pipe Nominal Size"
    closeCallback={() => gc?.setPopup(undefined)}
    >
      <Container>
      <ActionsContainer>
        <ACRow1>
          <SelectLabel>Select from saved Plastic Pipe Nominal Sizes:</SelectLabel>
          <Select
            type="styled"
            options={savedObjectsToOptions()}
            selected={crudableObjectToOption(selectedObject)}
            onChange={(obj: Option | undefined) =>
              setSelectedObject(getSavedObjectFromOption(obj))
            }
            placeholder={`Select`}
          ></Select>
          <Button onClick={handleLoadClick}>Load</Button>
        </ACRow1>
        <ACRow2>
          <GeneralInput
            darkBackground
            label="Name"
            autoComplete="none"
            value={
              api?.plasticPipeNominalSize && api?.plasticPipeNominalSize.name !== null
                ? api?.plasticPipeNominalSize.name
                : undefined
            }
            onChange={(e: any) => {
              api?.setPlasticPipeNominalSize((prev: any) => {
                const clone = { ...prev };
                clone.name = e.target.value;
                return clone;
              });
            }}
          />
          <Button onClick={handleOverwriteClick}>Overwrite</Button>
          <Button onClick={handleSaveAsNewClick}>Save As New</Button>
          <Button onClick={handleDeleteClick}>Delete</Button>
        </ACRow2>
      </ActionsContainer>
      <form>
        {
          impossibleShapeWarning?
          <div>Warning: Inner Diameter cannot be larger than Outer Diameter.</div>
          :<div><br></br></div>
        }
      <NumberInput
                label={"Inner Diameter"}
                value={
                  api?.plasticPipeNominalSize?.innerDiameter
                }
                max={1}
                min={0}
                decimals={14}
                outputUnit={"m"}
                allowedUnits={getAllowedUnitsFromBaseUnit("m")}
                setValue={(newVal: any) => {
                  api?.setPlasticPipeNominalSize((prev:any) => {
                    const clone = { ...prev };
                    clone.innerDiameter = newVal;
                    return clone;
                  });
                }}
                onChange={undefined}
              />
              <NumberInput
                label={"Outer Diameter"}
                value={
                    api?.plasticPipeNominalSize?.outerDiameter
                }
                max={1}
                min={0}
                decimals={14}
                outputUnit={"m"}
                allowedUnits={getAllowedUnitsFromBaseUnit("m")}
                setValue={(newVal: any) => {
                  api?.setPlasticPipeNominalSize((prev:any) => {
                    const clone = { ...prev };
                    clone.outerDiameter = newVal;
                    return clone;
                  });
                }}
                onChange={undefined}
              />
        </form>
    </Container>
    </Popup>
  );
};
export default PlasticPipeNominalSizeCrud;
