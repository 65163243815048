import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import { baseURL, GlobalContext } from "./Contexts/Global";
import { HorizFlex } from "./MainMenu";
import Button from "./Reusables/Button";
import CheckBox from "./Reusables/CheckBox";
import GeneralInput from "./Reusables/GeneralInput";
import { isValidPassword } from "lib/api";
import TOS from "./Popups/TOS";
import { config } from "lib/CONFIG-consts";
import { usePrevious } from "lib/usePrevious";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 98vh;

`;
const LogoSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const BothLogos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const BothTitles = styled.div`
    color: var(--c-green-bright);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const InnoviaLogo = styled.img`
    width: var(--s-18);
`;
const GPPLogo = styled.img`
    width: var(--s-18);
`;
const LoginSection = styled.div`
    background: var(--c-gray-medium);
    box-shadow: var(--shadow-inner);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--c-black);
    padding: var(--s-10);
    margin: var(--s-10);
`;
const LoginActionSection = styled.form`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;
const ProfilePhoto = styled.img`
    width: var(--s-14);
    margin-right: var(--s-10);
`;
const LoginTitle = styled.h4`
    color: var(--c-black);
    align-self: center;
`;
const DisclaimerSection = styled.div`
`;
const TOSLink = styled.div`
    color: var(--c-green);
    cursor: pointer;
    font-weight: 600;
`;
const CheckboxConfirmation = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
`;
const SilentDiv = styled.div`
  margin: 0;
  padding: 0;
  color: red;
`;
const PlainText = styled.div`
    margin:0;
    padding:0;
`;
const CB = styled.input`
    height: var(--s-10);
    width: var(--s-10);
`;

interface LoginPageProps {}
const LoginPage: React.FunctionComponent<LoginPageProps> = ({}) => {
  const [enteredPassword, setEnteredPassword] = useState<string>("");
  const prevEnteredPassword = usePrevious(enteredPassword);
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
  const [invalidTOS, setInvalidTOS] = useState<boolean>(false);
  const [agreeTOS, setAgreeTOS] = useState<boolean>(false);
  const gc = useContext(GlobalContext);

  //reset invalid password checker
  useEffect(() => {
    if ((enteredPassword !== prevEnteredPassword) && invalidPassword) setInvalidPassword(false);
  }, [enteredPassword, invalidPassword, prevEnteredPassword]);

  async function handleAuthenticateClick(e: any) {
    e.preventDefault();
    //do they agree to the terms of service?
    if (!agreeTOS) {
        setInvalidTOS(true);
        return;
    }
    //test if it is the correct password
    try {
      const result = await isValidPassword(enteredPassword, baseURL);
      //save the password
      gc?.setApiToken(enteredPassword);
      window.sessionStorage.setItem(config.apiTokenSessionStorageKey, enteredPassword);
      //close the popup
      gc?.setPopup(undefined);
    } catch {
      setInvalidPassword(true);
      return;
    }
  }
  return (
    <Container>
      <LogoSection>
        <BothLogos>
            <InnoviaLogo src="InnoviaLogoLight.svg"/>
            <GPPLogo src="simpleLogo.svg"/>
        </BothLogos>
        <BothTitles>
            <h2>Geo-Pile Predictive <br/>Software</h2>
            <p>
                Innovia GEO Corp. Copyright @ 2021.
            <br/>Created by Sarah R. Nicholson, Sheldon Frith, and Rony Shohet
        </p>
        </BothTitles>
      </LogoSection>
      <LoginSection>
        <LoginTitle>Login</LoginTitle>
        <LoginActionSection>
                <ProfilePhoto src="profile-photo.svg"/>
              <GeneralInput
                value={enteredPassword}
                onChange={(e: any) => setEnteredPassword(e.target.value)}
                type="password"
                placeholder="Enter password here"
              />


            <Button onClick={handleAuthenticateClick}>Authenticate</Button>
        </LoginActionSection>
              {invalidPassword ? (
                <SilentDiv>Invalid Password</SilentDiv>
              ) : (
                <></>
              )}
                {invalidTOS ? (
                <SilentDiv>You must agree to the Terms of Service.</SilentDiv>
              ) : (
                <></>
              )}
        <DisclaimerSection>
          <PlainText>
            By using this software you are agreeing to the Terms of Service.
          </PlainText>
          <TOSLink onClick={()=> {
              gc?.setPopup(<TOS/>);
              //console.log('sholud show tos')
        }}>Read Terms of Service</TOSLink>
          <CheckboxConfirmation>
              <CB type="checkbox" checked={agreeTOS} onClick={()=>{
                  setInvalidTOS(false);
                  setAgreeTOS(prev => !prev);
                }
                  }/>
            <PlainText>I have read and agree to the Terms of Service.</PlainText>
          </CheckboxConfirmation>
        </DisclaimerSection>
      </LoginSection>
    </Container>
  );
};
export default LoginPage;
