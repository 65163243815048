import { getPercentage } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IGroundTemperatureResults } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import SummaryPageTemplate from "./SummaryPageTemplate";
import ReportTemplate, { getConvertedText, getPileInletFlowRate, H1, H4 } from "../Template";
import { convertFlowRateToLPM } from "lib/units";

export interface SessionData {
  name: string;
  heatingOrCooling: "Heating" | "Cooling";
  wholeArrayGroundTemperature: number;
  averageGeoPileThermalCapacity: number;
  heatPumpCoefficientOfPerformance: number;
  targetWholeArrayGroundTemperature: number;
  numberOfActivePilesInTheArray: number;
  steelPileLength: number;
  steelPileNominalSize: string;
  plasticPipeNominalSize: string;
  pileInletFlowRate: number;
  heatPumpName: string;
  groundThermalConductivityMean: number;
  groundTemperature10mBelowTopOfPile: number;
  ambientAirTemperature: number;
}

interface GroundTemperatureReportProps {
  id: any;
  sessions: IGroundTemperatureResults[];
  imperial: boolean
}
const GroundTemperatureReport: React.FunctionComponent<GroundTemperatureReportProps> = ({
  id,
  sessions,
  imperial,
}) => {
 
  function getTopGroundTemperature(){
      return sessions.reduce((prev,curr)=>{
          if (curr.groundTemperature10mBelowTopOfPile > prev.groundTemperature10mBelowTopOfPile){
              return curr;
          }
          return prev;
      });
  }

  return (
    <SummaryPageTemplate
      id={id}
      mainTitle="Ground Temperature Prediction"
      topDisclaimerParagraph={`The ground temperatures were predicted using machine learning software based on given site and component details. 
        \n These predictions are generated with the most recent, validated data for geo-piles, list of assumptions and sources of error are provided in the attached appendix.
        `}
      explanationOfResultsParagraph={`Thermal performance of the system is predicted to reach the values presented in the following table. These are defined by the type of capacity supplied (as either heating or cooling), the total capacity to the building, and the thermal capacity per pile in the array. The Coefficient of Performance (COP) is also shown and reflects the efficiency of the heat pump based on eith heating or cooling. 
        `}
      summaryHighlightTopValue={getConvertedText(imperial, getTopGroundTemperature().groundTemperature10mBelowTopOfPile, 'degCEnv')}
      summaryHighlightTopLabel={"Ground Temperature 10m Below Top of Pile"}
      summaryHighlightBottomValue={getConvertedText(imperial, getTopGroundTemperature().changeInTemperatureAcrossPile, 'degCRel')}
      summaryHighlightBottomLabel={"Change in Fluid Temperature Across Pile"}
      summaryTableContent={
        <>
          <tr>
            <th>Session Name</th>
            <th>Load Type</th>
            <th>Deep Ground Temperature</th>
            <th>Ground Thermal Potential</th>
            <th>Change in Fluid Temperature (\u0394 T)</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr>
                <td>{session.calculator.name}</td>
                <td>
                  {(session.changeInTemperatureAcrossPile <=0) ? "Cooling" : "Heating"}
                </td>
                <td>{getConvertedText(imperial, session.groundTemperature10mBelowTopOfPile, 'degCEnv')}</td>
                <td>{getConvertedText(imperial, session.averageGeoPileThermalCapacity, 'W')}</td>
                <td>{getConvertedText(imperial,session.changeInTemperatureAcrossPile, 'degCRel')}</td>
              </tr>
            );
          })}
        </>
      }
      siteAndStudyBlurb={`These predictions are based on the following inputs for the site and study details. More details about the ground temperature inputs and ground thermal conductivity are provided in the appendix.`}
      siteAndStudyTableContent={
        <>
          <tr>
            <th>Session Name</th>
            <th>Ambient Air Temperature</th>
            <th>Average Ground Thermal Conductivity</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr>
                <th>{session.calculator.name}</th>
                <th>
                  {getConvertedText(imperial, session.calculator.climate?.ambientAirTemperature?.inputValue, 'degCEnv')}
                </th>
                <th>
                  {session.calculator.ground?.averageGroundThermalConductivity?.inputValue +' W/mK'}
                </th>
              </tr>
            );
          })}
        </>
      }
      componentDetailsBlurb={
        "These predictions are based on the following inputs for the component details. More details about the pile size and heat pump are provided in the appendix. Note that the number of piles reports the number of piles in the array that are on and contributing to the thermal capacity."
      }
      componentDetailsTableContent={
        <>
          <tr>
            <th>Session Name</th>
            <th>Fluid Temperature Into Pile</th>
            <th>Steel Pile Type</th>
            <th>Pile Length</th>
            <th>Pile Inlet Flow Rate</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr>
                <td>{session.calculator.name}</td>
                <td>
                  {getConvertedText(imperial, session.calculator.pileInFluid?.pileInletFluidTemperature?.inputValue, 'degCEnv')}
                </td>
                <td>
                  {
                    session.calculator.steelPile?.steelPileNominalSizeForClient
                      ?.inputValue
                  }
                </td>
                <td>
                    {getConvertedText(imperial, session.calculator.steelPile?.length?.inputValue, 'm')}
                </td>
                <td>
                  {getPileInletFlowRate(session.calculator,imperial)}
                </td>
              </tr>
            );
          })}
        </>
      }
    />
  );
};
export default GroundTemperatureReport;
