import { changeSecurityQuestion, getSecurityQuestion } from 'lib/api';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { GlobalContext } from './Contexts/Global';
import Button from './Reusables/Button';
import GeneralInput from './Reusables/GeneralInput';

const Container = styled.div`
    background: var(--c-black);
    width: var(--s-22);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
const WhiteContainer = styled.div`
    background: var(--c-green-very-light);
`;
const White = styled.div`
    color: var(--c-white);
`;
interface HiddenSecurityQuestionFormProps{

}
const HiddenSecurityQuestionForm: React.FunctionComponent<HiddenSecurityQuestionFormProps> =({})=> {
    const [currentPassword, setCurrentPassword] = useState<string>();
    const [currentSecurityQuestion, setCurrentSecurityQuestion] = useState<string>();
    const [currentAnswer, setCurrentAnswer] = useState<string>();
    const [newQuestion, setNewQuestion] = useState<string>();
    const [newAnswer, setNewAnswer] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const gc = useContext(GlobalContext);
    const history = useHistory();
    //onmount get current security question
    useEffect(()=>{
        //console.log('csq form mounted')
        if (!gc?.apiToken) {
            return;
        }
        //console.log('found security question from api');
        getSecurityQuestion(gc?.apiToken).then(data=>setCurrentSecurityQuestion(data));
    },[gc]);


function saveNewSecurityQuestion(e:any){
    e.preventDefault();
    if (!currentPassword || !currentAnswer || !newAnswer || !newQuestion){
        window.alert('Error: you must fill out all fields');
        return;
    }
    const shouldContinue = window.confirm('Are you sure you want to change your security question?');
    if(!shouldContinue) return;
    
    const result = changeSecurityQuestion(currentPassword, currentAnswer, newAnswer, newQuestion);
    if (!result){
        window.alert('Request failed. You probably entered the password or current security question answer incorrectly.');
        return;
    }
    else {
        setSuccess(true);
        setTimeout(()=>{
            history.push('/');
        },1000);
    }

}
if (success){
    return (
        <Container>
            <h1>SUCCESS! Changed your security question.</h1>
        </Container>
    );
}
return (
<Container>
    <Button onClick={()=>history.push('/')}>Main Menu</Button>
    <White>

    <h1>Change Security Question</h1>
    </White>
    <form>
    <WhiteContainer>

    <h4>Confirm Your Identity:</h4>
    <GeneralInput
        value={currentPassword}
        onChange={(e:any)=>setCurrentPassword(e.target.value)}
        label="Password:"
        type="password"
        />
    <h5>Current Security Question:</h5>
    <div>{currentSecurityQuestion}</div>
    <GeneralInput
        value={currentAnswer}
        onChange={(e:any)=>setCurrentAnswer(e.target.value)}
        label="Answer (case sensitive):"
        />
    </WhiteContainer>
    <WhiteContainer>

    <h4>Enter new Security Question and Answer:</h4>
    <GeneralInput
    value={newQuestion}
    onChange={(e:any)=>setNewQuestion(e.target.value)}
    label="New Security Question:"
    />
    <GeneralInput
    value={newAnswer}
    onChange={(e:any)=>setNewAnswer(e.target.value)}
    label="New Answer (case sensitive):"
    />
    <Button onClick={saveNewSecurityQuestion}>Save New Security Question</Button>
    </WhiteContainer>
    </form>
</Container>
);
}
export default HiddenSecurityQuestionForm;
