import { GlobalContext } from "components/Contexts/Global";
import InfoPopup from "components/Popups/InfoPopup";
import CheckBox from "components/Reusables/CheckBox";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import SectionContainer from "./SectionContainer";
import HelpIcon from "@material-ui/icons/Help";
import {
  BasicCols,
  InfoButton,
  InputInstr,
  LittleInstr,
  SecondaryInputInstr,
} from "./Styles";
import BulkPredCsvInstructions from "components/Instructions/BulkPredCsvInstructions";

interface ChecklistProps {}
const Checklist: React.FunctionComponent<ChecklistProps> = ({}) => {
  const gc = useContext(GlobalContext);
  const [buildingLoadCheck, setbuildingLoadCheck] = useState<boolean>(false);
  const [rowCheck, setrowCheck] = useState<boolean>(false);
  const [seasonCheck, setseasonCheck] = useState<boolean>(false);
  const [unitCheck, setunitCheck] = useState<boolean>(false);
  const [emptyCellCheck, setemptyCellCheck] = useState<boolean>(false);
  return (
    <SectionContainer title="7. Bulk Study Checklist">
      <InputInstr>
        Before calculating results, ensure the following items have been
        checked:
      </InputInstr>
      <BasicCols>
        <CheckBox
          checked={buildingLoadCheck}
          onClick={() => setbuildingLoadCheck((prev) => !prev)}
        />
        <SecondaryInputInstr>
          Building Loads, if any, have been normalized and converted to Watts
        </SecondaryInputInstr>
        <InfoButton
          onClick={() =>
            gc?.setPopup(<InfoPopup imageLink={"Target Building Load INFO.jpg"} />)
          }
        ></InfoButton>
      </BasicCols>
      <BasicCols>
        <CheckBox
          checked={rowCheck}
          onClick={() => setrowCheck((prev) => !prev)}
        />
        <SecondaryInputInstr>
          All rows in the file are separated by the same length of time
        </SecondaryInputInstr>
        <InfoButton
          onClick={() =>
            gc?.setPopup(<InfoPopup imageLink={"Bulk Date and Time INFO.jpg"} />)
          }
        ></InfoButton>
      </BasicCols>
      <BasicCols>
        <CheckBox
          checked={seasonCheck}
          onClick={() => setseasonCheck((prev) => !prev)}
        />
        <SecondaryInputInstr>
          Values of WhpT, WgheT, or AWgheT are negative for heating mode and
          positive for cooling mode
        </SecondaryInputInstr>
        <InfoButton
          onClick={() =>
            gc?.setPopup(<InfoPopup imageLink={"Target Building Load INFO.jpg"} />)
          }
        ></InfoButton>
      </BasicCols>
      <BasicCols>
        <CheckBox
          checked={unitCheck}
          onClick={() => setunitCheck((prev) => !prev)}
        />
        <SecondaryInputInstr>
          All csv input variables are entered in required units (click the "?" button to the right to see full csv instructions).
        </SecondaryInputInstr>
        <InfoButton
          onClick={() =>
            gc?.setPopup(<BulkPredCsvInstructions closeCallback={()=>gc.setPopup(undefined)}/>)
          }
        ></InfoButton>
      </BasicCols>
    </SectionContainer>
  );
};
export default Checklist;
