import Popup from 'components/Reusables/Popup';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--c-white);
    width: 20vw;
    height: 20vh;
    /* margin-top: 30%; */
    text-align: center;
`;
interface GTPResultProps{
    onClose: any
    result: string | undefined
}
const GTPResult: React.FunctionComponent<GTPResultProps> =({
    onClose, result
})=> {

return (
<Popup 
closeCallback={onClose}
title="Result"
>
    <Container>
Predicted Ground Temperature: {result} °C
</Container>
</Popup>
);
}
export default GTPResult;
