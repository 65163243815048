import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled, { css } from 'styled-components';

const Both = css`
    border-radius: 100%;
    width: var(--s-10);
    height: var(--s-10);
    cursor: pointer;
    :hover {
        background: var(--c-green-very-light);
    }
`;

const Unchecked = styled.div`
    ${Both}
    background: var(--c-white);
`;
const Checked = styled.div`
    ${Both}
    background: var(--c-green);
`;

interface CheckBoxProps{
    checked: boolean;
    onClick: () => void
}
const CheckBox: React.FunctionComponent<CheckBoxProps> =({
    onClick, checked
})=> {

    if (checked){
        return (
            <Checked onClick={onClick}/>
        );
    }
    else return (
        <Unchecked onClick={onClick}/>
    );
}
export default CheckBox;
