import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { GTPInputsForExternalAPI } from "components/Popups/GTPBulkPredictions";
import { createNonFatalHttpRequestErrorMessage } from "./ErrorAndMessageHandling/ErrorHandler";
import { Message } from "./ErrorAndMessageHandling/MessageHandler";
import { ICalculator, ICalculatorOut } from "./models";
import { BulkPredictionDTO } from "./models/bulk-prediction-dto";
import { BulkHeatPumpCapacityResult } from "./models/bulk-prediction-results";
import {
  FrontendErrorMessage,
  getFrontendErrorMessageFromMessage,
} from "./models/frontend-error-message";
import { ApiEndpoints } from "./types";

const UmnyBackendForGTP = "https://test-api.umnysoil.com/";
// const UmnyBackendForGTP = 'http://localhost:4000';

function makeStringSafeForURL(input: string) {
  return input.replace("/", "%2F");
}

export async function isValidPassword(password: string, baseURL: string) {
  return await (
    await axios.get("password", { headers: { authorization: password } })
  ).data;
}
export async function getSecurityQuestion(password: string) {
  return await (
    await axios.get("password", { headers: { authorization: password } })
  ).data;
}
export async function changePassword(
  oldPassword: string,
  newPassword: string,
  securityQuestionAnswer: string
): Promise<boolean> {
  const body = {
    NewPassword: newPassword,
    SecurityQuestionAnswer: securityQuestionAnswer,
  };
  return await (
    await axios.post("password", body, {
      headers: { authorization: oldPassword },
    })
  ).data;
}
export async function changeSecurityQuestion(
  password: string,
  oldAnswer: string,
  newAnswer: string,
  newQuestion: string
): Promise<boolean> {
  const body = {
    CurrentAnswer: oldAnswer,
    NewQuestion: newQuestion,
    NewAnswer: newAnswer,
  };
  return await (
    await axios.post("securityquestion", body, {
      headers: { authorization: password },
    })
  ).data;
}

export async function deleteFromApi<ResponseType>(
  endpoint: ApiEndpoints,
  name: string,
  password: string
): Promise<ResponseType> {
  return await (
    await axios.delete(`${endpoint}/${makeStringSafeForURL(name)}`, {
      headers: { authorization: password },
    })
  ).data;
}
export async function saveToApi<ResponseType>(
  endpoint: ApiEndpoints,
  obj: any,
  password: string
): Promise<ResponseType> {
  //console.log('save to api called');
  const response = await (
    await axios.post(endpoint, obj, { headers: { authorization: password } })
  ).data;
  //console.log('saveToApi returned with',response, (!response));
  return response;
}
export async function putToApi<ResponseType>(
  endpoint: ApiEndpoints,
  obj: any,
  password: string
): Promise<ResponseType> {
  return await await axios.put(`${endpoint}`, obj, {
    headers: { authorization: password },
  });
}
export async function saveFileToApi<ResponseType>(
  endpoint: string,
  obj: File,
  password: string
): Promise<ResponseType> {
  if (!obj) throw new Error("must provide a file to send");
  //console.log('file to send...', obj);
  const form = new FormData();
  form.append("file", obj);
  return await (
    await axios.post(endpoint, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: password,
      },
    })
  ).data;
}
export async function putMLModelAsActive<ResponseType>(
  endpoint: ApiEndpoints,
  id: number,
  password: string
): Promise<ResponseType> {
  return await (
    await axios.put(
      `${endpoint}/${id}`,
      {},
      { headers: { authorization: password } }
    )
  ).data;
}
export async function getFromApi<ResponseType>(
  endpoint: ApiEndpoints,
  password: string
): Promise<ResponseType> {
  return await (
    await axios.get(endpoint, { headers: { authorization: password } })
  ).data;
}
export async function getCalculationResult<ResponseType>(
  endpoint: ApiEndpoints,
  calc: ICalculatorOut | ICalculator,
  password: string
): Promise<ResponseType> {
  return await (
    await axios.post(endpoint, calc, { headers: { authorization: password } })
  ).data;
}

//! FOR GTP PREDICTIONS
export interface DemoInputs {
  latitude: number;
  longitude: number;
  depth: number;
  day: number;
  month: number;
}

export async function getGTPResult(
  inputs: DemoInputs
): Promise<number | string> {
  return new Promise(async (resolve, reject) => {
    // return axios
    //   .request(createJson1Options(inputs, getGTPKeyFromEnvironment()))
    //   .then(function (response: AxiosResponse<any[]>) {
    //     if (response.status >= 400) {
    //       console.error("API request returned error code: ", response.status);
    //       return "Error " + response.status;
    //     }
    //     return parseFloat(response.data[0]);
    //   })
    //   .catch(function (error: any) {
    //     console.error(error);
    //     return error as string;
    //   });
    let jobID = await requestStartBulkGTPPredictionAndGetJobId([
      {
        Lat: inputs.latitude,
        Long: inputs.longitude,
        Day: inputs.day,
        Month: inputs.month,
        Depth: inputs.depth,
        Year: 2023,
      },
    ]);
    if (typeof jobID === "string") {
      jobID = String(jobID);
    }
    // set up interval and don't return until its done, with promise
    const maxTimeTrying = 1000 * 20; // 20 seconds
    const startTime = Date.now();

    while (maxTimeTrying > Date.now() - startTime) {
      console.log("checking status of jobID", jobID);
      const result =
        await checkBulkGTPPredictionStatusAndGetProgressResultOrError(
          jobID as string
        );
      if (result && Array.isArray(result) && result.length) {
        return resolve(Number(result[0].Prediction));
      }
      console.log("waiting");
      // wait 1 second
      await new Promise((resolve2) => setTimeout(resolve2, 1000));
    }
  });
}

function getGTPKeyFromEnvironment() {
  ////console.log('key is ',process.env.RAPIDAPI_FREE_KEY)
  // return process.env.REACT_APP_RAPIDAPI_INNOVIA_KEY || 'key not found';
  return process.env.REACT_APP_FOR_INNOVIA_ONLY_GTP || "key not found";
}
// function createJson1Options(
//   body: DemoInputs,
//   apiKey: string
// ): AxiosRequestConfig {
//   return {
//     method: "POST",
//     url: "https://predicting-ground-temperatures.p.rapidapi.com/predictions/ground-temperatures/json/1",
//     url: "https://predicting-ground-temperatures.p.rapidapi.com/predictions/ground-temperatures/json/1",

//     headers: {
//       "content-type": "application/json",
//       "x-rapidapi-key": apiKey,
//       "x-rapidapi-host": "predicting-ground-temperatures.p.rapidapi.com",
//     },
//     data: body,
//   };
// }

//! for bulk GTP results
export async function requestStartBulkGTPPredictionAndGetJobId(
  gtpInputs: GTPInputsForExternalAPI[]
): Promise<string | Message> {
  console.log("requestStartBulkGTPPredictionAndGetJobId", gtpInputs);
  return axios
    .request({
      method: "POST",
      // "url":"https://predicting-ground-temperatures.p.rapidapi.com/predictions/ground-temperatures/json/bulk",
      url: UmnyBackendForGTP + "predictions/v1p5-Apr3/asynchronous/start",
      headers: {
        "content-type": "application/json",
        gpps_innovia_key: getGTPKeyFromEnvironment(),
        useQueryString: true,
      },
      data: {
        inputs: gtpInputs,
      },
    })
    .then(function (response: AxiosResponse<string>) {
      if (response.status >= 400) {
        return new Message(
          createNonFatalHttpRequestErrorMessage(
            "Start Bulk GTP Predictions",
            response.status,
            response.statusText
          )
        );
      }
      return response.data;
    })
    .catch(function (error: Error) {
      return new Message(
        createNonFatalHttpRequestErrorMessage(
          "Start Bulk GTP Predictions",
          500,
          error.message + error.stack
        )
      );
    });
}

const jobStatuses = [
  "Running",
  "Completed",
  "Failed",
  "CancelledWhileRunning",
  "Stale",
] as const;

export type JobStatus = typeof jobStatuses[number];
interface CheckBulkGTPPredictionResponse {
  secureID: string;
  ipAddress: string;
  rapidAPIUser: string;
  versionID: "Pre_Apr3_23" | "Apr3_23";
  receivedAt: string;
  requestedPredictionCount: number;
  status: JobStatus;
  errors: string[] | undefined | null;
  results: SingleResponseSchema[] | undefined | null;
}

export interface SingleResponseSchema {
  Prediction: number;
  IsInAnyWater: boolean;
  IsInDeepWater: boolean;
  Lat: number;
  Long: number;
  Depth: number;
  Day: number;
  Month: number;
  Year: number;
}

export async function checkBulkGTPPredictionStatusAndGetProgressResultOrError(
  gtpTaskId: string
): Promise<number | SingleResponseSchema[] | Message> {
  return axios
    .request({
      method: "GET",
      // url: "https://predicting-ground-temperatures.p.rapidapi.com/predictions/ground-temperatures/json/bulk/retrieve",
      url: UmnyBackendForGTP + "predictions/v1p5-Apr3/asynchronous/retrieve",
      headers: {
        "content-type": "application/octet-stream",
        gpps_innovia_key: getGTPKeyFromEnvironment(),
        useQueryString: true,
      },
      params: {
        id: gtpTaskId,
      },
    })
    .then(function (response: AxiosResponse<CheckBulkGTPPredictionResponse>) {
      if (response.status >= 400) {
        return new Message(
          createNonFatalHttpRequestErrorMessage(
            "Retrieve/Check Bulk GTP Predictions",
            response.status,
            response.statusText
          )
        );
      }
      if (response.data.results && response.data.results.length > 0) {
        return response.data.results;
      } else {
        return 0;
      }
    })
    .catch(function (error: Error) {
      return new Message(
        createNonFatalHttpRequestErrorMessage(
          "Retrieve/Check Bulk GTP Predictions",
          500,
          error.message + error.stack
        )
      );
    });
}

// ! For bulk predictions...
export async function requestStartBulkPrediction(
  dto: BulkPredictionDTO,
  password: string
): Promise<number | string> {
  const endpoint = "bulkHeatPumpCapacityFromCSV/Initialize";
  return await (
    await axios.post(endpoint, dto, {
      headers: {
        authorization: password,
        // 'Content-Type': 'multipart/form-data',
        "content-type": "application/json",
        // 'content-type': undefined
      },
    })
  ).data;
}
export async function addCsvToBulkPrediction(
  csv: File,
  studyName: string,
  password: string
): Promise<number | string> {
  //! DEBUG
  // console.warn("HERE")
  // console.log('csv is ',JSON.stringify(await csv.text()))
  const endpoint = "bulkHeatPumpCapacityFromCSV/ProvideCsv/" + studyName;
  const form = new FormData();
  form.append("csv", csv);
  //! DEBUG
  // console.log('form is ',JSON.stringify(form.get('csv')))
  return await (
    await axios.post(endpoint, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: password,
      },
    })
  ).data;
}
export async function checkBulkPredictionStatus(
  studyName: string,
  password: string
): Promise<number | string> {
  const endpoint = "bulkHeatPumpCapacityFromCSV/ProvideCsv/" + studyName;
  return await (
    await axios.get(endpoint, {
      headers: {
        authorization: password,
        // 'Content-Type': 'multipart/form-data',
        // 'content-type': 'multipart/form-data'
        // 'content-type': undefined
      },
    })
  ).data;
}

// export interface bulkPredictionStatusReport {
// results?: any
// progress?: number
// error?: string
// }
export async function fetchBulkPrediction(
  studyName: string,
  password: string
): Promise<BulkHeatPumpCapacityResult> {
  //TODO generate bulk prediction fetch endpoint based on study
  const endpoint = "bulkHeatPumpCapacityFromCSV/Retrieve/" + studyName;
  return await (
    await axios.get(endpoint, { headers: { authorization: password } })
  ).data;
}

export async function logMessageToDb(
  message: Message,
  password: string
): Promise<string> {
  const messageDto = getFrontendErrorMessageFromMessage(message);
  const endpoint = "FrontendErrorLogging";
  return await (
    await axios.post(endpoint, messageDto, {
      headers: { authorization: password },
    })
  ).data;
}
