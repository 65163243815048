import { Schema } from "ajv";
import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble, UiInputVariableDoubleSchema } from "./ui-input-variable-double";
import { convertUiInputStringToDto, IUiInputVariableString, IUiInputVariableStringOut, UiInputVariableString, UiInputVariableStringSchema } from "./ui-input-variable-string";

export interface ISteelPile extends IBaseModel {
  steelPileNominalSizeForClient?: IUiInputVariableString | null;

  length?: IUiInputVariableDouble | null;
}
export interface ISteelPileOut extends IBaseModel {
  steelPileNominalSizeForClient?: IUiInputVariableStringOut;
  length?: IUiInputVariableDoubleOut;
}
export class SteelPile extends BaseModel implements ISteelPile{

  public constructor(possibleValues: string[]){
    super();
    this.steelPileNominalSizeForClient = new UiInputVariableString('steelPileNominalSize', possibleValues);
  }

  public steelPileNominalSizeForClient?: IUiInputVariableString = new UiInputVariableString('steelPileNominalSize',[
    'not loaded', 
  ]);

  public length?: IUiInputVariableDouble = new UiInputVariableDouble('steelPileLength');
}

export const SteelPileSchema: Schema = {
  type: 'object',
  properties: {
    steelPileNominalSizeForClient: UiInputVariableStringSchema(undefined),
    length: UiInputVariableDoubleSchema(4, 100)
  },
  required: ['steelPileNominalSizeForClient'],
  additionalProperties: true
}

export function steelPileToDto(base: ISteelPile): ISteelPileOut{
  return {
    id: base.id,
    name: base.name,
    length: convertUiInputDoubleToDto(base.length),
    steelPileNominalSizeForClient: convertUiInputStringToDto(base.steelPileNominalSizeForClient)
  }
}