//! process the raw results
//! generate a csv and download it

import {
  GTPInputs,
  GTPResultSingle,
} from "components/Popups/GTPBulkPredictions";
import { forEach, i } from "mathjs";
import { exportToCsv } from "./export-to-csv";
import { SingleResponseSchema } from "./api";

export function processBulkGTPResultsAndDownloadCSV(
  gtpResults: SingleResponseSchema[],
  gtpQueue: GTPInputs[],
  listOfCsvInputRowDatetimes: Date[]
) {
  const groundTempCalculator = new GroundTempCalculator(gtpResults, gtpQueue);
  //output csv with 4 cols, left is datetime, then the ground temps
  const listOfListsForCSV = [
    [
      "Date_Time",
      "Tground0",
      "Tground5",
      "Tground10",
      "Pile Top Depth",
      "Latitude",
      "Longitude",
    ],
  ];
  const pileTopDepth = gtpQueue[0].pileTopDepth;
  let lat = gtpQueue[0].lat;
  let long = gtpQueue[0].long;
  if (typeof lat !== "number") {
    lat = parseFloat(lat) || 0;
  }
  if (typeof long !== "number") {
    long = parseFloat(long) || 0;
  }
  const csvFileName = `BulkGTP-pileTopAt${pileTopDepth}m-lat${lat.toFixed(
    2
  )}-long${long.toFixed(2)}.csv`;

  //!generate actual content for csv
  const dataByDatetime: {
    [dateString: string]: {
      Tground0: undefined | number;
      Tground5: undefined | number;
      Tground10: undefined | number;
      pileTopDepth: number;
      latitude: number;
      longitude: number;
    };
  } = {};
  listOfCsvInputRowDatetimes.forEach((q) => {
    const dateString = q.toISOString();
    dataByDatetime[dateString] = {
      Tground0: groundTempCalculator.autoGetGroundTemperature(0, q),
      Tground5: groundTempCalculator.autoGetGroundTemperature(5, q),
      Tground10: groundTempCalculator.autoGetGroundTemperature(10, q),
      pileTopDepth: pileTopDepth,
      latitude: lat,
      longitude: long,
    };
  });
  Object.keys(dataByDatetime).forEach((datetimeString) => {
    const o = dataByDatetime[datetimeString];
    listOfListsForCSV.push([
      datetimeString,
      o.Tground0?.toString() || "",
      o.Tground5?.toString() || "",
      o.Tground10?.toString() || "",
      o.pileTopDepth.toString() || "",
      o.latitude.toString() || "",
      o.longitude.toString() || "",
    ]);
  });
  exportToCsv(csvFileName, listOfListsForCSV);
}

class GroundTempCalculator {
  private standardYear = 2020; //TODO
  private gtpResultsFor0M: GTPResultSingle[] | undefined;
  private gtpResultsFor5M: GTPResultSingle[] | undefined;
  private gtpResultsFor10M: GTPResultSingle[] | undefined;
  constructor(gtpResults: SingleResponseSchema[], gtpQueue: GTPInputs[]) {
    console.log("constructor of GroundTempCalculator ", gtpResults, gtpQueue);
    this.gtpResultsFor0M = gtpQueue
      .map((gtpInputs, i) => {
        if (gtpInputs.predictionDepth - gtpInputs.pileTopDepth === 0) {
          return {
            date: gtpQueue[i].datetime,
            temperature: gtpResults[i].Prediction,
          };
        } else {
          return undefined;
        }
      })
      .filter((x) => x !== undefined) as GTPResultSingle[];
    this.gtpResultsFor5M = gtpQueue
      .map((gtpInputs, i) => {
        if (gtpInputs.predictionDepth - gtpInputs.pileTopDepth === 5) {
          return {
            date: gtpQueue[i].datetime,
            temperature: gtpResults[i].Prediction,
          };
        } else {
          return undefined;
        }
      })
      .filter((x) => x !== undefined) as GTPResultSingle[];
    this.gtpResultsFor10M = gtpQueue
      .map((gtpInputs, i) => {
        if (gtpInputs.predictionDepth - gtpInputs.pileTopDepth === 10) {
          return {
            date: gtpQueue[i].datetime,
            temperature: gtpResults[i].Prediction,
          };
        } else {
          return undefined;
        }
      })
      .filter((x) => x !== undefined) as GTPResultSingle[];

    console.log(
      "results by depth",
      this.gtpResultsFor0M,
      this.gtpResultsFor5M,
      this.gtpResultsFor10M
    );
  }
  public autoGetGroundTemperature(depthPosition: number, dateTimeOfYear: Date) {
    switch (depthPosition) {
      case 0:
        if (!this.gtpResultsFor0M || this.gtpResultsFor0M?.length < 1)
          return undefined;
        break;
      case 5:
        if (!this.gtpResultsFor5M || this.gtpResultsFor5M?.length < 1)
          return undefined;
        break;
      case 10:
        if (!this.gtpResultsFor10M || this.gtpResultsFor10M.length < 1)
          return undefined;
        break;
    }
    const allResultsForThisDepth: GTPResultSingle[] =
      depthPosition == 0
        ? this.gtpResultsFor0M!
        : depthPosition == 5
        ? this.gtpResultsFor5M!
        : this.gtpResultsFor10M!;
    const exactMatches = allResultsForThisDepth.filter((res) => {
      if (dateTimeOfYear.getTime() === res.date.getTime()) {
        return true;
      }
      return false;
    });
    if (exactMatches.length) {
      return exactMatches[0].temperature;
    }
    //rare case, but just use the value directly
    const slopeAndIntercept = this.getGTPSlopeAndInterceptAtGivenTime(
      depthPosition,
      dateTimeOfYear
    );
    console.log(dateTimeOfYear.toISOString(), dateTimeOfYear.getTime());
    const result =
      slopeAndIntercept.slope * dateTimeOfYear.getTime() +
      slopeAndIntercept.yIntercept;
    console.log("temp result = ", result);
    return result;
  }
  private getGTPSlopeAndInterceptAtGivenTime(
    depthPosition: number,
    dateTimeOfYear: Date
  ) {
    //! why would I standardize the year
    //! if I do I have to add code to standardize all other datetime years below
    //standardize the year of the given datetime
    // dateTimeOfYear = new Date(
    //   this.standardYear,
    //   dateTimeOfYear.getMonth(),
    //   dateTimeOfYear.getDate(),
    //   dateTimeOfYear.getHours(),
    //   dateTimeOfYear.getMinutes(),
    //   dateTimeOfYear.getSeconds()
    // );
    console.log("in getGTPSlopeAndInterceptAtGivenTime");
    console.log("date time to use = ", dateTimeOfYear);
    // get the next-lowest datetime associated with a real GTP prediction
    const allResultsForThisDepth: GTPResultSingle[] =
      depthPosition == 0
        ? this.gtpResultsFor0M!
        : depthPosition == 5
        ? this.gtpResultsFor5M!
        : this.gtpResultsFor10M!;
    console.log(
      "all results for this depth ",
      depthPosition,
      allResultsForThisDepth
    );
    let nextHighest: GTPResultSingle | undefined = undefined;

    // get the next-HIGHEST datetime associated with a real GTP prediction
    let nextLowest: GTPResultSingle | undefined = undefined;
    allResultsForThisDepth.forEach((res) => {
      const rT = res.date.getTime();
      //check if should be next highest
      const isLater = rT > dateTimeOfYear.getTime();
      if (nextHighest === undefined) {
        if (isLater) {
          nextHighest = res;
        }
      } else {
        const isEarlierThanNextHighest = rT < nextHighest.date.getTime();
        if (isLater && isEarlierThanNextHighest) {
          nextHighest = res;
        }
      }

      //check if should be next lowest
      const isEarlier = rT < dateTimeOfYear.getTime();
      if (nextLowest === undefined) {
        if (isEarlier) {
          nextLowest = res;
        }
      } else {
        const isLaterThanNextLowest = rT > nextLowest.date.getTime();
        if (isEarlier && isLaterThanNextLowest) {
          nextLowest = res;
        }
      }
      // console.log('for ',res, nextHighest, nextLowest,' is LaterThanNextLowes = ',isLaterThanNextLowest, ' isEarlierThanNextHighest', isEarlierThanNextHighest);
      console.log(
        "is earlier than reference time ",
        isEarlier,
        "is later than reference time",
        isLater
      );
    });
    console.log("next highest and next lowest =", nextHighest, nextLowest);
    if (nextHighest === undefined) {
      nextHighest = allResultsForThisDepth[0];
    }
    if (nextLowest === undefined) {
      nextLowest = allResultsForThisDepth[0];
    }
    const slope =
      (nextHighest.temperature - nextLowest.temperature) /
      (nextHighest.date.getTime() - nextLowest.date.getTime());
    console.log("slope = ", slope);
    const y = nextHighest.temperature;
    const x = nextHighest.date.getTime();
    const yIntercept = y - slope * x;
    //y = mx + b
    // b = y - mx
    console.log("y intercept = ", yIntercept);
    return { slope: slope, yIntercept: yIntercept };
  }
}
// private getAllGTPResultsForThisDepth(depthBelowPile: number, GTPQueue: GTPInputs[]): GTPResultSingle[]
// {
//     return GTPQueue.filter(gtpRes => {
//       const differenceToDesiredDepth = Math.abs((gtpRes.predictionDepth - gtpRes.pileTopDepth) - depthBelowPile);
//       return (differenceToDesiredDepth < 1);
//     }).map(gtpRes => {
//       return {
//         date: new Date(standardYear, gtpRes.month, gtpRes.day),
//         temperature: gtpRes.predictedTemperature || 0
//       }
//     });
// }
