import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styles from "styles/InputSection.module.scss";

import GoogleMapReact from "google-map-react";
import { usePrevious } from "lib/usePrevious";
import LocationPin from "components/Reusables/LocationPin";

interface LocationInputProps {
  lat: number;
  long: number;
  setLat: any;
  setLong: any;
}
const LocationInput: React.FunctionComponent<LocationInputProps> = ({
  lat,
  long,
  setLat,
  setLong,
}) => {
  function handleApiLoaded(maps: { map: any; maps: any; ref: Element | null }) {
    maps.map.addListener("click", (event: any) => {
      handleMapClick2({
        x: event.pixel.x,
        y: event.pixel.y,
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    });
  }
  // function handleMapClick({x, y, lat, lng, event}, force){
  //  //console.log('==> handleMapClick(..) : ' + lat + ',' + lng + ' (x=' + x + ',y=' + y + ')');
  //   }
  function handleMapClick2({ x, y, lat, lng }: any) {
    //console.log('==> handleMapClick2(..) : ' + lat + ',' + lng + ' (x=' + x + ',y=' + y + ')');
  }
  // function handleApiLoaded({ map, maps }){
  // });
  // }

  console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  return (
    <>
      <div className={styles.graphicalSection + " productTour3"}>
        <div className={styles.googleMap + " remove-div-styling"}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
            }}
            // defaultCenter={defaultLocation}
            defaultZoom={3}
            center={{ lat: lat, lng: long }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={handleApiLoaded}
            onClick={(e) => {
              //console.log(e)
              setLat(e.lat);
              setLong(e.lng);
            }}
            options={{ draggableCursor: "crosshair" }}
          >
            <LocationPin
              lat={lat}
              lng={long}
              //   text={location.address}
            />
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
};
export default LocationInput;
