import {unit} from 'mathjs';

export const lengthUnits = [
    'm','cm','mm','ft','in','yd'
];
export const tempUnits= [
    'degC', 'degF', 'K'
];
export const flowRateUnits=[
    'm3/s','cc/mins','cuft/s', 'gal/mins', 'L/mins'
];

// export function convertInterval(startVal:number, startUnit:string, endUnit:string){//must be handled differently than regular conversions, offset must be removed
//     const conversionType = startUnit+endUnit;
//     switch(conversionType){
//         case "degFdegC": return (5.0/9.0)*startVal;
//         case "degFK": return (5.0/9.0)*startVal;
//         case "KdegF": return (9.0/5.0)*startVal;
//         case "KdegC": return startVal;
//         case "degCK":   return startVal;
//         case "degCdegF": return (9.0/5.0)*startVal;
//         default:
//             return unit(startVal,startUnit).toNumber(endUnit);
//     }
// }

export function getAllowedUnitsFromBaseUnit(unit: string): string[] | undefined {
    switch (unit) {
      case "degC":
        return tempUnits;
      case "m":
        return lengthUnits;
      case "m3/s":
        return flowRateUnits;
      default:
        return undefined;
    }
  }

  export function convertMetreToCentimetre(value?: number){
    if (!value)return 0;
    return value * 100;
  }
  export function convertFlowRateToLPM(value: number|undefined){
    if (!value) return 0;
    return value * 60000;
  }

  export type ConvertableUnits = 'W' |'m'|'m3/s'|'cm'|'l/m'|'degCEnv'|'degCRel'|'W/m';
  const convertableUnitsMap = {
    'W': ['W','tons'],
    'm': ['m','ft'],
    'm3/s': ['m3/s','gpm'],
    'cm': ['cm','in'],
    'l/m': ['l/min','gpm'],
    'degCEnv': ['\u00B0C','\u00B0F'],
    'degCRel': ['\u00B0C','\u00B0F'],
    'W/m': ['W/m', 'ton/ft']
  }

  export function convertableUnitsToReadable(metricUnit: ConvertableUnits, imperial = false){
    if (imperial){
      return convertableUnitsMap[metricUnit][1];
    }else {
      return convertableUnitsMap[metricUnit][0];
    }
  }

  export function convertToImperial(baseUnit: ConvertableUnits, value: number|undefined){
    if (!value) return 0;
    return simpleUnitConversions[baseUnit](value);
  }

  const simpleUnitConversions = {
    W: (val:number) => val * 0.0002843,
    m: (val:number) => val * 3.28084,
    'm3/s':(val: number) => val * 60000,
    cm: (val:number) => val * 0.393701,
    'l/m': (val: number) => val / 60000,
    'degCEnv': (val: number) => 32.0 + (val * (9.0/5.0)),
    'degCRel': (val: number) => val * (9.0/5.0),
    'W/m': (val: number) => val * (0.0002843/3.28084),
  };

  export function prettifyTempUnit(unit?: string){
    // //console.log("attempting to prettify unit", unit);
    if (unit == 'degC' || unit == ' degC' || unit =='degC ' || unit == 'C'){
      return '\u00B0C';
    }
    if (unit == 'degF' || unit == ' degF' || unit =='degF '){
      return '\u00B0F';
    }
    return unit;
  }