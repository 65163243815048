import { GlobalContext } from 'components/Contexts/Global';
import Button from 'components/Reusables/Button';
import GeneralInput from 'components/Reusables/GeneralInput';
import Popup from 'components/Reusables/Popup';
import { changePassword, getSecurityQuestion, isValidPassword } from 'lib/api';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Form = styled.form`
    background: var(--c-green-very-light);
    padding: var(--s-8);
    margin: var(--s-5);
`;

interface ChangePasswordProps{

}
const ChangePassword: React.FunctionComponent<ChangePasswordProps> =({})=> {
    const [securityQuestion, setSecurityQuestion] = useState<string>();
    const [answer,setAnswer] = useState<string>();
    const [newPassword, setNewPassword] = useState<string>();
    const [oldPassword, setOldPassword] = useState<string>();
    const [wasSuccessful, setWasSuccessful] = useState<boolean>();
    const gc = useContext(GlobalContext);
    //on mount, get security question from backend
    useEffect(()=>{
        if (!gc?.apiToken) {
            return;
        }
        getSecurityQuestion(gc?.apiToken).then(data=>setSecurityQuestion(data));
    },[gc]);

    function handleChangePasswordClick(e:any){
        e.preventDefault();
        //use the supplied password instead of standard auth token on this request, for extra security
        if (!oldPassword || !newPassword || !answer){
            window.alert('Error: You must fill out all fields to change the password');
            return;
        }
        //validate password strength
        // must be at least 8 characters
        if (newPassword.length <8 ){
            window.alert('Error: Your new password must be at least 8 characters long');
            return;
        }
        if (newPassword.length > 30){
            window.alert('Error: Your new password cannot be longer than 30 characters');
        }
        //must include a capital letter, lowercase letter, and number
        const digitCheck = new RegExp(/\d/);
        const capitalCheck = new RegExp(/[A-Z]/);
        const lowercaseCheck = new RegExp(/[a-z]/);
        if (!digitCheck.test(newPassword) || !capitalCheck.test(newPassword) || !lowercaseCheck.test(newPassword)){
            window.alert('Error: Your new password must contain a lowercase letter, capital letter and a number');
            return;
        }
        const success = changePassword(oldPassword,newPassword,answer);
        if (!success) {
            window.alert('Error: The information you provided was not correct, the password is unchanged.');
            return;
        } else {
            setWasSuccessful(true);
            setTimeout(()=>{
                gc?.setPopup(undefined);
                gc?.logout();
            },1000); 
        }
    }
if (wasSuccessful) {
    return (
        <Popup 
        title="Change Password"
        closeCallback={()=>gc?.setPopup(undefined)}
        >
            <h1>Successfully Changed Password</h1>
        </Popup>
    )
}
return (
<Popup
title={"Change Password"}
    closeCallback={()=>gc?.setPopup(undefined)}
>
    <Form>

    <GeneralInput
    value={oldPassword}
    onChange={(e:any)=>setOldPassword(e.target.value)}
    label="Current Password:"
    type="password"
    />
    <GeneralInput
    value={newPassword}
    onChange={(e:any)=>setNewPassword(e.target.value)}
    label="New Password:"
    />
    <h4>Security Question:</h4>
    {securityQuestion?
    <>
    <div>{securityQuestion}</div>
    <GeneralInput
    value={answer}
    onChange={(e:any)=>setAnswer(e.target.value)}
    label="Answer (case sensitive):"
    />
    </>
    :<div>Loading...</div>}
    <Button onClick={handleChangePasswordClick}>Save New Password</Button>
    </Form>
</Popup>
);
}
export default ChangePassword;
