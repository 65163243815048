export const config = {
    currentBulkPredNameLocalStorageKey : 'innovia-gpps-current-bulk-prediction-name,',
    productionURL: 'https://gpps-api.innoviageo.com/api/',
    // productionURL: 'https://localhost:44301/api/',
    developmentURL: 'https://gpps-api.innoviageo.com/api/',
    // developmentURL: 'https://localhost:44301/api/',
    apiTokenSessionStorageKey: 'innovia-gpps-api-key',
    currentBulkPredMessageIdStorageKey: 'innovia-gpps-current-bulk-prediction-message-id',
    currentBulkGTPNameLocalStorageKey: 'innovia-gpps-current-bulk-gtp-name',
    currentBulkGTPMessageIdStorageKey: 'innovai-gpps-current-bulk-gtp-message-id',
} as const;