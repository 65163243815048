import { ChartData } from "chart.js/auto";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Chart from "react-chartjs-2";


export interface LineChartData {
  data: number[]
  label: string
  color: string
  barOverride?: true
}
export interface TripleLineChartProps {
  xAxisLabels: string[];
  lines: LineChartData[]
  title?: string
  forSmallDisplay?: boolean
}
const TripleLineChart: React.FunctionComponent<TripleLineChartProps> = ({
  xAxisLabels,
  lines,
  title,
  forSmallDisplay
}) => {
  const data = {
    labels: xAxisLabels,
    datasets: lines.map(lineData => ({
      type: lineData.barOverride?"bar" as const: "line" as const,
      label: lineData.label,
      borderColor: lineData.color,
      borderWidth: forSmallDisplay?2:1,
      backgroundColor: lineData.barOverride?lineData.color:undefined,
      fill: lineData.color,
      data: lineData.data,
      radius: forSmallDisplay?0.1:0.4
    }))
  };

  const forSmallDisplayOptions = {
    animation: {
      duration: 0
    }
  }

  return (
    <>
    {title?
      <h3>{title}</h3>
    :<></>}
      <Chart
      // height={100}
      type="line" 
      data={data} 
      options={forSmallDisplay?{
        ...forSmallDisplayOptions,
        devicePixelRatio: 6,
      }:{
        devicePixelRatio: 6,
      }}
      />
    </>
  );
};
export default TripleLineChart;
