import { getPercentage, trimDecimals } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IHeatPumpCapacityResults, IPileLengthDynamicReturnType, IPileLengthResultsWithHeatPump } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import SummaryPageTemplate, { TD, TDGreen, THGreen } from "./SummaryPageTemplate";
import ReportTemplate, { getConvertedText, getPileInletFlowRate, H1, H4 } from "../Template";
import { convertFlowRateToLPM } from "lib/units";

export interface SessionData {
  name: string;
  heatingOrCooling: "Heating" | "Cooling";
  wholeArrayHeatPumpCapacity: number;
  averageGeoPileThermalCapacity: number;
  heatPumpCoefficientOfPerformance: number;
  targetWholeArrayHeatPumpCapacity: number;
  numberOfActivePilesInTheArray: number;
  steelPileLength: number;
  steelPileNominalSize: string;
  plasticPipeNominalSize: string;
  pileInletFlowRate: number;
  heatPumpName: string;
  groundThermalConductivityMean: number;
  groundTemperature10mBelowTopOfPile: number;
  ambientAirTemperature: number;
}

interface PileLengthReportProps {
  id: any;
  sessions: IPileLengthResultsWithHeatPump[];
  imperial: boolean
}
const PileLengthReport: React.FunctionComponent<PileLengthReportProps> = ({
  id,
  sessions,
  imperial,
}) => {
    
    function getLongestSteelPile(){
        return sessions.reduce((prev, curr)=>{
            if (curr.steelPileLength > prev.steelPileLength){
                return curr;
            }
            return prev;
        });
    }
      function getWholeArrayHeatPumpCapacity(session: IPileLengthResultsWithHeatPump) {
        if (!session?.calculator?.pileArray?.numberOfActivePilesInTheArray?.inputValue) return 0;
        return Math.abs(
          session.perPileHeatPumpCapacity *
          session.calculator.pileArray.numberOfActivePilesInTheArray.inputValue
        );
      }

  return (
    <SummaryPageTemplate
      id={id}
      mainTitle="Pile Length Prediction"
      topDisclaimerParagraph={`The following pile lengths were predicted using machine learning software based on given site and component details. 
        \n These predictions are generated with the most recent, validated data for geo-piles, list of assumptions and sources of error are provided in the attached appendix.
        `}
      explanationOfResultsParagraph={`Thermal performance of the system is predicted to reach the values presented in the following table. These are defined by the type of capacity supplied (as either heating or cooling), the total capacity to the building, and the thermal capacity per pile in the array. The Coefficient of Performance (COP) is also shown and reflects the efficiency of the heat pump based on eith heating or cooling. 
        `}
      summaryHighlightTopValue={getConvertedText(imperial, getLongestSteelPile().steelPileLength, 'm')}
      summaryHighlightTopLabel={"Steel Pile Length"}
      summaryHighlightBottomValue={getConvertedText(imperial,getLongestSteelPile().averageGeoPileThermalCapacity/getLongestSteelPile().steelPileLength,'W/m')}
      summaryHighlightBottomLabel={"Thermal Capacity By Depth"}
      summaryTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Load Type</th>
            <th>Optimal Steel Pile Length</th>
            <th>Capacity By Depth</th>
            <th>Whole-Array Heat Pump Capacity</th>
            <th>% of Target Demand Met</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {session.isCoolingSeasonAtHeatPump ? "Cooling" : "Heating"}
                </TD>
                <TD>{getConvertedText(imperial,session.steelPileLength,'m')}</TD>
                <TD>{getConvertedText(imperial, session.averageGeoPileThermalCapacity/session.steelPileLength, 'W/m')}</TD>
                <TD>{getConvertedText(imperial, getWholeArrayHeatPumpCapacity(session), 'W')}</TD>
                <TD>
                  {trimDecimals(getPercentage(
                    getWholeArrayHeatPumpCapacity(session),
                    session.calculator.pileArray?.targetWholeArrayHeatPumpCapacity
                      ?.inputValue || 0
                  ),1)}
                </TD>
              </tr>
            );
          })}
        </>
      }
      siteAndStudyBlurb={`These predictions are based on the following inputs for the site and study details. More details about the ground temperature inputs and ground thermal conductivity are provided in the appendix.`}
      siteAndStudyTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Ambient Air Temperature</th>
            <th>Ground Temperature 10m</th>
            <th>Average Ground Thermal Conductivity</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {getConvertedText(imperial, session.calculator.climate?.ambientAirTemperature?.inputValue, 'degCEnv')}
                </TD>
                <TD>
                  {getConvertedText(imperial, session.calculator.ground?.groundTemperature10mBelowTopOfPile?.inputValue, 'degCEnv')}
                </TD>
                <TD>
                  {session.calculator.ground?.averageGroundThermalConductivity?.inputValue+' W/mK'}
                </TD>
              </tr>
            );
          })}
        </>
      }
      componentDetailsBlurb={
        "These predictions are based on the following inputs for the component details. More details about the pile size and heat pump are provided in the appendix. Note that the number of piles reports the number of piles in the array that are on and contributing to the thermal capacity."
      }
      componentDetailsTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Number of Active Piles</th>
            <th>Steel Pile Type</th>
            <th>Plastic Pipe Type</th>
            <th>Pile Inlet Flow Rate</th>
            <th>Heat Pump Type</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {session.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue}
                </TD>
                <TD>
                  {session.calculator.steelPile?.steelPileNominalSizeForClient?.inputValue}
                </TD>
                <TD>
                    {session.calculator.plasticPipe?.plasticPipeNominalSizeForClient?.inputValue}
                </TD>
                <TD>
                  {getPileInletFlowRate(session.calculator,imperial)}
                </TD>
                <TD>{session.calculator.heatPump?.name}</TD>
              </tr>
            );
          })}
        </>
      }
    />
  );
};
export default PileLengthReport;
