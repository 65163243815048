import { getPercentage } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IHeatPumpCapacityResults } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import ReportTemplate, { getConvertedText, H1, H4 } from "../Template";
import { convertFlowRateToLPM } from "lib/units";
import DetailsPageTemplate, {DetailsTablePropsData} from "./Template";
import { readableVariable, readableVariableNoAbrev } from "lib/types";
import { BackendAPIContext } from "components/Contexts/BackendAPI";


interface HeatPumpCapacityDetailsProps {
  id: any;
  sessions: IHeatPumpCapacityResults[];
  imperial: boolean
}
const HeatPumpCapacityDetails: React.FunctionComponent<HeatPumpCapacityDetailsProps> = ({
  id,
  sessions,
  imperial,
}) => {
 const api = useContext(BackendAPIContext);
 
  return (
    <DetailsPageTemplate
      id={id}
      tables={[
          {
            title: 'Capacity Details',
            variableNames: [
                readableVariable.targetWholeArrayHeatPumpCapacity,
                readableVariable.changeInTemperatureAcrossPile,
                readableVariable.pileInletFluidTemperature,
                readableVariable.pileOutletFluidTemperature,
            ],
            data: sessions.map((session): DetailsTablePropsData => {
                return {
                    sessionName: session.calculator.name||'',
                    valuesInOrder: [
                        getConvertedText(imperial, session.calculator.pileArray?.targetWholeArrayHeatPumpCapacity?.inputValue,'W'),
                        getConvertedText(imperial,session.changeInTemperatureAcrossPile,'degCRel'),
                        getConvertedText(imperial,session.pileInletFluidTemperature, 'degCEnv'),
                        getConvertedText(imperial, session.pileOutletFluidTemperature, 'degCEnv')
                    ]
                }
            })
          },
        {
            title: 'Soil and Site Details',
            variableNames: [
                readableVariable.groundTemperatureAtTopOfPile,
                readableVariable.groundTemperature5mBelowTopOfPile,
                readableVariable.maxGroundThermalConductivity,
                readableVariable.minGroundThermalConductivity,

            ],
            data: sessions.map((session): DetailsTablePropsData => {
                return {
                    sessionName: session.calculator.name||'',
                    valuesInOrder: [
                        getConvertedText(imperial, session.calculator.ground?.groundTemperatureAtTopOfPile?.inputValue,'degCEnv'),
                        getConvertedText(imperial,session.calculator.ground?.groundTemperature5mBelowTopOfPile?.inputValue,'degCEnv'),
                        session.calculator.ground?.maxGroundThermalConductivity?.inputValue+' W/mK',
                        session.calculator.ground?.minGroundThermalConductivity?.inputValue+' W/mK'
                    ]
                }
            })
        },
        {
            title: 'Pile Details',
            variableNames: [
                readableVariable.steelPileNominalSize,
                'Steel Pile Inner/Outer Diameters',
                readableVariable.plasticPipeNominalSize,
                'Plastic Pipe Inner/Outer Diameters'
            ],
            data: sessions.map(session => {
                const nomSizeNameP = session.calculator.plasticPipe?.plasticPipeNominalSizeForClient?.inputValue ||''
                const nomSizeNameS = session.calculator.steelPile?.steelPileNominalSizeForClient?.inputValue ||''
                const diamsP = api?.getPlasticPipeDiametersFromName(nomSizeNameP);
                const diamsS = api?.getSteelPileDiametersFromName(nomSizeNameS);
                return {
                    sessionName: session.calculator.name ||'',
                    valuesInOrder: [
                        nomSizeNameS,
                        getConvertedText(imperial,(diamsS?.innerDiameter ||0)*100.0,'cm',4)+'/'+getConvertedText(imperial, (diamsS?.outerDiameter ||0)*100.0, 'cm',4),
                        nomSizeNameP,
                        getConvertedText(imperial,(diamsP?.innerDiameter ||0)*100.0,'cm',4)+'/'+getConvertedText(imperial, (diamsP?.outerDiameter ||0)*100.0, 'cm',4)
                    ]
                }
            })
        },
        {
            title: "Heat Pump and Array Details",
            variableNames: [
                readableVariable.heatPumpOutletFluidTemperature,
                // readableVariable.heatPumpInletFluidTemperature
            ],
            data: sessions.map(session => {
                return {
                    sessionName :session.calculator.name||'',
                    valuesInOrder: [
                        getConvertedText(imperial, session.calculator.pileInFluid?.heatPumpOutletFluidTemperature?.inputValue,'degCEnv')||'',
                        // getConvertedText(imperial, session.calculator)
                    ]
                }
            })
        },
        {
            title: "Temperature Losses and Gains",
            variableNames: [
                readableVariable.temperatureChangeBeforeArray,
                readableVariable.averageInterPileTemperatureChangeInflow,
                readableVariable.averageInterPileTemperatureChangeOutflow,
                readableVariable.temperatureChangeAfterArray
            ],
            data: sessions.map(session => {
                return {
                    sessionName: session.calculator.name||'',
                    valuesInOrder: [
                        getConvertedText(imperial,session.calculator.pileInFluid?.temperatureChangeBeforeArray?.inputValue,'degCRel'),
                        getConvertedText(imperial,session.calculator.pileInFluid?.averageInterPileTemperatureChangeInflow?.inputValue,'degCRel'),
                        getConvertedText(imperial,session.calculator.pileOutFluid?.averageInterPileTemperatureChangeOutflow?.inputValue,'degCRel'),
                        getConvertedText(imperial,session.calculator.pileOutFluid?.temperatureChangeAfterArray?.inputValue,'degCRel')
                    ]
                }
            })
        },
        {
            title: "Flow Rate Losses and Gains",
            variableNames: [
                readableVariable.flowChangeBeforeArray,
                readableVariable.averageInterPileFlowChangeInflow
            ],
            data: sessions.map(session => {
                return {
                    sessionName: session.calculator.name||'',
                    valuesInOrder: [
                        getConvertedText(imperial, session.calculator.pileInFluid?.flowChangeBeforeArray?.inputValue,'m3/s'),
                        getConvertedText(imperial,session.calculator.pileInFluid?.averageInterPileFlowChangeInflow?.inputValue,'m3/s')
                    ]
                }
            })
        }
      ]}
    />
  );
};
export default HeatPumpCapacityDetails;
