import { GlobalContext } from "components/Contexts/Global";
import InfoPopup from "components/Popups/InfoPopup";
import DatetimePicker from "components/Reusables/DatetimePicker";
import NumberInput from "components/Reusables/NumberInput";
import Select from "components/Reusables/Select";
import { bulkPredictionsTimeUnit, BulkPredictionTimeUnit } from "lib/models/bulk-prediction-dto";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import SectionContainer from "./SectionContainer";
import { BasicCols, InfoButton, InnerTitleCont, InnerTitleText, InputInstr, LittleDivider, LittleInstr } from "./Styles";

interface TimeInputSectionProps {
    timestepUnit: BulkPredictionTimeUnit | undefined
    timestepMagnitude: number | undefined
    firstRowDatetime: Date | undefined
    setTimestepUnit: any
    setTimestepMagnitude: any
    setFirstRowDatetime: any

}
const TimeInputSection: React.FunctionComponent<TimeInputSectionProps> =
  ({
    timestepMagnitude, timestepUnit, firstRowDatetime,
    setFirstRowDatetime, setTimestepMagnitude, setTimestepUnit

  }) => {
      const gc = useContext(GlobalContext);


    return (
      <SectionContainer title="4. Time and Date Configuration">
         
          <BasicCols>
            <InputInstr>
              Define the units of each row in the file (must be consistent):
            </InputInstr>
            <Select
              type="styled"
              selected={{
                value: timestepUnit || "",
                id: timestepUnit || "",
                content: timestepUnit?.toUpperCase(),
              }}
              onChange={(newOption) => {
                setTimestepUnit(newOption?.value as BulkPredictionTimeUnit);
              }}
              options={bulkPredictionsTimeUnit.map((unit) => ({
                value: unit,
                id: unit,
                content: unit.toUpperCase(),
              }))}
            />
            <InfoButton
              onClick={() =>
                gc?.setPopup(<InfoPopup imageLink={"Bulk Date and Time INFO.jpg"} />)
              }
            ></InfoButton>
          </BasicCols>
          <LittleDivider></LittleDivider>
          <BasicCols>
            <InputInstr>
              Define the time step between each row in the file (in the units
              input above):
            </InputInstr>
            <NumberInput
              value={timestepMagnitude}
              setValue={setTimestepMagnitude}
              step={1}
              min={1}
            />
          </BasicCols>
          <LittleInstr>
            Example: For a file with 7 days between each row, this value would
            be "7" with time units of "Days". A file with hourly data would
            have a time step of "1" and "Hours" inputs.
          </LittleInstr>
          <LittleDivider></LittleDivider>
          <BasicCols>
            <InputInstr>
              Enter the exact starting date and time of the first row in the
              file:
            </InputInstr>
            <DatetimePicker
              datetime={firstRowDatetime}
              setDatetime={setFirstRowDatetime}
            />
          </BasicCols>
          <LittleInstr>
            This is the date of the first row of the input .CSV file, assumed to
            be the FIRST hour of the day.
          </LittleInstr>
      </SectionContainer>
    );
  };
export default TimeInputSection;
