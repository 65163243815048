
import axios, { AxiosInstance } from 'axios';
import Login from 'components/Popups/TOS';
import { config } from 'lib/CONFIG-consts';
import { OutputVariables } from 'lib/models';
import React, {useState, useEffect, useCallback, useContext, SetStateAction, ReactNode} from 'react';
import { useHistory, useLocation } from 'react-router';


//define types here
export interface GlobalContextProps {
    outputVariable?: OutputVariables
    setOutputVariable: React.Dispatch<React.SetStateAction<OutputVariables|undefined>>
    // errorMessage?:( string | ReactNode )[]
    popup: JSX.Element | undefined
    setPopup: React.Dispatch<SetStateAction<JSX.Element|undefined>>
    // loadingState: boolean
    // successState: boolean
    // setLoadingState: React.Dispatch<SetStateAction<boolean>>
    // setSuccessState: React.Dispatch<SetStateAction<boolean>>
    apiToken: string|null
    setApiToken: React.Dispatch<SetStateAction<string|null>>
    logout: ()=>void
    withHeatPump: boolean;
    setWithHeatPump: React.Dispatch<SetStateAction<boolean>>;
    formScrollTop: number|undefined;
    setFormScrollTop: React.Dispatch<SetStateAction<number|undefined>>;
    addWaitingAction: ()=>void;
    removeWaitingActionAndReturnAllActionsCompleted: ()=>boolean;
    // addErrorMessage: (message: ReactNode|string)=>void;
    // clearErrorMessages: ()=>void;
    waitingActions: number;
}
let myURL = ''
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // myURL = 'http://localhost:...'//TODO
    myURL = config.developmentURL; //INNOVIA

    // dev code
} else {
    myURL = config.productionURL; //INNOVIA
    // production code
}
export const baseURL = myURL;
// export const baseURL = 'https://localhost:44301/api/';
axios.defaults.baseURL = baseURL;
//initialize state structure here
// export const GlobalContext = React.createContext<Partial<GlobalContextProps>>({});
export const GlobalContext = React.createContext<GlobalContextProps|null>(null);


const GlobalProvider: React.FunctionComponent =({children}) =>{
    const [outputVariable, setOutputVariable]= useState<OutputVariables|undefined>();
    const [errorMessage, setErrorMessage] = useState<(string|ReactNode|undefined)[]>();
    const [popup, setPopup] = useState<JSX.Element>();
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [successState, setSuccessState] = useState<boolean>(false);
    const [apiToken, setApiToken] = useState<string|null>(window.sessionStorage.getItem(config.apiTokenSessionStorageKey));
    const [withHeatPump, setWithHeatPump]= useState<boolean>(true);
    const [formScrollTop, setFormScrollTop] = useState<number>();
    const [waitingActions, setWaitingActions] = useState<number>(0);

    

    //debugging
    useEffect(()=>{
       //console.log('output var' , outputVariable)
    },[outputVariable]);


    //onmount see if there is an existing token
    useEffect(()=>{
        const token = window.sessionStorage.getItem(config.apiTokenSessionStorageKey);
        if (token){
            setApiToken(token);
        }
    },[]);

    function addErrorMessage(message: ReactNode | string){
        setErrorMessage(prev => {
            if (!prev) return [message];
            return [...prev, message];
        })
    }
    function clearErrorMessages(){
        setErrorMessage(undefined);
    }

    function addWaitingAction(){
        setWaitingActions(prev => prev = prev + 1);
    }
    function removeWaitingActionAndReturnAllActionsCompleted(){
        const currentWaitingActions = waitingActions;
        setWaitingActions(prev => prev>0?prev - 1:0);
        return (currentWaitingActions <=1);
    }
    //debugging
    useEffect(()=>{
       //console.log('gc reloaded or apiToken change = ',apiToken)
    },[apiToken]);
    useEffect(()=>{
        console.warn('Global Context re-rendering/mounting')
    },[])

    function logout(){
        setApiToken(null);
        window.sessionStorage.removeItem(config.apiTokenSessionStorageKey);
    }


    //ensure that success state and errorMessage always overrides loading state, UNLESS There are still active actions
    useEffect(()=>{
        if (successState && loadingState && waitingActions <1) {
            //console.log('turning off loading state 1');
            setLoadingState(false);
        }
        if (errorMessage && loadingState && waitingActions <1) {
            //console.log('turning off loading state 2');
            setLoadingState(false);
        }
    },[successState, loadingState, errorMessage, waitingActions]);
    // Debugging
    useEffect(()=>{
        //console.log('sucess state: ',successState);
        //console.log('loading state: ',loadingState);
        //console.log('error message:', errorMessage);
        //console.log('waitingActions:',waitingActions);
    },[successState, loadingState, errorMessage, waitingActions]);

    //ensure that success state always turns of after a short time
    useEffect(()=>{
        if (!successState) return;
        setTimeout(()=>{
            setSuccessState(false);
        }, 500);
    },[successState]);


return (
<GlobalContext.Provider
value={{
    outputVariable:outputVariable,
    setOutputVariable: setOutputVariable,
    // errorMessage: errorMessage,
    popup: popup,
    setPopup: setPopup,
    // loadingState: loadingState,
    // setLoadingState: setLoadingState,
    // successState: successState,
    // setSuccessState: setSuccessState,
    setApiToken: setApiToken,
    apiToken: apiToken,
    logout: logout,
    withHeatPump: withHeatPump,
    setWithHeatPump:setWithHeatPump,
    setFormScrollTop: setFormScrollTop,
    formScrollTop: formScrollTop,
    addWaitingAction: addWaitingAction,
    removeWaitingActionAndReturnAllActionsCompleted: removeWaitingActionAndReturnAllActionsCompleted,
    // addErrorMessage: addErrorMessage,
    // clearErrorMessages: clearErrorMessages,
    waitingActions: waitingActions,
}}
>
{children}
</GlobalContext.Provider>
);
}
export default GlobalProvider;
