import React from "react";
export type MessageHandlingStates = 'created'|'inQueue'|'beingHandled'|'handled';
type UserAllowedActions =
  | "none"
  | "continue"
  | "restartApp"
  | "continueOrCancel";
export type MessageImportanceLevels =
  | "not-important"
  | "important"
  | "very important"
  | undefined;
export type MessageTypes =
  | "application error"
  | "user error"
  | "validation error"
  | "progress report"
  | "status report"
  | "misc info";
export type CreateMessageFunction = (...args: any) => Message;
interface MessageConfig {
  isBlocking?: boolean;
  showToUser?: boolean;
  messageToDeveloper?: boolean;
  hideInProduction?: boolean;
  dontAlertDevInProduction?: boolean;
  dontAlertDevInDev?: boolean;
  importanceLevel?: MessageImportanceLevels;
  type?: MessageTypes;
  updateable?: boolean;
  contentForDisplay: MessageContentForDisplay;
  userAllowedActionType: UserAllowedActions;
  messageHandlingState?: MessageHandlingStates;
  messageResolved?: MessageResponse | false;
  // updateableVariable?: string
}

export type GetUpdatedMessageCallback = () => Message | undefined;

function returnIfFalseOrNotNullish(val: any, alternate: any) {
  if (val === false || val === true || val) {
    return val;
  } else {
    return alternate;
  }
}

export function deepCloneMessage(m: Message){
  return new Message({
    isBlocking: m.isBlocking,
    showToUser: m.showToUser,
    messageToDeveloper: m.messageToDeveloper,
    hideInProduction: m.hideInProduction,
    dontAlertDevInProduction: m.dontAlertDevInProduction,
    dontAlertDevInDev: m.dontAlertDevInDev,
    importanceLevel: m.importanceLevel,
    type: m.type,
    updateable: m.updateable,
    contentForDisplay: {...m.contentForDisplay},
    userAllowedActionType: m.userAllowedActionType,
    messageHandlingState: m.messageHandlingState,
    messageResolved: m.messageResolved
  })
}

export class Message {
  isBlocking: boolean;
  showToUser: boolean;
  messageToDeveloper: boolean;
  hideInProduction: boolean;
  dontAlertDevInProduction: boolean;
  dontAlertDevInDev: boolean;
  importanceLevel: MessageImportanceLevels;
  type: MessageTypes;
  updateable: boolean;
  contentForDisplay: MessageContentForDisplay;
  getUpdatedMessageCallback: GetUpdatedMessageCallback | undefined;
  messageResolved: MessageResponse | false;
  userAllowedActionType: UserAllowedActions;
  messageHandlingState: MessageHandlingStates;
  // updateableVariable: string
  constructor(config: MessageConfig) {
    this.messageResolved = false;
    this.isBlocking = returnIfFalseOrNotNullish(config.isBlocking, false);
    this.showToUser = returnIfFalseOrNotNullish(config.showToUser, false);
    this.messageToDeveloper = returnIfFalseOrNotNullish(
      config.messageToDeveloper,
      false
    );
    this.hideInProduction = returnIfFalseOrNotNullish(
      config.hideInProduction,
      true
    );
    this.dontAlertDevInProduction = returnIfFalseOrNotNullish(
      config.dontAlertDevInProduction,
      true
    );
    this.dontAlertDevInDev = returnIfFalseOrNotNullish(
      config.dontAlertDevInDev,
      true
    );
    this.importanceLevel = returnIfFalseOrNotNullish(
      config.importanceLevel,
      undefined
    );
    this.type = returnIfFalseOrNotNullish(config.type, "application error");
    this.updateable = returnIfFalseOrNotNullish(config.updateable, false);
    if (
      !config.contentForDisplay ||
      (!config.contentForDisplay.title &&
        config.contentForDisplay.summary &&
        config.contentForDisplay.additionalInfo)
    ) {
      throw new Error(
        'You tried to create a Message without a "contentForDisplay" value. Since the point of a message is to have a message, you must provide this property... it is the "message" of the "Message".'
      );
    }
    this.contentForDisplay = config.contentForDisplay;
    this.userAllowedActionType = returnIfFalseOrNotNullish(
      config.userAllowedActionType,
      "continue"
    );
    this.messageHandlingState = 'created';
    if (config.messageHandlingState !== undefined){
      this.messageHandlingState = config.messageHandlingState;
    }
    if (config.messageResolved !== undefined){
      this.messageResolved = config.messageResolved
    }
    // this.updateableVariable = config.updateableVariable || '';
  }
}

export interface MessageContentForDisplay {
  title: string;
  summary: string;
  additionalInfo?: string;
  numericProgress?: number;
}

export type MessageResponse =
  | "handledByUserCanMoveOn"
  | "handledByUserRestartApp"
  | "handledByUserCancel"
  | "failedToDisplay"
  | "failedToBeHandledByUser"
  | undefined;

export type HandleMessageResponseCallback = (
  r: MessageResponse,
  m: Message
) => void;
export type MessageHandlingCallback = (
  m: Message,
  tempId: string,
  responseCallback: HandleMessageResponseCallback
) => void;

export class MessageHandler {
  private _displayBlockingMessage;
  private _displayNonBlockingMessage;
  private _sendMessageToDeveloper;
  private _restartApplicationCallback;
  private _cleanupMessageNotBeingDisplayedToUser;
  constructor(
    displayBlockingMessage: MessageHandlingCallback,
    displayNonBlockingMessage: MessageHandlingCallback,
    sendMessageToDeveloper: MessageHandlingCallback,
    restartApplicationCallback: () => void,
    cleanupMessageNotBeingDisplayedToUser: MessageHandlingCallback,
  ) {
    this._displayBlockingMessage = displayBlockingMessage;
    this._displayNonBlockingMessage = displayNonBlockingMessage;
    this._sendMessageToDeveloper = sendMessageToDeveloper;
    this._restartApplicationCallback = restartApplicationCallback;
    this._cleanupMessageNotBeingDisplayedToUser = cleanupMessageNotBeingDisplayedToUser;
  }

  public async handleMessage(m: Message, tempId: string) {
   //console.log("message handler, handling message", m);
    let messageBeingDisplayedToUser = false;
    ////console.log(1);
    const inProduction =
      process.env.NODE_ENV && process.env.NODE_ENV !== "development";
    ////console.log(2);
    const toDev = inProduction
      ? !m.dontAlertDevInProduction && m.messageToDeveloper
      : !m.dontAlertDevInDev && m.messageToDeveloper;
   //console.log(inProduction, toDev);
    if (toDev) {
      try {
        this._sendMessageToDeveloper(m, tempId, this.handleMessageCompleted);
      } catch {
        console.warn(
          'Warning: the "sendMessageToDeveloper" callback in MessageHandler has failed.'
        );
      }
    }
    const toUser = inProduction
      ? m.showToUser && !m.hideInProduction
      : m.showToUser;
    if (toUser) {
      if (m.isBlocking) {
        messageBeingDisplayedToUser = true;
        this._displayBlockingMessage(m, tempId, this.handleMessageCompleted);
      } else if (!m.isBlocking) {
        messageBeingDisplayedToUser = true;
        this._displayNonBlockingMessage(m, tempId, this.handleMessageCompleted);
      }
    }
   //console.log(
    //   "done handling message. Sent to dev?",
    //   toDev,
    //   ". Sent to user?",
    //   toUser
    // );
    if (!messageBeingDisplayedToUser){
      this._cleanupMessageNotBeingDisplayedToUser(m, tempId,()=>{});
    }
  }

  public handleMessageCompleted(mr: MessageResponse, m: Message) {
    //handle message status

    const shouldRestartAppOnFailure =
      m.isBlocking &&
      (m.type == "application error" ||
        m.type == "user error" ||
        m.type == "validation error") &&
      m.importanceLevel != "not-important";
    switch (mr) {
      case "failedToBeHandledByUser":
        if (shouldRestartAppOnFailure) {
          alert(
            'Message handler recieved the "failedToBeHandledByUser" response from a message handling callback, the application will restart now.'
          );
          this._restartApplicationCallback();
        }
        return;
      case "failedToDisplay":
        if (shouldRestartAppOnFailure) {
          alert(
            'Message handler recieved the "failedToDisplay" response from a message handling callback, the application will restart now.'
          );
          this._restartApplicationCallback();
        }
        return;
      case "handledByUserCanMoveOn":
        return;
      case "handledByUserCancel":
        return;
      case "handledByUserRestartApp":
        this._restartApplicationCallback();
        return;
      default:
        window.alert(
          "Warning, developer has failed to provide a valid response to a MessageHandler callback, restarting application."
        );
        this._restartApplicationCallback();
        return;
    }
  }
}

//! convenience Message creation functions
export function createBlockingLoadingMessage(
  title: string,
  summary: string,
  additionalInfo?: string
) {
  const m = new Message({
    contentForDisplay: {
      title: title,
      summary: summary,
      additionalInfo: additionalInfo,
    },
    dontAlertDevInProduction: true,
    hideInProduction: false,
    importanceLevel: "not-important",
    isBlocking: true,
    messageToDeveloper: false,
    showToUser: true,
    type: "progress report",
    updateable: true,
    userAllowedActionType: "none",
  });
  return m;
}

export function createLoadingMessageWithProgress(
  startingNumericProgress: number,
  title: string,
  summary: string,
  additionalInfo?: string
) {
  const m = new Message({
    contentForDisplay: {
      title: title,
      summary: summary,
      additionalInfo: additionalInfo,
      numericProgress: startingNumericProgress,
    },
    dontAlertDevInProduction: true,
    hideInProduction: false,
    importanceLevel: "not-important",
    isBlocking: true,
    messageToDeveloper: false,
    showToUser: true,
    type: "progress report",
    updateable: true,
    userAllowedActionType: "none",
  });
  return m;
}

export function createHttpRequestSuccessMessage(
  title: string,
  summary: string,
  additionalInfo?: string
) {
  return new Message({
    contentForDisplay: {
      title: title,
      summary: summary,
      additionalInfo: additionalInfo,
    },
    dontAlertDevInProduction: true,
    hideInProduction: false,
    importanceLevel: "not-important",
    isBlocking: true,
    messageToDeveloper: false,
    showToUser: true,
    type: "status report",
    updateable: false,
    userAllowedActionType: 'continue',
  });
}
export function createBlockingWarningContinueMessage(
  title: string,
  summary: string,
  additionalInfo?: string
) {
  return new Message({
    contentForDisplay: {
      title: title,
      summary: summary,
      additionalInfo: additionalInfo,
    },
    dontAlertDevInProduction: true,
    hideInProduction: false,
    importanceLevel: "not-important",
    isBlocking: true,
    messageToDeveloper: false,
    showToUser: true,
    type: "misc info",
    updateable: false,
    userAllowedActionType: "continueOrCancel",
  });
}
