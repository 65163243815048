import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';
import ReportTemplate, { BlackTitle, GreenTitle } from './Template';

const TopArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--c-white);
    width: 100%;
    margin: 0;
    padding: 0;
`;
const LeftTop = styled.div`
    margin: 0;
    padding: 0;
`;
const RightTop = styled.div`
    font-size: var(--ss-4);
    padding-left: var(--ss-9);
    padding-right: 0;
    padding-block: 0;
    margin: 0;
`;
// const BlackTitle = styled.h3`
//     color: var(--c-black);
//     font-size: var(--ss-6);
//     margin-block: var(--ss-4);
//     padding: 0;
// `;
// const GreenTitle = styled.h3`
//     color: var(--c-green);
//     font-size: var(--ss-6);
//     margin-block: var(--ss-4);
//     padding:0;
// `;

const DiagramArea = styled.div`
    margin: 0;
    padding: 0;
`;
const Diagram = styled.img`
    width: 100%;
    margin-bottom:0;
`;
const SubTitle =styled.h5`
    margin: 0;
    padding: 0;
    font-size: var(--ss-5);
`;
const LegendArea = styled.div`
    margin: 0;
    padding: 0;
    width: 100%;
`;
const Legend = styled.img`
    height: var(--ss-975);
`;
const BottomDisclaimer = styled.div`
    margin: 0;
    padding: 0;
    font-size: var(--ss-4);
`;
const GreenDivider = styled.div`
    margin-block: var(--ss-4);
    margin-inline: 0;
    border-radius: 0;
    padding: 0;
    height: var(--ss-2);
    width: var(--ss-10);
    background: var(--c-green-very-light);
`;
interface DiagramsReportProps{
    id: any

}
const DiagramsReport: React.FunctionComponent<DiagramsReportProps> =({
    id
})=> {

return (
<ReportTemplate id={id} xPosition={-9999} yPosition={9999}>
    <TopArea>
        <LeftTop>
            <BlackTitle>Appendix</BlackTitle>
            <GreenTitle>System <br/> Schematics</GreenTitle>
        </LeftTop>
        <RightTop>
        The following system schematic represents the components and variables used in the software.
        <br/><br/>
        This schematic may not report exact placement or orientation of components, and does not include any structural functions of the geo-piles. 
        </RightTop>
    </TopArea>
    <DiagramArea>
        <SubTitle>Overall System View</SubTitle>
        <GreenDivider></GreenDivider>
        <Diagram src="whole-system-diagram.svg"/>
    </DiagramArea>
    <LegendArea>
        <SubTitle>List of Variables</SubTitle>
        <GreenDivider></GreenDivider>
        <Legend src="VariableLegend.png" />
    </LegendArea>
</ReportTemplate>
);
}
export default DiagramsReport;
