import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled, { css } from 'styled-components';
import ReportTemplate, { H1, H4,  } from '../Template';


const Summary = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin: var(--ss-3);
    padding: var(--ss-3);
`;

export const TD = styled.th`
    font-weight: 400;
`;
export const TDG = styled.th`
    font-weight: 400;
    color: var(--c-green);
    width: var(--ss-8);
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
  /* word-wrap: break-word; */
`;
export const TDGreen : React.FunctionComponent<{children: any}> = ({children})=>{
    const stringToPrint = (typeof children === "string"?children:" ").substring(0,16);
    return <TDG id="test TGD">{stringToPrint}...</TDG>;
}

export const THGreen = styled.th`
    color: var(--c-green);
    width: var(--ss-8);
    overflow: hidden;
`;
const TH = styled.th`
`;
const TR = styled.tr`

`;
const SummaryLeft = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
margin: var(--ss-3);
padding: var(--ss-3);
width: 180%;
`;
const SummaryRight = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-between;
margin: var(--ss-3);
padding: var(--ss-3);
width:100%;
`;
export const DisclaimerText = styled.div`
margin: var(--ss-3);
padding: var(--ss-3);
font-size: 0.12in;
`;
export const InfoText = styled.div`
margin: var(--ss-3);
padding: var(--ss-3);
font-size: 0.12in;
`;
const BaseTable = css`
    width: 100%;
    margin: var(--ss-3);
    padding: var(--ss-3);
    font-size: 0.1in;
`;
const ComparisonTable = styled.table`
    ${BaseTable}
    border: solid var(--c-light-gray);

`;
const SiteAndStudyTable = styled.table`
    ${BaseTable}
    background: var(--c-light-gray);
`;
const ComponentTable = styled.table`
${BaseTable}
    background: var(--c-light-gray);
`;
const SummaryHighlight = styled.div`
    background: var(--c-black);
    margin: var(--ss-3);
    padding: var(--ss-3);
    width: 100%;
`;
const H2 = styled.h2`
    color: var(--c-white);
    font-size: 0.35in;
    margin: var(--ss-3);
    padding: var(--ss-3);
    font-family: 'Ubuntu';
    font-weight: 400;
`;
const SummaryBigLabel = styled.div`
    color: var(--c-white);
    font-size: 0.2in;
    margin: var(--ss-3);
    padding: var(--ss-3);
`;
const SummaryDivider = styled.div`
    width: 100%;
    height: 0.01in;
    margin: 0;
    padding: 0;
    background: var(--c-green-very-light);
`;

interface SummaryPageTemplateProps{
    id: any
    mainTitle: string
    topDisclaimerParagraph: string
    explanationOfResultsParagraph: string
    summaryHighlightTopValue: number | string
    summaryHighlightTopLabel: string
    summaryHighlightBottomValue: number | string
    summaryHighlightBottomLabel: string
    summaryTableContent: JSX.Element
    siteAndStudyBlurb: string
    siteAndStudyTableContent: JSX.Element
    componentDetailsBlurb: string
    componentDetailsTableContent: JSX.Element
}
const SummaryPageTemplate: React.FunctionComponent<SummaryPageTemplateProps> =({
    id, mainTitle, topDisclaimerParagraph, explanationOfResultsParagraph, summaryHighlightTopValue,
    summaryHighlightTopLabel, summaryHighlightBottomLabel, summaryHighlightBottomValue, summaryTableContent,
    siteAndStudyBlurb, siteAndStudyTableContent, componentDetailsBlurb, componentDetailsTableContent
})=> {

return (
 <ReportTemplate id={id} xPosition={9999} yPosition={9999}> 
{/* <ReportTemplate id={id} xPosition={0} yPosition={0}> */}
    <Summary>
        <SummaryLeft> 
            <H1>{mainTitle}</H1>
            <DisclaimerText>
                {topDisclaimerParagraph}
            </DisclaimerText>
            <H4>Explanation of Results</H4>
            <InfoText>
                {explanationOfResultsParagraph}
            </InfoText>
        </SummaryLeft>
        <SummaryRight>
            <H4>Maximum Performance</H4>
            <SummaryHighlight>
                <H2>{summaryHighlightTopValue}</H2>
                <SummaryBigLabel>{summaryHighlightTopLabel}</SummaryBigLabel>
                <SummaryDivider></SummaryDivider>
                <H2>{summaryHighlightBottomValue}</H2>
                <SummaryBigLabel>{summaryHighlightBottomLabel}</SummaryBigLabel>
            </SummaryHighlight>
        </SummaryRight>
    </Summary>
    <ComparisonTable>

        {summaryTableContent}
    </ComparisonTable>
    <H4>Site & Study Details</H4>
    <InfoText>{siteAndStudyBlurb}</InfoText>
    <SiteAndStudyTable>
        {siteAndStudyTableContent}

    </SiteAndStudyTable>
    <H4>Component Details</H4>
    <InfoText>{componentDetailsBlurb}</InfoText>
    <ComponentTable>

        {componentDetailsTableContent}
    </ComponentTable>
</ReportTemplate>
);
}
export default SummaryPageTemplate;
