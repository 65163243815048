import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
} from "react";
import styles from 'styles/Calendar.module.scss'

export type Months =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";
export const months: Months[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysPerMonth: { [month in Months]: number } = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};

interface YearlessDatePickerProps {
    month: Months|undefined,
    setMonth: React.Dispatch<SetStateAction<Months|undefined>>,
    day: number|undefined,
    setDay: any,
}
const YearlessDatePicker: React.FunctionComponent<YearlessDatePickerProps> =
  ({month,day, setMonth, setDay}) => {
    function getWeeksArrays(daysInMonth: number): number[][] {
      const result = [];
      const weeks = Math.ceil(daysInMonth / 7);
      for (let week = 1; week < weeks + 1; week++) {
        const thisWeek = [];
        for (
          let day = week * 7 - 6;
          day <= daysInMonth && day < week * 7 + 1;
          day++
        ) {
          thisWeek.push(day);
        }
        result.push(thisWeek);
      }
      return result;
    }
    return (
      <div className={styles.container}>
        {Object.keys(daysPerMonth).map((m: string) => {
          const m2: Months = m as Months
          const days = daysPerMonth[m2];
          const weeks = getWeeksArrays(days);
          return (
            <div key={m} className={styles.month}>
              <h3 className={styles.monthTitle}>{m}</h3>
              <table className={styles.table}>
                {weeks.map((week) => {
                  return (
                    <tr key={week[0]} className={styles.row}>
                      {week.map((d) => {
                        return (
                          <td key={d} className={styles.cell} onClick={(e)=>{
                              setMonth(m2)
                              setDay(d)
                          }}>
                            {(d===day && m===month)?
                            <div className={styles.selectedDay}>{d}</div>
                            :<div className={styles.unselectedDay}>{d}</div>
                        }
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          );
        })}
      </div>
    );
  };
export default YearlessDatePicker;
