import { ICalculator, IUiInputVariableDouble, IUiInputVariableInt, IUiInputVariableString, OutputVariables, UiInputVariableDouble, UiInputVariableInt, UiInputVariableString } from "./models";
import { ICalculators } from "./types";

type OutputType = 'HeatPumpCapacity' | 'NumberOfPiles' | 'PileLengthWithHeatPump' | 'PileLengthWithoutHeatPump' | 'GroundTemperature';

export function getOutputTypeFromCalculator(calc: ICalculator): OutputType | undefined{
    if (!calc.outputVariable) return undefined;
    if(calc.outputVariable === OutputVariables.GroundTemperature){
        return 'GroundTemperature';
    }
    if (calc.outputVariable === OutputVariables.PileHeatPumpCapacity){
        return 'HeatPumpCapacity';
    }
    if (calc.outputVariable === OutputVariables.NumberOfPiles){
        return 'NumberOfPiles';
    }
    //only remaining option is pile length
        if (calc.pileArray?.targetGeoPileThermalCapacity?.inputValue){
            return 'PileLengthWithoutHeatPump';
        }
            return 'PileLengthWithHeatPump';
}

export function getInvalidMessageBasedOnOutputType(calc: ICalculator, outputType: OutputType | undefined){
    if (!outputType) return `There is no output variable defined for session ${calc.name}`;
    //console.log('getting validation errors');
    const result = validationMessagesByOutputType[outputType](calc);
    //console.log('result?',result);
    return result;
}

function returnFirstMessageFound(list: ((calc: ICalculator)=>string|undefined)[], calc: ICalculator): string | undefined{
    //console.log('in first message found');
    let result = undefined;
    list.forEach((item:(calc: ICalculator)=>string|undefined)=>{
        const message = item(calc);
        if (message) result = message;
    });
    //console.log('got this first message found,',result);
    return result;
}



interface ErrorMessageOptions{
    req?: boolean; //required
    string?: boolean; 
    minIn?: number; //min INCLUSIVE
    minEx?: number; //min EXCLUSIVE
    maxIn?: number; //max INCLUSIVE
    maxEx?: number; //max EXCLUSIVE
    custom?: (varName:string, value: string | number)=> string | undefined;
}

type ValidationVar = IUiInputVariableDouble | IUiInputVariableString | IUiInputVariableInt | undefined | null;

export function isNotNullOrUndefined(val:any): boolean{
    if (val === null || val === undefined) return false;
    return true;
}
export function isNullishOrNanButNotZero(val: any): boolean{
    if (isNotNullOrUndefined(val)){
        if (val === NaN){ //! Do not use the isNaN function here! 
            return true
        }
        if (val === ""){
            return true
        }
        return false
    }
    return true;
}

function getErrorMessage(name: string, variable: ValidationVar, o: ErrorMessageOptions ): string | undefined{

    // allow for 0!
    if (!variable && o.req) return `Missing required variable: ${name}.`;
    if (!variable) return undefined;
    const value = variable.inputValue;
    const n = variable.name || name;
    const isMissingButRequired =(
        o.req && (value === undefined || value === null || value === "" || (isNaN(parseFloat(value.toString())) && !o.string ))
        );
    if (isMissingButRequired) return `Missing required variable: ${n}`;
    if (isNullishOrNanButNotZero(value)) return undefined;
    const isStringButShouldntBe = (isNaN(parseFloat(value!.toString()))  && !o.string);
    if (isStringButShouldntBe) return `Invalid characters included in number-type variable: ${n}. The value detected was: ${value!.toString()}`;
    if (o.string){
        const valStr = value!.toString();
        const isAboveMaxEx = (isNotNullOrUndefined(o.maxEx) && valStr.length >= o.maxEx!);
        const isAboveMaxIn = (isNotNullOrUndefined(o.maxIn) && valStr.length > o.maxIn!);
        if (isAboveMaxEx || isAboveMaxIn) return `The length of text input "${n}" exceeded the maximum length of ${o.maxEx || o.maxIn}`;
        const isBelowMinEx = (isNotNullOrUndefined(o.minEx) && valStr.length <= o.minEx!);
        const isBelowMinIn = (isNotNullOrUndefined(o.minIn) && valStr.length < o.minEx!);
        if (isBelowMinEx || isBelowMinIn) return `The length of text input "${n}" was below the minimum length of ${o.minEx || o.minIn}`;
    }else {//SHOULD BE A NUMBER
        const isAboveMaxEx = (isNotNullOrUndefined(o.maxEx) && value! >= o.maxEx!);
        const isAboveMaxIn = (isNotNullOrUndefined(o.maxIn) && value! > o.maxIn!);
        if (isAboveMaxEx || isAboveMaxIn) return `The value of number input "${n}" exceeded the maximum value of ${o.maxEx || o.maxIn}`;
        const isBelowMinEx = (isNotNullOrUndefined(o.minEx) && value! <= o.minEx!);
        const isBelowMinIn = (isNotNullOrUndefined(o.minIn) && value! < o.minIn!);
        if (isBelowMinEx || isBelowMinIn) return `The value of number input "${n}" was below the minimum value of ${o.minEx || o.minIn}`;
    }
    //regardless of whether its a number or a string
    const customMessage = o.custom?o.custom(n, value!):undefined;
    return customMessage;
}
export const tempVal = {req: true, minIn: -70, maxIn: 70};//TEMPERATURE STANDARD VALIDATION
export const wattVal = {minIn: -1000000, maxIn: 1000000};//1 million min and max

const validationMessagesByOutputType: {[type in OutputType]:(calc: ICalculator)=>string|undefined} = {
    'HeatPumpCapacity': (calc: ICalculator) => {
        //console.log('in val mess by output');
        const a = allVariablesStandardValidation;
        const list = [
            a.plasticPipeNominalSize,
            a.steelPileNominalSize,
            a.steelPileLength,
            a.groundTempDeep,
            a.groundTempMid,
            a.groundTempSurface,
            a.thermalCondMin,
            a.thermalCondMean,
            a.thermalCondMax,
            a.ambientAirTemp,
            a.CCA,
            a.CCB,
            a.CCC,
            a.HCA,
            a.HCB,
            a.HCC,
            a.arrToHPTemp,
            a.arrToPileFlow,
            a.arrToPileTemp,
            a.flowOutOfPump,
            a.hpToArrTemp,
            a.numPiles,
            a.pileToArrTemp,
            a.pumpToArrFlow,
            a.targHPOut,
            a.temOutHP,
        ];
        return returnFirstMessageFound(list, calc);
    },
    'NumberOfPiles': (calc: ICalculator)=>{
        //console.log('in val mess by output');
        const a = allVariablesStandardValidation;
        const list = [
            a.plasticPipeNominalSize,
            a.steelPileNominalSize,
            a.steelPileLength,
            a.groundTempDeep,
            a.groundTempMid,
            a.groundTempSurface,
            a.thermalCondMin,
            a.thermalCondMean,
            a.thermalCondMax,
            a.ambientAirTemp,
            a.CCA,
            a.CCB,
            a.CCC,
            a.HCA,
            a.HCB,
            a.HCC,
            a.arrToHPTemp,
            // a.arrToPileFlow,
            a.arrToPileTemp,
            // a.flowOutOfPump,
            a.hpToArrTemp,
            a.pileToArrTemp,
            // a.pumpToArrFlow,
            a.targHPOutRequired,
            a.temOutHP,
            a.flowIntoPile,
        ];
        return returnFirstMessageFound(list,calc);

    },
    'PileLengthWithHeatPump': (calc: ICalculator)=>{
        const a = allVariablesStandardValidation;
        const list = [
            a.plasticPipeNominalSize,
            a.steelPileNominalSize,
            a.groundTempDeep,
            a.groundTempMid,
            a.groundTempSurface,
            a.thermalCondMin,
            a.thermalCondMean,
            a.thermalCondMax,
            a.ambientAirTemp,
            a.CCA,
            a.CCB,
            a.CCC,
            a.HCA,
            a.HCB,
            a.HCC,
            a.arrToHPTemp,
            a.arrToPileFlow,
            a.arrToPileTemp,
            a.flowOutOfPump,
            a.hpToArrTemp,
            a.numPiles,
            a.pileToArrTemp,
            a.pumpToArrFlow,
            a.targHPOutRequired,
            a.temOutHP,
        ];
        return returnFirstMessageFound(list, calc);

    },
    
    'PileLengthWithoutHeatPump': (calc: ICalculator)=>{
        const a = allVariablesStandardValidation;
        const list = [
            a.plasticPipeNominalSize,
            a.steelPileNominalSize,
            a.groundTempDeep,
            a.groundTempMid,
            a.groundTempSurface,
            a.thermalCondMin,
            a.thermalCondMean,
            a.thermalCondMax,
            a.ambientAirTemp,
            a.targetPPThermalCap,
            a.fluidTempIntoPile,
            a.flowIntoPile,
        ];
        return returnFirstMessageFound(list, calc);
    },
    'GroundTemperature': (calc: ICalculator)=>{
        const a = allVariablesStandardValidation;
        const list = [
            a.plasticPipeNominalSize,
            a.steelPileNominalSize,
            a.steelPileLength,
            a.thermalCondMin,
            a.thermalCondMean,
            a.thermalCondMax,
            a.ambientAirTemp,
            a.fluidTempOutOfPile,
            a.fluidTempIntoPile,
            a.flowIntoPile,
        ];
        return returnFirstMessageFound(list, calc);
    }
}



const allVariablesStandardValidation: {[key: string]: (calc: ICalculator)=>string|undefined} = {
    plasticPipeNominalSize: (calc: ICalculator)=>getErrorMessage('PlasticPipe Nominal Size',calc.plasticPipe?.plasticPipeNominalSizeForClient, {req: true, string: true}),
    steelPileNominalSize: (calc: ICalculator)=>getErrorMessage('SteelPile Nominal Size',calc.steelPile?.steelPileNominalSizeForClient,{req: true, string: true}),
    steelPileLength: (calc: ICalculator)=>getErrorMessage('Steel Pile Length',calc.steelPile?.length,{req:true, minIn: 10, maxIn: 100}),
    groundTempDeep:(calc: ICalculator)=>getErrorMessage('Ground Temperature 10m', calc.ground?.groundTemperature10mBelowTopOfPile,tempVal),
    groundTempMid:(calc: ICalculator)=>getErrorMessage('Ground Temperature 5m', calc.ground?.groundTemperature5mBelowTopOfPile,tempVal),
    groundTempSurface:(calc: ICalculator)=>getErrorMessage('Ground Temperature Top of Pile', calc.ground?.groundTemperatureAtTopOfPile,tempVal),
    thermalCondMin: (calc: ICalculator)=>getErrorMessage('Ground Thermal Conductivity Min', calc.ground?.minGroundThermalConductivity,{req:true, maxIn: 4,minEx: 0, custom: (n:string,val:any)=>{
        if (!calc.ground?.maxGroundThermalConductivity?.inputValue || !calc.ground?.averageGroundThermalConductivity?.inputValue) return undefined; //will be handled elsewhere;
        const isLargerThanThermCondMean = (calc.ground?.averageGroundThermalConductivity?.inputValue < val);
        const isLargerThanThermCondMax = (calc.ground?.maxGroundThermalConductivity?.inputValue < val);
        if (isLargerThanThermCondMax || isLargerThanThermCondMean) return `${n} cannot be larger than Ground Thermal Conductivity Mean or Max.`;
        return undefined;
    }}),
    thermalCondMean: (calc: ICalculator)=>getErrorMessage('Ground Thermal Conductivity Mean', calc.ground?.averageGroundThermalConductivity, {req:true, maxIn: 4, minEx:0}),
    thermalCondMax:(calc: ICalculator)=>getErrorMessage('Ground Thermal Conductivity Max', calc.ground?.maxGroundThermalConductivity, {req: true, maxIn: 4, minEx: 0, custom: (n: string, val:any)=>{
        if (!calc.ground?.minGroundThermalConductivity?.inputValue || !calc.ground?.averageGroundThermalConductivity?.inputValue) return undefined; //will be handled elsewhere;
        const isSmallerThanThermCondMean = (calc.ground?.averageGroundThermalConductivity?.inputValue > val);
        const isSmallerThanThermCondMin = (calc.ground?.minGroundThermalConductivity?.inputValue > val);
        if (isSmallerThanThermCondMean || isSmallerThanThermCondMin) return `${n} cannot be smaller than Ground Thermal Conductivity Mean or Min.`;
        return undefined;
    }}),
    ambientAirTemp: (calc: ICalculator)=>getErrorMessage('Ambient Air Temperature',calc.climate?.ambientAirTemperature, tempVal),
    temOutHP: (calc: ICalculator)=>getErrorMessage('Heat Pump Outlet Fluid Temperature', calc.pileInFluid?.heatPumpOutletFluidTemperature, tempVal),
    hpToArrTemp: (calc: ICalculator)=>getErrorMessage('Temperature Change Before Array', calc.pileInFluid?.temperatureChangeBeforeArray, {...tempVal,req:false}),
    arrToPileTemp: (calc: ICalculator)=>getErrorMessage('Average Inter-Pile Temperature Change (In)', calc.pileInFluid?.averageInterPileTemperatureChangeInflow, {...tempVal, req:false}),
    pileToArrTemp: (calc: ICalculator)=>getErrorMessage('Average Inter-Pile Temperature Change (Out)', calc.pileOutFluid?.averageInterPileTemperatureChangeOutflow,{...tempVal,req:false}),
    arrToHPTemp: (calc: ICalculator)=>getErrorMessage('Temperature Change After Array', calc.pileOutFluid?.temperatureChangeAfterArray,{...tempVal,req:false}),
    flowOutOfPump: (calc: ICalculator)=>getErrorMessage('Array Pump Outlet Flow Rate', calc.pileInFluid?.arrayPumpOutletFlowRate, {req:true, maxIn: 1, minEx: 0, custom: (n:string, val: any)=>{
            const flowChangeBeforeArray = parseFloat(calc.pileInFluid?.flowChangeBeforeArray?.inputValue?.toString()||'0' )||0;
            const perPileFlowChange = parseFloat(calc.pileInFluid?.averageInterPileFlowChangeInflow?.inputValue?.toString()||'0')||0;
            const numberOfActivePilesInTheArray = calc.pileArray?.numberOfActivePilesInTheArray?.inputValue ||1 ;
            const finalFlowRate = (val + flowChangeBeforeArray)/numberOfActivePilesInTheArray + perPileFlowChange ;
            if (finalFlowRate <=0) return `Final flow rate into the piles must be greater than 0.`;
            return undefined;
    }}),
    pumpToArrFlow: (calc: ICalculator)=>getErrorMessage('Pump to Array Flow Loss', calc.pileInFluid?.flowChangeBeforeArray, {req:false, maxIn: 0.5}),
    arrToPileFlow: (calc: ICalculator)=>getErrorMessage('Total Flow Loss Across Array', calc.pileInFluid?.averageInterPileFlowChangeInflow,{req:false, maxIn: 0.5 }),
    numPiles: (calc: ICalculator)=>getErrorMessage('Number of Piles', calc.pileArray?.numberOfActivePilesInTheArray, {req:true, minIn:1, maxIn: 10000}),
    targHPOut: (calc: ICalculator)=>getErrorMessage('Target Whole-Array Heat Pump Capacity', calc.pileArray?.targetWholeArrayHeatPumpCapacity, {req: false, ...wattVal}),
    targHPOutRequired : (calc: ICalculator)=>getErrorMessage('Target Whole-Array Heat Pump Capacity', calc.pileArray?.targetWholeArrayHeatPumpCapacity, {req: true, ...wattVal}),
    CCA:(calc: ICalculator)=>getErrorMessage('Cooling Season Coefficient A', calc.heatPump?.coolingSeasonCoefficientA, {req:true}),
    CCB:(calc: ICalculator)=>getErrorMessage('Cooling Season Coefficient B', calc.heatPump?.coolingSeasonCoefficientB, {req:true}),
    CCC:(calc: ICalculator)=>getErrorMessage('Cooling Season Coefficient C', calc.heatPump?.coolingSeasonCoefficientC, {req:true}),
    HCA:(calc: ICalculator)=>getErrorMessage('Heating Season Coefficient A', calc.heatPump?.heatingSeasonCoefficientA, {req:true}),
    HCB:(calc: ICalculator)=>getErrorMessage('Heating Season Coefficient B', calc.heatPump?.heatingSeasonCoefficientB, {req:true}),
    HCC:(calc: ICalculator)=>getErrorMessage('Heating Season Coefficient C', calc.heatPump?.heatingSeasonCoefficientC, {req:true}),
    targetPPThermalCap: (calc: ICalculator)=>getErrorMessage('Target Average Geo-Pile Thermal Capacity', calc.pileArray?.targetGeoPileThermalCapacity, {req:true, ...wattVal}),
    fluidTempIntoPile: (calc: ICalculator)=>getErrorMessage('Fluid Temperature Entering Pile', calc.pileInFluid?.pileInletFluidTemperature, tempVal),
    flowIntoPile: (calc: ICalculator)=>getErrorMessage('Flow Rate Entering Pile', calc.pileInFluid?.pileInletFlowRate, {req:true, maxIn: 1, minEx: 0}),
    fluidTempOutOfPile: (calc: ICalculator)=>getErrorMessage('Fluid Temperature Exiting Pile', calc.pileOutFluid?.pileOutletFluidTemperature,tempVal)
    };


