import { daysPerMonth, Months } from "components/Reusables/YearlessDatePicker";

const inputValueRanges = {
  lat: [],
  long: [],
  depth: [0, 500],
};

function dayIsInvalid(day: number | undefined, month: Months) {
  if (day === undefined) return "You must define a day of the month.";
  if (day < 1) return "Your day must be an integer larger than 0.";
  if (!Number.isInteger(day))
    return "Your chosen day value must be an integer.";
  if (day > daysPerMonth[month])
    return "Your day is outside of the range for your chosen month.";
  return "";
}
function numberInputIsInvalid(
  value: number | undefined,
  name: string,
  minMax: number[]
) {
  if (isUndefinedNullOrNaN(value))
    return "Your " + name + " value must be a valid number.";
  const m = isOutOfRangeMessage(value, minMax[0], minMax[1], name);
  if (m) return m;
  return undefined;
}
export function invalidInputsMessage(
  lat: number | undefined,
  long: number | undefined,
  depth: number | undefined,
  day: number | undefined,
  month: Months | undefined
): string | undefined {
  if (month === undefined) return "You must define a month.";
  const dayMessage = dayIsInvalid(day, month);
  if (dayMessage) return dayMessage;
  const latM = numberInputIsInvalid(lat, "Latitude", inputValueRanges.lat);
  if (latM) return latM;
  const longM = numberInputIsInvalid(long, "Longitude", inputValueRanges.long);
  if (longM) return longM;
  const depthM = numberInputIsInvalid(depth, "Depth", inputValueRanges.depth);
  if (depthM) return depthM;
  return undefined;
}

function isOutOfRangeMessage(
  value: any,
  min: number,
  max: number,
  valueName: string
) {
  if (value > max)
    return valueName + " is larger than the maximum value of " + max;
  if (value < min)
    return valueName + " is smaller than the minimum value of " + min;
}

function isUndefinedNullOrNaN(value: any) {
  if (value === undefined || value === null || value === NaN) {
    return true;
  }
  return false;
}
