
import React, {useState, useEffect, useContext, useCallback, useRef, ChangeEvent, SetStateAction} from 'react';
import { Option } from 'sheldons-components';
import styled from 'styled-components';
import HelpIcon from '@material-ui/icons/Help';
import { HorizFlex, VertFlex } from './MainMenu';
import GeneralInput from './Reusables/GeneralInput';
import Select from './Reusables/Select';
import Button from './Reusables/Button';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: left;
justify-content: space-between;
color: var(--c-black);
width: 100%; 
`;
const CrudSection = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: space-between;
`;
const Title = styled.h2`
    font-size: var(--fs-6);
    font-weight: 200;
    color: var(--c-green);
`;
const Description = styled.div`

`;
const SaveSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    background: var(--c-white-dark);
    box-shadow: var(--shadow-inner);
    position: relative;
    font-size: var(--fs-3);
`;
const DeleteSection = styled.div`
    font-size: var(--fs-3);
    margin-left: var(--s-10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
const DeleteTopLabel = styled.div`
    padding: 0;
    margin: var(--s-2);
`;
const DeleteSubsection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    background: var(--c-gray-very-light);
    margin: 0;
    padding: var(--s-6);
`;
const DeleteSubSubSection = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
    margin: 0;
    padding: 0;
`;

const InfoButton = styled(HelpIcon)`
position: absolute;
top: var(--s-5);
right: calc(-1 * var(--s-9));
cursor: pointer;
:hover {
    color: var(--c-green-dark);
}
`;

const FileInput = styled.input`
    background: none;
    color: var(--c-green);

`;
const NameAndSave = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--c-white);
    box-shadow: var(--shadow-inner);
`;
interface MLModelCrudSectionProps{
    title: string
    associatedOutputs: string[]
    saveCallback: ()=>void
    existingModelsOptions: Option[] | undefined
    deleteModelCallback: (option: Option)=>void
    infoButtonOnClick: (e: any)=>void
    file: File | undefined
    fileName: string | undefined
    setFile: React.Dispatch<SetStateAction<File|undefined>>
    setFileName: React.Dispatch<SetStateAction<string|undefined>>
    selectedModel: Option | undefined,
    setSelectedModel: (newModel: Option | undefined) =>void
}
const MLModelCrudSection: React.FunctionComponent<MLModelCrudSectionProps> =({
    title, associatedOutputs, saveCallback, existingModelsOptions, deleteModelCallback, infoButtonOnClick,
    selectedModel, setSelectedModel,file, setFile, fileName, setFileName
})=> {


    //debugging
    useEffect(()=>{
        //console.log(selectedModel);
        //console.log(existingModelsOptions);
    },[selectedModel, existingModelsOptions]);
return (
<Container>
<Title>{title}</Title>
<Description>
    This model is used when generating outputs for<br/>
    {associatedOutputs.map((outputName,index) => {
    if (associatedOutputs.length <2 ){
        return <b>{outputName}.</b>
    }
    if (index === 0){
        return <b>{outputName}</b>
    } if (index === associatedOutputs.length - 1){
        return <span>, and <b>{outputName}.</b></span>
    }
    return <span>, <b>{outputName}</b></span>
    })}
</Description>
<CrudSection>
    <SaveSection>
        <div>Upload your new file, ensuring it meets the model specifications.</div>
            <FileInput
            type="file"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const newFiles = e.target.files;
              if (!newFiles) return;
              const newFile = newFiles[0];
              setFile(newFile);
            }}
            />
        <div>Save this model under a new name to be used in predictions.</div>
        <NameAndSave>
        <GeneralInput
            type="text"
            placeholder={"Enter Name Here"}
            value={fileName}
            onChange={(e: any) => {
                const newName = e.target.value;
                setFileName(newName);
            }}
            />
          <Button
          onClick={saveCallback}
          >Save New Model</Button>
        </NameAndSave>
    <InfoButton onClick={infoButtonOnClick}></InfoButton>
    </SaveSection>
    <DeleteSection>
            <div>Permanently delete a model from the software</div>
            <DeleteSubsection>
                <DeleteTopLabel>Select an existing model to delete.</DeleteTopLabel>
                <DeleteSubSubSection>
             <Select
              type="styled"
              selected={selectedModel}
              onChange={(e: Option | undefined) => setSelectedModel(e)}
              options={existingModelsOptions}
              />
            <Button
              onClick={()=>selectedModel?deleteModelCallback(selectedModel):null}
              >
              Delete
            </Button> 
            </DeleteSubSubSection>
            </DeleteSubsection>
    </DeleteSection>
</CrudSection>
</Container>
);
}
export default MLModelCrudSection;
