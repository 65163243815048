
import { map } from 'mathjs';
import React, {useState, useEffect, useContext, useCallback, useRef, ReactNode, SetStateAction} from 'react';
import styled, { css } from 'styled-components';
import { isLabeledStatement } from 'typescript';


const Container = styled.div`
    margin-top: var(--s-13);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: var(--s-13);
`;

const ItemBase = css`
display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 0;
    box-sizing: content-box;
    width: 100%;
    font-size: var(--s-8);
    justify-content: space-between;
    :hover {
        background: var(--c-green);
        color: var(--c-white);
    }
    z-index: 100;
`;

const ItemContainer = styled.div`
    ${ItemBase}
`;
const SelectedItem = styled.div`
    ${ItemBase}
    border: var(--c-green) solid;

`;

export  type FormNavigationItem = {
    text: string
    ref: React.MutableRefObject<HTMLElement|null>
}

interface FormNavigationProps{
    items?: FormNavigationItem[] 
    selectedItem?: FormNavigationItem
    onSelect: (newSelectedItem: FormNavigationItem) =>void
}
const FormNavigation: React.FunctionComponent<FormNavigationProps> =({
    items, selectedItem, onSelect
})=> {

    ////console.log('selected item: ', selectedItem)
    ////console.log('items: ', items)

return (
<Container>
    {items?items.map((item,index)=>{
        if (!selectedItem && index ===0){
            return (
                <SelectedItem key={item.text}>
                    {item.text}
                </SelectedItem>
            );
        }
        if (item.text === selectedItem?.text){
            return (
                <SelectedItem key={item.text}>
                    {item.text}
                </SelectedItem>
            );
        } 
        return (
            <ItemContainer onClick={()=>onSelect(item)} key={item.text}>
                {item.text}
            </ItemContainer>
        );
    }):<></>}
</Container>
);
}
export default FormNavigation;
