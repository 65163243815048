import {
  BulkPredictionDTO,
  BulkPredictionDTOSchema,
  GTPInputsForBulkPredictionSchema,
} from "./models/bulk-prediction-dto";
import Ajv from "ajv";
import CSVFileValidator, { FieldSchema, ValidatorConfig } from 'csv-file-validator'
import { isNotNullOrUndefined, isNullishOrNanButNotZero, tempVal } from "./validation-helpers";
import { HeatPump, PileArray, PileInFluid, PlasticPipe, SteelPile } from "./models";
import { AllAbreviations, getOfficialVariableFromAbrv, readableVariableNoAbrev } from "./types";
import { sheldonsCSVFileValidator, SheldonsCSVValidatorColumnConfig, SheldonsCSVValidatorConfig } from "./my-csv-validator";


const ajv = new Ajv();

export async function validateBulkPredictionDTO(
  input: BulkPredictionDTO,
): Promise<string | undefined> {
  // check basic type of all required fields
  // check that all required fields exist
  //remove heatPump scaffolding object if not withHeatPupm
  if (!input.withHeatPump){
      input.heatPump = undefined
  }
  const validatorForBulkPrediction = ajv.compile(BulkPredictionDTOSchema(input.withHeatPump));
  if (input.maxInletFluidTemperature <= input.minInletFluidTemperature){
    return "Your Maximum Fluid Temperature limit cannot be lower than or equal to your Minimum Fluid Temperature limit."
  }
  const valid = validatorForBulkPrediction(input);
  if (!valid && validatorForBulkPrediction.errors) {
     //console.log('got json schema validation errors')
     //console.log(validatorForBulkPrediction.errors)
      const fE = validatorForBulkPrediction.errors[0];
      return `${fE.instancePath}, ${fE.keyword} has error: ${fE.message}`;
  }
}

export async function validateBulkPredictionCsv(csv: File | undefined){
   //console.log('running validateBulkPredictionCSV')
    if (!csv){
       //console.log('no csv file upload found');
        return 'No CSV file upload found.'
    }
    const result = await sheldonsCSVFileValidator(csv,bulkPredCsvValidatorConfig);
    return result;
}

const csvCols = [
    'WhpT',
    'WgheT',
    'AWgheT',
    'Tair',
    'Tground0',
    'Tground5',
    'Tground10',
    'Kavg',
    'Kmax',
    'Kmin',
    'Qoap',
    'Tlpre',
    'Tohp',
    'Tin',
    'Tliin',
    'Tliout',
    'Tlpost',
] as const;

function generateCsvValidatorConfigHeader(abrv: csvColsType): SheldonsCSVValidatorColumnConfig{
    return {
        validNames: abrv,
        columnRequired: false,
        validateCellCallback: (colVal: string,row: any)=>{
            const errorMessage = getErrorMessage(abrv,colVal, row,getErrorMessageOptionsByCol[abrv])
           //console.log('got this ErrorMessage',errorMessage);
            if (!errorMessage){
                return {
                    valid: true,
                    errorMessage: undefined
                }
            }else {
                // console.warn(errorMessage)
                return {
                    valid: false,
                    errorMessage: errorMessage
                }
            }

        }
    }
}
const bulkPredCsvValidatorConfig: SheldonsCSVValidatorConfig  ={
    columns : csvCols.map(abrv =>generateCsvValidatorConfigHeader(abrv)),
    validateEntireCSVCallback: (csvRows: any) => {
        //checking first row
        const tohp = csvRows[0]['Tohp'];
        const tin = csvRows[0]['Tin'];
       //console.log('in validate entire csv callback', tohp, tin, csvRows);
        if (isNullishOrNanButNotZero(tohp) && isNullishOrNanButNotZero(tin)){
            return 'You have forgotten to input either "Tohp" or "Tin" in the first row of your csv.';
        }
        return undefined;
    }
}

// function getCsvValidationErrors(file: File): Promise<string | undefined> {
//     //TODO
//     return CSVFileValidator(file, csvValidatorConfig)
//       .then(csvData => {
//           //TODO do something with the error messages
//         //   csvData.data // Array of objects from file
//        //console.log('got csv error messages...')
//           console.warn(csvData.inValidMessages) // Array of error messages
//           if (csvData.inValidMessages){
//               return csvData.inValidMessages[0]
//           }
//           return undefined
//       })
//       .catch(err => {
//          //console.log('error in csv file validator')
//        //console.log(err)
//         return err;
//     })

//   }



export type csvColsType = (typeof csvCols)[number]


interface CsvRowAndConsts extends CsvRow, BulkPredictionDTO{
}

type CsvRow =  {
    [colName in csvColsType]: string | number
}

const getErrorMessageOptionsByCol
    :{ [key in csvColsType]: ErrorMessageOptions   }
    = {
        WgheT:{
            req: false,
            custom:(n,v,r)=>{
               //console.log('testing WgheT, with ',r);
                if (isNullishOrNanButNotZero(r.WgheT) && isNullishOrNanButNotZero(r.AWgheT) && isNullishOrNanButNotZero(r.WhpT))
                {
                   //console.log(r)
                    return 'you must enter one of either "WgheT", "WhpT", or "AWgheT"'
                }
                if (!isNullishOrNanButNotZero(r.WgheT) && (!isNullishOrNanButNotZero(r.WhpT) || !isNullishOrNanButNotZero(r.AWgheT))){
                   //console.log(r)
                    return 'You cannot use more than one type of target variable in a single study.'
                }
            }
        },
        WhpT:{req: false, custom:(n,v,r)=>{
                if (isNullishOrNanButNotZero(r.WgheT) && isNullishOrNanButNotZero(r.AWgheT) && isNullishOrNanButNotZero(r.WhpT))
                {
                   //console.log(r)
                    return 'you must enter one of either "WgheT", "WhpT", or "AWgheT"'
                }
                if (!isNullishOrNanButNotZero(r.WhpT) && (!isNullishOrNanButNotZero(r.WgheT) || !isNullishOrNanButNotZero(r.AWgheT))){
                   //console.log(r)
                    return 'You cannot use more than one type of target variable in a single study.'
                }
            }
        },
        AWgheT:{req:false,
            custom:(n,v,r)=>{
                if (isNullishOrNanButNotZero(r.WgheT) && isNullishOrNanButNotZero(r.AWgheT) && isNullishOrNanButNotZero(r.WhpT)){
                    return 'you must enter one of either "WgheT", "WhpT", or "AWgheT"'
                }
                if (!isNullishOrNanButNotZero(r.AWgheT) && (!isNullishOrNanButNotZero(r.WgheT) || !isNullishOrNanButNotZero(r.WhpT))){
                   //console.log(r)
                    return 'You cannot use more than one type of target variable in a single study.'
                }
            }
        },
        Tground10:{...tempVal, req:true},
        Tground5:{...tempVal, req:true},
        Tground0:{...tempVal, req:true},
        Kmin: {req:true, maxIn: 4,minEx: 0, custom: (n:string,v,r)=>{
            if (v > r.Kmax || v > r.Kavg) {
                return 'Kmin cannot be larger than Kmax or Kavg'
            }
            return undefined
        }},
        Kavg:  {req:true, maxIn: 4, minEx:0},
        Kmax: {req: true, maxIn: 4, minEx: 0, custom: (n: string, v,r)=>{
            if (v < r.Kavg || v < r.Kmin){
                return 'Kmax cannot be smaller than Kmin or Kavg'
            }
            return undefined;
        }},
        Tair:  tempVal,
        'Tohp':  {...tempVal,req:false, custom:(n,v,r)=>{
            if (r.withKnownInletTemperature && r.withHeatPump){
                if (isNullishOrNanButNotZero(v)){
                    return 'All rows must have valid "Tohp" values since you have indicated you want to run the calculation with a heat pump and with a known incoming fluid temp value.'
                }
            }
            return undefined
        }},
        'Qoap':  {req:true, maxIn: 1, minEx: 0, custom: (n:string, v,r)=>{
                const flowChangeBeforeArray = r.flowChangeBeforeArray;
                const perPileFlowChange = r.averageInterPileFlowChangeInflow;
                const numberOfActivePilesInTheArray = r.numberOfActivePilesInTheArray;
                const finalFlowRate = (parseFloat(v.toString()) + flowChangeBeforeArray)/numberOfActivePilesInTheArray + perPileFlowChange ;
                if (finalFlowRate <=0) return `Final flow rate into the piles, after accounting for flow losses, must be greater than 0.`;
                return undefined;
        }},
        'Tin': {...tempVal, req:false, custom:(n,v,r)=>{
            if (r.withKnownInletTemperature && !r.withHeatPump){
                if (isNullishOrNanButNotZero(v)){
                    return 'All rows must have a valid "Tin" value since you have indicated you want to run the calculation with a known inlet temperature value.'
                }
            }
            return undefined
        }},
       'Tlpre':tempVal,
       'Tlpost':tempVal,
       'Tliout':tempVal,
       'Tliin':tempVal,
}



interface ErrorMessageOptions{
    req?: boolean; //required
    string?: boolean;
    minIn?: number; //min INCLUSIVE
    minEx?: number; //min EXCLUSIVE
    maxIn?: number; //max INCLUSIVE
    maxEx?: number; //max EXCLUSIVE
    custom?: (varName:string, value: string | number, row:CsvRowAndConsts)=> string | undefined;
}


export function getErrorMessage(abrv: AllAbreviations, value: string | number,row: CsvRowAndConsts, o: ErrorMessageOptions ): string | undefined{
    ////console.log('in getErrorMessage with ',abrv, value, row,o );
    // allow for 0!
    //regardless of whether its a number or a string
    const name = readableVariableNoAbrev[getOfficialVariableFromAbrv(abrv)];
    const customMessage = o.custom?o.custom(name, value, row):undefined;
    if (customMessage){
        return customMessage;
    }
    if ((!value && value !== 0 ) && o.req) return `Missing required variable: ${name}.`;
    if (!value && value !== 0) return undefined;
    const n = name;
    const isMissingButRequired =(
        o.req && (value === undefined || value === null || value === "" || (isNaN(parseFloat(value.toString())) && !o.string ))
        );
    if (isMissingButRequired) return `Missing required variable: ${n}`;
    if (isNullishOrNanButNotZero(value)) return undefined;
    const isStringButShouldntBe = (isNaN(parseFloat(value!.toString()))  && !o.string);
    if (isStringButShouldntBe) return `Invalid characters included in number-type variable: ${n}. The value detected was: ${value!.toString()}`;
    if (o.string){
        const valStr = value!.toString();
        const isAboveMaxEx = (isNotNullOrUndefined(o.maxEx) && valStr.length >= o.maxEx!);
        const isAboveMaxIn = (isNotNullOrUndefined(o.maxIn) && valStr.length > o.maxIn!);
        if (isAboveMaxEx || isAboveMaxIn) return `The length of text input "${n}" exceeded the maximum length of ${o.maxEx || o.maxIn}`;
        const isBelowMinEx = (isNotNullOrUndefined(o.minEx) && valStr.length <= o.minEx!);
        const isBelowMinIn = (isNotNullOrUndefined(o.minIn) && valStr.length < o.minEx!);
        if (isBelowMinEx || isBelowMinIn) return `The length of text input "${n}" was below the minimum length of ${o.minEx || o.minIn}`;
    }else {//SHOULD BE A NUMBER
        const isAboveMaxEx = (isNotNullOrUndefined(o.maxEx) && value! >= o.maxEx!);
        const isAboveMaxIn = (isNotNullOrUndefined(o.maxIn) && value! > o.maxIn!);
        if (isAboveMaxEx || isAboveMaxIn) return `The value of number input "${n}" exceeded the maximum value of ${o.maxEx || o.maxIn}`;
        const isBelowMinEx = (isNotNullOrUndefined(o.minEx) && value! <= o.minEx!);
        const isBelowMinIn = (isNotNullOrUndefined(o.minIn) && value! < o.minIn!);
        if (isBelowMinEx || isBelowMinIn) return `The value of number input "${n}" was below the minimum value of ${o.minEx || o.minIn}`;
    }
    
}