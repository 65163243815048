/* tslint:disable */
/* eslint-disable */
/**
 * GPPApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum PossibleCategoryNames {
    Calculator = 'Calculator',
    Ground = 'Ground',
    Climate = 'Climate',
    PileArray = 'PileArray',
    HeatPump = 'HeatPump',
    PileOutFluid = 'PileOutFluid',
    PileInFluid = 'PileInFluid',
    PlasticPipe = 'PlasticPipe',
    SteelPile = 'SteelPile',
    MiscOutputs = 'MiscOutputs'
}

