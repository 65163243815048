import { VertFlex } from 'components/MainMenu';
import { trimDecimalPlaces } from 'lib/helper-functions';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';
import { TextLabel } from './Template';

const StaticResult = styled.div`
    background: var(--c-black);
    color: var(--c-green-bright);
    font-size: var(--fs-6);
    /* font-size: var(--fs-6); */
    width: var(--s-17);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* padding: 0; */
    `;
    const GreenUnit = styled.div`
    color: var(--c-green-bright);
    font-size: var(--fs-10);
    padding: 0;
    margin-block: 0;
`;
const VariableContainer = styled.div`
background: var(--c-black);
box-sizing: border-box;
border: 1px solid var(--c-gray-light);
border-radius: var(--s-3);
margin-right:0;
`;
interface StaticOutputVariableProps{
    label: string
    value: number | string
    unit?:string
    decimalPlaces?: number
}
const StaticOutputVariable: React.FunctionComponent<StaticOutputVariableProps> =({
    value, label, unit, decimalPlaces
})=> {

return (
    <VariableContainer>
    <VertFlex>
    
       <StaticResult>
           {
               
               ((decimalPlaces) || decimalPlaces === 0)?
               trimDecimalPlaces(value.toString(), decimalPlaces):
               value
           }
    <GreenUnit>
        {unit}
    </GreenUnit> 

    </StaticResult>
    <TextLabel>
        {label}
    </TextLabel>
</VertFlex>
</VariableContainer>
);
}
export default StaticOutputVariable;
