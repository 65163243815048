import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";
import { convertUiInputStringToDto, IUiInputVariableString, IUiInputVariableStringOut, UiInputVariableString } from "./ui-input-variable-string";

export interface IPlasticPipeNominalSize extends IBaseModel {
  innerDiameter?: number;
  outerDiameter?: number;
}
export interface IPlasticPipeNominalSizeOut extends IBaseModel {
  innerDiameter?: number;
  outerDiameter?: number;

}
export class PlasticPipeNominalSize extends BaseModel implements IPlasticPipeNominalSize{
}
export function plasticPipeNominalSizeToDto(base: IPlasticPipeNominalSize): IPlasticPipeNominalSizeOut {
  return {
    id: base.id,
    name: base.name,
    innerDiameter:  base.innerDiameter,
    outerDiameter: base.outerDiameter,
  }
}