import { Schema } from "ajv";
import { OfficialVariables, readableVariable } from "lib/types";
import { BaseModel, IBaseModel } from "./base-model";
import { PossibleCategoryNames } from "./possible-category-names";


export const UiInputVariableStringSchema=(options: string[] | undefined): Schema=> {
 if (options == undefined){
  return {
    type: 'object',
  properties: {
    'inputValue': {type: 'string'}
  },
  required: ['inputValue'],
  additionalProperties: true,
  }
 }else {
  return {
    type: 'array',
    items: {
        type: 'string',
        enum: options
    }
  }
 }
}

export interface IUiInputVariableString extends IBaseModel {
  inputValue?: string | null;

  calculatedValue?: string | null;

  category?: PossibleCategoryNames;

  possibleValueNames?: string[] | null;

  possibleNamesJsonForDb?: string | null;


  unit?: string | null;

  canSolveFor?: boolean;
}
export interface IUiInputVariableStringOut {
  inputValue?: string;
}
export class UiInputVariableString extends BaseModel implements IUiInputVariableString{
  public constructor(type: OfficialVariables, possibilities: string[]){
    super();
    this.name = readableVariable[type];
    this.possibleValueNames = possibilities;
  }
  
  public inputValue?: string | null;

  public calculatedValue?: string | null;

  public category?: PossibleCategoryNames;

  public possibleValueNames?: string[] | null;

  public possibleNamesJsonForDb?: string | null;

  public solveFor?: boolean = false;

  public unit?: string | null;

  public canSolveFor?: boolean;
}

export function convertUiInputStringToDto(base?: null | IUiInputVariableString):undefined | IUiInputVariableStringOut{
  return (base)?{inputValue: (base.inputValue===null)?undefined:base.inputValue}:undefined;
}