import { BackendAPIContextProps } from "components/Contexts/BackendAPI";
import { createEmpty } from "lib/helper-functions";
import React from "react";
import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";

export interface IClimate extends IBaseModel{

  ambientAirTemperature?: IUiInputVariableDouble | null;
}
export interface IClimateOut extends IBaseModel{

  ambientAirTemperature?: IUiInputVariableDoubleOut | null;
}
export class Climate extends BaseModel implements IClimate{
  public ambientAirTemperature?: IUiInputVariableDouble = new UiInputVariableDouble('ambientAirTemperature');
}
export function climateToDto(base: IClimate): IClimateOut{
  return {
    id: base.id,
    name: base.name,
    ambientAirTemperature: convertUiInputDoubleToDto(base.ambientAirTemperature)
  };
}

