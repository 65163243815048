import { BaseModel, IBaseModel } from "./base-model";
import { IPileInFluidOut } from "./pile-in-fluid";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";

export interface IPileOutFluid extends IBaseModel {
  averageInterPileTemperatureChangeOutflow?: IUiInputVariableDouble | null;

  temperatureChangeAfterArray?: IUiInputVariableDouble | null;

  pileOutletFluidTemperature?: IUiInputVariableDouble | null;
}

export interface IPileOutFluidOut extends IBaseModel {
  averageInterPileTemperatureChangeOutflow?: IUiInputVariableDoubleOut | null;

  temperatureChangeAfterArray?: IUiInputVariableDoubleOut | null;

  pileOutletFluidTemperature?: IUiInputVariableDoubleOut | null;
}
export class PileOutFluid extends BaseModel implements IPileOutFluid{
  //the spaces in 'degC ' are there on purpose, do not remove them, the prevent the user from being able to convert those units

  public averageInterPileTemperatureChangeOutflow?: IUiInputVariableDouble = new UiInputVariableDouble('averageInterPileTemperatureChangeOutflow');

  public temperatureChangeAfterArray?: IUiInputVariableDouble = new UiInputVariableDouble('temperatureChangeAfterArray');

  public pileOutletFluidTemperature?: IUiInputVariableDouble = new UiInputVariableDouble('pileOutletFluidTemperature');
}
export function pileOutFluidToDto(base: IPileOutFluid): IPileOutFluidOut{
  return {
    id: base.id,
    name: base.name,
    averageInterPileTemperatureChangeOutflow: convertUiInputDoubleToDto(base.averageInterPileTemperatureChangeOutflow),
    temperatureChangeAfterArray: convertUiInputDoubleToDto(base.temperatureChangeAfterArray),
    pileOutletFluidTemperature: convertUiInputDoubleToDto(base.pileOutletFluidTemperature),

  };
}