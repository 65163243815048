import { GlobalContext } from 'components/Contexts/Global';
import BulkPredCsvInstructions from 'components/Instructions/BulkPredCsvInstructions';
import InfoPopup from 'components/Popups/InfoPopup';
import Button from 'components/Reusables/Button';
import MyFileInput from 'components/Reusables/MyFileInput';
import React, {useState, useEffect, useContext, useCallback, useRef, ChangeEvent, SetStateAction} from 'react';
import styled from 'styled-components';
import SectionContainer from './SectionContainer';
import { BasicCols,  InfoButton, InnerRows, InputInstr, LittleInstr, SimpleRow } from './Styles';

const FileInput = styled.input`
  background: none;
  color: var(--c-green);
`;
interface FileUploadSectionProps{
    setCsv: React.Dispatch<SetStateAction<File|undefined>>
    csv: File |undefined
}
const FileUploadSection: React.FunctionComponent<FileUploadSectionProps> =({setCsv, csv})=> {
    const gc = useContext(GlobalContext);
return (
<SectionContainer
    title="2. Upload Input File"
>

                      <BasicCols>
                        <InputInstr>
                          Upload your new file, ensuring it meets the model
                          specifications:
                        </InputInstr>
                        <MyFileInput setFile={setCsv} file={csv}/>                       
                        <InfoButton
                          onClick={() =>
                            gc?.setPopup(
                              <BulkPredCsvInstructions closeCallback={()=>gc.setPopup(undefined)}/>
                            )
                          }
                        ></InfoButton>
                      </BasicCols>
                      <BasicCols>
                        <LittleInstr>
                          For results to be generated this file must be in the
                          required format. Download a sample file to use as a
                          template for the headers and column order of your
                          .CSV:
                        </LittleInstr>
                        <Button
                          custCss="background-color: var(--c-black);"
                          onClick={() =>
                            window.open(
                              "ExampleBulkPredictionCsv.csv"
                            )
                          }
                        >
                          Download Sample File
                        </Button>
                      </BasicCols>
               
</SectionContainer>
);
}
export default FileUploadSection;