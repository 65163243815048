import { IBaseModel } from "lib/models/base-model";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  ChangeEvent,
} from "react";
import { useHistory } from "react-router";
import { Option } from "sheldons-components";
import GeneralInput from "./Reusables/GeneralInput";
import styled from "styled-components";
import { BackendAPIContext } from "./Contexts/BackendAPI";
import { GlobalContext } from "./Contexts/Global";
import { HorizFlex } from "./MainMenu";
import Button from "./Reusables/Button";
import Select from "./Reusables/Select";
import Header from "./Reusables/Header";
import MLModelCrudSection from "./MLModelCrudSection";
import Popup from "./Reusables/Popup";
import { OfficialVariables, readableVariable, variableUnit } from "lib/types";

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  padding-bottom: var(--s-12);
  background: var(--c-white);
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;
const GreenDivider = styled.div`
  background: var(--c-green-very-light);
  min-width: 33%;
  border-radius: none;
  padding: var(--s-2);
  align-self: flex-start;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--s-14);
`;

const MainTitle = styled.h1`
  color: var(--c-black);
  font-size: var(--fs-6);
`;

const ProfilePhoto = styled.img``;
const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30%;
  justify-content: space-between;
  height: 100%;
`;
const InnoviaLogo = styled.img`
  height: 100%;
`;
const GPPLogo = styled.img`
  height: 100%;
`;
const PopupContent = styled.div`
  background: var(--c-white);
  display: flex;
  flex-direction: column;
  align-items: left;
  color: var(--c-black);
  justify-content: space-between;
  height: var(--s-20);
  overflow: auto;
`;

interface MLModelCrudProps {}
const MLModelCrud: React.FunctionComponent<MLModelCrudProps> = ({}) => {
  const [fluidTempFile, setFluidTempFile] = useState<File>();
  const [fluidTempFileName, setFluidTempFileName] = useState<string>();
  const [pileLengthFile, setPileLengthFile] = useState<File>();
  const [pileLengthFileName, setPileLengthFileName] = useState<string>();
  const [groundTempFile, setGroundTempFile] = useState<File>();
  const [groundTempFileName, setGroundTempFileName] = useState<string>();
  const [toDeleteFluidFile, setToDeleteFluidFile] = useState<Option>();
  const [toDeleteGroundFile, setToDeleteGroundFile] = useState<Option>();
  const [toDeletePileLengthFile, setToDeletePileLengthFile] = useState<Option>();
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);
  const history = useHistory();
  //if the file changes, set its name to the name to be saved
  useEffect(() => {
    if (!groundTempFile) return;
    setGroundTempFileName(groundTempFile.name);
  }, [groundTempFile]);
  useEffect(() => {
    if (!fluidTempFile) return;
    setFluidTempFileName(fluidTempFile.name);
  }, [fluidTempFile]);
  useEffect(() => {
    if (!pileLengthFile) return;
    setPileLengthFileName(pileLengthFile.name);
  }, [pileLengthFile]);


  const handleSaveModel = useCallback(
    (
      endpoint:
        | "GroundTemperatureMLModels"
        | "PileOutletFluidTemperatureMLModels"
        | "PileLengthMLModels",
      fileRef: File | undefined,
      fileName: string | undefined,
      apiModelsList: any[] | undefined
    ) => {
      // //console.log('attempting to save model', fileRef, gc?.apiToken);
      if (!fileRef || !gc || !gc?.apiToken) return;
      // //console.log('here..');
      const fileType = fileRef.name.substr(fileRef.name.length - 5, 5);
      if (fileType !== ".onnx") {
        window.alert(
          'Error: The file you are trying to use is not an ".onnx" file.'
        );
        return;
      }
      if (!fileName) {
        window.alert("Error: You must provide a name for the file.");
        return;
      }
      if (fileName.length > 30) {
        window.alert("Error: The name cannot be longer than 30 characters");
        return;
      }
      if (
        apiModelsList &&
        apiModelsList.filter((x) => x.name === fileName).length > 0
      ) {
        window.alert("Error: That name is already in use.");
        return;
      }
      api?.saveFile(`${endpoint}?name=${fileName}`, fileRef, gc.apiToken!);
    },
    [api, gc]
  );
  const saveFluidTempModel = useCallback(() => {
    handleSaveModel(
     'PileOutletFluidTemperatureMLModels',
      fluidTempFile,
      fluidTempFileName,
      api?.pileOutletFluidTemperatureMLModels
    );
  }, [
    handleSaveModel,
    fluidTempFile,
    fluidTempFileName,
    api?.pileOutletFluidTemperatureMLModels,
  ]);
  const savePileLengthModel = useCallback(() => {
    handleSaveModel(
     'PileLengthMLModels',
      pileLengthFile,
      pileLengthFileName,
      api?.pileLengthMLModels
    );
  }, [
    handleSaveModel,
    pileLengthFile,
    pileLengthFileName,
    api?.pileLengthMLModels,
  ]);
  const saveGroundTempModel = useCallback(() => {
    handleSaveModel(
      "GroundTemperatureMLModels",
      groundTempFile,
      groundTempFileName,
      api?.groundTemperatureMLModels
    );
  }, [
    handleSaveModel,
    groundTempFile,
    groundTempFileName,
    api?.groundTemperatureMLModels,
  ]);

  function handleDeleteMLModel(type: "fluid" | "ground"|'pileLength') {
    if (!gc?.apiToken) return;
    if (type === "fluid") {
      if (!api || !toDeleteFluidFile) return;
      const name = toDeleteFluidFile.content?.toString();
      api?.deleteModel("PileOutletFluidTemperatureMLModels", name!, gc.apiToken);
    } else if(type === 'pileLength') {
      if (!api || !toDeletePileLengthFile)return;
      const name = toDeletePileLengthFile.content?.toString();
      api.deleteModel("PileLengthMLModels",name!, gc.apiToken);
    }
    else {
      if (!api || !toDeleteGroundFile) return;
      const name = toDeleteGroundFile.content?.toString();
      api?.deleteModel("GroundTemperatureMLModels", name!, gc.apiToken);
    }
  }

  function getMLModelsAsOptions(
    type: "fluidTemp" | "groundTemp" | "pileLength"
  ): Option[] | undefined {
    if (!api) return undefined;
    let baseList = type==="fluidTemp"?api.pileOutletFluidTemperatureMLModels:
                  type==="groundTemp"?api.groundTemperatureMLModels:
                  api.pileLengthMLModels;
      if (!baseList) return undefined;
      const fullList = baseList.map((x:any) =>
        baseModelToOption(x)
      );
      //@ts-ignore
      const validList: Option[] = fullList.filter((x) => x !== undefined);
      if (validList.length < 1 || !validList) return undefined;
      return validList;
  }


  function baseModelToOption(baseModel?: IBaseModel) {
    if (!baseModel || !baseModel.id || !baseModel.name) return undefined;
    return { id: baseModel.id, content: baseModel.name, value: baseModel.name };
  }

  function getLI(type: OfficialVariables) {
    return <li>{readableVariable[type]} [{variableUnit[type]}]</li>;
  }

  return (
    <Container>
      <HeaderContainer>
        <ProfilePhoto src="profile-photo.svg" />
        <Button onClick={() => history.push("/")}>Exit</Button>
        <MainTitle>Edit Machine Learning Models</MainTitle>
        <Logos>
          <InnoviaLogo src="logo.svg" />
          <GPPLogo src="simpleLogo.svg" />
        </Logos>
      </HeaderContainer>
      <ContentContainer>
        <MLModelCrudSection
          title="1. Pile Capacity Model"
          associatedOutputs={[
            "Heat Pump Capacity",
            "Number of Piles",
          ]}
          saveCallback={saveFluidTempModel}
          deleteModelCallback={() => handleDeleteMLModel("fluid")}
          infoButtonOnClick={() => {
            gc?.setPopup(
              <Popup
                title="Pile Capacity Model Specifications"
                closeCallback={() => gc.setPopup(undefined)}
              >
                <PopupContent>
                  <div>The uploaded model must: </div>
                  <ul>
                    <li>be a ".onnx" file type</li>
                    <li>
                      be compatable with NuGetPackage: Microsoft.ML.OnnxRuntime
                      version 1.7
                    </li>
                  </ul>
                  <GreenDivider></GreenDivider>
                  <div>
                    Model inputs are a one-dimmensional list of floats, each
                    with the name "float_input".
                  </div>
                  <div>
                    This model takes 15 values in its input list, in the
                    following order:
                  </div>
                  <ol>
                    {[
                      getLI("ambientAirTemperature"),
                      getLI("pileInletFluidTemperature"),
                      getLI("groundTemperatureAtTopOfPile"),
                      getLI("groundTemperature5mBelowTopOfPile"),
                      getLI("groundTemperature10mBelowTopOfPile"),
                      getLI("maxGroundThermalConductivity"),
                      getLI("averageGroundThermalConductivity"),
                      getLI("minGroundThermalConductivity"),
                      getLI("steelPileOuterDiameter"),
                      getLI("steelPileInnerDiameter"),
                      getLI("plasticPipeOuterDiameter"),
                      getLI("plasticPipeInnerDiameter"),
                      getLI("steelPileLength"),
                      getLI("plasticInletPipeLength"),
                      getLI("pileInletFlowRate"),
                    ]}
                  </ol>
                </PopupContent>
              </Popup>
            );
          }}
          existingModelsOptions={getMLModelsAsOptions("fluidTemp")}
          file={fluidTempFile}
          fileName={fluidTempFileName}
          setFile={setFluidTempFile}
          setFileName={setFluidTempFileName}
          selectedModel={toDeleteFluidFile}
          setSelectedModel={setToDeleteFluidFile}
        />
        <GreenDivider></GreenDivider>
        <MLModelCrudSection
          title="2. Soil Temperature Model"
          associatedOutputs={["Soil Temperature"]}
          saveCallback={saveGroundTempModel}
          deleteModelCallback={() => handleDeleteMLModel("ground")}
          infoButtonOnClick={() => {
            gc?.setPopup(
              <Popup
                title="Soil Temperature Model Specifications"
                closeCallback={() => gc.setPopup(undefined)}
              >
                <PopupContent>
                  <div>The uploaded model must: </div>
                  <ul>
                    <li>be a ".onnx" file type</li>
                    <li>
                      be compatable with NuGetPackage: Microsoft.ML.OnnxRuntime
                      version 1.7
                    </li>
                  </ul>
                  <GreenDivider></GreenDivider>
                  <div>
                    Model inputs are a one-dimmensional list of floats, each
                    with the name "float_input".
                  </div>
                  <div>
                    This model takes 13 values in its input list, in the
                    following order:
                  </div>
                  <ol>
                    {[
                      getLI("pileOutletFluidTemperature"),
                      getLI("ambientAirTemperature"),
                      getLI("pileInletFluidTemperature"),
                      getLI("maxGroundThermalConductivity"),
                      getLI("averageGroundThermalConductivity"),
                      getLI("minGroundThermalConductivity"),
                      getLI("steelPileOuterDiameter"),
                      getLI("steelPileInnerDiameter"),
                      getLI("plasticPipeOuterDiameter"),
                      getLI("plasticPipeInnerDiameter"),
                      getLI("steelPileLength"),
                      getLI("plasticInletPipeLength"),
                      getLI("pileInletFlowRate"),
                    ]}
                  </ol>
                </PopupContent>
              </Popup>
            );
          }}
          existingModelsOptions={getMLModelsAsOptions("groundTemp")}
          file={groundTempFile}
          fileName={groundTempFileName}
          setFile={setGroundTempFile}
          setFileName={setGroundTempFileName}
          selectedModel={toDeleteGroundFile}
          setSelectedModel={setToDeleteGroundFile}
        />
        <GreenDivider></GreenDivider>
        <MLModelCrudSection
          title="3. Pile Length Model"
          associatedOutputs={["Pile Length"]}
          saveCallback={savePileLengthModel}
          deleteModelCallback={() => handleDeleteMLModel("pileLength")}
          infoButtonOnClick={() => {
            gc?.setPopup(
              <Popup
                title="Pile Length Model Specifications"
                closeCallback={() => gc.setPopup(undefined)}
              >
                <PopupContent>
                  <div>The uploaded model must: </div>
                  <ul>
                    <li>be a ".onnx" file type</li>
                    <li>
                      be compatable with NuGetPackage: Microsoft.ML.OnnxRuntime
                      version 1.7
                    </li>
                  </ul>
                  <GreenDivider></GreenDivider>
                  <div>
                    Model inputs are a one-dimmensional list of floats, each
                    with the name "float_input".
                  </div>
                  <div>
                    This model takes 14 values in its input list, in the
                    following order:
                  </div>
                  <ol>
                    {[
                      getLI("pileOutletFluidTemperature"),
                      getLI("ambientAirTemperature"),
                      getLI("pileInletFluidTemperature"),
                      getLI("groundTemperatureAtTopOfPile"),
                      getLI("groundTemperature5mBelowTopOfPile"),
                      getLI("groundTemperature10mBelowTopOfPile"),
                      getLI("maxGroundThermalConductivity"),
                      getLI("averageGroundThermalConductivity"),
                      getLI("minGroundThermalConductivity"),
                      getLI("steelPileOuterDiameter"),
                      getLI("steelPileInnerDiameter"),
                      getLI("plasticPipeOuterDiameter"),
                      getLI("plasticPipeInnerDiameter"),
                      getLI("pileInletFlowRate"),
                    ]}
                  </ol>
                </PopupContent>
              </Popup>
            );
          }}
          existingModelsOptions={getMLModelsAsOptions("pileLength")}
          file={pileLengthFile}
          fileName={pileLengthFileName}
          setFile={setPileLengthFile}
          setFileName={setPileLengthFileName}
          selectedModel={toDeletePileLengthFile}
          setSelectedModel={setToDeletePileLengthFile}
        />
      </ContentContainer>
    </Container>
  );
};
export default MLModelCrud;
