import math, { filter, random } from "mathjs";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
  ChangeEvent,
} from "react";
import styled from "styled-components";
import Button from "./Button";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const Label = styled.div`

`;

interface MyFileInputProps {
  setFile: React.Dispatch<SetStateAction<File | undefined>>;
  file: File | undefined;
}
const MyFileInput: React.FunctionComponent<MyFileInputProps> = ({
  setFile,
  file,
}) => {
  const randomId = random(0, 10000).toString();
  const hiddenInputId = "hidden_file_input_" + randomId;
  return (
    <Container>
      <input
        id={hiddenInputId}
        type="file"
        accept="csv"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          
          const newFiles = e.target.files;
          if (!newFiles) return;
          const newFile = newFiles[0];
          setFile(newFile);
        }}
        onClick={(e: any)=>{e.target.value = null}}
        style={{ display: "none" }}
      />
      <Button onClick={() => document.getElementById(hiddenInputId)!.click()}>
        Choose File
      </Button>
      <Label>{file ? file.name : "No file chosen"}</Label>
    </Container>
  );
};
export default MyFileInput;
