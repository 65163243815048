import NumberInput from "components/Reusables/NumberInput";
import Select from "components/Reusables/Select";
import { IUiInputVariableDouble, IUiInputVariableInt } from "lib/models";
import {
  flowRateUnits,
  getAllowedUnitsFromBaseUnit,
  lengthUnits,
  tempUnits,
} from "lib/units";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Option } from "sheldons-components";
import _ from "lodash";
import styled from "styled-components";
import Button from "components/Reusables/Button";
import { HorizFlex } from "components/MainMenu";
import HelpIcon from '@material-ui/icons/Help';
import { GlobalContext } from "components/Contexts/Global";
import InfoPopup from "components/Popups/InfoPopup";
import { OfficialVariables } from "lib/types";
import { getSpecialButtonOnClick, getSpecialButtonText } from "lib/objectCrudPropsHelpers";
import { BackendAPIContext } from "components/Contexts/BackendAPI";

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  padding-left: var(--s-7);
  width: 100%;
`;
const SubSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width:var(--s-20);
`;

const InfoButton = styled(HelpIcon)`
margin-left: var(--s-10);
cursor: pointer;
color: var(--c-green);
:hover{
  color: var(--c-black);
}
`;

const FieldAndInstructionContainer = styled.div<{ emphasize?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin:0;
  padding:0;
  ${(props) => {
    if (props.emphasize) {
      return `
        border: solid var(--c-green);
      
      `;
    }
  }}
`;
export const Instructions = styled.div`
  font-style: italic;
  font-weight: 600;
  color: var(--c-green);
  text-align: left;
  align-self: flex-start;
`;

const UnitPosition = styled.div`
  /* margin: 0; */
  /* padding: 0; */
  min-width: var(--s-15);
`;
const FieldContainer = styled.div`
  /* padding: 0; */
  /* margin:0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
  
`;
const DisabledOverlay = styled.div`
  backdrop-filter: blur(1px);
  background: var(--c-light-transparent-half);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  color: var(--c-green);
  font-style: italic;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
const InvisibleDiv = styled.div`
  margin: 0;
  padding: 0;
`;

interface EditFormGeneratorProps {
  object: { [key: string]: any };
  setObject: (...args: any) => void;
  instructionKeyMap: { [key: string]: string | undefined };
  fieldsToExclude?: string[];
  fieldsToEmphasize?: string[];
  fieldsToDisable?: string[];
  universalDisabledMessage?: string;
  imageLinkMap: {[fieldName:string]:string};
  fieldsInOrder: OfficialVariables[];
}
const EditFormGenerator: React.FunctionComponent<EditFormGeneratorProps> = ({
  object,
  setObject,
  instructionKeyMap,
  fieldsToExclude,
  fieldsToDisable,
  fieldsToEmphasize,
  universalDisabledMessage,
  imageLinkMap,
  fieldsInOrder
}) => {
  // //debugging
  // useEffect(()=>{
  //     //console.log('object has changed', object);
  // },[object]);
  const gc = useContext(GlobalContext);
  const api = useContext(BackendAPIContext);

  function isInvalidKey(key: string, object: any) {
    const result =
      key === "id" ||
      key === "name" ||
      !object[key] ||
      !object[key].name ||
      fieldsToExclude?.includes(key);
    // //console.log('input is invalid type?', result);
    return result;
  }
  function isSelectType(key: string, object: any) {
    const result = object[key]?.possibleValueNames;
    // //console.log('input is select type?', result);
    return result;
  }

  function possibleValueNamesToOptions(pvn: string[]): Option[] {
    return pvn.map((str) => singleStringToOption(str));
  }
  function singleStringToOption(str: string): Option {
    return {
      id: str,
      value: str,
      content: str,
    };
  }
  function alterObjectDeep(
    fieldPath: string,
    newValue: any,
    setObject: any
  ): void {
    setObject((prev: any) => {
      const clone = { ...prev };
      _.set(clone, fieldPath + ".inputValue", newValue);
      return clone;
    });
  }
  //debugging
  // useEffect(()=>{
  //   //console.log('object in edit form generator has changed, and input values should have changed too ',object);
  // },[object]);
  return (
    <>
      {object ? (
        fieldsInOrder.map((key) => {
          // //console.log('rerendering in EditFormGenerator');
          if (isInvalidKey(key, object))
            return <InvisibleDiv key={key}></InvisibleDiv>;
          if (isSelectType(key, object)) {
            const selectOptions = object[key]?.possibleValueNames;
            return (
              <FieldAndInstructionContainer
              emphasize={fieldsToEmphasize?.includes(key)}
              
              >
                {instructionKeyMap[key] ? (
                  <Instructions>{instructionKeyMap[key]}</Instructions>
                ) : (
                  <></>
                )}
              <FieldContainer
                key={key}
                // emphasize={fieldsToEmphasize?.includes(key)}
              >
                {fieldsToDisable?.includes(key) ? (
                  <DisabledOverlay>{universalDisabledMessage}</DisabledOverlay>
                ) : (
                  <></>
                )}
                
                <SelectContainer>
                  <SubSelect>
                    <p>{object[key].name}</p>
                  <Select
                    key={key}
                    type={"styled"}
                    placeholder={"Select An Option"}
                    options={possibleValueNamesToOptions(selectOptions)}
                    selected={
                      object[key].inputValue
                        ? singleStringToOption(object[key].inputValue)
                        : undefined
                    }
                    onChange={(newVal?: Option) =>
                      alterObjectDeep(key, newVal?.value, setObject)
                    }
                  />
                  </SubSelect>
                  
                </SelectContainer>
                <UnitPosition></UnitPosition>
                
                  <InfoButton onClick={()=>gc?.setPopup(<InfoPopup imageLink={imageLinkMap[key]}/>)}></InfoButton>
              </FieldContainer>
              </FieldAndInstructionContainer>
            );
          } else {
            const subObj: IUiInputVariableDouble | IUiInputVariableInt | null =
              object[key];
            // //console.log('getting number input type with ', subObj);
            return (
              <FieldAndInstructionContainer
              emphasize={fieldsToEmphasize?.includes(key)}
              
              >
                {instructionKeyMap[key] ? (
                  <Instructions>{instructionKeyMap[key]}</Instructions>
                ) : (
                  <></>
                )}
                  <FieldContainer
                    key={key}
                  >
                {fieldsToDisable?.includes(key) ? (
                  <DisabledOverlay>{universalDisabledMessage}</DisabledOverlay>
                ) : (
                  <></>
                )}
                <NumberInput //ts not smart enough to recognize subObj cant be null
                  onChange={undefined}
                  key={key}
                  label={subObj!.name !== null ? subObj!.name : undefined}
                  value={

                    (subObj?.inputValue !== undefined && typeof subObj?.inputValue === "number" && !isNaN(subObj?.inputValue))? subObj?.inputValue:
                      (subObj?.inputValue !== undefined && !isNaN(parseFloat(subObj?.inputValue?.toString())))? parseFloat(subObj?.inputValue?.toString()):undefined
                  }
                  max={subObj!.maxAllowed}
                  min={subObj!.minAllowed}
                  decimals={14}
                  outputUnit={subObj!.unit ? subObj!.unit : undefined}
                  allowedUnits={
                    subObj!.unit
                      ? getAllowedUnitsFromBaseUnit(subObj!.unit)
                      : undefined
                  }
                  setValue={(newValue: any)=>{
                    //console.log('number input in frontend received this new value from sheldons components:'+newValue);
                    alterObjectDeep(key, newValue, setObject);
                  }}
                  specialButtonText={getSpecialButtonText(key)}
                  specialButtonOnClick={api?getSpecialButtonOnClick(key, api):undefined}
                />
                  <InfoButton onClick={()=>gc?.setPopup(<InfoPopup imageLink={imageLinkMap[key]}/>)}></InfoButton>


              </FieldContainer>
              </FieldAndInstructionContainer>
            );
          }
        })
      ) : (
        <></>
      )}
    </>
  );
};
export default EditFormGenerator;
