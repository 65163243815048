import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  background: var(--c-gray);
  position: relative;
  padding-left: var(--s-7);
  padding-right: var(--s-7);
  padding-top: var(--s-2);
  padding-bottom: var(--s-2);
  margin-bottom: var(--s-15);
  color: var(--c-white);
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;
const TitleContainer = styled.div`
  background: var(--c-black);
  width: 99%;
  position: absolute;
  top: -4vw;
  left: 0;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
  box-shadow: inset 0px 4px 8px rgba(255, 255, 255, 0.5);
`;
export const BasicRows = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: left;
justify-content: flex-start;
position: relative;
/* margin-inline: var(--s-10); */
padding: var(--s-8);
margin: var(--s-8);
margin-left: var(--s-5);
background: var(--c-gray-dark);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;


export const ContentContrastContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: left;
justify-content: flex-start;
position: relative;
/* margin-inline: var(--s-10); */
padding: var(--s-8);
margin: var(--s-8);
margin-left: var(--s-5);
background: var(--c-gray-dark);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

interface SectionContainerProps {
    title: string

}
const SectionContainer: React.FunctionComponent<SectionContainerProps> = ({
  children, title
}) => {
  return (
    <MainContainer>
      <TitleContainer>
        <TitleText>{title}</TitleText>
      </TitleContainer>
      <ContentContrastContainer>
      {children}
      </ContentContrastContainer>
    </MainContainer>
  );
};
export default SectionContainer;
