import { GlobalContext } from "components/Contexts/Global";
import { trimDecimalPlaces } from "lib/helper-functions";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
  ReactNode,
} from "react";
import styled, { css } from "styled-components";
import CircleChart from "./CircleChart";
import DynamicOutputVariable from "./DynamicOutputVariable";
import GenerateReportForm from "./GenerateReportForm";
import InputOutputVariable from "./InputOutputVariable";
import StaticOutputVariable from "./StaticOutputVariable";
import { createEmpty, getPictureByOutputType, vw } from "lib/helper-functions";
// import BarLineChart from "./BulkResultsGraph";
import BarLineChart from "./BarLineChart";
import { HorizFlex, VertFlex } from "components/MainMenu";
import TripleLineChart, { TripleLineChartProps } from "./TripleLineChart";
// import BulkResultsGraph from './BulkResultsGraph';
//import CircleChart from './CircleChart';

const ResultsImage = styled.img`
  height: var(--s-20);
`;
const DetailImage = styled.img`
  height: var(--s-16);
  align-items: center;
  margin: var(--s-4);
`;

export const TextLabel = styled.h4`
  font-size: var(--s-9);
  color: var(--c-gray-light);
  align-items: center;
`;

export const Unit = styled.div`
  color: var(--c-green-bright);
  font-size: var(--s-20);
  padding: 0;
  margin-block: 0;
`;

const StandardSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TopSection = styled.div`
  ${StandardSection}
`;
const MiddleResultsSection = styled.div`
  ${StandardSection}
  flex-direction: row;
  align-items: flex-start;
`;
const MiddleSection = styled.div`
  ${StandardSection}
  flex-direction: row;
  align-items: flex-start;
`;
const LeftSection = styled.div`
  ${StandardSection}
`;

const CenterSection = styled.div`
  ${StandardSection}
`;
const RightSection = styled.div`
  ${StandardSection}
`;
const BottomSection = styled.div`
  ${StandardSection}
`;
const RightActionSection = styled.div`
  ${StandardSection}
  justify-content: flex-start;
  height: 100%;
`;

type ResultsVariableType =
  | "static"
  | "dynamic"
  | "circle"
  | "input"
  | "minorStatic"
  | "barLineGraph"
  | 'tripleLineGraph'
  ;

export interface IResultsVariable {
  label: string;
  unit?: string;
  array?: number[];
  value?: number | string;
  multiGraphValues?: {
    xAxisLabels: string[];
    lineData: number[];
    barData: number[];
    title: string;
  };
  tripleLineGraphValues?: TripleLineChartProps;
  decimalPlaces?: number;
  type: ResultsVariableType;
  setValue?: React.Dispatch<SetStateAction<number | undefined | null>>;
  column: "left" | "top" | "center" | "right" | "bottom";
  orderWithinColumn: number;
}

interface ResultsViewTemplateProps {
  resultsVariables: IResultsVariable[];
  withHeatPump?: boolean;
  sessions: any[];
}
const ResultsViewTemplate: React.FunctionComponent<ResultsViewTemplateProps> =
  ({ resultsVariables, withHeatPump, sessions }) => {
    const gc = useContext(GlobalContext);

    const topVars = resultsVariables
      .filter((x) => x.column === "top")
      .sort((a, b) => a.orderWithinColumn - b.orderWithinColumn);
    const botVars = resultsVariables
      .filter((x) => x.column === "bottom")
      .sort((a, b) => a.orderWithinColumn - b.orderWithinColumn);
    const leftVars = resultsVariables
      .filter((x) => x.column === "left")
      .sort((a, b) => a.orderWithinColumn - b.orderWithinColumn);
    const centerVars = resultsVariables
      .filter((x) => x.column === "center")
      .sort((a, b) => a.orderWithinColumn - b.orderWithinColumn);
    const rightVars = resultsVariables
      .filter((x) => x.column === "right")
      .sort((a, b) => a.orderWithinColumn - b.orderWithinColumn);

    function variablesToComponents(vars: IResultsVariable[]): ReactNode {
      return vars.map((i) => {
        if (i.type === 'barLineGraph' && i.multiGraphValues) {
          return (
            <BarLineChart
              key={i.label}
              xAxisLabels={i.multiGraphValues.xAxisLabels}
              lineData={i.multiGraphValues.lineData}
              lineLabel="Target"
              barData={i.multiGraphValues.barData}
              barLabel="Actual"
              title={i.multiGraphValues.title}
              // value={ parseFloat((parseFloat(i.value.toString())||0).toFixed(2))
              //  } label={i.label}
            />
          );
        }
        if (i.type === 'tripleLineGraph' && i.tripleLineGraphValues) {
          return (
            <TripleLineChart
            key={i.label}
            title={i.tripleLineGraphValues.title}
            lines={i.tripleLineGraphValues.lines}
            xAxisLabels={i.tripleLineGraphValues.xAxisLabels}
            />
          );
        }
        if (i.type === "static") {
          return (
            <StaticOutputVariable
              key={i.label}
              value={i.value || 0}
              unit={i.unit}
              decimalPlaces={i.decimalPlaces}
              label={i.label}
            />
          );
        }
        if (i.type === "dynamic") {
          return (
            <DynamicOutputVariable
              key={i.label}
              value={i.value || 0}
              unit={i.unit}
              label={i.label}
              decimalPlaces={i.decimalPlaces}
            />
          );
        }
        if (i.type === "input" && i.setValue && typeof i.value === "number") {
          return (
            <InputOutputVariable
              key={i.label}
              value={i.value}
              unit={i.unit}
              label={i.label}
              setValue={i.setValue}
            />
          );
        }
        if (i.type === "circle" && i.value != undefined) {
          return (
            <CircleChart
              key={i.label}
              value={parseFloat(
                (parseFloat(i.value.toString()) || 0).toFixed(2)
              )}
              label={i.label}
            />
          );
        }

        return <></>;
      });
    }

    return (
      <VertFlex>
        {topVars?.length ? (
          <TopSection>{variablesToComponents(topVars)}</TopSection>
        ) : (
          <></>
        )}
        <MiddleSection>
          <MiddleResultsSection>
            {leftVars?.length ? (
              <LeftSection>{variablesToComponents(leftVars)}</LeftSection>
            ) : (
              <></>
            )}
            {(centerVars?.length || getPictureByOutputType(gc?.outputVariable)) ? (
              <CenterSection>
                {getPictureByOutputType(gc?.outputVariable) ? (
                  <ResultsImage
                    src={getPictureByOutputType(gc?.outputVariable)}
                    alt=""
                  ></ResultsImage>
                ) : (
                  <></>
                )}
                {variablesToComponents(centerVars)}
              </CenterSection>
            ) : (
              <></>
            )}
            {rightVars?.length ? (
              <RightSection>{variablesToComponents(rightVars)}
              {gc?.outputVariable === "Bulk Heat Pump Capacity" ? (
          <></>
        ) : (
          <DetailImage src="DeltaT.svg" />
        )}
              
              </RightSection>
            ) : (
              <></>
            )}
          </MiddleResultsSection>
          <RightActionSection>
            <GenerateReportForm
              type={gc?.outputVariable!}
              withHeatPump={withHeatPump}
              sessions={sessions}
            />
            
          </RightActionSection>
        </MiddleSection>
        {botVars?.length ? (
          <BottomSection>{variablesToComponents(botVars)}</BottomSection>
        ) : (
          <></>
        )}
        
      </VertFlex>
    );
  };
export default ResultsViewTemplate;
