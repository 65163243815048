import { ICalculator, OutputVariables } from "lib/models";
import { IBaseModel } from "lib/models/base-model";
import { useOnClickOutside } from "lib/useOnClickOutside";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { Option } from "sheldons-components";
import styled, { css } from "styled-components";
import { BackendAPIContext } from "./Contexts/BackendAPI";
import { GlobalContext } from "./Contexts/Global";
import ChangePassword from "./Popups/ChangePassword";
import LocationInput from "./Popups/LocationInput";
import BarLineChart from "./ResultsViews/Reusables/BarLineChart";
import Button from "./Reusables/Button";
import Select from "./Reusables/Select";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: var(--s-15);
  margin-bottom: var(--s-2);
  margin-right: var(--s-2);
  margin-left: var(--s-2);
  height: 100vh;
  /* background: var(--c-gray-very-light) */
`;
const Topcontainer = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: var(--s-5);
  z-index: 10;
  background: var(--c-black);
  border-radius: 0;
`;

const GrayButton = styled(Button)`
  background: var(--c-gray);
  height: var(--s-11);
  font-size: var(--fs-3);
  border-radius: var(--light-rounding);
`;
const TopGreenButton = styled(Button)`
  height: var(--s-11);
  font-size: var(--fs-3);
`;

const InnoviaLogo = styled.img`
  height: var(--s-14);
  margin: var(--s-4);
  position: fixed;
  top: var(--s-15);
  right: var(--s-14);
`;

const SmallSelect = styled(Select)`
  /* font-size: var(--s-4); */
`;

const ProfilePhoto = styled.img`
  height: var(--s-11);
`;

const GPPLogo = styled.img`
  height: var(--s-16);

`;
const CapacityIcon = styled.img`
  height: var(--s-15);
`;
const NumberIcon = styled.img`
  height: var(--s-15);
`;
const LengthIcon = styled.img`
  height: var(--s-15);
`;
const TemperatureIcon = styled.img`
  height: var(--s-15);
`;
const OutputTitle = styled.h5`
  margin-block: var(--s-6);
  color: var(--c-green-bright);
`;
const OutputButton = styled(Button)`
  box-shadow: var(--shadow-1);
  height: var(--s-13);
  border-radius: var(--light-rounding);
`;
const BulkOutputButton = styled(Button)`
  box-shadow: var(--shadow-1);
  height: var(--s-13);
  border-radius: var(--light-rounding);
  background-color: var(--c-green-light);
  color: var(--c-black);
`;

const DarkSection = css`
  background: var(--c-black);
  box-shadow: var(--shadow-inner);
  color: var(--c-white);
  padding: var(--s-7);
  font-size: var(--fs-3);
`;
const LightSection = css`
  background: var(--c-gray-very-light);
  box-shadow: var(--shadow-inner);
  /* margin-bottom: 8vh; */
`;
const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: var(--s-20); */
`;
const OutputSelector = styled.div`
  ${LightSection}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: var(--c-gray-light);
  border: 1px solid var(--c-gray-medium);
  border-radius: 5px;
`;
const MLModelCrudArea = styled.div<{display:boolean}>`
  ${DarkSection}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: var(--s-13);
  left: var(--s-5);
  ${props=> 
  `display: ${props.display?'flex':'none'}`
  }
`;
const UserArea = styled.div`
  ${DarkSection}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  margin: var(--s-2);
  padding: var(--s-2);
`;
// const UserAreaRightSide = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
//   margin: 0;
//   padding: 0;
// `;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* justify-content: space-between; */
  padding: var(--s-6);
  min-width: 100%;

`;
const OptionContainer = styled.div<{backrgound?: string, boxShadow?: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s-6);
  border-radius: var(--s-6);
  background: ${props=> props.backrgound?props.backrgound:'var(--c-gray-dark)'};
  box-shadow: ${props=>props.boxShadow?props.boxShadow:'none'};
`;
const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s-3);
  border-radius: var(--s-4);
  background: var(--c-gray-dark);
  width: -webkit-fill-available;
  margin: var(--s-8);
`;
const SmallDiv = styled.div`
  margin: 0.1em;
  padding: 0.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--c-white);
  font-size: var(--s-35);
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  color: var(--c-black);
  padding: var(--s-3);
  margin: var(--s-5);
  width: 100%;
  border: 1px solid var(--c-gray);
`;
export const HorizFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const VertFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
export const MinimalDiv = styled.div`
  margin: 0;
  padding: 0;
`;
export const VertFlexMin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;
export const HorizFlexMin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin:0;
  padding: 0;
`;
const MainTitle = styled.h1`
  color: var(--c-green-bright);
  font-weight: 400;
  text-align: center;
`;
const AttributionText = styled.div`
  font-style: italic;
  text-align: center;
  color: var(--c-gray-medium-light);
`;

const InvisibleDiv = styled.div`
  margin: 0;
  padding: 0;
`;
interface MainMenuProps {}
const MainMenu: React.FunctionComponent<MainMenuProps> = (props) => {
  const gc = useContext(GlobalContext);
  const history = useHistory();
  const api = useContext(BackendAPIContext);
  const [selectedFluidMLModel, setSelectedFluidMLModel] = useState<Option>();
  const [selectedGroundMLModel, setSelectedGroundMLModel] = useState<Option>();
  const [selectedPileLengthMLModel, setSelectedPileLengthMLModel] = useState<Option>();
  const [showMLModelChoiceBox, setShowMLModelChoiceBox] = useState<boolean>(false);
  const [mlChoiceButtonDisabled, setMLChoiceButtonDisabled] = useState<boolean>(false);


  //whenever ml model choice model closes, disable the button briefly to prevent double trigger
  useEffect(()=>{
    if (showMLModelChoiceBox === false){
      setMLChoiceButtonDisabled(true);
      setTimeout(()=>{
        setMLChoiceButtonDisabled(false);
      },500);
    }
  },[showMLModelChoiceBox])

  //whenever active ml models change, update the locally selected models to reflect
  useEffect(() => {
    if (!api?.activePileOutletFluidTemperatureMLModel) return;
    setSelectedFluidMLModel(
      baseModelToOption(api.activePileOutletFluidTemperatureMLModel)
    );
  }, [api?.activePileOutletFluidTemperatureMLModel]);
  useEffect(() => {
    if (!api?.activeGroundTemperatureMLModel) return;
    setSelectedGroundMLModel(baseModelToOption(api.activeGroundTemperatureMLModel));
  }, [api?.activeGroundTemperatureMLModel]);
  useEffect(() => {
    if (!api?.activePileLengthMLModel) return;
    setSelectedPileLengthMLModel(baseModelToOption(api.activePileLengthMLModel));
  }, [api?.activePileLengthMLModel]);
  function startNumberOfPilesForm(){
    gc?.setOutputVariable(OutputVariables.NumberOfPiles);
    history.push('/form');
  }

  function startHeatPumpCapacityForm() {
    gc?.setOutputVariable(OutputVariables.PileHeatPumpCapacity);
    history.push("/form");
  }
  function goToReportView() {
    history.push("/report");
  }
  function startPileLengthForm() {
    gc?.setOutputVariable(OutputVariables.PileLength);
    history.push("/form");
  }
  function startGroundTemperatureForm() {
    gc?.setOutputVariable(OutputVariables.GroundTemperature);
    history.push("/form");
  }

  function calculatorToOption(calc: ICalculator): Option | undefined {
    if (!calc.name) return undefined;
    return {
      id: calc.id || 0,
      content: calc.name,
      value: calc.name,
    };
  }
  function handleSelectedCalculatorChange(newOption?: Option) {
    if (!api || !api.calculators || !newOption) return;
    const selectedCalc = api.calculators.filter(
      (calc) => calc.id === newOption.id
    )[0];
    const newCalc: ICalculator = api.calculators.filter(
      (calc) => calc.id === selectedCalc.id
    )[0];
    api?.setCalculator(newCalc);
  }
  function handleSessionLoadClick(e: any) {
    if (!api || !api.calculator) return;
    //console.log("attempting to load calculator with", api.calculator);
    api.loadCalculator(api.calculator);
    gc?.setOutputVariable(api.calculator.outputVariable);
    switch (api.calculator.outputVariable) {
      case OutputVariables.PileHeatPumpCapacity:
        startHeatPumpCapacityForm();
        break;
      case OutputVariables.NumberOfPiles:
        startNumberOfPilesForm();
        break;
      case OutputVariables.PileLength:
        startPileLengthForm();
        break;
      case OutputVariables.GroundTemperature:
        startGroundTemperatureForm();
        break;
      default:
        throw new Error(
          "Error: the selected session did not have a valid output variable"
        );
    }
  }

  function getCalculatorsAsOptions(outputType: OutputVariables): Option[] | undefined {
    if (!api || !api.calculators) return undefined;
    const fullList = api.calculators.map((calc) => calc.outputVariable===outputType?calculatorToOption(calc):undefined);
    //@ts-ignore
    const validList: Option[] = fullList.filter((item) => item !== undefined);
    if (validList.length < 1 || !validList) return undefined;
    return validList;
  }
  function getMLModelsAsOptions(
    type: "fluidTemp" | "groundTemp" | "pileLength"
  ): Option[] | undefined {
    if (!api) return undefined;
    let baseList = type==="fluidTemp"?api.pileOutletFluidTemperatureMLModels:
                  type==="groundTemp"?api.groundTemperatureMLModels:
                  api.pileLengthMLModels;
      if (!baseList) return undefined;
      const fullList = baseList.map((x:any) =>
        baseModelToOption(x)
      );
      //@ts-ignore
      const validList: Option[] = fullList.filter((x) => x !== undefined);
      if (validList.length < 1 || !validList) return undefined;
      return validList;
  }
  function baseModelToOption(baseModel?: IBaseModel) {
    if (!baseModel || !baseModel.id || !baseModel.name) return undefined;
    return { id: baseModel.id, content: baseModel.name, value: baseModel.name };
  }

  function getSelectedForSessionsDropdown(outputType: OutputVariables){
    if (!api || !api.calculator) return null;
    if (api.calculator.outputVariable !== outputType) return null;
    return calculatorToOption(api.calculator);
  }

  const ref = useRef(null);
  useOnClickOutside(ref, handleClickOutside);
  function handleClickOutside() {
    setShowMLModelChoiceBox(false);
  }


  return (
    
    <Container>
      <Topcontainer>  
      <InvisibleDiv>
      <TopGreenButton
      onClick={()=>{
        if (!showMLModelChoiceBox && !mlChoiceButtonDisabled){
          setShowMLModelChoiceBox(true)
        }
      }}
      >Choose Machine Learning Models</TopGreenButton> 
      <MLModelCrudArea ref={ref} display={showMLModelChoiceBox}>
        <Section>
          <SmallDiv>Pile Capacity Model</SmallDiv>
          <SmallDiv>
            <SmallSelect
              type="styled"
              selected={selectedFluidMLModel}
              onChange={(e?: Option) => setSelectedFluidMLModel(e)}
              options={getMLModelsAsOptions("fluidTemp")}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}

            />
            <GrayButton
              onClick={() => {
                if (!selectedFluidMLModel || !gc?.apiToken) return;
                api?.setMLModelAsActive(
                  "PileOutletFluidTemperatureMLModels",
                  typeof selectedFluidMLModel.id === "string"
                    ? parseInt(selectedFluidMLModel.id)
                    : selectedFluidMLModel.id,
                  gc.apiToken
                );
              }}
            >
              Save Selection
            </GrayButton>
          </SmallDiv>
          </Section>
          <Section>
          <SmallDiv>Ground Temperature Model</SmallDiv>
          <SmallDiv>
            <SmallSelect
              type="styled"
              selected={selectedGroundMLModel}
              onChange={(e?: Option) => setSelectedGroundMLModel(e)}
              options={getMLModelsAsOptions("groundTemp")}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}
            />
            <GrayButton
              onClick={() => {
                if (!selectedGroundMLModel || !gc?.apiToken) return;
                api?.setMLModelAsActive(
                  "GroundTemperatureMLModels",
                  typeof selectedGroundMLModel.id === "string"
                    ? parseInt(selectedGroundMLModel.id)
                    : selectedGroundMLModel.id,
                  gc.apiToken
                );
              }}
            >
              Save Selection
            </GrayButton>
          </SmallDiv>
          </Section>
          <Section>
          <SmallDiv>Pile Length Model</SmallDiv>
          <SmallDiv>
            <SmallSelect
              type="styled"
              selected={selectedPileLengthMLModel}
              onChange={(e?: Option) => setSelectedPileLengthMLModel(e)}
              options={getMLModelsAsOptions("pileLength")}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}
            />
            <GrayButton
              onClick={() => {
                if (!selectedPileLengthMLModel || !gc?.apiToken) return;
                api?.setMLModelAsActive(
                  "PileLengthMLModels",
                  typeof selectedPileLengthMLModel.id === "string"
                    ? parseInt(selectedPileLengthMLModel.id)
                    : selectedPileLengthMLModel.id,
                  gc.apiToken
                );
              }}
            >
              Save Selection
            </GrayButton>
          </SmallDiv>
          </Section>
          <GrayButton onClick={() => history.push("/ml-models")}>
            Add or Delete Machine Learning Models
          </GrayButton>
      </MLModelCrudArea>   
          <GrayButton onClick={()=>{window.open('/User Manual GPPS V1 - July 2021.pdf')}}>User Manual</GrayButton>
          </InvisibleDiv>
         <UserArea>
            <ProfilePhoto src="profile-photo.svg" />
            <SmallDiv>
              <GrayButton onClick={gc?.logout}>Logout</GrayButton>
              <GrayButton onClick={() => gc?.setPopup(<ChangePassword />)}>
              Change Password
              </GrayButton>
            </SmallDiv>
         </UserArea>
        
        <InnoviaLogo src={"InnoviaLogoLight.svg"} alt="Logo" />
      </Topcontainer>
      <TitleArea>

        <GPPLogo src={"simpleLogo.svg"} alt={"Title"} />
        <MainTitle>
          Geo-Pile Predictive <br /> Software
        </MainTitle>
        <AttributionText>
          Innovia GEO Corp. Copyright @ 2021. <br />
          Created by Sarah R. Nicholson, Sheldon Frith, and Rony Shohet.
        </AttributionText>
      </TitleArea>
              {/* <BarLineChart/> */}

      {/* <GrayButton onClick={goToReportView}>Test Report View</GrayButton> */}

      <OutputSelector>
        <InstructionContainer>
        <OutputTitle>
          Begin your prediction analysis by selecting your desired output:{" "}
        </OutputTitle>
        </InstructionContainer>

        <ButtonContainer>
          <OptionContainer>
            <CapacityIcon src = {"capacityicondark.svg"} />
            <OutputButton onClick={startHeatPumpCapacityForm}>
             Heat Pump Capacity
            </OutputButton>
            <Section>
            <SmallDiv>Load Saved Sessions</SmallDiv>
            {api?.calculator && api.calculators ? (
              <SmallDiv>
                <SmallSelect
                  options={getCalculatorsAsOptions(OutputVariables.PileHeatPumpCapacity)}
                  type="styled"
                  selected={getSelectedForSessionsDropdown(OutputVariables.PileHeatPumpCapacity)}
                  onChange={handleSelectedCalculatorChange}
                  placeholder={"Select Session"}
                  custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}
                />
                <GrayButton onClick={handleSessionLoadClick}>Load</GrayButton>
              </SmallDiv>
            ) : (
              <div>...loading</div>
            )}

          </Section>      
          </OptionContainer>
          <OptionContainer>
          <NumberIcon src = {"numbericondark.svg"} />
         <OutputButton onClick={startNumberOfPilesForm}>
           Number of Piles
         </OutputButton>
         <Section>
            <SmallDiv>Load Saved Sessions</SmallDiv>
            {api?.calculator && api.calculators ? (
              <SmallDiv>
                <SmallSelect
                  options={getCalculatorsAsOptions(OutputVariables.NumberOfPiles)}
                  type="styled"
                  selected={getSelectedForSessionsDropdown(OutputVariables.NumberOfPiles)}
                  onChange={handleSelectedCalculatorChange}
                  placeholder={"Select Session"}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}

                />
                <GrayButton onClick={handleSessionLoadClick}>Load</GrayButton>
              </SmallDiv>
            ) : (
              <div>...loading</div>
            )}

          </Section>      
         </OptionContainer>
         </ButtonContainer>
         <ButtonContainer>
         <OptionContainer>
         <LengthIcon src = {"lengthicondark.svg"} />
          <OutputButton onClick={startPileLengthForm}>
            Pile Lengths
          </OutputButton>
          <Section>
            <SmallDiv>Load Saved Sessions</SmallDiv>
            {api?.calculator && api.calculators ? (
              <SmallDiv>
                <SmallSelect
                  options={getCalculatorsAsOptions(OutputVariables.PileLength)}
                  type="styled"
                  selected={getSelectedForSessionsDropdown(OutputVariables.PileLength)}
                  onChange={handleSelectedCalculatorChange}
                  placeholder={"Select Session"}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}

                />
                <GrayButton onClick={handleSessionLoadClick}>Load</GrayButton>
              </SmallDiv>
            ) : (
              <div>...loading</div>
            )}

          </Section>      
          </OptionContainer>
          <OptionContainer>
          <TemperatureIcon src = {"temperatureicondark.svg"} />
          <OutputButton onClick={startGroundTemperatureForm}>
            Ground Temperature
          </OutputButton>
          <Section>
            <SmallDiv>Load Saved Sessions</SmallDiv>
            {api?.calculator && api.calculators ? (
              <SmallDiv>
                <SmallSelect
                  options={getCalculatorsAsOptions(OutputVariables.GroundTemperature)}
                  type="styled"
                  selected={getSelectedForSessionsDropdown(OutputVariables.GroundTemperature)}
                  onChange={handleSelectedCalculatorChange}
                  placeholder={"Select Session"}
              custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}

                />
                <GrayButton onClick={handleSessionLoadClick}>Load</GrayButton>
              </SmallDiv>
            ) : (
              <div>...loading</div>
            )}

          </Section>      
          </OptionContainer>
          </ButtonContainer>
          <ButtonContainer>
          <OptionContainer boxShadow="var(--shadow-bright)">
            <CapacityIcon src = {"BulkiconDark.svg"} />
            <BulkOutputButton onClick={()=>{
              history.push('/bulk')
            }}>
             Bulk / Long-Term Predictions
            </BulkOutputButton>
            {/* <Section>
            <SmallDiv>Load Saved Study Results</SmallDiv>
            {api?.calculator && api.calculators ? 
            // TODO
            (
              <SmallDiv>
                <SmallSelect
                  options={getCalculatorsAsOptions(OutputVariables.PileHeatPumpCapacity)}
                  type="styled"
                  selected={getSelectedForSessionsDropdown(OutputVariables.PileHeatPumpCapacity)}
                  onChange={handleSelectedCalculatorChange}
                  placeholder={"Select Session"}
                  custCssSelectedOptionDisplayMain={css`box-shadow: var(--shadow-select);`}
                />
                <GrayButton onClick={handleSessionLoadClick}>Load</GrayButton>
              </SmallDiv>
            ) : (
              <div>...loading</div>
            )}

          </Section>       */}
          </OptionContainer>
        </ButtonContainer>
      </OutputSelector>
    </Container>
  );
};
export default MainMenu;
