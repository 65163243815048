import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import ReportTemplate, { BlackTitle, GreenTitle } from "./Template";

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-white);
  width: 100%;
  margin: 0;
  padding: 0;
`;
const LeftTop = styled.div`
  margin: 0;
  padding: 0;
`;
const RightTop = styled.div`
  font-size: var(--ss-4);
  padding-left: var(--ss-9);
  padding-right: 0;
  padding-block: 0;
  margin: 0;
`;
// const BlackTitle = styled.h3`
//   color: var(--c-black);
//   font-size: var(--ss-6);
//   margin-block: var(--ss-4);
//   padding: 0;
// `;
// const GreenTitle = styled.h3`
//   color: var(--c-green);
//   font-size: var(--ss-6);
//   margin-block: var(--ss-4);
//   padding: 0;
// `;
const ColSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  justify-content: flex-start;
  margin: var(--ss-3);
  padding: var(--ss-2);
  width: 50%;
  height: 100%;
`;

const AssumptionSection = styled.div`
  margin: 0;
  padding: 0;
  font-size: var(--ss-35);
`;
const P = styled.p``;

const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: var(--ss-5);
`;
const SubSubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: var(--ss-4);
`;
const SubSubSubTitle = styled.h5`
  color: var(--c-green);
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: var(--ss-4);
`;
const GreenDivider = styled.div`
  margin-block: var(--ss-4);
  margin-inline: 0;
  border-radius: 0;
  padding: 0;
  height: var(--ss-2);
  width: var(--ss-10);
  background: var(--c-green-very-light);
`;
const FullDivider = styled.div`
  margin-block: var(--ss-35);
  margin-inline: 0;
  border-radius: 0;
  padding: 0;
  height: var(--ss-2);
  width: 100%;
  background: var(--c-green-very-light);
`;
const Equation = styled.div`
  background: var(--c-green-very-light);
  margin: var(--ss-1);
  padding: var(--ss-3);
  border-radius: 0;
  font-family: monospace;
`;
const NewEquation = styled.div`
  background: var(--c-green-very-light);
  margin: var(--ss-1);
  padding: var(--ss-3);
  border-radius: 0;
  font-size: var(--ss-4);
  font-family: monospace;
  text-align: center;
`;
const SoilTChart = styled.img`
  width: 90%;
`;

interface AssumptionsReportProps {
  id: any;
}
const AssumptionsReport: React.FunctionComponent<AssumptionsReportProps> = ({
  id,
}) => {
  return (
    <ReportTemplate id={id} xPosition={-9999} yPosition={-9999}>
      <TopArea>
        <LeftTop>
          <BlackTitle>Appendix</BlackTitle>
          <GreenTitle>
            List of <br /> Assumptions
          </GreenTitle>
        </LeftTop>
        <RightTop>
          System assumptions are detailed here which may have an effect on the
          software outputs. This list of assumptions is not guaranteed to be
          complete.
          <br />
          <br />
          Additional important details about how this software generates
          predictions are also included here.
        </RightTop>
      </TopArea>
      <AssumptionSection>
        {/* <FullDivider></FullDivider> */}
        <SubTitle>Ground Domain Assumptions</SubTitle>
        <ColSection>
          <Column>
            <P>
              The thermodynamic properties of the ground domain are, at present,
              relatively understudied. The temperature and thermal conductivity
              of the ground domain play a significant role on the operating
              performance of in-ground heat exchangers, but are properties too
              complex to represent in calculations without some common
              assumptions.
            </P>
            <P>
              As such, the temperature of the ground domain in this software is
              simplified to three points. The first ground temperature input,
              "Ground Temperature At Top of Pile (Tground0)" is meant to reflect
              shallow ground temperatures, these may vary widely with time and
              external temperature influences. The second ground temperature,
              "Ground Temperature 5m Below Top of Pile (Tground5)" is measured 5
              m (16.4 ft) below this point, and is assumed to capture the
              midpoint between a highly variable shallow ground temperature and
              the relatively constant deep ground temperature. The final
              temperature point, "Ground Temperature 10m Below Top of Pile
              (Tground10)", is 10 m below the surface of the pile (32.8 ft) and
              is assumed to capture the deep ground temperature. Using widely
              accepted ground temperature curves for a four-season climate, the
              following graph explains the location of these three points and
              their relationship to ground temperature variation. The exact
              location of these ground temperature points may not coincide with
              the depth below the ground surface if the pile surface is buried
              below this point.
            </P>
          </Column>
          <Column>
            <SoilTChart src="graph of ground temperatures.png" />
            <P>
              The thermal conductivity of the ground domain is also simplified
              to three values. However these three values represent properties
              of the entire depth of the ground around the piles, instead of
              being measures of specific depths like the ground temperature
              values. The three measures of ground thermal conductivity are the
              maximum value ("Kmax"), minimum value ("Kmin"), and simple average
              within the ground sample area ("Kmean"), where the ground sample
              area is the area of ground surrounding the pile as well as
              extending 5-10 m (16.4 - 32.8 ft) below the bottom of the pile. In
              this way, the complex nature of the ground domain’s thermal
              properties can be more closely represented than by assuming one
              constant value.
            </P>
          </Column>
        </ColSection>
      </AssumptionSection>
      <AssumptionSection>
        <FullDivider></FullDivider>
        <SubTitle>Pile Array Assumptions</SubTitle>
        <ColSection>
          <Column>
            <P>
              The current version of this software assumes the flow network is a
              parallel flow (not series), meaning the incoming flow is split
              from a manifold to each pile separately, and the returning flow
              from each pile is separately joined to the return manifold for the
              array. To account for the variety of site-specific influences on
              fluid temperature and flow rate throughout the array several
              categories of inputs have been included in the software.
            </P>
          </Column>
          <Column>
            <P>
              Where these inputs are labelled as "changes" a positive input
              value represents a GAIN (increase in temperature or flow rate) and
              a negative input value represents a LOSS (decrease in temperature
              or flow rate) and zero represents no change during that section of
              the pile array.
            </P>
          </Column>
        </ColSection>
      </AssumptionSection>
      <AssumptionSection>
        <SubSubTitle>Flow Rate</SubSubTitle>
        <GreenDivider></GreenDivider>

        <ColSection>
          <Column>
            <P>
              The most important assumption made about flow rate in this
              software is that the flow rate does not change within each pile
              (from the pile inlet to the pile outlet). Calculating the real
              flow rate change within each pile will require further research
              and model development. Therefore, the algorithm used by this
              software only uses the flow rate of the fluid entering the pile
              inlet ("Qin"), which is either entered directly, or calculated
              from the user-input values of "Qoap", "Nop", "Qlpre", and "Qliin".
            </P>
            <P>
              This allows for faster prototyping as the flow rate exiting the
              pump (which is pump dependent) is an easier figure to determine
              than the average flow rate entering each pile. These flow rate
              change variables are meant to account for losses or gains in flow
              rate due to forces such as: friction, pipe bends, leaks, and more.
            </P>
          </Column>
          <Column>
            <P>
              {/* <Equation> FlowRateEnteringPileInlet(Qin) = FlowRateExitingArrayPump(Qoap) +
            FlowChangeBetweenPumpAndArrayManifold(Qlpre) +
            AverageFlowChangeBetweenArrayManifoldAndPileInlet(Qliin)</Equation> */}
              <SubSubSubTitle>Flow Rate of the In-Flow Fluid</SubSubSubTitle>
              <P>
                In some cases, if this flow rate is not provided directly, this
                software will calculate it from three related user inputs using
                the following function:
              </P>

              <NewEquation> Qin = (Qoap/Nop) + Qlpre + Qliin</NewEquation>
            </P>
          </Column>
        </ColSection>
      </AssumptionSection>
      <AssumptionSection>
        <SubSubTitle>Fluid Temperatures</SubSubTitle>
        <GreenDivider></GreenDivider>

        <ColSection>
          <Column>
            <P>
              The temperature of the fluid entering each pile ("Tin") is a
              critical value in all predictions performed by this software. If
              this value is not input directly the software will calculate it
              based on other inputs (see next section). "Temperature change" is
              meant to account for losses or gains in temperature due to thermal
              interference from uninsulated pipes, external forces, and leaks.
            </P>
            <P>
              Average geo-pile thermal capacity ("Wghe") where it is given to
              the user, has been calculated using the temperature exiting the
              average pile. However, when calculating Heat Pump Capacity ("Whp")
              temperature changes between pile outlets and the heat pump inlet
              must be included, if there are any.
            </P>
          </Column>
          <Column>
            <SubSubSubTitle>Temperature of the In-Flow Fluid</SubSubSubTitle>
            <P>
              The fluid temperature entering the pile inlet (if it is not input
              directly) is calculated by the software using the following
              formula:
              {/* <Equation>
                  FluidTemperatureEnteringPileInlet(Tin) =
              FluidTemperatureExitingHeatPump(Tohp) +
              TemperatureChangeBetweenHeatPumpOutletAndArrayManifold(Tlpre) +
              AverageTemperatureChangeBetweenArrayManifoldAndPileInlet(Tliin)
                  </Equation>  */}
              <NewEquation>Tin = Tohp + Tlpre + Tliin</NewEquation>
            </P>
            <SubSubSubTitle>Temperature of the Out-Flow Fluid</SubSubSubTitle>
            <P>
              The fluid temperature entering the heat pump is calculated by the
              software using the following formula:
              {/* <Equation>
                   FluidTemperatureEnteringHeatPumpInlet(Tihp) =
              FluidTemperatureExitingPileOutlet(Tout) +
              AverageTemperatureChangeBetweenPileOutletAndArrayManifold(Tliout) +
              TemperatureChangeBetweenArrayManifoldAndHeatPumpInlet(Tlpost)
              </Equation> */}
              <NewEquation>Tihp = Tout + Tliout + Tlpost</NewEquation>
            </P>
          </Column>
        </ColSection>
      </AssumptionSection>
      <AssumptionSection>
        <FullDivider></FullDivider>
        <SubTitle>Material Property Assumptions</SubTitle>
        <ColSection>
          <Column>
            <P>
              This version of the software assumes that the materials used for
              the pile and pipe components are constant and reflect the
              properties of steel and PEX plastic respectively. Furthermore,
              this machine learning model was trained with constant working
              fluid properties to reflect a working fluid of water. It is
              assumed that the system will be designed and maintained to avoid
              freezing of the working fluid, and that appropriate adjustments in
              use of this software’s predictions will be made to reflect changes
              to materials.
            </P>
          </Column>
          <Column>
            <SoilTChart src="List of mat props.png" />
          </Column>
        </ColSection>
      </AssumptionSection>
    </ReportTemplate>
  );
};
export default AssumptionsReport;
