import { Message, MessageImportanceLevels, MessageTypes } from "lib/ErrorAndMessageHandling/MessageHandler";

export interface FrontendErrorMessage
    {
        title: string
        summary: string
        fullInfo?: string
        type: MessageTypes
        isBlocking: boolean
        importanceLevel: MessageImportanceLevels
    }

export function getFrontendErrorMessageFromMessage(m: Message): FrontendErrorMessage{
    return {
        title: m.contentForDisplay.title,
        summary: m.contentForDisplay.summary,
        fullInfo: m.contentForDisplay.additionalInfo,
        type: m.type,
        isBlocking: m.isBlocking,
        importanceLevel: m.importanceLevel
    }
}