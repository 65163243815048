import {
  Calculator,
  Climate,
  HeatPump,
  ICalculator,
  IClimate,
  IHeatPump,
  IPileArray,
  IPileInFluid,
  IPileOutFluid,
  IPlasticPipe,
  IGround,
  ISteelPile,
  IUiInputVariableDouble,
  IUiInputVariableInt,
  IUiInputVariableString,
  PileArray,
  PileInFluid,
  PileOutFluid,
  PlasticPipe,
  Ground,
  SteelPile,
  OutputVariables,
} from "./models";
import _ from "lodash";
import { PlasticPipeNominalSize } from "./models/plastic-pipe-nominal-size";
import { SteelPileNominalSize } from "./models/steel-pipe-nominal-size";

export type UiInput =
  | IUiInputVariableDouble
  | IUiInputVariableInt
  | IUiInputVariableString
  | null;

export function vw(v: number) {
  var w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  return (v * w) / 100;
}

export function emptyUiInputVar(variable?: UiInput): void {
  if (!variable) return;
  setThesePropertiesToUndefined(variable, [
    "id",
    "inputValue",
    "calculatedValue",
    "solveFor",
  ]);
}

export function setThesePropertiesToUndefined(
  obj: object,
  paths: string[]
): void {
  paths.forEach((path: string) => {
    _.set(obj, path, undefined);
  });
}

export const toEmpty = {
  calculator: getEmptyCalculator,
  climate: getEmptyClimate,
  heatPump: getEmptyHeatPump,
  pileArray: getEmptyPileArray,
  pileInFluid: getEmptyPileInFluid,
  pileOutFluid: getEmptyPileOutFluid,
  plasticPipe: getEmptyPlasticPipe,
  steelPile: getEmptySteelPile,
  ground: getEmptyGround,
};
export const createEmpty = {
  calculator: () => new Calculator(),
  climate: () => new Climate(),
  heatPump: () => new HeatPump(),
  pileArray: () => new PileArray(),
  pileInFluid: () => new PileInFluid(),
  pileOutFluid: () => new PileOutFluid(),
  plasticPipe: (possibleValues: string[]) => new PlasticPipe(possibleValues),
  steelPile: (possibleValues: string[]) => new SteelPile(possibleValues),
  ground: () => new Ground(),
  plasticPipeNominalSize: () => new PlasticPipeNominalSize(),
  steelPileNominalSize: () => new SteelPileNominalSize(),
};

function getEmptyCalculator(model: ICalculator) {
  const clone = { ...model };
  setThesePropertiesToUndefined(clone, [
    "id",
    "name",
    "outputVariable",
    "climate",
    "climateId",
    "heatPump",
    "heatPumpId",
    "pileArray",
    "pileArrayId",
    "pileInFluid",
    "pileInFluidId",
    "pileOutFluid",
    "pileOutFluidId",
    "plasticPipe",
    "plasticPipeId",
    "steelPile",
    "steelPileId",
    "ground",
    "groundId",
  ]);

  return clone;
}
function getEmptyClimate(model: IClimate) {
  const clone = clearAndClone<IClimate>(model);
  emptyUiInputVar(clone.ambientAirTemperature);
  return clone;
}
function getEmptyHeatPump(model: IHeatPump) {
  const clone = clearAndClone<IHeatPump>(model);
  emptyUiInputVar(clone.coolingSeasonCoefficientA);
  emptyUiInputVar(clone.coolingSeasonCoefficientB);
  emptyUiInputVar(clone.coolingSeasonCoefficientC);
  emptyUiInputVar(clone.heatingSeasonCoefficientA);
  emptyUiInputVar(clone.heatingSeasonCoefficientB);
  emptyUiInputVar(clone.heatingSeasonCoefficientC);
  return clone;
}
function getEmptyPileArray(model: IPileArray) {
  const clone = clearAndClone<IPileArray>(model);
  emptyUiInputVar(clone.numberOfActivePilesInTheArray);
  emptyUiInputVar(clone.perPileHeatPumpCapacity);
  emptyUiInputVar(clone.targetWholeArrayHeatPumpCapacity);
  emptyUiInputVar(clone.targetGeoPileThermalCapacity);
  return clone;
}
function getEmptyPileInFluid(model: IPileInFluid) {
  const clone = clearAndClone<IPileInFluid>(model);
  emptyUiInputVar(clone.averageInterPileTemperatureChangeInflow);
  emptyUiInputVar(clone.heatPumpOutletFluidTemperature);
  emptyUiInputVar(clone.temperatureChangeBeforeArray);
  emptyUiInputVar(clone.arrayPumpOutletFlowRate);
  emptyUiInputVar(clone.flowChangeBeforeArray);
  emptyUiInputVar(clone.averageInterPileFlowChangeInflow);
  emptyUiInputVar(clone.pileInletFlowRate);
  emptyUiInputVar(clone.pileInletFluidTemperature);
  return clone;
}
function getEmptyPileOutFluid(model: IPileOutFluid) {
  const clone = clearAndClone<IPileOutFluid>(model);
  emptyUiInputVar(clone.temperatureChangeAfterArray);
  emptyUiInputVar(clone.averageInterPileTemperatureChangeOutflow);
  return clone;
}
function getEmptyPlasticPipe(model: IPlasticPipe) {
  const clone = clearAndClone<IPlasticPipe>(model);
  emptyUiInputVar(clone.plasticPipeNominalSizeForClient);
  return clone;
}
function getEmptySteelPile(model: ISteelPile) {
  const clone = clearAndClone<ISteelPile>(model);
  emptyUiInputVar(clone.length);
  emptyUiInputVar(clone.steelPileNominalSizeForClient);
  return clone;
}
function getEmptyGround(model: IGround) {
  const clone = clearAndClone<IGround>(model);
  emptyUiInputVar(clone.groundTemperature10mBelowTopOfPile);
  emptyUiInputVar(clone.groundTemperature5mBelowTopOfPile);
  emptyUiInputVar(clone.groundTemperatureAtTopOfPile);
  emptyUiInputVar(clone.maxGroundThermalConductivity);
  emptyUiInputVar(clone.averageGroundThermalConductivity);
  emptyUiInputVar(clone.minGroundThermalConductivity);
  return clone;
}

function clearAndClone<T>(model: any): T {
  const clone = { ...model };
  clone.id = undefined;
  clone.name = undefined;
  return clone as T;
}

export function removeNulls(arr: any[]) {
  return arr.filter((item) => item !== null && item !== undefined);
}

// you can't use "enum" as a type, so use this.
type EnumType = { [s: number]: string };

export function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  let enumMembers: any[] = Object.keys(enumerable).map(
    (key: any) => enumerable[key]
  );

  // we are only interested in the numeric identifiers as these represent the values
  let enumValues: number[] = enumMembers.filter((v) => typeof v === "number");

  // now map through the enum values
  return enumValues.map((m) => fn(m));
}
export function trimDecimalPlaces(input?: string, decimalsToKeep?: number) {
  if (!input || decimalsToKeep === undefined || decimalsToKeep === null)
    return input;
  return parseFloat(input).toFixed(decimalsToKeep);
}
export function trimDecimals(
  input?: number,
  decimalsToKeep?: number
): number | undefined {
  if (!input) return input;
  const result = input.toFixed(decimalsToKeep);
  if (!result) return undefined;
  return parseFloat(result);
}
export function getPercentage(part: number, whole: number) {
  //console.log('getting percentage of : ',part, whole);
  // if (part >= whole) return 100;
  return 100 * (part / whole);
}

export function getIconByOutputType(outputType: OutputVariables | undefined) {
  switch (outputType) {
    case OutputVariables.GroundTemperature:
      return "temperatureicondark.svg";
    case OutputVariables.NumberOfPiles:
      return "numbericondark.svg";
    case OutputVariables.PileHeatPumpCapacity:
      return "capacityicondark.svg";
    case OutputVariables.PileLength:
      return "lengthicondark.svg";
    case OutputVariables.BulkPredictions:
      return "BulkiconDark.svg";
    default:
      return "";
  }
}
export function getPictureByOutputType(outputType: OutputVariables | undefined) {
  switch (outputType) {
    case OutputVariables.GroundTemperature:
      return "ResultsLogoIconGT.svg";
    case OutputVariables.NumberOfPiles:
      return "ResultsLogoIconNOP.svg";
    case OutputVariables.PileHeatPumpCapacity:
      return "ResultsLogoIcon.svg";
    case OutputVariables.PileLength:
      return "ResultsLogoIconLSP.svg";
    case OutputVariables.BulkPredictions:
      return "";
    default:
      return "";
  }
}
