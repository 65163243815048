import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import Button from "components/Reusables/Button";
import Footer from "components/Reusables/Footer";
import Header from "components/Reusables/Header";
import Popup from "components/Reusables/Popup";
import Select from "components/Reusables/Select";
import { createEmpty, getIconByOutputType, vw } from "lib/helper-functions";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  ReactNode,
  SetStateAction,
} from "react";
import { useHistory } from "react-router";
import { Option, ReusableComponentBase } from "sheldons-components";
import styled, { css, keyframes } from "styled-components";
import FormNavigation, { FormNavigationItem } from "./FormNavigation";
import SessionCrud from "../Popups/SessionCrud";
import HeatPumpCapacityReport from "components/PrintableReports/SummaryPage/HeatPumpCapacity";

import { ICalculator, OutputVariables } from "lib/models";
import { getCalculationResult } from "lib/api";
import ResultsPopup from "components/Popups/ResultsPopup";
import MultiSessionReportGenerator from "components/Popups/MultiSessionReportGenerator";
import { VertFlex } from "components/MainMenu";
import { ApiEndpoints } from "lib/types";

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const HorizFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  /*changed the above from Sheldon's input which was "space-between"*/
`;
const Container = styled.div`
  margin: 0;
  padding: 0;
  /* background: linear-gradient(var(--c-gray-dark), var(--c-gray-medium-light), var(--c-gray)); */
  background: var(--c-black);
`;
const LeftCol = styled.div`
  min-width: var(--s-18);
  position: relative;
  margin-right: var(--s-10);
  margin-left: var(--s-1);
  background: var(--c-gray-dark);
  color: var(--c-white);
`;
const MidCol = styled.div`
  color: var(--c-black);
`;
const LowerBlankSection = styled.img`
align-self: center;
  width: 80%;
  margin-block: var(--s-17);
`;
const RightCol = styled.div``;
const LogoImage = styled.img`
  height: var(--s-13);
  margin-inline: var(--s-4);
  `;
const FunctionImage = styled.img`
  height: var(--s-12);
  margin-inline: var(--s-4);
`;
const MLModelIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--s-2);
  padding-inline: var(--s-5);
  /* margin: 0; */
  border: 1px solid var(--c-green);
`;
const MLModelIndicatorTitle = styled.h5`
  margin: 0;
  font-weight: 600;
  color: var(--c-white);
`;
const MLModelIndicatorText = styled.div`
  margin: 0;
  padding: 0;
  color: var(--c-white);
`;
const SessionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  /* position: fixed; */
  /* top: 29vw; */
  /* background: var(--c-green-very-light); */
  /* padding-block: var(--s-12); */
  /* border: 1px solid var(--c-green-very-light); */
`;
const SessionIndicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  /* padding: var(--s-2); */
  /* padding-inline: var(--s-5); */
  /* margin: var(--s-2); */
  /* margin-inline: var(--s-10); */
`;
const SessionIndicatorTitle = styled.h5`
  margin: 0;
  margin-right: var(--s-6);
  font-weight: 600;
  align-self: center;
  padding: 0;
`;
const SessionIndicatorText = styled.div`
  margin: 0;
  padding: 0;
  color: var(--c-white);
  align-self: center;
`;
const SessionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;
const SessionButton = css`
/* background: var(--c-white); */
/* color: var(--c-green); */
/* border: solid var(--c-green-very-light); */
/* font-weight: 800; */
height: var(--s-10);
font-size: var(--fs-3);
/* :hover {
  background: var(--c-green);
  color: var(--c-white);
  border: solid var(--c-white);
} */
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const LogoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  flex-direction: column;
  position: fixed;
  top: 29vw;
`;
const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  /* padding-bottom: var(--s-24); */
  padding-top: var(--s-15);
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
  justify-content: space-between;
  animation: ${FadeIn} 0.5s ease-in both;
`;
const VerticalDivider = styled.div`
  height: var(--s-12);
  border: solid var(--c-green-light);
  width: 0;
  margin: 0;
  padding: 0;
  margin-left: var(--s-10);
  margin-right: var(--s-10);
`;
const H4Title = styled.h4`
  align-self: center;
`;
let previousWithHeatPump = true;

export interface FormContentProps extends ReusableComponentBase {
  setNavigationItems: (items: FormNavigationItem[]) => void;
  selectedNavigationItem?: FormNavigationItem;
  setSelectedNavigationItem: React.Dispatch<
    SetStateAction<undefined | FormNavigationItem>
  >;
}
interface FormTemplateProps {
  FormContent: React.FunctionComponent<FormContentProps>;
  withHeatPumpOptionSelector?: boolean;
}
const FormTemplate: React.FunctionComponent<FormTemplateProps> = ({
  FormContent,
  withHeatPumpOptionSelector,
}) => {
  const [
    selectedScrollPosition,
    setSelectedScrollPosition,
  ] = useState<FormNavigationItem>();
  const [scrollPositionItems, setScrollPositionItems] = useState<
    FormNavigationItem[]
  >();
  const heatPumpOptions = [
    { id: 0, content: "With Heat Pump", value: "With Heat Pump" },
    { id: 1, content: "Without Heat Pump", value: "Without Heat Pump" },
  ];
  const history = useHistory();
  const gc = useContext(GlobalContext);
  const api = useContext(BackendAPIContext);

  //whenever withHeatPump changes, reset the stored current calculator object
  useEffect(() => {
    if (!gc) return;
    if (gc?.withHeatPump === previousWithHeatPump) return;
    else {
      previousWithHeatPump = gc?.withHeatPump;
      api?.setCalculator(createEmpty.calculator());
    }
  }, [gc, api]);

  //On UNMOUNT remove the calculator from memory that was being worked on (to prevent issues when switching between output types)
  useEffect(() => {
    return () => {
      api?.setCalculator(createEmpty.calculator());
    };
  }, []);

  function onSelect(newItem: any) {
    newItem.ref.current.scrollIntoView();
    window.scrollBy(0, vw(-10));
    setSelectedScrollPosition(newItem);
  }
  //debugging
  useEffect(() => {
    //console.log("formcontent changing");
  }, [api]);

  async function handleGetCalculationByOutputType(
    endpoint: ApiEndpoints,
    calc: ICalculator,
    resultPopup: (data: any) => JSX.Element
  ) {
    if (!api || !gc?.apiToken) return;
    const result: any = await api?.getCalculationResult(
      endpoint,
      calc,
      gc?.apiToken
    );
    if (!result) return;
    const newCalculator = result.calculator
      ? result.calculator
      : result.withHeatPump
      ? result.withHeatPump?.calculator
      : result.withoutHeatPump?.calculator;
    api.setCalculator(newCalculator);
    gc.setPopup(resultPopup(result));
  }
  const handleCalculationMap: {
    [key in OutputVariables]: (calc: ICalculator) => void;
  } = {
    "Ground Temperature": (calc: ICalculator) =>
      handleGetCalculationByOutputType(
        "CalculateGroundTemperature",
        calc,
        (data: any) => <ResultsPopup groundTemperatureData={data} />
      ),
    "Heat Pump Capacity": (calc: ICalculator) =>
      handleGetCalculationByOutputType(
        "CalculatePerPileHeatPumpCapacity",
        calc,
        (data: any) => <ResultsPopup heatPumpCapacityData={data} />
      ),
    "Number of Piles": (calc: ICalculator) =>
      handleGetCalculationByOutputType(
        "CalculateNumberOfActivePilesInTheArray",
        calc,
        (data: any) => <ResultsPopup numberOfActivePilesInTheArrayData={data} />
      ),
    "Pile Length": (calc: ICalculator) =>
      handleGetCalculationByOutputType(
        "CalculatePileLengths",
        calc,
        (data: any) => <ResultsPopup pileLengthData={data} />
      ),
    'Bulk Heat Pump Capacity': (calc: ICalculator) => {
      return
    }
  };

  async function handleCalculateClick() {
    if (!api || !gc || !gc?.apiToken || !gc.outputVariable) return;
    const calc = {...api.getCurrentCalculator()};
    if (!calc) return;
    if (gc.outputVariable === OutputVariables.PileLength && gc.withHeatPump){
      calc.pileArray!.targetGeoPileThermalCapacity!.inputValue = undefined;
    } else if (gc.outputVariable === OutputVariables.PileLength && !gc.withHeatPump){
      calc.pileArray!.targetWholeArrayHeatPumpCapacity!.inputValue = undefined;
    }
    // //console.log('handling calculate clikc');
    return handleCalculationMap[gc.outputVariable](calc);
  }

  function generateMultiSessionReportClick() {
    gc?.setPopup(<MultiSessionReportGenerator />);
  }
  if (!api || !gc) return <div>...loading</div>;
  return (
    <Container>
      <Header>
        <HeaderButtons>
          <Button onClick={() => history.push("/menu")}>Main Menu</Button>
          <Button
            custCss={css`
              background: var(--c-gray);
            `}
            onClick={gc?.logout}
          >
            Logout
          </Button>
        </HeaderButtons>
        <HorizFlex>
        <FunctionImage src={getIconByOutputType(gc?.outputVariable)} alt="icon"></FunctionImage>
        <H4Title>Predict {gc?.outputVariable}</H4Title>
        </HorizFlex>
        {withHeatPumpOptionSelector ? (
          <Select
            type="styled"
            options={heatPumpOptions}
            selected={gc.withHeatPump ? heatPumpOptions[0] : heatPumpOptions[1]}
            onChange={(newOption: Option | undefined) => {
              if (newOption?.id == 1) {
                gc.setWithHeatPump(false);
              } else {
                gc.setWithHeatPump(true);
              }
            }}
          ></Select>
        ) : (
          <></>
        )}
       <SessionSection>
            <SessionIndicator>
              <SessionIndicatorTitle>Current Session:</SessionIndicatorTitle>
              <SessionIndicatorText>
                {api?.calculator?.name || "Not Saved"}
              </SessionIndicatorText>
            </SessionIndicator>
            <SessionButtons>
            <Button
              onClick={() => gc?.setPopup(<SessionCrud />)}
              custCss={SessionButton}
            >
              Save Session
            </Button>
            <Button
              onClick={() => gc?.setPopup(<SessionCrud />)}
              custCss={SessionButton}
            >
              Load Session
            </Button>
            </SessionButtons>
          </SessionSection>
      </Header>
      <HorizFlex>
        <LeftCol>
          <FormNavigation
            items={scrollPositionItems}
            selectedItem={selectedScrollPosition}
            onSelect={onSelect}
          />
           <LogoGroup>
          <LogoImage src={"/InnoviaLogoLight.svg"} alt="logo" />
          <LogoImage src={"/simpleLogo.svg"} alt="logo" />
        </LogoGroup>
          
        </LeftCol>
        <MidCol>
          {gc.popup ? (
            <></>
          ) : (
            <MainContainer>
              <FormContent
                selectedNavigationItem={selectedScrollPosition}
                setNavigationItems={setScrollPositionItems}
                setSelectedNavigationItem={setSelectedScrollPosition}
              />
              <LowerBlankSection src="simpleLogo.svg"></LowerBlankSection>
            </MainContainer>
          )}
        </MidCol>
        {/* <RightCol>Right Column</RightCol> */}
      </HorizFlex>
      <Footer>
        <MLModelIndicator>
          <MLModelIndicatorTitle>
            Current Machine Learning Model:{" "}
          </MLModelIndicatorTitle>
          <MLModelIndicatorText>
            {gc?.outputVariable === OutputVariables.GroundTemperature?
             api?.activeGroundTemperatureMLModel?.name
              : gc.outputVariable === OutputVariables.PileLength?
              api.activePileLengthMLModel?.name
              : api?.activePileOutletFluidTemperatureMLModel?.name}
          </MLModelIndicatorText>
        </MLModelIndicator>
        <VerticalDivider />
        <Button onClick={handleCalculateClick}>Calculate Results</Button>
        <VerticalDivider />
        <Button onClick={generateMultiSessionReportClick}>
          Generate Multi-Session Report
        </Button>
      </Footer>
    </Container>
  );
};
export default FormTemplate;
