import { JSONSchemaType, Schema } from "ajv";
import { number } from "mathjs";
import {
    HeatPump,
    HeatPumpSchema,
  IHeatPump,
  IPlasticPipe,
  ISteelPile,
  PlasticPipe,
  PlasticPipeSchema,
  SteelPile,
  SteelPileSchema,
} from ".";

export const bulkPredictionsTimeUnit = [
  'seconds',
  'minutes',
  'hours',
  'days'
] as const;
const BulkPredictionTimeUnitSchema = {
    enum: bulkPredictionsTimeUnit,
};




export type BulkPredictionTimeUnit = (typeof bulkPredictionsTimeUnit)[number]
export const timeUnitsToMilliseconds: {[unit in BulkPredictionTimeUnit]:number} = {
  'seconds':1000,
  'minutes':60000,
  'hours':3.6e+6,
  'days': 8.64e+7,
}

interface GTPInputs {
  pileTopDepth: number;
  predictionDepth: number;
  month: number;
  day: number;
  lat: number;
  long: number;
}

export const GTPInputsForBulkPredictionSchema: JSONSchemaType<GTPInputsForBulkPrediction> = {
      type: "object",
      properties: {
        pileTopDepth: {
          type: "number",
          minimum: 0,
          maximum: 20,
        },
        lat: {
          type: "number",
          minimum: -63,
          maximum: 88,
        },
        long: {
          type: "number",
          minimum: -180,
          maximum: 180,
        },
      },
      required: ["pileTopDepth", "lat", "long"],
      additionalProperties: false,
};

export interface GTPInputsForBulkPrediction {
  pileTopDepth: number;
  lat: number;
  long: number;
}

const requiredBulkPredictionPropsWithoutHeatPump = [
  "name",
  "withHeatPump",
  "withKnownInletTemperature",
  "timeAndDateOfFirstRowStart",
  "timestepMagnitude",
  "timestepUnit",
  "autoGetGroundTemperature0m",
  "autoGetGroundTemperature5m",
  "autoGetGroundTemperature10m",
  "steelPile",
  "plasticPipe",
  "numberOfActivePilesInTheArray",
  "flowChangeBeforeArray",
  "averageInterPileFlowChangeInflow",
  "GTPInputs",
  "minInletFluidTemperature",
  "maxInletFluidTemperature"
]

export const BulkPredictionDTOSchema: (wHP: boolean)=>Schema = (withHeatPump: boolean)=> ({
  type: "object",
  properties: {
    name: { type: "string" },
    withHeatPump: { type: "boolean" },
    withKnownInletTemperature: { type: "boolean" },
    timeAndDateOfFirstRowStart: {type: 'object'},
    timestepMagnitude: { type: "integer", minimum: 1, maximum: 1000000 }, //int
    timestepUnit: BulkPredictionTimeUnitSchema,
    shouldReduceResolution: { type: "boolean" },
    reducedResolutionTimestepMagnitude: { type: "integer" },
    reducedResolutionTimestepUnit: BulkPredictionTimeUnitSchema,
    autoGetGroundTemperature0m: { type: "boolean" },
    autoGetGroundTemperature5m: { type: "boolean" },
    autoGetGroundTemperature10m: { type: "boolean" },
    steelPile: SteelPileSchema,
    plasticPipe: PlasticPipeSchema,
    heatPump: HeatPumpSchema,
    maxInletFluidTemperature: {type: "number", 
    minimum: -40,
    maximum: 80,
  },
    minInletFluidTemperature: {type:"number",
    minimum: -40,
    maximum: 80,
  },
    numberOfActivePilesInTheArray: {
      type: "number",
      minimum: 1,
      maximum: 10000,
    },
    flowChangeBeforeArray: { type: "number", minimum: 0, maximum: 1 },
    averageInterPileFlowChangeInflow: {
      type: "number",
      minimum: 0,
      maximum: 1,
    },
    // csv: {type: "object"},
    GTPInputs: GTPInputsForBulkPredictionSchema,
  },
  required: withHeatPump? [
    ...requiredBulkPredictionPropsWithoutHeatPump, 'heatPump'
  ]: requiredBulkPredictionPropsWithoutHeatPump,
  additionalProperties: false,
});



export type BulkPredictionDTO = {
  name: string;
  withHeatPump: boolean;
  withKnownInletTemperature: boolean;
  timeAndDateOfFirstRowStart: Date;
  timestepMagnitude: number; //int
  timestepUnit: BulkPredictionTimeUnit;
  shouldReduceResolution?: boolean;
  reducedResolutionTimestepMagnitude?: number; //int
  reducedResolutionTimestepUnit?: BulkPredictionTimeUnit;
  autoGetGroundTemperature0m: boolean;
  autoGetGroundTemperature5m: boolean;
  autoGetGroundTemperature10m: boolean;
  steelPile: ISteelPile;
  plasticPipe: IPlasticPipe;
  heatPump?: IHeatPump;
  numberOfActivePilesInTheArray: number;
  flowChangeBeforeArray: number;
  averageInterPileFlowChangeInflow: number;
  maxInletFluidTemperature: number;
  minInletFluidTemperature: number;
  // csv: File | undefined;
  GTPInputs: GTPInputsForBulkPrediction;
};

export function getBulkPredictionDtoWithDefaults(api:any): BulkPredictionDTO{
  return {
    name: '',
    'GTPInputs': {
      'lat': 0,
      'long': 0,
      'pileTopDepth':0,
    },
    'autoGetGroundTemperature0m': false,
    'autoGetGroundTemperature10m': false,
    'autoGetGroundTemperature5m': false,
    'averageInterPileFlowChangeInflow': 0,
    // 'csv': api.csv,
    'flowChangeBeforeArray': 0,
    'heatPump': api.HeatPump,
    'numberOfActivePilesInTheArray': 1,
    'plasticPipe': api.PlasticPipe,
    'steelPile': api.SteelPile,
    'timeAndDateOfFirstRowStart': new Date(),
    'timestepMagnitude': 1,
    'timestepUnit': 'days',
    'withHeatPump': true,
    'withKnownInletTemperature': false,
    'maxInletFluidTemperature': 30,
    'minInletFluidTemperature': 2,
  }
}