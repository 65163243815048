import styled, { css, keyframes } from "styled-components";
import HelpIcon from "@material-ui/icons/Help";
import Button from "components/Reusables/Button";


export const FadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const OuterContainer = styled.div`
margin: 0;
padding: 0;
/* background: linear-gradient(var(--c-gray-dark), var(--c-gray-medium-light), var(--c-gray)); */
background: var(--c-black);
`;
export const LeftCol = styled.div`
min-width: var(--s-18);
position: relative;
margin-right: var(--s-10);
margin-left: var(--s-1);
/* background: var(--c-gray-dark); */
color: var(--c-white);
`;
export const MidCol = styled.div`
color: var(--c-black);
`;
export const LowerBlankSection = styled.img`
align-self: center;
width: 80%;
margin-block: var(--s-17);
`;
export const RightCol = styled.div``;
export const LogoImage = styled.img`
height: var(--s-13);
margin-inline: var(--s-4);
`;
export const FunctionImage = styled.img`
height: var(--s-12);
margin-inline: var(--s-4);
`;
export const MLModelIndicator = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: var(--s-2);
padding-inline: var(--s-5);
/* margin: 0; */
border: 1px solid var(--c-green);
`;
export const MLModelIndicatorTitle = styled.h5`
margin: 0;
font-weight: 600;
color: var(--c-white);
`;
export const MLModelIndicatorText = styled.div`
margin: 0;
padding: 0;
color: var(--c-white);
`;
export const SessionSection = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
padding: 0;
margin: 0;
/* position: fixed; */
/* top: 29vw; */
/* background: var(--c-green-very-light); */
/* padding-block: var(--s-12); */
/* border: 1px solid var(--c-green-very-light); */
`;
export const SessionIndicator = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin: 0;
padding: 0;
/* padding: var(--s-2); */
/* padding-inline: var(--s-5); */
/* margin: var(--s-2); */
/* margin-inline: var(--s-10); */
`;
export const SessionIndicatorTitle = styled.h5`
margin: 0;
margin-right: var(--s-6);
font-weight: 600;
align-self: center;
padding: 0;
`;
export const SessionIndicatorText = styled.div`
margin: 0;
padding: 0;
color: var(--c-white);
align-self: center;
`;
export const SessionButtons = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0;
margin: 0;
`;
export const SessionButton = css`
/* background: var(--c-white); */
/* color: var(--c-green); */
/* border: solid var(--c-green-very-light); */
/* font-weight: 800; */
height: var(--s-10);
font-size: var(--fs-3);
/* :hover {
background: var(--c-green);
color: var(--c-white);
border: solid var(--c-white);
} */
`;

export const HeaderButtons = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`;
export const LogoGroup = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin: 0;
flex-direction: column;
position: fixed;
top: 29vw;
`;
export const MainContainerOuter = styled.div`
margin: 0;
padding: 0;
/* padding-bottom: var(--s-24); */
padding-top: var(--s-15);
display: flex;
flex-direction: column;
width: 100%;
/* align-items: center; */
justify-content: space-between;
animation: ${FadeIn} 0.5s ease-in both;
`;
export const VerticalDivider = styled.div`
height: var(--s-12);
border: solid var(--c-green-light);
width: 0;
margin: 0;
padding: 0;
margin-left: var(--s-10);
margin-right: var(--s-10);
`;
export const H4 = styled.h4`
align-self: center;
`;

export const NavLink = styled.div`
margin: 0;
padding: 0;
width: 0;
height: 0;
`;

export const InbetweenButton = styled(Button)`
/* margin-bottom: var(--s-13); */
`;
export const BasicCols = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
position: relative;
`;
export const InnerRows = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
position: relative;
`;
export const InnerCols = styled.div`
display: flex;
flex-direction: column;
align-items: left;
justify-content: flex-start;
position: relative;
`;


export const InnerTitleCont = styled.div`
background: var(--c-green);
width: 99%;
position: relative;
left: 0;
padding-top: var(--s-3);
padding-bottom: var(--s-3);
box-shadow: inset 0px 4px 8px rgba(255, 255, 255, 0.5);
`;

export const InnerTitleText = styled.h4`
color: var(--c-white);
`;
export const SimpleRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: left;
background: var(--c-gray);
margin: 0;
padding: var(--s-2);
width: 100%;
`;
export const InnerInput = styled.div`
display: flex;
flex-direction: column;
align-items: left;
justify-content: left;
background: var(--c-black);
margin-top: var(--s-8);
margin-bottom: var(--s-8);
padding: var(--s-8);
width: 100%;
`;

export const Container = styled.div`
background: var(--c-gray);
position: relative;
padding: var(--s-7);
margin-bottom: var(--s-15);
color: var(--c-white);
`;
export const TitleContainer = styled.div`
background: var(--c-black);
width: 99%;
position: absolute;
top: -4vw;
left: 0;
padding-top: var(--s-3);
padding-bottom: var(--s-3);
box-shadow: inset 0px 4px 8px rgba(255, 255, 255, 0.5);
`;

export const InputInstr = styled.h4`
margin-left: var(--s-5);
color: var(--c-background-white);
margin-right: var(--s-5);
padding-top: var(--s-5);
padding-bottom: var(--s-5);
width: 100%;
`;
export const SecondaryInputInstr = styled.h5`
margin-left: var(--s-5);
color: var(--c-green);
margin-right: var(--s-5);
padding-top: var(--s-5);
padding-bottom: var(--s-5);
`;
export const LittleLabel = styled.p`
margin-left: var(--s-5);
color: var(--c-background-white);
margin-right: var(--s-5);
`;
export const LittleInstr = styled.p`
margin-left: var(--s-8);
color: var(--c-green);
margin-right: var(--s-5);
font-style: italic;
padding-top: var(--s-5);
padding-bottom: var(--s-5);
`;
export const InputCols = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
position: relative;
/* margin-inline: var(--s-10); */
padding: var(--s-8);
margin: 0;
margin-left: var(--s-5);
background: var(--c-gray-dark);
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const GTPLogo = styled.img`
width: 20%;
`;
export const BottomBar = styled.div`
position: relative;
/* border-radius: 0; */
/* padding: 0; */
/* margin: 0; */
color: var(--c-grey-very-light);
width: 70%;
box-sizing: border-box;
background: var(--c-black);
/* left: 30%; */
display: flex;
flex-direction: row;
align-items: right;
justify-content: space-between;
box-shadow: var(--shadow-1-surround);
`;
export const LogoSection = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 0;
margin: 0;
`;
export const LittleDivider = styled.div`
background: var(--c-gray);
min-width: 90%;
border-radius: none;
padding: var(--s-2);
align-self: flex-start;
`;
export const InfoButton = styled(HelpIcon)`
margin-left: var(--s-10);
cursor: pointer;
color: var(--c-green);
:hover {
  color: var(--c-black);
}
`;