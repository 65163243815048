import { GlobalContext } from 'components/Contexts/Global';
import Popup from 'components/Reusables/Popup';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    background: var(--c-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;


interface ValidationErrorPopupProps{
    message: string
}
const ValidationErrorPopup: React.FunctionComponent<ValidationErrorPopupProps> =({message})=> {
const gc = useContext(GlobalContext);
return (
<Popup
    closeCallback={()=>gc?.setPopup(undefined)}
    title="Error:"
>
    <Container>
        {message}
    </Container>
</Popup>
);
}
export default ValidationErrorPopup;
