import { getPercentage, trimDecimals } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IHeatPumpCapacityResults } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import SummaryPageTemplate, { TD, TDGreen, THGreen } from "./SummaryPageTemplate";
import ReportTemplate, { getConvertedText, getPileInletFlowRate, H1, H4 } from "../Template";
import { convertFlowRateToLPM, convertToImperial } from "lib/units";

export interface SessionData {
  name: string;
  heatingOrCooling: "Heating" | "Cooling";
  wholeArrayHeatPumpCapacity: number;
  averageGeoPileThermalCapacity: number;
  heatPumpCoefficientOfPerformance: number;
  targetWholeArrayHeatPumpCapacity: number;
  numberOfActivePilesInTheArray: number;
  steelPileLength: number;
  steelPileNominalSize: string;
  plasticPipeNominalSize: string;
  pileInletFlowRate: number;
  heatPumpName: string;
  groundThermalConductivityMean: number;
  groundTemperature10mBelowTopOfPile: number;
  ambientAirTemperature: number;
}



interface HeatPumpCapacityReportProps {
  id: any;
  sessions: IHeatPumpCapacityResults[];
  imperial: boolean
  TESTING?: boolean
}
const HeatPumpCapacityReport: React.FunctionComponent<HeatPumpCapacityReportProps> = ({
  id,
  sessions,
  imperial,
  TESTING,
}) => {


  

  function getWholeArrayHeatPumpCapacity(session: IHeatPumpCapacityResults) {
    if (!session?.calculator?.pileArray?.numberOfActivePilesInTheArray?.inputValue || TESTING) return 1000;
    const result = Math.abs(
      session.perPileHeatPumpCapacity *
      session.calculator.pileArray.numberOfActivePilesInTheArray.inputValue
    );
    return result;
  }

  function getTopWholeArrayHeatPumpCapacity(){
    if (TESTING) return 1000;
    const result =Math.abs(getWholeArrayHeatPumpCapacity(sessions.reduce((prev, curr)=> {
          const thisVal = getWholeArrayHeatPumpCapacity(curr);
          const prevVal = getWholeArrayHeatPumpCapacity(prev);
          if (thisVal > prevVal)
            return curr;
            else 
            return prev;
        })));
      return trimDecimals(result,2);
  }
  function getTopGeoPileThermalCapacity(){
    if (TESTING)return 1000;
    const result = Math.abs(sessions.reduce((prev, curr)=>{
         if (Math.abs(curr.averageGeoPileThermalCapacity) > Math.abs(prev.averageGeoPileThermalCapacity)){
             return curr;
         }else {
             return prev;
         }
      }).averageGeoPileThermalCapacity);
      return trimDecimals(result,2);
  }

  return (
    <SummaryPageTemplate
      id={id}
      mainTitle="Heat Pump Capacity Prediction"
      topDisclaimerParagraph={`The following thermal performance measures were predicted using machine learning software based on given site and component details. 
        \n These predictions are generated with the most recent, validated data for geo-piles, list of assumptions and sources of error are provided in the attached appendix.
        `}
      explanationOfResultsParagraph={`Thermal performance of the system is predicted to reach the values presented in the following table. These are defined by the type of capacity supplied (as either heating or cooling), the total capacity to the building, and the thermal capacity per pile in the array. The Coefficient of Performance (COP) is also shown and reflects the efficiency of the heat pump based on eith heating or cooling. 
        `}
      summaryHighlightTopValue={getConvertedText(imperial, getTopWholeArrayHeatPumpCapacity(),'W')}
      summaryHighlightTopLabel={"Whole-Array Heat Pump Capacity"}
      summaryHighlightBottomValue={getConvertedText(imperial,getTopGeoPileThermalCapacity(),'W')}
      summaryHighlightBottomLabel={"Average Geo-Pile Thermal Capacity"}
      summaryTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Load Type</th>
            <th>Heat Pump Capacity Supplied</th>
            <th>Average Geo-Pile Thermal Capacity</th>
            <th>COP</th>
            <th>Target Demand Met</th>
          </tr>
          {TESTING?
          <tr>
          <TDGreen>
          {'testing' || "     "}
            </TDGreen>
          <TD>
            " bount"
          </TD>
          <TD>
            14324
          </TD>
          <TD>
            123
            </TD>
          <TD>
            324
          </TD>
          <TD>
            324
          </TD>
        </tr>
        :<></>}
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {session.isCoolingSeasonAtHeatPump ? "Cooling" : "Heating"}
                </TD>
                <TD>
                  {getConvertedText(imperial, getWholeArrayHeatPumpCapacity(session),'W')}
                </TD>
                <TD>
                  {getConvertedText(imperial, session.averageGeoPileThermalCapacity,'W')}
                  </TD>
                <TD>{trimDecimals(session.heatPumpCoefficientOfPerformance,1)}</TD>
                <TD>
                  {trimDecimals(getPercentage(
                    getWholeArrayHeatPumpCapacity(session),
                    session.calculator.pileArray?.targetWholeArrayHeatPumpCapacity
                      ?.inputValue || 0
                  ),1)}
                  {' %'}
                </TD>
              </tr>
            );
          })}
        </>
      }
      siteAndStudyBlurb={`These predictions are based on the following inputs for the site and study details. More details about the ground temperature inputs and ground thermal conductivity are provided in the appendix.`}
      siteAndStudyTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Ambient Air Temperature</th>
            <th>Ground Temperature 10m</th>
            <th>Average Ground Thermal Conductivity</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {getConvertedText(imperial, session.calculator.climate?.ambientAirTemperature?.inputValue,'degCEnv')}
                </TD>
                <TD>
                  {getConvertedText(imperial, session.calculator.ground?.groundTemperature10mBelowTopOfPile?.inputValue,'degCEnv')}
                </TD>
                <TD>
                  {session.calculator.ground?.averageGroundThermalConductivity?.inputValue +' W/mK'}
                </TD>
              </tr>
            );
          })}
        </>
      }
      componentDetailsBlurb={
        "These predictions are based on the following inputs for the component details. More details about the pile size and heat pump are provided in the appendix. Note that the number of piles reports the number of piles in the array that are on and contributing to the thermal capacity."
      }
      componentDetailsTableContent={
        <>
          <tr>
            <THGreen>Session Name</THGreen>
            <th>Number of Active Piles</th>
            <th>Steel Pile Type</th>
            <th>Plastic Pipe Type</th>
            <th>Pile Length</th>
            <th>Pile Inlet Flow Rate</th>
            <th>Heat Pump Type</th>
          </tr>
          {sessions.map((session) => {
            return (
              <tr key={session.calculator.name}>
                <TDGreen>{session.calculator.name}</TDGreen>
                <TD>
                  {session.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue}
                </TD>
                <TD>
                  {
                    session.calculator.steelPile?.steelPileNominalSizeForClient
                      ?.inputValue
                  }
                </TD>
                <TD>
                  {
                    session.calculator.plasticPipe?.plasticPipeNominalSizeForClient
                      ?.inputValue
                  }
                </TD>
                <TD>
                  {getConvertedText(imperial,session.calculator.steelPile?.length?.inputValue,'m')}
                  </TD>
                <TD>
                  {getPileInletFlowRate(session.calculator, imperial)}
                </TD>
                <TD>{session.calculator.heatPump?.name}</TD>
              </tr>
            );
          })}
        </>
      }
    />
  );
};
export default HeatPumpCapacityReport;
