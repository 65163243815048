import { HorizFlex } from 'components/MainMenu';
import { trimDecimalPlaces } from 'lib/helper-functions';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';
import {TextLabel, Unit} from './Template';

const DynamicResult = styled.div`
  color: var(--c-white);
  background: var(--c-green);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: var(--s-17);
  /* padding: 0; */
    /* font-size: var(--fs-6); */
`;
interface DynamicOutputVariableProps{
  value: number | string
  label: string
  unit?: string
  decimalPlaces?: number
}
const DynamicOutputVariable: React.FunctionComponent<DynamicOutputVariableProps> =({
  value, label, unit, decimalPlaces
})=> {

return (
        <HorizFlex>
            <TextLabel>
              {label}
            </TextLabel>
              <DynamicResult>
                {decimalPlaces?
                trimDecimalPlaces(value.toString(), decimalPlaces)
                :value}
                <Unit>
                {unit}
            </Unit> 
            </DynamicResult>
             
        </HorizFlex>
);
}
export default DynamicOutputVariable;
