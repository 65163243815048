import { ICalculator, IClimate, IHeatPump, IPileArray, IPileInFluid, IPileOutFluid, IPlasticPipe, PossibleCategoryNames as Cat, IGround, ISteelPile, IUiInputVariableDouble, IUiInputVariableInt, IUiInputVariableString, OutputVariables, pileArrayToDto, heatPumpToDto, plasticPipeToDto, steelPileToDto, groundToDto, climateToDto, pileOutFluidToDto, pileInFluidToDto, PileInFluid } from 'lib/models';
import { IBaseModel } from 'lib/models/base-model';
import { ReusableComponentBase } from 'sheldons-components';
import { IPlasticPipeNominalSize } from './models/plastic-pipe-nominal-size';
import { ISteelPileNominalSize } from './models/steel-pipe-nominal-size';

export interface ObjectCrudBase extends ReusableComponentBase{
  object: any,
  setObject: any,
  instructionKeyMap: {[key: string]: string | undefined}
  fieldsToExclude?: string[]
}
  
  export interface IGroundTemperatureCalculator extends ApiBaseModel {
    climate: IClimate;
    plasticPipe: IPlasticPipe;
    steelPile: ISteelPile;
    pileOutFluid: IPileOutFluid;
    pileInFluid: IPileInFluid;
    ground: IGround;
  }
  
  export type ApiSingleVariableFromApi = IUiInputVariableDouble | IUiInputVariableInt | IUiInputVariableString;
  
  export interface ApiBaseModel extends IBaseModel{}
  
  
  export type ICalculators = ICalculator[];
  export type IGrounds = IGround[];
  export type IClimates = IClimate[];
  export type IPileOutFluids = IPileOutFluid[];
  export type IPileInFluids = IPileInFluid[];
  export type IHeatPumps = IHeatPump[];
  export type IPlasticPipes = IPlasticPipe[];
  export type ISteelPiles = ISteelPile[];
  export type IPileArrays = IPileArray[];
  export type IPlasticPipeNominalSizes = IPlasticPipeNominalSize[];
  export type ISteelPileNominalSizes = ISteelPileNominalSize[];

  export type ApiEndpoints = 
    'CalculatePerPileHeatPumpCapacity'|'calculateperpileheatpumpcapacity'|
    'CalculateNumberOfActivePilesInTheArray'|'calculatenumberofactivepilesinthearray'|
    // 'CalculatePileDiameters'|'calculatepilediameters'|
    'CalculatePileLengths'|'calculatepilelengths'|
    'CalculateGroundTemperature'|'calculategroundtemperature'|
    'Calculators'|'calculators'|
    'Climates'|'climates'|
    'PileOutletFluidTemperatureMLModels'|'pileoutletfluidtemperaturemlmodels'|
    'PileLengthMLModels'|'pilelengthmlmodels'|
    'HeatPumps'|'heatpumps'|
    'Password'|'password'|
    'PileArrays'|'pilearrays'|
    'PileInFluids'|'pileinfluids'|
    'PileOutFluids'|'pileoutfluids'|
    'PlasticPipeNominalSizes'|'plasticpipenominalsizes'|
    'PlasticPipes'|'plasticpipes'|
    ''|
    'SecurityQuestion'|'securityquestion'|
    'Grounds'|'grounds'|
    'GroundTemperatureMLModels'|'groundtemperaturemlmodels'|
    'SteelPileNominalSizes'|'steelpilenominalsizes'|
    'SteelPiles'|'steelpiles'|
    'User'|'user';
    export const readableVariableNoAbrev: {[key in OfficialVariables]:string } =  {
      wholeArrayHeatPumpCapacity : 'Whole Array Heat Pump Capacity',
      perPileHeatPumpCapacity : 'Per-Pile Heat Pump Capacity',
      averageGeoPileThermalCapacity : 'Average Geo-Pile Thermal Capacity',
      targetWholeArrayHeatPumpCapacity : 'Target Whole Array Heat Pump Capacity',
      targetGeoPileThermalCapacity : 'Target Geo-Pile Thermal Capacity',
      targetWholeArrayHeatPumpCapacityMet : 'Target Whole Array Heat Pump Capacity Met',
      targetGeoPileThermalCapacityMet : 'Target Geo-Pile Thermal Capacity Met',
      heatPumpCoefficientOfPerformance : 'Heat Pump Coefficient of Performance',
      coolingSeasonCoefficientA: 'Cooling Season Coefficient A',
      coolingSeasonCoefficientB: 'Cooling Season Coefficient B',
      coolingSeasonCoefficientC: 'Cooling Season Coefficient C',
      heatingSeasonCoefficientA: 'Heating Season Coefficient A',
      heatingSeasonCoefficientB: 'Heating Season Coefficient B',
      heatingSeasonCoefficientC: 'Heating Season Coefficient C',
      ambientAirTemperature : 'Ambient Air Temperature',
      groundTemperature10mBelowTopOfPile : 'Ground Temperature 10m Below Top of Pile',
      groundTemperature5mBelowTopOfPile : 'Ground Temperature 5m Below Top of Pile',
      groundTemperatureAtTopOfPile : 'Ground Temperature at Top of Pile',
      averageGroundThermalConductivity : 'Average Ground Thermal Conductivity',
      maxGroundThermalConductivity : 'Maximum Ground Thermal Conductivity',
      minGroundThermalConductivity : 'Minimum Ground Thermal Conductivity',
      steelPileLength : 'Steel Pile Length',
      plasticInletPipeLength : 'Plastic Inlet Pipe Length',
      steelPileNominalSize : 'Steel Pile Nominal Size',
      steelPileNominalSizeForClient : 'Steel Pile Nominal Size',
      plasticPipeNominalSizeForClient : 'Plastic Pipe Nominal Size',
      plasticPipeNominalSize : 'Plastic Pipe Nominal Size',
      steelPileInnerDiameter : 'Steel Pile Inner Diameter',
      steelPileOuterDiameter : 'Steel Pile Outer Diameter',
      plasticPipeInnerDiameter : 'Plastic Pipe Inner Diameter',
      plasticPipeOuterDiameter : 'Plastic Pipe Outer Diameter',
      pileInletFlowRate : 'Pile Inlet Flow Rate',
      pileInletFluidTemperature : 'Pile Inlet Fluid Temperature',
      pileOutletFluidTemperature : 'Pile Outlet Fluid Temperature',
      heatPumpOutletFluidTemperature : 'Heat Pump Outlet Fluid Temperature',
      heatPumpInletFluidTemperature : 'Heat Pump Inlet Fluid Temperature',
      arrayPumpOutletFlowRate : 'Array Pump Outlet Flow Rate',
      flowChangeBeforeArray : 'Flow Rate Change Before Array',
      averageInterPileFlowChangeInflow : 'Average Inter-Pile Flow Rate Change, Inflow',
      temperatureChangeBeforeArray : 'Temperature Change Before Array',
      averageInterPileTemperatureChangeInflow : 'Average Inter-Pile Temperature Change, Inflow',
      averageInterPileTemperatureChangeOutflow : 'Average Inter-Pile Temperature Change, Outflow',
      temperatureChangeAfterArray : 'Temperature Change After Array',
      groundThermalPotential : 'Ground Thermal Potential',
      changeInTemperatureAcrossPile : 'Change in Temperature Across Pile',
      numberOfActivePilesInTheArray : 'Number of Active Piles in the Array',
      targetWholeArrayGeoPileCapacity: 'Target Whole-Array Geo-Pile Capacity',
      wholeArrayGeoPileCapacity: 'Whole-Array Geo-Pile Capacity'
    }
  


export type OfficialVariables = 
'wholeArrayHeatPumpCapacity' |
'perPileHeatPumpCapacity'|
'averageGeoPileThermalCapacity' |
'targetWholeArrayHeatPumpCapacity' |
'targetGeoPileThermalCapacity' |
'targetWholeArrayHeatPumpCapacityMet' |
'targetGeoPileThermalCapacityMet' |
'heatPumpCoefficientOfPerformance' |
'ambientAirTemperature' |
'groundTemperature10mBelowTopOfPile' |
'groundTemperature5mBelowTopOfPile' |
'groundTemperatureAtTopOfPile' |
'averageGroundThermalConductivity' |
'maxGroundThermalConductivity' |
'minGroundThermalConductivity' |
'steelPileLength' |
'plasticInletPipeLength' |
'steelPileNominalSize' |
'steelPileNominalSizeForClient'|
'plasticPipeNominalSize' |
'plasticPipeNominalSizeForClient'|
'steelPileInnerDiameter' |
'steelPileOuterDiameter' |
'plasticPipeInnerDiameter' |
'plasticPipeOuterDiameter' |
'pileInletFlowRate' |
'pileInletFluidTemperature' |
'pileOutletFluidTemperature' |
'heatPumpOutletFluidTemperature' |
'heatPumpInletFluidTemperature' |
'arrayPumpOutletFlowRate' |
'numberOfActivePilesInTheArray' |
'flowChangeBeforeArray' |
'averageInterPileFlowChangeInflow' |
'temperatureChangeBeforeArray' |
'averageInterPileTemperatureChangeInflow' |
'averageInterPileTemperatureChangeOutflow' |
'temperatureChangeAfterArray' |
'groundThermalPotential' |
'changeInTemperatureAcrossPile' |
'coolingSeasonCoefficientA' |
'coolingSeasonCoefficientB' |
'coolingSeasonCoefficientC' |
'heatingSeasonCoefficientA' |
'heatingSeasonCoefficientB' |
'heatingSeasonCoefficientC' |
'targetWholeArrayGeoPileCapacity' |
'wholeArrayGeoPileCapacity'


export const allAbreviations = [
  'Whp',
 'Whppp',
 'Wghe',
 'WhpT',
 'WgheT',
 '%Thpc',
 '%Tgpc',
 'COP',
 'cAc',
 'cBc',
 'cCc',
 'cAh',
 'cBh',
 'cCh',
 'Tair',
 'Tground10',
 'Tground5',
 'Tground0',
 'Kavg',
 'Kmax',
 'Kmin',
 'Lsp',
 'Lpp',
'',
 'Disp',
 'Dosp',
 'Dipp',
 'Dopp',
 'Qin',
 'Tin',
 'Tout',
 'Tohp',
 'Tihp',
 'Qoap',
 'Qlpre',
 'Qliin',
 'Tlpre',
 'Tliin',
 'Tliout',
 'Tlpost',
 'Wghe',
 '\u0394T',
 'Nop',
 'AWgheT',
 'AWghe'
] as const;

export type AllAbreviations = (typeof allAbreviations)[number]

export const VariableAbreviation: {[key in OfficialVariables]: AllAbreviations} =  {
  wholeArrayHeatPumpCapacity : 'Whp',
  perPileHeatPumpCapacity : 'Whppp',
  averageGeoPileThermalCapacity : 'Wghe',
  targetWholeArrayHeatPumpCapacity : 'WhpT',
  targetGeoPileThermalCapacity : 'WgheT',
  targetWholeArrayHeatPumpCapacityMet : '%Thpc',
  targetGeoPileThermalCapacityMet : '%Tgpc',
  heatPumpCoefficientOfPerformance : 'COP',
  coolingSeasonCoefficientA: 'cAc',
  coolingSeasonCoefficientB: 'cBc',
  coolingSeasonCoefficientC: 'cCc',
  heatingSeasonCoefficientA: 'cAh',
  heatingSeasonCoefficientB: 'cBh',
  heatingSeasonCoefficientC: 'cCh',
  ambientAirTemperature : 'Tair',
  groundTemperature10mBelowTopOfPile : 'Tground10',
  groundTemperature5mBelowTopOfPile : 'Tground5',
  groundTemperatureAtTopOfPile : 'Tground0',
  averageGroundThermalConductivity : 'Kavg',
  maxGroundThermalConductivity : 'Kmax',
  minGroundThermalConductivity : 'Kmin',
  steelPileLength : 'Lsp',
  plasticInletPipeLength : 'Lpp',
  steelPileNominalSize :'',
  steelPileNominalSizeForClient :'',
  plasticPipeNominalSize : '',
  plasticPipeNominalSizeForClient : '',
  steelPileInnerDiameter : 'Disp',
  steelPileOuterDiameter : 'Dosp',
  plasticPipeInnerDiameter : 'Dipp',
  plasticPipeOuterDiameter : 'Dopp',
  pileInletFlowRate : 'Qin',
  pileInletFluidTemperature : 'Tin',
  pileOutletFluidTemperature : 'Tout',
  heatPumpOutletFluidTemperature : 'Tohp',
  heatPumpInletFluidTemperature : 'Tihp',
  arrayPumpOutletFlowRate : 'Qoap',
  flowChangeBeforeArray : 'Qlpre',
  averageInterPileFlowChangeInflow : 'Qliin',
  temperatureChangeBeforeArray : 'Tlpre',
  averageInterPileTemperatureChangeInflow : 'Tliin',
  averageInterPileTemperatureChangeOutflow : 'Tliout',
  temperatureChangeAfterArray : 'Tlpost',
  groundThermalPotential : 'Wghe',
  changeInTemperatureAcrossPile : '\u0394T',
  numberOfActivePilesInTheArray : 'Nop',
  targetWholeArrayGeoPileCapacity: 'AWgheT',
  wholeArrayGeoPileCapacity: 'AWghe'
}

type UnitVariableNames = 'W'|'%'|''|'degC'|'C'|'W/mK'|'m'|'m3/s'

export const variableUnit: {[key in OfficialVariables]:UnitVariableNames}= {
  wholeArrayHeatPumpCapacity : 'W',
  perPileHeatPumpCapacity : 'W',
  averageGeoPileThermalCapacity : 'W',
  targetWholeArrayHeatPumpCapacity : 'W',
  targetGeoPileThermalCapacity : 'W',
  targetWholeArrayHeatPumpCapacityMet : '%',
  targetGeoPileThermalCapacityMet : '%',
  heatPumpCoefficientOfPerformance : '',
  coolingSeasonCoefficientA: '',
  coolingSeasonCoefficientB: '',
  coolingSeasonCoefficientC: '',
  heatingSeasonCoefficientA: '',
  heatingSeasonCoefficientB: '',
  heatingSeasonCoefficientC: '',
  ambientAirTemperature : 'degC',
  groundTemperature10mBelowTopOfPile : 'degC',
  groundTemperature5mBelowTopOfPile : 'degC',
  groundTemperatureAtTopOfPile : 'degC',
  averageGroundThermalConductivity : 'W/mK',
  maxGroundThermalConductivity : 'W/mK',
  minGroundThermalConductivity : 'W/mK',
  steelPileLength : 'm',
  plasticInletPipeLength : 'm',
  steelPileNominalSize :'',
  steelPileNominalSizeForClient :'',

  plasticPipeNominalSize : '',
  plasticPipeNominalSizeForClient : '',

  steelPileInnerDiameter : 'm',
  steelPileOuterDiameter : 'm',
  plasticPipeInnerDiameter : 'm',
  plasticPipeOuterDiameter : 'm',
  pileInletFlowRate : 'm3/s',
  pileInletFluidTemperature : 'degC',
  pileOutletFluidTemperature : 'degC',
  heatPumpOutletFluidTemperature : 'degC',
  heatPumpInletFluidTemperature : 'degC',
  arrayPumpOutletFlowRate : 'm3/s',
  numberOfActivePilesInTheArray : '',
  flowChangeBeforeArray : 'm3/s',
  averageInterPileFlowChangeInflow : 'm3/s',
  temperatureChangeBeforeArray : 'C',
  averageInterPileTemperatureChangeInflow : 'C',
  averageInterPileTemperatureChangeOutflow : 'C',
  temperatureChangeAfterArray : 'C',
  groundThermalPotential : 'W',
  changeInTemperatureAcrossPile : 'degC',
  targetWholeArrayGeoPileCapacity: 'W',
  wholeArrayGeoPileCapacity: 'W'
}

export const readableUnits: {[key in UnitVariableNames]:string} = {
  '%': '%',
  'C': '°C',
  'W': 'Watts',
  'W/mK': 'W/mK',
  'degC': '°C',
  'm': 'meters',
  'm3/s': 'm3/s',
  '': 'No Unit'
}

function generateReadableVariables(){
  const result: any = {};
  Object.keys(readableVariableNoAbrev).forEach((k: string) => {
    const key: OfficialVariables = k as OfficialVariables;
    const abrev = VariableAbreviation[key]?` (${VariableAbreviation[key]})`:''
    result[key] = readableVariableNoAbrev[key] + abrev;
  })
  return result;
}

export const readableVariable: {[key in OfficialVariables]: string} = generateReadableVariables()

export function getOfficialVariableFromAbrv(abrv: AllAbreviations):OfficialVariables{
  return Object.keys(VariableAbreviation).find((key) => VariableAbreviation[key as OfficialVariables] === abrv) as OfficialVariables
}