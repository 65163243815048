import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import Popup from "components/Reusables/Popup";

import { createEmpty } from "lib/helper-functions";
import {
    ICalculator,
  Calculator,
} from "lib/models/calculator";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { getAllowedUnitsFromBaseUnit } from "lib/units";
import NumberInput from "components/Reusables/NumberInput";
import { Option } from "sheldons-components";
import _ from 'lodash';
import styled, {css} from 'styled-components';
import Select from "components/Reusables/Select";
import Button from "components/Reusables/Button";
import GeneralInput from "components/Reusables/GeneralInput";
import { OutputVariables } from "lib/models";

const Container = styled.div`
  background: var(--c-green-very-light);
  position: relative;
  padding: var(--s-7);
`;
const TitleContainer = styled.div`
  background: var(--c-black);
  width: fit-content;
  position: absolute;
  top: -4vw;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--c-black);
  color: var(--c-background-white);
`;
const ACRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
`;
const ACRow1 = styled.div`
  ${ACRow}
  background: var(--c-gray);
`;
const SelectLabel = styled.div`
  color: var(--c-green-very-light);
  font-weight: bold;
`;
const ACRow2 = styled.div`
  ${ACRow}
`;



interface CalculatorCrudProps {}
const CalculatorCrud: React.FunctionComponent<CalculatorCrudProps> = (
  props
) => {
  const gc = useContext(GlobalContext);
  const api = useContext(BackendAPIContext);
  const [selectedObject, setSelectedObject] = useState<
    ICalculator | undefined
  >();
 
    const emptyObject = createEmpty.calculator();
  function savedObjectsToOptions(): Option[] | undefined {
    if (!api?.calculators || api?.calculators.length < 1) return undefined;
    //! Filter sessions by current output variable
    // //console.log(api.calculators);
    const calculatorsWithSameOutputVariable = api?.calculators.filter(calc => calc.outputVariable === gc?.outputVariable);
    let calculatorsWithSameWithHeatPumpOption = [];
    if (gc?.outputVariable === OutputVariables.PileLength){
      if (gc.withHeatPump){
        calculatorsWithSameWithHeatPumpOption = calculatorsWithSameOutputVariable.filter(calc => !calc.pileArray?.targetGeoPileThermalCapacity?.inputValue);
      }else {
        calculatorsWithSameWithHeatPumpOption = calculatorsWithSameOutputVariable.filter(calc => calc.pileArray?.targetGeoPileThermalCapacity?.inputValue);
      }
    }else {
      calculatorsWithSameWithHeatPumpOption = calculatorsWithSameOutputVariable;
    }
    const filteredCalcsAsOptions = calculatorsWithSameWithHeatPumpOption.map(calc => crudableObjectToOption(calc));
    const sanitizedOptions = filteredCalcsAsOptions.filter(item => item) as Option[];
    return sanitizedOptions;
  }
  function crudableObjectToOption(
    obj: ICalculator | undefined
  ): Option | undefined {
    if (!obj || obj.id === null || obj.id === undefined) return undefined;
    return { id: obj.id, value: obj.id, content: obj.name };
  }
  function getSavedObjectFromOption(option: Option | undefined) {
    if (!api?.calculators || !option) return undefined;
    return api.calculators.find((savedObject) => savedObject.id === option.id);
  }
  async function handleOverwriteClick() {
    if (!api?.calculator || !api?.saveModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    if (_.isEqual(api?.calculator, emptyObject )) return;
    await api?.updateCalculator(false,gc.apiToken);
    await api?.refreshModelsLists(gc.apiToken);
    refreshSelectedObject();
  }
  function refreshSelectedObject(){
    //console.log('refreshing selected object');
    const id = selectedObject?.id;
    const newSelected = api?.calculators?.find(object => object.id === id);
    //console.log('found this new selected object', newSelected);
    setSelectedObject(newSelected);
  }
  function handleSaveAsNewClick(){
    if (!api?.calculator || !api?.saveModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    if (_.isEqual(api?.calculator, emptyObject)) return;
    if (!api.calculator.name){
      window.alert('Error: You must enter a name.');
      return;
    }
    if (api.calculator.name.length > 30) {
      window.alert('Error: You cannot use a name longer than 30 characters.');
      return;
    }
    //console.log('saving as new, setting id to undefined');
    api.setCalculator(prev => ({...prev!, id: undefined}))
    api.saveCalculator(true,gc?.apiToken);
  }
  async function handleDeleteClick() {
    if (!api?.calculator || !api.calculator.name || !api?.deleteModel || !api?.refreshModelsLists || !gc?.apiToken) return;
    await api?.deleteModel('calculators',api?.calculator.name,gc?.apiToken);
    await api?.refreshModelsLists(gc?.apiToken);
    setSelectedObject(undefined);
   
  }
  function handleLoadClick() {
      if (!selectedObject) return;
      api?.loadCalculator(selectedObject);
  }


 

  return (
    <Popup 
    title="Sessions"
    closeCallback={() => gc?.setPopup(undefined)}>
      <Container>
      <ActionsContainer>
        <ACRow1>
          <SelectLabel>Select from saved Sessions:</SelectLabel>
          <Select
            type="styled"
            options={savedObjectsToOptions()}
            selected={crudableObjectToOption(selectedObject)}
            onChange={(obj: Option | undefined) =>
              setSelectedObject(getSavedObjectFromOption(obj))
            }
            placeholder={`Select`}
          ></Select>
          <Button onClick={handleLoadClick}>Load</Button>
        </ACRow1>
        <ACRow2>
          <GeneralInput
            darkBackground
            label="Name"
            autoComplete="none"
            value={
              api?.calculator && api?.calculator.name !== null
                ? api?.calculator.name
                : undefined
            }
            onChange={(e: any) => {
              api?.setCalculator((prev: any) => {
                const clone = { ...prev };
                clone.name = e.target.value;
                return clone;
              });
            }}
          />
          <Button onClick={handleOverwriteClick}>Overwrite</Button>
          <Button onClick={handleSaveAsNewClick}>Save As New</Button>
          <Button onClick={handleDeleteClick}>Delete</Button>
        </ACRow2>
      </ActionsContainer>
    </Container>
    </Popup>
  );
};
export default CalculatorCrud;
