import { Schema } from "ajv";
// import { UiInputVariableDoubleSchema } from ".";
import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble, UiInputVariableDoubleSchema } from "./ui-input-variable-double";

export interface IHeatPump extends IBaseModel {

  coolingSeasonCoefficientA?: IUiInputVariableDouble | null;

  coolingSeasonCoefficientB?: IUiInputVariableDouble | null;

  coolingSeasonCoefficientC?: IUiInputVariableDouble | null;

  heatingSeasonCoefficientA?: IUiInputVariableDouble | null;

  heatingSeasonCoefficientB?: IUiInputVariableDouble | null;

  heatingSeasonCoefficientC?: IUiInputVariableDouble | null;
}
export interface IHeatPumpOut extends IBaseModel {

  coolingSeasonCoefficientA?: IUiInputVariableDoubleOut | null;

  coolingSeasonCoefficientB?: IUiInputVariableDoubleOut | null;

  coolingSeasonCoefficientC?: IUiInputVariableDoubleOut | null;

  heatingSeasonCoefficientA?: IUiInputVariableDoubleOut | null;

  heatingSeasonCoefficientB?: IUiInputVariableDoubleOut | null;

  heatingSeasonCoefficientC?: IUiInputVariableDoubleOut | null;
}
export class HeatPump extends BaseModel implements IHeatPump{
  public coolingSeasonCoefficientA?: IUiInputVariableDouble = new UiInputVariableDouble('coolingSeasonCoefficientA');

  public coolingSeasonCoefficientB?: IUiInputVariableDouble = new UiInputVariableDouble('coolingSeasonCoefficientB');

  public coolingSeasonCoefficientC?: IUiInputVariableDouble = new UiInputVariableDouble('coolingSeasonCoefficientC');

  public heatingSeasonCoefficientA?: IUiInputVariableDouble = new UiInputVariableDouble('heatingSeasonCoefficientA');

  public heatingSeasonCoefficientB?: IUiInputVariableDouble = new UiInputVariableDouble('heatingSeasonCoefficientB');

  public heatingSeasonCoefficientC?: IUiInputVariableDouble = new UiInputVariableDouble('heatingSeasonCoefficientC');

}

const hpCoefficientSchema = UiInputVariableDoubleSchema();

export const HeatPumpSchema: Schema = {
  type: 'object',
  properties: {
    coolingSeasonCoefficientA: hpCoefficientSchema,
    coolingSeasonCoefficientB: hpCoefficientSchema,
    coolingSeasonCoefficientC: hpCoefficientSchema,
    heatingSeasonCoefficientA: hpCoefficientSchema,
    heatingSeasonCoefficientB: hpCoefficientSchema,
    heatingSeasonCoefficientC: hpCoefficientSchema,
  },
  required: [
    'coolingSeasonCoefficientA',
    'coolingSeasonCoefficientB',
    'coolingSeasonCoefficientC',
    'heatingSeasonCoefficientA',
    'heatingSeasonCoefficientB',
    'heatingSeasonCoefficientC',
  ],
  additionalProperties: true
}


export function heatPumpToDto(base: IHeatPump): IHeatPumpOut{
  return {
    id: base.id,
    name: base.name,
    coolingSeasonCoefficientA: convertUiInputDoubleToDto(base.coolingSeasonCoefficientA),

  coolingSeasonCoefficientB: convertUiInputDoubleToDto(base.coolingSeasonCoefficientB),

  coolingSeasonCoefficientC: convertUiInputDoubleToDto(base.coolingSeasonCoefficientC),

  heatingSeasonCoefficientA: convertUiInputDoubleToDto(base.heatingSeasonCoefficientA),

  heatingSeasonCoefficientB: convertUiInputDoubleToDto(base.heatingSeasonCoefficientB),

  heatingSeasonCoefficientC: convertUiInputDoubleToDto(base.heatingSeasonCoefficientC),
  };
}