import { getPercentage, trimDecimals } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IHeatPumpCapacityResults } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import ReportTemplate, {
  getConvertedText,
  getPileInletFlowRate,
  H1,
  H4,
} from "../Template";
import { convertFlowRateToLPM, convertToImperial } from "lib/units";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";
import { HorizFlex, VertFlex } from "components/MainMenu";
import TripleLineChart, {
  TripleLineChartProps,
} from "components/ResultsViews/Reusables/TripleLineChart";
import { GlobalContext } from "components/Contexts/Global";
import {
  getOfficialVariableFromAbrv,
  OfficialVariables,
  readableVariable,
} from "lib/types";

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: var(--ss-3);
  padding: var(--ss-3);
`;

export const TD = styled.th`
  font-weight: 400;
`;
export const TDG = styled.th`
  font-weight: 400;
  color: var(--c-green);
  width: var(--ss-8);
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* word-wrap: break-word; */
`;
export const TDGreen: React.FunctionComponent<{ children: any }> = ({
  children,
}) => {
  const stringToPrint = (
    typeof children === "string" ? children : " "
  ).substring(0, 16);
  return <TDG id="test TGD">{stringToPrint}...</TDG>;
};

export const THGreen = styled.th`
  color: var(--c-green);
  width: var(--ss-8);
  overflow: hidden;
`;
const TH = styled.th``;
const TR = styled.tr``;
const SummaryLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 180%;
`;
const SummaryRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 100%;
`;
export const DisclaimerText = styled.div`
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.12in;
`;
export const InfoText = styled.div`
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.12in;
`;
const BaseTable = css`
  width: 100%;
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.1in;
`;
const ComparisonTable = styled.table`
  ${BaseTable}
  border: solid var(--c-light-gray);
`;
const SiteAndStudyTable = styled.table`
  ${BaseTable}
  background: var(--c-light-gray);
`;
const ComponentTable = styled.table`
  ${BaseTable}
  background: var(--c-light-gray);
`;
const SummaryHighlight = styled.div`
  background: var(--c-black);
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 100%;
`;
const H2 = styled.h2`
  color: var(--c-white);
  font-size: 0.35in;
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-family: "Ubuntu";
  font-weight: 400;
  position: relative;
`;
const SummaryBigLabel = styled.div`
  color: var(--c-white);
  font-size: 0.2in;
  margin: var(--ss-3);
  padding: var(--ss-3);
`;
const SummaryDivider = styled.div`
  width: 100%;
  height: 0.01in;
  margin: 0;
  padding: 0;
  background: var(--c-green-very-light);
`;
const LeftContentSection = styled.div`
  width: 66%;
  height: 100%;
`;
const RightContentSection = styled.div`
  width: 33%;
  height: 100%;
`;
const ContentSectionTitle = styled.h3`
  font-size: var(--ss-5);
`;
const VariableAndLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const VariableAndLabelLeft = styled.div`
  width: 100%;
`;
const VariableAndLabelRight = styled.div`
  color: var(--c-green);
  font-weight: 700;
  width: 50%;
  text-align: right;
`;
const BoldText = styled.div`
  font-weight: 700;
`;
const HorizDivider = styled.div`
  min-width: 100%;
  height: -1cm;
  border-radius: 0;
  border-bottom-style: solid;
  border-bottom-width: var(--ss-2);
  border-bottom-color: var(--c-green);
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: var(--ss-4);
  width: 100%;
  height: 100%;
`;
const LightBorderBox = css`
  background: #f4f4f4;
  border: 5px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 10px;
  padding: var(--ss-4);
`;
const SmallLightBorderBox = css`
  ${LightBorderBox}
  padding: var(--ss-2);
  border: 2px solid #e3e3e3;
  border-radius: 8px;
  width: 100%;
  margin: var(--ss-2);
`;
const VertFlexLBB = styled.div`
  ${LightBorderBox};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  height: 88%;
`;
const VertFlexSLBB = styled.div`
  ${SmallLightBorderBox}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const HorizFlexLBB = styled.div`
  ${LightBorderBox};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  height: 88%;
`;
const HorizFlexSLBB = styled.div`
  ${SmallLightBorderBox};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HorizFlex100 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const SummarySectionAdditionalInfo = styled.div`
  position: absolute;
  top: 0;
  right: -0.05in;
  color: var(--c-light-green);
  z-index: 1;
  margin: 0;
  padding: 0;
  font-size: 0.15in;
  max-width: 0.4in;
`;
const SummarySectionText = styled.span`
  font-size: 0.1in;
`;

export interface SessionData {
  name: string;
  heatingOrCooling: "Heating" | "Cooling";
  wholeArrayHeatPumpCapacity: number;
  averageGeoPileThermalCapacity: number;
  heatPumpCoefficientOfPerformance: number;
  targetWholeArrayHeatPumpCapacity: number;
  numberOfActivePilesInTheArray: number;
  steelPileLength: number;
  steelPileNominalSize: string;
  plasticPipeNominalSize: string;
  pileInletFlowRate: number;
  heatPumpName: string;
  groundThermalConductivityMean: number;
  groundTemperature10mBelowTopOfPile: number;
  ambientAirTemperature: number;
}

interface BulkHeatPumpCapacityReportProps {
  id: any;
  sessions: BulkHeatPumpCapacityResult;
  imperial: boolean;
  TESTING?: boolean;
}
const BulkHeatPumpCapacityReport: React.FunctionComponent<BulkHeatPumpCapacityReportProps> =
  ({ id, sessions, imperial, TESTING }) => {
    const gc = useContext(GlobalContext);

    function targetVariableProperName() {
      return getOfficialVariableFromAbrv(sessions.targetVariableName);
    }
    const targetProperName = targetVariableProperName();
    const targetReadableName = readableVariable[targetProperName];
    const tReadableNameNoT = readableVariable[resultsVariableNameFromTarget()];
    function resultsVariableNameFromTarget(): OfficialVariables {
      switch (sessions.targetVariableName) {
        case "WhpT":
          return "wholeArrayHeatPumpCapacity";
        case "WgheT":
          return "averageGeoPileThermalCapacity";
        case "AWgheT":
          return "wholeArrayGeoPileCapacity";
      }
    }

    function getFirstGraphValues(): TripleLineChartProps {
      return {
        xAxisLabels: sessions.perRowResults.map((r) =>
          new Date(r.dateTime).toISOString().slice(0, 10)
        ),
        lines: [
          {
            data: sessions.perRowResults.map((r) => r.targetValueInput || 0),
            label: "Target",
            color: "black",
          },
          {
            data: sessions.perRowResults.map(
              (r) => r.targetValuePredicted || 0
            ),
            label: "Predicted",
            color: "#5AA144",
            barOverride: true,
          },
          {
            data: sessions.perRowResults.map(
              (r) => r.targetValuePredictedMinusInput || 0
            ),
            label: "Difference",
            color: "red",
          },
        ],
        title: "",
        forSmallDisplay: true,
      };
    }
    function getSecondGraphValues(): TripleLineChartProps {
      return {
        xAxisLabels: sessions.perRowResults.map((r) =>
          new Date(r.dateTime).toISOString().slice(0, 10)
        ),
        lines: [
          {
            data: sessions.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileInletFluidTemperature
                : r.withoutHeatPumpResults.pileInletFluidTemperature || 0
            ),
            label: "Inlet Temperature",
            color: "blue",
          },
          {
            data: sessions.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileOutletFluidTemperature
                : r.withoutHeatPumpResults.pileOutletFluidTemperature || 0
            ),
            label: "Outlet Temperature",
            color: "#5AA144",
          },
          {
            data: sessions.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileInletFluidTemperature -
                  r.withHeatPumpResults.pileOutletFluidTemperature
                : r.withoutHeatPumpResults.pileInletFluidTemperature -
                    r.withoutHeatPumpResults.pileOutletFluidTemperature || 0
            ),
            label: "Difference",
            color: "red",
          },
        ],
        title: "",
        forSmallDisplay: true,
      };
    }
  

    return (
      <ReportTemplate id={id} xPosition={9999} yPosition={9999}>
        {/* <ReportTemplate id={id} xPosition={0} yPosition={0}> */}
        <Summary>
          <SummaryLeft>
            <H1>Bulk Capacity Prediction</H1>
            <DisclaimerText>
              Thermal performance of the system is predicted to reach the values
              presented in the following graphs. These are defined as the Peak
              Thermal Capacity (heating or cooling), the supplied and target
              Thermal Capacity Across Time, and the Total Thermal Capacity. The
              Heat Pump Coefficient of Performance (COP) is also shown and
              reflects the ratio of thermal energy supplied to energy used by
              the heat pump. Finally, the Change in Pile Fluid Temperature
              Across Time is presented.
            </DisclaimerText>
            <H4>Explanation of Results</H4>
            <InfoText>
              Thermal performance of the system is predicted to reach the values
              presented in the following table. These are defined by the type of
              capacity supplied (as either heating or cooling), the total
              capacity to the building, and the thermal capacity per pile in the
              array. The Coefficient of Performance (COP) is also shown and
              reflects the efficiency of the heat pump based on eith heating or
              cooling.
            </InfoText>
          </SummaryLeft>
          <SummaryRight>
            <H4>Peak Performance</H4>
            <SummaryHighlight>
              <H2>
                {getConvertedText(
                  imperial,
                  sessions.dataSummaries.minTargetValueActual.value || 0,
                  "W"
                )}
                <SummarySectionAdditionalInfo>
                  {getPercentage(
                    sessions.dataSummaries.minTargetValueActual.value || 0,
                    sessions.dataSummaries.minTargetValueInput.value || 0
                  ).toFixed(0)}%
                  <SummarySectionText>
                    {' '}Of Target
                  </SummarySectionText>
                </SummarySectionAdditionalInfo>
              </H2>
              <SummaryBigLabel>Peak Heating Capacity</SummaryBigLabel>
              {/* TODO add circle chartes here */}
              <SummaryDivider></SummaryDivider>
              <H2>
                {getConvertedText(
                  imperial,
                  sessions.dataSummaries.maxTargetValueActual.value || 0,
                  "W"
                )}
                <SummarySectionAdditionalInfo>
                  {getPercentage(
                    sessions.dataSummaries.maxTargetValueActual.value || 0,
                    sessions.dataSummaries.maxTargetValueInput.value || 0
                  ).toFixed(0)}%
                  <SummarySectionText>
                    {' '}Of Target
                  </SummarySectionText>
                </SummarySectionAdditionalInfo>
              </H2>
              <SummaryBigLabel>Peak Cooling Capacity</SummaryBigLabel>
            </SummaryHighlight>
          </SummaryRight>
        </Summary>
        <ContentSection>
          <LeftContentSection>
            <ContentSectionTitle>
              {tReadableNameNoT + " [W]"}
            </ContentSectionTitle>
            <VertFlexLBB>
              <TripleLineChart {...getFirstGraphValues()} />
            </VertFlexLBB>
          </LeftContentSection>
          <RightContentSection>
            {sessions.withHeatPump ? (
              <>
                <ContentSectionTitle>System Performance</ContentSectionTitle>
                <VertFlexLBB>
                  <BoldText>Heat Pump Coefficient of Performance</BoldText>
                  <VariableAndLabel>
                    <VariableAndLabelLeft>
                      Average Across Total Time-Period
                    </VariableAndLabelLeft>
                    <VariableAndLabelRight>
                      {sessions.dataSummaries.averageCOP?.toFixed(1)}
                    </VariableAndLabelRight>
                  </VariableAndLabel>
                  <HorizFlex100>
                    <VertFlexSLBB>
                      <BoldText>HEATING COP</BoldText>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>Average</VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.averageCOPHeatingPeriods?.toFixed(
                            1
                          )}
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>At Peak</VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.copAtPeakHeating?.value?.toFixed(
                            1
                          )}
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                    </VertFlexSLBB>
                    <VertFlexSLBB>
                      <BoldText>COOLING COP</BoldText>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>Average</VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.averageCOPCoolingPeriods?.toFixed(
                            1
                          )}
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>At Peak</VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.copAtPeakCooling?.value?.toFixed(
                            1
                          )}
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                    </VertFlexSLBB>
                  </HorizFlex100>
                  <HorizDivider></HorizDivider>
                  <BoldText>Total System Energy Balance</BoldText>
                  <VariableAndLabel>
                    <VariableAndLabelLeft>
                      Produced (Absolute)
                    </VariableAndLabelLeft>
                    <VariableAndLabelRight>
                      {sessions.dataSummaries.totalEnergyProducedByHeatPumpkWh?.toFixed(
                        0
                      )}{" "}
                      kWh
                    </VariableAndLabelRight>
                  </VariableAndLabel>
                  <VariableAndLabel>
                    <VariableAndLabelLeft>
                      Consumed (Absolute)
                    </VariableAndLabelLeft>
                    <VariableAndLabelRight>
                      {sessions.dataSummaries.totalEnergyConsumedByHeatPumpkWh?.toFixed(
                        0
                      )}{" "}
                      kWh
                    </VariableAndLabelRight>
                  </VariableAndLabel>
                  <HorizFlex>
                    <VertFlexSLBB>
                      <BoldText>HEATING ENERGY</BoldText>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>
                          Produced, Total
                        </VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.totalEnergyProducedByHeatPumpHeatingkWh?.toFixed(
                            0
                          )}{" "}
                          kWh
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>
                          Consumed, Total
                        </VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.totalEnergyConsumedByHeatPumpHeatingkWh?.toFixed(
                            0
                          )}{" "}
                          kWh
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                    </VertFlexSLBB>
                    <VertFlexSLBB>
                      <BoldText>COOLING ENERGY</BoldText>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>
                          Produced, Total
                        </VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.totalEnergyProducedByHeatPumpCoolingkWh?.toFixed(
                            0
                          )}{" "}
                          kWh
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                      <VariableAndLabel>
                        <VariableAndLabelLeft>
                          Consumed, Total
                        </VariableAndLabelLeft>
                        <VariableAndLabelRight>
                          {sessions.dataSummaries.totalEnergyConsumedByHeatPumpCoolingkWh?.toFixed(
                            0
                          )}{" "}
                          kWh
                        </VariableAndLabelRight>
                      </VariableAndLabel>
                    </VertFlexSLBB>
                  </HorizFlex>
                </VertFlexLBB>
              </>
            ) : (
              <VertFlex>
                <img src="logo.svg" />
              </VertFlex>
            )}
          </RightContentSection>
        </ContentSection>
        <ContentSection>
          <LeftContentSection>
            <ContentSectionTitle>
              Fluid Temperature At Pile [°C]
            </ContentSectionTitle>
            <VertFlexLBB>
              <TripleLineChart {...getSecondGraphValues()} />
            </VertFlexLBB>
          </LeftContentSection>
          <RightContentSection>
            <ContentSectionTitle>System Description</ContentSectionTitle>
            <VertFlexLBB>
              <VertFlexSLBB>
                <BoldText>Study Name:</BoldText>
                {/* TODO make sure name works */}
                <div>{sessions.perRowResults[0].calculator.name}</div>
                {sessions.withHeatPump ? (
                  <>
                    <BoldText>Heat Pump Name:</BoldText>
                    <div>
                      {sessions.perRowResults[0].calculator.heatPump?.name}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </VertFlexSLBB>
              <VariableAndLabel>
                <VariableAndLabelLeft>
                  Average Flow Rate Across Time
                </VariableAndLabelLeft>
                <VariableAndLabelRight>
                  {getConvertedText(
                    imperial,
                    sessions.dataSummaries.averageArrayPumpFlowRate || 0,
                    "m3/s"
                  )}
                </VariableAndLabelRight>
              </VariableAndLabel>
              <VariableAndLabel>
                <VariableAndLabelLeft>
                  {readableVariable["numberOfActivePilesInTheArray"]}
                </VariableAndLabelLeft>
                <VariableAndLabelRight>
                  {
                    sessions.perRowResults[0].calculator.pileArray
                      ?.numberOfActivePilesInTheArray?.inputValue
                  }
                </VariableAndLabelRight>
              </VariableAndLabel>
              <VariableAndLabel>
                <VariableAndLabelLeft>
                  {readableVariable["steelPileNominalSize"]}
                </VariableAndLabelLeft>
                <VariableAndLabelRight>
                  {
                    sessions.perRowResults[0].calculator.steelPile
                      ?.steelPileNominalSizeForClient?.inputValue
                  }
                </VariableAndLabelRight>
              </VariableAndLabel>
              <VariableAndLabel>
                <VariableAndLabelLeft>
                  {readableVariable["steelPileLength"]}
                </VariableAndLabelLeft>
                <VariableAndLabelRight>
                  {getConvertedText(
                    imperial,
                    sessions.perRowResults[0].calculator.steelPile?.length
                      ?.inputValue,
                    "m"
                  )}
                </VariableAndLabelRight>
              </VariableAndLabel>
              <VariableAndLabel>
                <VariableAndLabelLeft>
                  {readableVariable["plasticPipeNominalSize"]}
                </VariableAndLabelLeft>
                <VariableAndLabelRight>
                  {
                    sessions.perRowResults[0].calculator.plasticPipe
                      ?.plasticPipeNominalSizeForClient?.inputValue
                  }
                </VariableAndLabelRight>
              </VariableAndLabel>
            </VertFlexLBB>
          </RightContentSection>
        </ContentSection>
      </ReportTemplate>
    );
  };
export default BulkHeatPumpCapacityReport;
