import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import {Select as OutSelect}from 'sheldons-components';
import { Option, StyledSelectProps } from 'sheldons-components/dist/components/inputs/Select';
import { css, FlattenSimpleInterpolation } from 'styled-components';


interface SelectProps extends StyledSelectProps{
    custCssMainContainer?: string |FlattenSimpleInterpolation
    custCssSelectedOptionDisplayMain?: string | FlattenSimpleInterpolation
}

const Select: React.FunctionComponent<SelectProps> =(props)=> {

    function getOptions(){
        //filter out options with blank values/content
        let result: Option[]|undefined = [{value: 'NothingSaved', content: 'Nothing Saved', id:0}];
        if (props.options) result = props.options;
        if (!result) return result;
        return result.filter(option => option.content);
    }


return (
<OutSelect 
styles={{
    Container: {
        main: css`
        position:relative;  
        font-size: inherit; 
        padding: 0;
        height: 2em;
        margin: 0;
        display: flex;
        border-radius: var(--light-rounding);
        flex-direction: row;
        align-items: center;
        justify-content:row;
        background: var(--c-gray);
        width: 14em;
        ${props.custCssMainContainer};
        `
    },
    SelectedOptionDisplay: {
        main: css`
        box-shadow: inherit;
        /* height: var(--s-8); */
        
        margin: 0;
        background: var(--c-gray);
        color: var(--c-white);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: var(--light-rounding);
        justify-content: space-between;
        flex-wrap: none;
        cursor: pointer;
        /* min-width: var(--s-18); */
        width: 100%;
        ${props.custCssSelectedOptionDisplayMain}
        height: 100%;
        `,
        downIcon: css`
        padding: 0;
        margin: 0;
        /* height: 2em; */
        `,
        content: css`
        padding: var(--s-2);
        height: var(--s-8);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: none;
        `,
    },
    DropdownContainer: {
        main: css`
        position: absolute;
        border-radius: var(--light-rounding);
        top: 80%;
        right: 0;
        left: 0;
        z-index: 200;
        background: var(--c-gray-dark);
        color: var(--c-white);
        box-shadow: var(--shadow1);
        padding-inline: var(--s-3);
        padding-block: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        max-height: var(--s-16);
        overflow: auto;
        `
    },
    DropdownItem: {
        main: css`
        cursor: pointer;
        border-radius: var(--light-rounding);
        :hover{
            background: var(--c-green);
            color: var(--c-white);
        }
        `
    },
}}
{...props}
options={getOptions()}
/>
);
}
export default Select;
