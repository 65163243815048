import { HorizFlex } from "components/MainMenu";
import Button from "components/Reusables/Button";
import Select from "components/Reusables/Select";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Option } from "sheldons-components";
import CheckBox from "components/Reusables/CheckBox";
import styled from "styled-components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HeatPumpCapacityReport from "components/PrintableReports/SummaryPage/HeatPumpCapacity";
import { OutputVariables } from "lib/models";
import DiagramsReport from "components/PrintableReports/DiagramsReport";
import AssumptionsReport from "components/PrintableReports/AssumptionsReport";
import ErrorSourcesReport from "components/PrintableReports/ErrorSourcesReport";
import PileLengthReport from "components/PrintableReports/SummaryPage/PileLengthWHP";
import GroundTemperatureReport from "components/PrintableReports/SummaryPage/GroundTemperature";
import { ApiEndpoints, ICalculators } from "lib/types";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { gcd } from "mathjs";
import { GlobalContext } from "components/Contexts/Global";
import NumberOfPilesReport from "components/PrintableReports/SummaryPage/NumberOfPiles";
import DisclaimerReport from "components/PrintableReports/DisclaimerPage";
import HeatPumpCapacityDetails from "components/PrintableReports/DetailsPage/HeatPumpCapacity";
import NumberOfPilesDetails from "components/PrintableReports/DetailsPage/NumberOfPiles";
import PileLengthWHPDetails from "components/PrintableReports/DetailsPage/PileLengthWHP";
import PileLengthNHPDetails from "components/PrintableReports/DetailsPage/PileLengthNHP";
import GroundTemperatureDetails from "components/PrintableReports/DetailsPage/GroundTemperature";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import { createBlockingLoadingMessage } from "lib/ErrorAndMessageHandling/MessageHandler";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";
import BulkHeatPumpCapacityReport from "components/PrintableReports/SummaryPage/BulkHeatPumpCapacity";

const Container = styled.div`
  color: var(--c-white);
  display: flex;
  background: var(--c-black);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid var(--c-gray-light);
  border-radius: var(--s-3);
`;
const JustForColor = styled.div`
  margin: 0;
  padding: 0;
  color: var(--c-green);
`;
const ExportTitle = styled.h4`
  display: flex;
  color: var(--c-white);
  font-size: var(--s-9);
  padding: var(--s-2);
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--c-gray-light);
  border-radius: var(--s-3);
`;
const IncludeQuestion = styled.h4`
  display: flex;
  color: var(--c-white);
  font-size: var(--s-9);
  text-decoration: underline;
  padding: 0;
  align-items: center;
  box-sizing: border-box;
`;
type DynamicPageNames = "summary" ;
type StaticPageNames =
  | "diagrams"
  | "assumptions"
  | "errorSources"
  | "disclaimer";
type PageNames = DynamicPageNames | StaticPageNames;
const pageNameToId: {[name in PageNames]: string} = {
    summary: 'mainReportPage',
    diagrams: 'diagramsPage',
    assumptions: 'assumptionsPage',
    errorSources: 'errorSourcesPage',
    disclaimer: 'disclaimerPage'
}


type PageComponentMap = Record<PageNames, JSX.Element>;
type StaticPageComponentMap = Record<StaticPageNames, JSX.Element>;
type DynamicPageComponentMap = Record<DynamicPageNames, JSX.Element>;

interface GenerateReportFormBulkProps {
  withHeatPump?: boolean;
  session: BulkHeatPumpCapacityResult;
}
const GenerateReportFormBulk: React.FunctionComponent<GenerateReportFormBulkProps> = ({
  withHeatPump,
  session,
}) => {
 
  const [imperialUnits, setImperialUnits] = useState<boolean>(false);
  const [includeDiagrams, setIncludeDiagrams] = useState<boolean>(false);
  const [includeAssumptions, setIncludeAssumptions] = useState<boolean>(false);
  const [includeErrorSources, setIncludeErrorSources] = useState<boolean>(
    false
  );
  const [showPrintReport, setShowPrintReport] = useState<boolean>(false);
  const [activePageNames, setActivePageNames] = useState<PageNames[]>([
    "summary",
    "disclaimer",
  ]);
  
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);
  // //console.log('rendering GenerateReportFormBulk');
  //change active pages whenever a checkbox changes
  useEffect(() => {
    const newPagesNames: PageNames[] = ["summary"];
    if (includeDiagrams) newPagesNames.push("diagrams");
    if (includeAssumptions) newPagesNames.push("assumptions");
    if (includeErrorSources) newPagesNames.push("errorSources");
    newPagesNames.push('disclaimer');
    setActivePageNames(newPagesNames);
  }, [includeDiagrams, includeAssumptions, includeErrorSources]);

  const UnitOptions = [
    { id: 0, content: "Metric", value: "Metric" },
    { id: 1, content: "Imperial", value: "Imperial" },
  ];


  async function printDocument() {
    const messageId = messageHandler.addMessage!(createBlockingLoadingMessage(
        'Generating Printable Reports','',''
      ));
      // gc?.addWaitingAction();
      printStep2().then(() => {
        setTimeout(async () => {
          await printStep3();
          if (gc?.removeWaitingActionAndReturnAllActionsCompleted()){
            messageHandler.resolveMessage!(messageId);
            // gc?.setLoadingState(false);
            // gc.setSuccessState(true)
          }
        }, 500); //waiting for state changes from step2 to complete
      });
  }

  async function printStep2() {
    setShowPrintReport(true);
  }
  
  async function printStep3() {
    let pdf = new jsPDF();
    let pageNumber = 1;
    for (let i = 0; i < activePageNames.length; i++){
        const pageName = activePageNames[i];
        //console.log('print step 3, pageNum',pageNumber);
        //console.log(pageName);
        const element = document.getElementById(pageNameToId[pageName]);
        //console.log(element);
        if (!element) return;
        const pdf1 = await addPageToPdf(
            pdf,
            element,
            pageNumber
        );
        pageNumber ++;
        pdf = pdf1;
        }
    await pdf.save("GPPReport.pdf");
  }

  async function addPageToPdf(
    pdf: jsPDF,
    element: HTMLElement | null,
    pageNumber: number
  ) {
    if (!element) return pdf;
    const canvas = await html2canvas(element,{scale: 4});
    const img = canvas.toDataURL("image/png",1);
    ////console.log('image of page '+pageNumber, img);
    if (pageNumber !== 1) pdf.addPage();
    pdf.setPage(pageNumber);
    // pdf.addImage(img, "PNG", 0, 0, 210, 300, "NONE"+pageNumber);
    pdf.addImage(img, "PNG", 0, 0, 210, 298, "NONE"+pageNumber);

    return pdf;
  }

  const getStaticPages = useCallback(
    (): StaticPageComponentMap => ({
      diagrams: <DiagramsReport key="diagramsPage" id="diagramsPage" />,
      assumptions: (
        <AssumptionsReport key="assumptionsPage" id="assumptionsPage" />
      ),
      errorSources: (
        <ErrorSourcesReport key="errorSourcesPage" id="errorSourcesPage" />
      ),
      disclaimer: <DisclaimerReport key="disclaimerPage" id="disclaimerPage" />,
    }),
    []
  );

  const BulkHeatPumpCapacityPages = useCallback(
    (): PageComponentMap => ({
      summary: (
        <BulkHeatPumpCapacityReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={session}
        />
      ),
      ...getStaticPages(),
    }),
    [session, imperialUnits, getStaticPages]
  );
 

  const getActualReportComponent = useCallback(() => {
    let pageComponents = BulkHeatPumpCapacityPages();
    return <>{activePageNames.map((page) => pageComponents[page])}</>;
  }, [
    activePageNames,
    withHeatPump,
    BulkHeatPumpCapacityPages
  ]);

  return (
    <>
      {showPrintReport ? <>{getActualReportComponent()}</> : <></>}
      <Container>
        <ExportTitle> Export These Results </ExportTitle>
        <HorizFlex>
          Units: 
          <JustForColor>
            <Select
              type="styled"
              options={UnitOptions}
              selected={imperialUnits ? UnitOptions[1] : UnitOptions[0]}
              onChange={(opt?: Option) =>
                opt?.id !== 0 ? setImperialUnits(true) : setImperialUnits(false)
              }
            />
          </JustForColor>
        </HorizFlex>
        <IncludeQuestion> Include in report: </IncludeQuestion>
        <HorizFlex>
          <CheckBox
            checked={includeDiagrams}
            onClick={() => setIncludeDiagrams((prev) => !prev)}
          />
          Diagrams and Schematics
        </HorizFlex>
        <HorizFlex>
          <CheckBox
            checked={includeAssumptions}
            onClick={() => setIncludeAssumptions((prev) => !prev)}
          />
          List of Assumptions?
        </HorizFlex>
        <HorizFlex>
          <CheckBox
            checked={includeErrorSources}
            onClick={() => setIncludeErrorSources((prev) => !prev)}
          />
          Sources of Error
        </HorizFlex>
        <Button onClick={() => printDocument()}>Generate Report</Button>
      </Container>
    </>
  );
};
export default GenerateReportFormBulk;
