import { HorizFlex, VertFlex } from "components/MainMenu";
import { trimDecimalPlaces } from "lib/helper-functions";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import { TextLabel, Unit } from "./Template";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const StaticResult = styled.div`
  background: var(--c-black);
  color: var(--c-green-bright);
  /* font-size: var(--fs-20); */
  /* font-size: var(--fs-6); */
  width: var(--s-17);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* padding: 0; */
`;
const GreenUnit = styled.div`
  color: var(--c-green-bright);
  font-size: var(--fs-10);
  padding: 0;
  margin-block: 0;
`;
const VariableContainer = styled.div`
  background: var(--c-black);
  box-sizing: border-box;
  border: 1px solid var(--c-gray-light);
  border-radius: var(--s-3);
  margin-right: 0;
`;

interface CircleChartProps {
  value: number;
  label: string;
  unit?: string;
  decimalPlaces?: number;
}
const CircleChart: React.FunctionComponent<CircleChartProps> = ({
  value,
  label,
}) => {
  return (
    <VariableContainer>
      <VertFlex>
        <StaticResult>
          <CircularProgressbar
            value={value}
            text={`${value}%`}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              // rotation: 0.25,

              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: "butt",

              // Text size
              textSize: "15px",

              // How long animation takes to go from one percentage to another, in seconds
              // pathTransitionDuration: 0.5,

              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',
              // Colors
              pathColor: `var(--c-green)`,
              textColor: "var(--c-green)",
              trailColor: "var(--c-white)",
              backgroundColor: "var(--c-black)",
            })}
          />
        </StaticResult>
        <TextLabel>{label}</TextLabel>
      </VertFlex>
    </VariableContainer>
  );
};
export default CircleChart;
