import React, {
  ReactNode,
  useEffect,
  useContext,
  useState,
  SetStateAction,
} from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import Button from "../../Reusables/Button";
import { Card, Option, ReusableComponentBase } from "sheldons-components";
import Select from "../../Reusables/Select";
import GeneralEditForm from "components/ModelEditForms/GeneralEditForm";
import GeneralInput from "../../Reusables/GeneralInput";
import { createEmpty } from "lib/helper-functions";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import { ApiEndpoints, OfficialVariables } from "lib/types";
import { ModelNameTypes } from "lib/objectCrudPropsHelpers";
import HelpIcon from "@material-ui/icons/Help";

const Container = styled.div`
  background: var(--c-gray);
  position: relative;
  padding: var(--s-7);
  margin-bottom: var(--s-15);
  color: var(--c-white);
`;
const TitleContainer = styled.div`
  background: var(--c-black);
  width: 99%;
  position: absolute;
  top: -4vw;
  left: 0;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
  box-shadow: inset 0px 4px 8px rgba(255, 255, 255, 0.5);
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;

const ACRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: var(--c-gray);
  margin: 0;
  padding: var(--s-2);
  width: 100%;
`;


const InputInstr = styled.h4`
  margin-left: var(--s-5);
  color: var(--c-background-white);
  margin-right: var(--s-5);
`;
const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  /* margin-inline: var(--s-10); */
  padding: var(--s-8);
  margin: 0;
  margin-left: var(--s-5);
  background: var(--c-gray-dark);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Instructions = styled.div`
  font-style: italic;
  font-weight: 600;
  color: var(--c-green);
  text-align: left;
  align-self: flex-start;
`;


export interface StudyNameInputProps extends ReusableComponentBase {
  textValue: string | undefined;
  setTextValue: (newText: string) => void;
  title: string;
  instructions: string;
}

const StudyNameInput: React.FunctionComponent<StudyNameInputProps> = ({
  textValue,
  setTextValue,
  title,
  instructions,
}) => {


  return (
    <Container>
      <TitleContainer>
        <TitleText>{title}</TitleText>
      </TitleContainer>
      <InputRow>
        <InputInstr>{instructions}</InputInstr>
          <GeneralInput
            darkBackground
            label="Name"
            autoComplete="none"
            value={textValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>)=>{
              const newTextValue = event.target.value;
              setTextValue(newTextValue)
            }}
          />
      </InputRow>

    </Container>
  );
};
export default StudyNameInput;
