import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';



const Container = styled.div`
    height: 5%;
      width: 100%;
      background-color: #e0e0de;
      border-radius: var(--def-rounding);
      margin: 0;
      position: relative;
`;
const Filler = styled.div<{progress:number}>`
     height: 90%;
     margin: 0;
      width: ${(props)=>props.progress}%;
      background-color: var(--c-green);
      transition: width 1s ease-in-out;
      border-radius: inherit;
      text-align: right;
`;
const Label = styled.span`
     padding: 0;
      color: black;
      font-weight: bold;
      position: absolute;
      left: 47%;
      top: 28%;

`;

interface ProgressDisplayerProps{
    progress: number
}
const ProgressDisplayer: React.FunctionComponent<ProgressDisplayerProps> =({
    progress
})=> {
  // progress = 80;
  ////console.log('progress in progress displayer props', progress);
    return (
        <Container>
            <Label>{`${progress.toFixed(1)}%`}</Label>
          <Filler progress={progress}>
          </Filler>
        </Container>
      );
    }
export default ProgressDisplayer;
