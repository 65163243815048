import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Option } from "sheldons-components";
import ObjectCrud from "../Reusables/ObjectCrud";
import { deleteFromApi, saveToApi, getFromApi } from "../../lib/api";
import {
  ICalculator,
  IClimate,
  IHeatPump,
  IPileArray,
  IPileInFluid,
  IPileOutFluid,
  IPlasticPipe,
  PossibleCategoryNames as Cat,
  IGround,
  ISteelPile,
  IUiInputVariableDouble,
  IUiInputVariableInt,
  IUiInputVariableString,
  OutputVariables,
  pileArrayToDto,
  heatPumpToDto,
  plasticPipeToDto,
  steelPileToDto,
  groundToDto,
  climateToDto,
  pileOutFluidToDto,
  pileInFluidToDto,
  PileInFluid,
  Calculator,
  SteelPile,
  PlasticPipe,
  PileArray,
  HeatPump,
  Ground,
  Climate,
  PileOutFluid,
} from "lib/models";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import Button from "components/Reusables/Button";
import styled from "styled-components";
import { FormNavigationItem } from "./FormNavigation";
import { FormContentProps } from "./Template";
import { createEmpty, vw } from "lib/helper-functions";
import { GlobalContext } from "components/Contexts/Global";
import PlasticPipeNominalSizeCrud from "components/Popups/PlasticPipeNominalSizeCrud";
import SteelPileNominalSizeCrud from "components/Popups/SteelPileNominalSizeCrud";
import { generateObjCrudProps } from "lib/objectCrudPropsHelpers";

const NavLink = styled.div`
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
`;

const InbetweenButton = styled(Button)`
  /* margin-bottom: var(--s-13); */
`;

const GroundTemperatureFlow: React.FunctionComponent<FormContentProps> = ({
  children,
  styles,
  setNavigationItems,
  selectedNavigationItem,
  setSelectedNavigationItem,
}) => {
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);
  const [navItems, setNavItems] = useState<FormNavigationItem[]>();

  const groundRef = useRef(null);
  const climateRef = useRef(null);
  const pileArrayRef = useRef(null);
  const plasticPipeRef = useRef(null);
  const steelPileRef = useRef(null);
  const pileInFluidRef = useRef(null);
  const pileOutFluidRef = useRef(null);
  const heatPumpRef = useRef(null);

  //whenever a ref changes, change the internal navigation items
  useEffect(() => {
    setNavItems(buildNavigationItems());
    function buildNavigationItems() {
      return [
        { text: "1. Plastic Pipe", ref: plasticPipeRef },
        { text: "2. Steel Pile", ref: steelPileRef },
        { text: "3. Ground", ref: groundRef },
        { text: "4. Climate", ref: climateRef },
        { text: "5. Outflow Fluid Properties", ref: pileOutFluidRef },
        { text: "6. Inflow Fluid Properties", ref: pileInFluidRef },
      ];
    }
  }, [
    groundRef,
    climateRef,
    pileArrayRef,
    plasticPipeRef,
    steelPileRef,
    pileInFluidRef,
    pileOutFluidRef,
    heatPumpRef,
  ]);

  //whenever internal nav items change change the outputted navigation items
  useEffect(() => {
    if (!navItems) return;
    setNavigationItems(navItems);
  }, [navItems, setNavigationItems]);

  const getUpperMostNavLink = useCallback(
    (yPosition: number) => {
      const magicNumber = vw(10);
      let result = undefined;
      // //console.log('nav items: ', navItems);
      navItems?.forEach((item: FormNavigationItem) => {
        if (!item.ref) return;
        const itemY = item.ref.current?.offsetTop;
        // //console.log('comparing these: ',item, itemY, yPosition);
        if (!itemY) return;
        if (itemY < yPosition + magicNumber) result = item;
        return;
      });
      return result;
    },
    [navItems]
  );

  const handleScroll = useCallback(() => {
    // //console.log('scroll detected');
    const currentY = window.scrollY;
    gc?.setFormScrollTop(currentY);

    const uppermostNavLink = getUpperMostNavLink(currentY);
    // //console.log('got this component: ',uppermostNavLink);
    setSelectedNavigationItem(uppermostNavLink);
  }, [getUpperMostNavLink, setSelectedNavigationItem]);

  //detect window scroll events
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  if (!api) return <div>...Loading...</div>;
  return (
    <>
      <NavLink ref={plasticPipeRef}></NavLink>
      <ObjectCrud
        {...generateObjCrudProps("PlasticPipes", api, {
          constructorParams: [api.getPlasticPipeNominalSizeNames()],
        })}
        appendNodes={
          <Button onClick={() => gc?.setPopup(<PlasticPipeNominalSizeCrud />)}>
            Add New Plastic Pipe Nominal Size
          </Button>
        }
      />
      <NavLink ref={steelPileRef}></NavLink>
      <ObjectCrud
        {...generateObjCrudProps("SteelPiles", api, {
          constructorParams: [api.getSteelPileNominalSizeNames()],
        })}
        appendNodes={
          <Button onClick={() => gc?.setPopup(<SteelPileNominalSizeCrud />)}>
            Add New Steel Pile Nominal Size
          </Button>
        }
      />
      <NavLink ref={groundRef}></NavLink>
      <ObjectCrud
        {...generateObjCrudProps("Grounds", api)}
        fieldsToExclude={[
          "groundTemperature10mBelowTopOfPile",
          "groundTemperature5mBelowTopOfPile",
          "groundTemperatureAtTopOfPile",
        ]}
      />
      <NavLink ref={climateRef}></NavLink>
      <ObjectCrud {...generateObjCrudProps("Climates", api)} />

      <NavLink ref={pileOutFluidRef}></NavLink>
      <ObjectCrud
        {...generateObjCrudProps("PileOutFluids", api, {
          customTitle: "Outflow Fluid Properties",
        })}
        fieldsToExclude={[
          "averageInterPileTemperatureChangeOutflow",
          "temperatureChangeAfterArray",
        ]}
      />
      <NavLink ref={pileInFluidRef}></NavLink>
      <ObjectCrud
        {...generateObjCrudProps("PileInFluids", api, {
          customTitle: "Inflow Fluid Properties",
        })}
        fieldsToExclude={[
          "averageInterPileTemperatureChangeInflow",
          "temperatureChangeBeforeArray",
          "heatPumpOutletFluidTemperature",
          'arrayPumpOutletFlowRate',
          'flowChangeBeforeArray',
          'averageInterPileFlowChangeInflow'
        ]}
      />
    </>
  );
};
export default GroundTemperatureFlow;
