import { BackendAPIContext } from 'components/Contexts/BackendAPI';
import { HorizFlex } from 'components/MainMenu';
import NumberInput from 'components/Reusables/NumberInput';
import { getPercentage, trimDecimalPlaces } from 'lib/helper-functions';
import { IHeatPumpCapacityResults } from 'lib/models/calculation-results';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';
import ResultsViewTemplate, { IResultsVariable } from './Reusables/Template';

function useTraceUpdate(props:any) {
    const prev = useRef(props);
    useEffect(() => {
      const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
        if (prev.current[k] !== v) {
            //@ts-ignore
          ps[k] = [prev.current[k], v];
        }
        return ps;
      }, {});
      if (Object.keys(changedProps).length > 0) {
        //console.log('Changed props:', changedProps);
      }
      prev.current = props;
    });
  }

interface HeatPumpCapacityResultsProps{
    data: IHeatPumpCapacityResults
}
const HeatPumpCapacityResults: React.FunctionComponent<HeatPumpCapacityResultsProps> =({
    data
})=> {
    const api = useContext(BackendAPIContext);
    const [wholeArrayHeatPumpCapacity, setWholeArrayHeatPumpCapacity]  = useState<number>(
        data.perPileHeatPumpCapacity *
             (data.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue || 0)
    );
    


    const resultsVariables = useCallback(():IResultsVariable[] => [
     
        {
            label: "Heat Pump Coefficient Of Performance",
           value: data.heatPumpCoefficientOfPerformance,
           type: 'static',
           decimalPlaces: 2,
           column: 'left',
           orderWithinColumn: 0
        },
        {
            label: "Whole-Array Heat Pump Capacity Supplied",
            value: wholeArrayHeatPumpCapacity ,
            type: 'static',
            unit: 'W',
            decimalPlaces: 2,
            column: 'left',
            orderWithinColumn: 1,
        },
        {
            label: "Target Whole-Array Heat Pump Capacity",
            value: data.calculator.pileArray?.targetWholeArrayHeatPumpCapacity?.inputValue||0,
            type: 'static',
            unit: 'W',
            decimalPlaces: 2,
            column: 'left',
            orderWithinColumn: 2,
        },
        {
            label: "Percentage of Target Supplied",
            value: getPercentage(
                wholeArrayHeatPumpCapacity || 0,
                 data.calculator.pileArray?.targetWholeArrayHeatPumpCapacity?.inputValue || 100),
            type: 'circle',
            unit: '%',
            decimalPlaces: 0,
            column: 'left',
            orderWithinColumn: 3
        },
        {
            label: "Average Geo-Pile Thermal Capacity",
            value: data.averageGeoPileThermalCapacity,
            type: 'static',
            unit: 'W',
            decimalPlaces: 2,
            column: 'right',
            orderWithinColumn: 0
        },
       {
            label: "Per-Pile Heat Pump Capacity Supplied",
            value: data.perPileHeatPumpCapacity,
            type: 'static',
            decimalPlaces: 2,
            unit: 'W',
            column: 'right',
            orderWithinColumn: 1,
        },
        {
            label: "Number of Active Piles",
            value: data.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue || 1,
            type: 'static',
            decimalPlaces: 0,
            column: 'right',
            orderWithinColumn: 2
        },
        
        {
            label: "Change in Temperature Across Average Pile (\u0394T)",
            value: data.changeInTemperatureAcrossPile ,
            type: 'static',
            decimalPlaces: 1,
            column: 'right',
            orderWithinColumn: 3
        }
    ],[data, wholeArrayHeatPumpCapacity]);

    //console.log('rendering heat pump output and number...');
return (
 <ResultsViewTemplate 
    resultsVariables={resultsVariables()}
    sessions={[data]}
/>
);
}
export default HeatPumpCapacityResults;
