import { Schema } from "ajv";
import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputStringToDto, IUiInputVariableString, IUiInputVariableStringOut, UiInputVariableString, UiInputVariableStringSchema } from "./ui-input-variable-string";

export interface IPlasticPipe extends IBaseModel {
  plasticPipeNominalSizeForClient?: IUiInputVariableString | null;
}
export interface IPlasticPipeOut extends IBaseModel {
  plasticPipeNominalSizeForClient?: IUiInputVariableStringOut | null;
}

export const PlasticPipeSchema: Schema = {
  type: 'object',
  properties: {
    plasticPipeNominalSizeForClient: UiInputVariableStringSchema(undefined)
  },
  required: ['plasticPipeNominalSizeForClient'],
  additionalProperties: true
}

export class PlasticPipe extends BaseModel implements IPlasticPipe{
  public constructor(possibleValues: string[]){
    super();
    this.plasticPipeNominalSizeForClient = new UiInputVariableString('plasticPipeNominalSize', possibleValues);
  }
  public plasticPipeNominalSizeForClient?: IUiInputVariableString = new UiInputVariableString('plasticPipeNominalSize',[
    'not loaded',
  ]);
}
export function plasticPipeToDto(base: IPlasticPipe): IPlasticPipeOut {
  return {
    id: base.id,
    name: base.name,
    plasticPipeNominalSizeForClient: convertUiInputStringToDto(base.plasticPipeNominalSizeForClient)
  }
}