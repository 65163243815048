import { GlobalContext } from 'components/Contexts/Global';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    background: var(--c-black);
    border-radius: 0;
    padding-left: var(--s-10);
    padding-right: var(--s-10);
`;

interface HeaderProps{

}
const Header: React.FunctionComponent<HeaderProps> =({children})=> {
return (
<Container >
    {children}
</Container>
);
}
export default Header;
