import { BackendAPIContextProps } from "components/Contexts/BackendAPI";
import { InstructionKeyMap } from "components/Reusables/ObjectCrud";
import { CrudableObject } from "sheldons-components/dist/components/controllers/ObjectCrud";
import { createEmpty } from "./helper-functions";
import { ApiEndpoints, OfficialVariables } from "./types";

export interface ModelNameTypes {
  lc: AllCrudableModelTypes;
  lcp: string;
  uc: string;
  ucp: ApiEndpoints;
  display: string;
}
export interface PartialObjectCrudProps {
  title: string;
  emptyObject: object;
  // savedObjects?: CrudableObject[];
  // setSavedObjects: React.Dispatch<React.SetStateAction<any>>;
  // editingObject?: CrudableObject;
  // setEditingObject: React.Dispatch<React.SetStateAction<any>>;
  instructionKeyMap: InstructionKeyMap;
  modelEndpoint: ApiEndpoints;
  imageLinkMap: { [fieldName: string]: string };
  names: ModelNameTypes;
  fieldsInOrder: OfficialVariables[]
}

interface GenerateObjectCrudPropsOptions {
  constructorParams?: any[];
  customTitle?: string;
}


export function generateObjCrudProps(
  titleCase: ApiEndpoints,
  api: BackendAPIContextProps,
  options?: GenerateObjectCrudPropsOptions
): PartialObjectCrudProps {
  const name = getModelNameTypes(titleCase);
  return {
    //   @ts-ignore
    emptyObject: createEmpty[name.lc](
      options?.constructorParams ? [...options?.constructorParams] : undefined
    ),
    title: options?.customTitle ? options?.customTitle : name.display,
    //@ts-ignore
    //   savedObjects: api[name.lcp],
    //@ts-ignore
    //   editingObject: api[name.lc],
    //@ts-ignore
    //   setEditingObject: api['set'+name.uc],
    //@ts-ignore
    //   setSavedObjects: api['set'+name.ucp],
    names: name,
    modelEndpoint: name.ucp,
    fieldsInOrder: standardVariableOrderLists[name.lc],
    instructionKeyMap: standardInstructionKeyMaps[name.lc],
    imageLinkMap: standardImageLinkMaps[name.lc],
  };
}

export type AllCrudableModelTypes =
  | "calculator"
  | "climate"
  | "pileOutletFluidTemperatureMLModel"
  | "heatPump"
  | "pileArray"
  | "pileInFluid"
  | "pileOutFluid"
  | "plasticPipeNominalSize"
  | "plasticPipe"
  | "groundTemperatureMLModel"
  | "ground"
  | "steelPileNominalSize"
  | "steelPile";

export function getModelNameTypes(
  upperCasePlural: ApiEndpoints
): ModelNameTypes {
  const upperCase = upperCasePlural.substr(0, upperCasePlural.length - 1);
  const camelCase = (upperCase.charAt(0).toLowerCase() +
    upperCase.slice(1)) as AllCrudableModelTypes;
  const displayCase = upperCase.replace(/([A-Z])/g, " $1").trim();
  return {
    lc: camelCase,
    lcp: camelCase + "s",
    uc: upperCase,
    ucp: upperCasePlural,
    display: displayCase,
  };
}

export const standardInstructionKeyMaps: {
  [model in AllCrudableModelTypes]: InstructionKeyMap;
} = {
  calculator: {},
  climate: {
    ambientAirTemperature: "Ambient air temperature on the day of your study.",
  },
  pileOutletFluidTemperatureMLModel: {},
  heatPump: {},
  pileArray: {
    targetWholeArrayHeatPumpCapacity:
      "If a target heat pump capacity is known, enter it below to calculate the percentage of the target that the specified system will meet.",
    numberOfActivePilesInTheArray:
      'Only count piles that will be "ON"/"Active" at the time of your study. NOTE: Whenever you change this value you may also need to change the flow rate value (see instructions above "Qoap" input).',
  },
  pileInFluid: {
    heatPumpOutletFluidTemperature:
      "This value is essential and must be calculated using your heat pump's specifications.",
    averageInterPileTemperatureChangeInflow:
      "Average temperature change occuring in the INFLOW pipes between each pile in the array. Positive (+) values represent temperature gain, negative (-) values represent temperature loss.",
    temperatureChangeBeforeArray:
      "Temperature change the fluid will experience flowing between the outlet of the Heat Pump and the beginning of the pile array. Positive (+) values represent temperature gain, negative (-) values represent temperature loss.",
    arrayPumpOutletFlowRate:
      "Enter the flow rate exiting the array pump (not the heat pump) here. IMPORTANT: This value will be divided by the number of piles in the array to get the flow rate into each pile (because we are predicting a parallel system), so if you know what flow rate you want per-pile, be sure to multiply it by the number of piles in your array before inputting it here.",
    flowChangeBeforeArray:
      "Total flow rate changes to the fluid between the outlet of the array pump and the beginning of the pile array. Positive (+) values represent flow gain, negative (-) values represent flow loss.",
    averageInterPileFlowChangeInflow:
      "Average flow loss between each pile within the array occuring in the INFLOW pipes. Positive (+) values represent flow gain, negative (-) values represent flow loss",
    pileInletFlowRate:
      "This value is the average flow rate per-pile, at the pile inlet.",
  },
  pileOutFluid: {
    averageInterPileTemperatureChangeOutflow:
      "Average temperature change occuring in the OUTFLOW pipes between each pile in the array. Positive (+) values represent temperature gain, negative (-) values reperent temperature loss.",
    temperatureChangeAfterArray:
      "Temperature change the fluid will experience flowing between the last pile outlet in the array and the inlet of the heat pump. Positive values (+) represent temperature gain, negative (-) values represent temperature loss.",
  },
  plasticPipeNominalSize: {},
  plasticPipe: {
    plasticPipeNominalSizeForClient:
      "Note: The length of the plastic inner-pipe does not have a significant effect on performance. We recommend the plastic pipe be about 1ft/0.3m shorter than the steel pile.",
  },
  groundTemperatureMLModel: {},
  ground: {
    groundTemperatureAtTopOfPile:
      "Ground temperature at the level of the top of your piles, on your study date.",
    groundTemperature5mBelowTopOfPile:
      "Ground temperature 5 meters below the top of your piles, on your study date.",
    groundTemperature10mBelowTopOfPile:
      "Ground temperature 10 metres below the top of your piles, on your study date.",
  },
  steelPileNominalSize: {},
  steelPile: {},
};

export const standardVariableOrderLists: {
  [model in AllCrudableModelTypes]: OfficialVariables[];
} = {
  calculator: [],
  pileOutletFluidTemperatureMLModel: [],
  groundTemperatureMLModel: [],
  plasticPipe: ["plasticPipeNominalSizeForClient"],
  //@ts-ignore
  steelPile: ["steelPileNominalSizeForClient", "length"],
  climate: ["ambientAirTemperature"],
  ground: [
    "groundTemperatureAtTopOfPile",
    "groundTemperature5mBelowTopOfPile",
    "groundTemperature10mBelowTopOfPile",
    "maxGroundThermalConductivity",
    "averageGroundThermalConductivity",
    "minGroundThermalConductivity",
  ],
  pileArray: [
    'numberOfActivePilesInTheArray',
    'perPileHeatPumpCapacity',
    'targetGeoPileThermalCapacity',
    'targetWholeArrayHeatPumpCapacity',
  ],
  pileInFluid: [
    "heatPumpOutletFluidTemperature",
    "temperatureChangeBeforeArray",
    "averageInterPileTemperatureChangeInflow",
    "pileInletFluidTemperature",
    "arrayPumpOutletFlowRate",
    "flowChangeBeforeArray",
    "averageInterPileFlowChangeInflow",
    "pileInletFlowRate",
  ],
  pileOutFluid: [
    "pileOutletFluidTemperature",
    "averageInterPileTemperatureChangeOutflow",
    "temperatureChangeAfterArray",
  ],
  heatPump: [
    "coolingSeasonCoefficientA",
    "coolingSeasonCoefficientB",
    "coolingSeasonCoefficientC",
    "heatingSeasonCoefficientA",
    "heatingSeasonCoefficientB",
    "heatingSeasonCoefficientC",
  ],
  steelPileNominalSize: [
    "steelPileInnerDiameter",
    "steelPileOuterDiameter"
  ],
  plasticPipeNominalSize: [
    "plasticInletPipeLength",
    "plasticPipeOuterDiameter"
  ],
};
  export const standardImageLinkMaps: {[model in AllCrudableModelTypes]:{[fieldName:string]:string}} = {
    calculator: {},
    climate: {
      ambientAirTemperature: 'soil and climate.png'
    },
    pileOutletFluidTemperatureMLModel: {},
    heatPump: {
      coolingSeasonCoefficientA: 'cop coefficients.png',
      coolingSeasonCoefficientB: 'cop coefficients.png',
      coolingSeasonCoefficientC: 'cop coefficients.png',
      heatingSeasonCoefficientA: 'cop coefficients.png',
      heatingSeasonCoefficientB: 'cop coefficients.png',
      heatingSeasonCoefficientC: 'cop coefficients.png',
    },
    pileArray: {
        targetWholeArrayHeatPumpCapacity:'thermal performance.png',
        targetGeoPileThermalCapacity:'thermal performance.png',
        numberOfActivePilesInTheArray: 'pile array.jpg',
        heatPumpCapacity: 'thermal performance.png',
    },
    pileInFluid:{
        heatPumpOutletFluidTemperature:'pile array.png',
        averageInterPileTemperatureChangeInflow:'losses within array.png',
        temperatureChangeBeforeArray: 'losses before array.png',
        arrayPumpOutletFlowRate: 'pile array.png',
        flowChangeBeforeArray: 'losses before array.png',
        averageInterPileFlowChangeInflow: 'losses within array.png',
        pileInletFlowRate: 'flow within one pile.png',
        pileInletFluidTemperature:'flow within one pile.png',
    },
    pileOutFluid:{
        averageInterPileTemperatureChangeOutflow: 'losses within array.png',
        temperatureChangeAfterArray: 'losses before array.png',
        pileOutletFluidTemperature:'flow within one pile.png',
    },
    plasticPipeNominalSize:{
      innerDiameter: 'pile size and length.png',
      outerDiameter: 'pile size and length.png',
    },
    plasticPipe:{
        plasticPipeNominalSizeForClient: 'pile size and length.png'
    },
    groundTemperatureMLModel:{},
    ground:{
        groundTemperatureAtTopOfPile:'soil and climate.png', 
        groundTemperature5mBelowTopOfPile:'soil and climate.png',
        groundTemperature10mBelowTopOfPile:'soil and climate.png',
        maxGroundThermalConductivity:'soil and climate.png',
        averageGroundThermalConductivity:'soil and climate.png',
        minGroundThermalConductivity:'soil and climate.png',
    },
    steelPileNominalSize:{
      innerDiameter: 'pile size and length.png',
      outerDiameter: 'pile size and length.png',
    },
    steelPile:{
      length: 'pile size and length.png',
      steelPileNominalSizeForClient: 'pile size and length.png',
    },
  };


  export function getSpecialButtonText(fieldName: OfficialVariables): undefined | string{
    const GTPPredictionText = 'Get Prediction'
    switch(fieldName){
      case 'groundTemperatureAtTopOfPile':
        return GTPPredictionText;
      case 'groundTemperature5mBelowTopOfPile':
        return GTPPredictionText;
      case 'groundTemperature10mBelowTopOfPile':
        return GTPPredictionText;
      default: 
        return undefined
    }
  }
  export function getSpecialButtonOnClick(fieldName: OfficialVariables, api: BackendAPIContextProps): undefined | ((e:any)=>void) {
    switch(fieldName){
      case 'groundTemperatureAtTopOfPile':
        return (e)=>api.startGTPPrediction(e,0)
      case 'groundTemperature5mBelowTopOfPile':
        return (e)=>api.startGTPPrediction(e,5) 
      case 'groundTemperature10mBelowTopOfPile':
        return (e)=>api.startGTPPrediction(e,10)
      default: return undefined;
    }
  }
