// (function (global, factory) {
// 	typeof exports === 'object' && typeof module !== 'undefined'
// 		? module.exports = factory(require('papaparse'), require('lodash/isFunction'), require('famulus/isValuesUnique'))
// 		: typeof define === 'function' && define.amd
// 			? define(['papaparse', 'lodash/isFunction', 'famulus/isValuesUnique'], factory)
// 			: (global.myBundle = factory(global.Papa, global._isFunction, global.isValuesUnique));
// }(this, (function (Papa, _isFunction, isValuesUnique) {
// 'use strict';

// Papa = Papa && Papa.hasOwnProperty('default') ? Papa['default'] : Papa;
// isValuesUnique = isValuesUnique && isValuesUnique.hasOwnProperty('default') ? isValuesUnique['default'] : isValuesUnique;
// _isFunction = _isFunction && _isFunction.hasOwnProperty('default') ? _isFunction['default'] : _isFunction;

/**
 * @param {File} csvFile
 * @param {Object} config
 */

// define the colmns...
//what should the header names be, exactly
//turn the csv after that into a list of dicts for each row, with header names as keys and row values as values
//pass these dicts to a custom callback for each column, passing in the entire row dict to allow dependent row validation
import Papa from "papaparse";

export type SheldonsCSVValidatorColumnConfig = {
  validNames: string | string[];
  columnRequired: boolean;
  validateCellCallback: (
    cellValue: any,
    row: {
      [headerValue: string]: any;
    }
  ) => { valid: boolean; errorMessage: string | undefined };
};

export type SheldonsCSVValidatorConfig = {
  columns: SheldonsCSVValidatorColumnConfig[];
  validateEntireCSVCallback: (csvRows: unknown[]) => string | undefined;
};

export async function sheldonsCSVFileValidator(
  csv: File,
  config: SheldonsCSVValidatorConfig
): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    //console.log("running sheldonsCSVFileValidator");
    Papa.parse(csv, {
      header: true,
      dynamicTyping: true, //!maybe no?
      skipEmptyLines: true,
      complete: function (results) {
        //console.log("starting sheldonsCSVFileValidator");
        let allErrorMessages: undefined | string = undefined;
        const configColIndexToCsvColName: string[] =
          linkRealCsvColNamesToConfig(
            results.data[0] as { [key: string]: any },
            config
          );
        allErrorMessages = config.validateEntireCSVCallback(results.data);
        results.data.forEach((r, rIndex) => {
          if (allErrorMessages) return;
          const row = r as { [key: string]: string | number };
          //console.log("checking new row ", row);
          //TODO not parsing numbers with commas correctly, check if there are any strings present where numbers should be
          const stringTypeErrorMessage =
            checkForCommasOrStringsWhereNumbersShouldBe(row, rIndex);
          if (stringTypeErrorMessage) {
            allErrorMessages = stringTypeErrorMessage;
          }
          const rowIndex = rIndex + 2;
          //check all required columns are present
          const missingRequiredCols = checkThatRequiredColsArePresentInRow(
            rowIndex,
            row,
            config
          );
          if (missingRequiredCols) {
            allErrorMessages = missingRequiredCols;
          }
          //TODO maybe check if there are any extra columns not included in config?
          //validate data in each cell of each row
          const errorMessageOrVoid = validateSingleCell(
            row,
            rowIndex,
            config,
            configColIndexToCsvColName
          );
          //console.log("2", errorMessageOrVoid);
          if (errorMessageOrVoid) {
            allErrorMessages = errorMessageOrVoid;
          }
        });
        resolve(allErrorMessages);
      },
    });
  });
}

function validateSingleCell(
  row: { [key: string]: any },
  rowIndex: number,
  config: SheldonsCSVValidatorConfig,
  configColIndexToCsvColName: string[]
): string | void {
  const errors = config.columns
    .map((colDefinition, colIndex) => {
      const actualColName = configColIndexToCsvColName[colIndex];
      const correspondingVal = row[actualColName];
      const validationResult = colDefinition.validateCellCallback(
        correspondingVal,
        row
      );
      if (!validationResult.valid) {
        const errorMessage = `Error in row ${rowIndex}, under column named "${actualColName}". Error message: ${validationResult.errorMessage}`;
        //console.log("er mes = ", errorMessage);
        return errorMessage;
      }
    })
    .filter((x) => x);
  //console.log("errorrs ", errors);
  return errors[0];
}

function linkRealCsvColNamesToConfig(
  firstRow: { [key: string]: string | number },
  config: SheldonsCSVValidatorConfig
) {
  const configColIndexToCsvColName: string[] = [];
  // const firstRow = results.data[0] as { [key: string]: string | number };
  Object.keys(firstRow).forEach((colName) => {
    config.columns.forEach((colDefinition, colDefIndex) => {
      if (typeof colDefinition.validNames == "string") {
        if (colDefinition.validNames === colName) {
          configColIndexToCsvColName[colDefIndex] = colName;
          return;
        }
      } else {
        // is array
        if (colDefinition.validNames.includes(colName)) {
          configColIndexToCsvColName[colDefIndex] = colName;
          return;
        }
      }
      return;
    });
  });
  return configColIndexToCsvColName;
}

function checkThatRequiredColsArePresentInRow(
  rowIndex: number,
  row: { [key: string]: string | number },
  config: SheldonsCSVValidatorConfig
): string | void {
  const rowColNames = Object.keys(row);
  config.columns.filter((colDefinition) => {
    if (colDefinition.columnRequired) {
      if (typeof colDefinition.validNames === "string") {
        //does this string exist in the collection of keys for this row?
        if (!rowColNames.includes(colDefinition.validNames)) {
          const errorMessage = `You are missing a required column , ${colDefinition.validNames}, in row ${rowIndex}`;
          return errorMessage;
        }
      } else {
        //must be array of strings
        //does ONE of these strigs exist in the collection of keys for this row?
        let aColNameIsPresent = false;
        colDefinition.validNames.forEach((colName) => {
          if (rowColNames.includes(colName)) {
            aColNameIsPresent = true;
          }
        });
        if (!aColNameIsPresent) {
          const errorMessage = `You are missing a required column, ${colDefinition.validNames[0]}, in row ${rowIndex}`;
          return errorMessage;
        }
      }
    }
  });
}

function checkForCommasOrStringsWhereNumbersShouldBe(
  row: { [key: string]: string | number },
  rowIndex: number
): string | void {
  //TODO not parsing numbers with commas correctly, check if there are any strings present where numbers should be
  let errorMessage = "";
  Object.keys(row).forEach((colName) => {
    const v = row[colName];
    if (typeof v === "string") {
      errorMessage = `Text was found in the csv where a number should be, at col ${colName}, row ${rowIndex}`;
    }
    // console.log(v);
    // console.log(typeof v);
  });
  if (errorMessage.length) {
    // console.log("GOT HERE");
    return errorMessage;
  }
}

// 	function CSVFileValidator(csvFile: File, config: unknown) {
// 		return new Promise(function (resolve, reject) {
// 			if (!config || (config && !config.headers)) {
// 				return resolve({
// 					inValidMessages: ['config headers are required'],
// 					data: []
// 				});
// 			}

// 			Papa.parse(csvFile, {
// 				...config.parserConfig,
// 				skipEmptyLines: true,
// 				complete: function (results) {
// 					resolve(_prepareDataAndValidateFile(results.data, config));
// 				},
// 				error: function (error, file) {
// 					reject({ error: error, file: file });
// 				}
// 			});
// 		})
// 	}

// 	/**
// 	 * @param {Array} csvData
// 	 * @param {Object} config
// 	 * @private
// 	 */
// 	function _prepareDataAndValidateFile(csvData, config) {
// 		const file = {
// 			inValidMessages: [],
// 			data: []
// 		};

// 		csvData.forEach(function (row, rowIndex) {
// 			const columnData = {};
// 			const rowFormattedForUser = formatCsvRowForPassingToUser(row)
// 			// fields are mismatch
// 			if (rowIndex !== 0 && row.length !== config.headers.length) {
// 				file.inValidMessages.push(
// 					'Number of fields mismatch: expected ' + config.headers.length + ' fields' +
// 					' but parsed ' + row.length + '. In the row ' + rowIndex
// 				);
// 			}

// 			row.forEach(function (columnValue, columnIndex) {
// 				const valueConfig = config.headers[columnIndex];

// 				// Remove BOM character
// 				columnValue = columnValue.replace(/^\ufeff/g, '');

// 				if (!valueConfig) {
// 					return;
// 				}

// 				// header validation, skip if isHeaderNameOptional
// 				if (rowIndex === 0) {
// 					if (config.isHeaderNameOptional && valueConfig.name === columnValue) {
// 						return;
// 					}

// 					if (!config.isHeaderNameOptional) {
// 						if (valueConfig.name !== columnValue) {
// 							file.inValidMessages.push(
// 								_isFunction(valueConfig.headerError)
// 									? valueConfig.headerError(columnValue, valueConfig.name, rowIndex + 1, columnIndex + 1)
// 									: 'Header name ' + columnValue + ' is not correct or missing in the ' + (rowIndex + 1) + ' row / '
// 										+ (columnIndex + 1) + ' column. The Header name should be ' + valueConfig.name
// 							);
// 						}

// 						return;
// 					}
// 				}

// 				if (valueConfig.required && !columnValue.length) {
// 					file.inValidMessages.push(
// 						_isFunction(valueConfig.requiredError)
// 							? valueConfig.requiredError(valueConfig.name, rowIndex + 1, columnIndex + 1)
// 							: String(valueConfig.name + ' is required in the ' + (rowIndex + 1) + ' row / ' + (columnIndex + 1) + ' column')
// 					);
// 				} else if (valueConfig.validate && !valueConfig.validate(columnValue)) {
// 					file.inValidMessages.push(
// 						_isFunction(valueConfig.validateError)
// 							? valueConfig.validateError(valueConfig.name, rowIndex + 1, columnIndex + 1)
// 							: String(valueConfig.name + ' is not valid in the ' + (rowIndex + 1) + ' row / ' + (columnIndex + 1) + ' column')
// 					);
// 				} else if (valueConfig.dependentValidate && !valueConfig.dependentValidate(columnValue, rowFormattedForUser)){
// 					file.inValidMessages.push(
// 						_isFunction(valueConfig.validateError)
// 							? valueConfig.validateError(valueConfig.name, rowIndex + 1, columnIndex + 1)
// 							: String(valueConfig.name + ' is not valid in the ' + (rowIndex + 1) + ' row / ' + (columnIndex + 1) + ' column')
// 					)
// 				}

// 				if (valueConfig.optional) {
// 					columnData[valueConfig.inputName] = columnValue;
// 				}

// 				if (valueConfig.isArray) {
// 					columnData[valueConfig.inputName] = columnValue.split(',').map(function (value) {
// 						return value.trim();
// 					});
// 				} else {
// 					columnData[valueConfig.inputName] = columnValue;
// 				}
// 			});

// 			if (Object.keys(columnData).length) {
// 				file.data.push(columnData);
// 			}
// 		});

// 		_checkUniqueFields(file, config);

// 		return file;
// 	}

// 	/**
// 	 *
// 	 * @param {Array} row
// 	 * @returns
// 	 */
// 	function formatCsvRowForPassingToUser(row){
// 		return row.map((columnValue) => columnValue.replace(/^\ufeff/g, ''))
// 	}

// 	/**
// 	 * @param {Object} file
// 	 * @param {Object} config
// 	 * @private
// 	 */
// 	function _checkUniqueFields(file, config) {
// 		if (!file.data.length) {
// 			return;
// 		}

// 		config.headers
// 			.filter(function (header) {
// 				return header.unique;
// 			})
// 			.forEach(function (header) {
// 				if (!isValuesUnique(file.data, header.inputName)) {
// 					const duplicates = [];

// 					file.data.forEach((row, rowIndex) => {
// 						const value = row[header.inputName];

// 						if (duplicates.indexOf(value) >= 0) {
// 							file.inValidMessages.push(
// 								_isFunction(header.uniqueError)
// 									? header.uniqueError(header.name, rowIndex + 2)
// 									: String(
// 										header.name + " is not unique at the " + (rowIndex + 2) + "row"
// 									)
// 							);
// 						} else {
// 							duplicates.push(value);
// 						}
// 					});
// 				}
// 			});
// 	}

// 	return CSVFileValidator;
// })));
