import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";
import { convertUiInputIntToDto, IUiInputVariableInt, IUiInputVariableIntOut, UiInputVariableInt } from "./ui-input-variable-int";

export interface IPileArray extends IBaseModel {

  numberOfActivePilesInTheArray?: IUiInputVariableInt | null;
  perPileHeatPumpCapacity?: IUiInputVariableDouble | null;

  // wholeArrayHeatPumpCapacity?: IUiInputVariableDouble | null;
  targetWholeArrayHeatPumpCapacity?: IUiInputVariableDouble | null;
  targetGeoPileThermalCapacity?: IUiInputVariableDouble | null;

}
export interface IPileArrayOut extends IBaseModel {

  numberOfActivePilesInTheArray?: IUiInputVariableIntOut | null;
  perPileHeatPumpCapacity?: IUiInputVariableDoubleOut | null;

  // wholeArrayHeatPumpCapacity?: IUiInputVariableDoubleOut | null;
  targetWholeArrayHeatPumpCapacity?: IUiInputVariableDoubleOut | null;
  targetGeoPileThermalCapacity?: IUiInputVariableDoubleOut | null;
}
export class PileArray extends BaseModel implements IPileArray{
  public perPileHeatPumpCapacity?: IUiInputVariableDouble = new UiInputVariableDouble('perPileHeatPumpCapacity');

  // public wholeArrayHeatPumpCapacity?: IUiInputVariableDouble = new UiInputVariableDouble(
  //   "W",
  //   "Whole Array Heat Pump Capacity"
  // );
  public numberOfActivePilesInTheArray?: IUiInputVariableInt = new UiInputVariableInt('numberOfActivePilesInTheArray');
  public targetWholeArrayHeatPumpCapacity?: IUiInputVariableDouble = new UiInputVariableDouble('targetWholeArrayHeatPumpCapacity');
    public targetGeoPileThermalCapacity?: IUiInputVariableDouble = new UiInputVariableDouble('targetGeoPileThermalCapacity');
}
export function pileArrayToDto(base: IPileArray): IPileArrayOut{
  return {
    id: base.id,
    name: base.name,
    numberOfActivePilesInTheArray: convertUiInputIntToDto(base.numberOfActivePilesInTheArray),
  targetWholeArrayHeatPumpCapacity: convertUiInputDoubleToDto(base.targetWholeArrayHeatPumpCapacity),
    targetGeoPileThermalCapacity: convertUiInputDoubleToDto(base.targetGeoPileThermalCapacity),
  };
}