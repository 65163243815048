/* tslint:disable */
/* eslint-disable */
/**
 * GPPApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @enum {string}
 */
export enum OutputVariables {
    PileLength = 'Pile Length',
    NumberOfPiles = 'Number of Piles',
    PileHeatPumpCapacity = 'Heat Pump Capacity',
    GroundTemperature = 'Ground Temperature',
    BulkPredictions = 'Bulk Heat Pump Capacity'
}

