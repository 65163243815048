import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Datetime from "react-datetime";
import styled from "styled-components";

const Container = styled.div`
  color: var(--c-black);
`;
interface DatetimePickerProps {
  datetime: Date | undefined;
  setDatetime: (...args: any) => void;
}
const DatetimePicker: React.FunctionComponent<DatetimePickerProps> = ({
  setDatetime,
  datetime,
}) => {
  return (
    <Container>
      <Datetime
        value={datetime}
        onChange={(value) => {
          if (typeof value === "string") {
            console.warn("invalid datetime input");
          } else {
            setDatetime(value.toDate());
          }
        }}
      />
    </Container>
  );
};
export default DatetimePicker;
