import { HorizFlex } from "components/MainMenu";
import Popup from "components/Reusables/Popup";
import {
  allAbreviations,
  OfficialVariables,
  readableUnits,
  readableVariableNoAbrev,
  VariableAbreviation,
  variableUnit,
} from "lib/types";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: var(--s-23); */
  background: var(--c-white);
  overflow: auto;
  max-height: 99%;
`;
const KeyPointsSection = styled.div`
  background: var(--c-gray-very-dark);
color: var(--c-green-very-light);
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
`;
const BigTitle = styled.h2`
    color: var(--c-green);
`;

const SubTitle = styled.h3`
    color: var(--c-green);
`;
const SubSubTitle= styled.h4`
    color: var(--c-green);
`;
const Text = styled.div`
    color: var(--c-black);
`;
const PerVariableSection = styled.div`
    background: var(--c-gray-very-light);
    width:80%;
`;
const ShortVariableSection = styled.div`
    background: var(--c-white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;
interface SingleVariableInstructions {
  whenIsRequired: string;
  suggestedValues?: string;
  howToFindValues?: string;
  otherComments?: string;
}

interface BulkPredCsvInstructionsProps {
  closeCallback: () => void;
}
const BulkPredCsvInstructions: React.FunctionComponent<BulkPredCsvInstructionsProps> =
  ({ closeCallback }) => {
    const perRowInstructions: {
      [key in OfficialVariables]?: SingleVariableInstructions;
    } = {
      targetWholeArrayHeatPumpCapacity: {
        whenIsRequired: `
                When ${VariableAbreviation["targetWholeArrayGeoPileCapacity"]} and ${VariableAbreviation["targetGeoPileThermalCapacity"]} are not being used as your target variable. Should be empty otherwise.
            `,
        howToFindValues: `
                If you have expected Building Load (W) and the building is using a heat pump and no other cooling/heating source you can directly input your Building Load here, otherwise you will need to do some pre-calculations.
            `,
        otherComments: `
                You must pick one target variable (one of ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, or ${VariableAbreviation["targetGeoPileThermalCapacity"]}) and ONLY use that target variable in your study.
            `,
      },
      targetWholeArrayGeoPileCapacity: {
        whenIsRequired: `
                When ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]} and ${VariableAbreviation["targetGeoPileThermalCapacity"]} are both not being used as your target variable. Should be empty otherwise.
            `,
        otherComments: `
            You must pick one target variable (one of ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, or ${VariableAbreviation["targetGeoPileThermalCapacity"]}) and ONLY use that target variable in your study.
            `,
      },
      targetGeoPileThermalCapacity: {
        whenIsRequired: `
            When ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]} and ${VariableAbreviation["targetWholeArrayGeoPileCapacity"]} are both not being used as your target variable. Should be empty otherwise.
            `,
        otherComments: `
            You must pick one target variable (one of ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, ${VariableAbreviation["targetWholeArrayHeatPumpCapacity"]}, or ${VariableAbreviation["targetGeoPileThermalCapacity"]}) and ONLY use that target variable in your study.
            `,
      },
      ambientAirTemperature: {
        whenIsRequired: `
            Always Required.
            `,
        howToFindValues: `
                The wikipedia pages of most cities have annual climate information in a graph. This can be used to create rough air temperature estimates for your chosen area.
            `,
      },
      groundTemperatureAtTopOfPile: {
        whenIsRequired: `
                Always Required.
            `,
        howToFindValues: `
                Use the built in Ground Temperature Predictor (GTP) to get a csv with all of the predicted ground temperatures for your location and time period. Simply copy the corresponding columns from that csv into your input csv.
            `,
      },
      groundTemperature5mBelowTopOfPile: {
        whenIsRequired: `
            Always Required.
        `,
        howToFindValues: `
            Use the built in Ground Temperature Predictor (GTP) to get a csv with all of the predicted ground temperatures for your location and time period. Simply copy the corresponding columns from that csv into your input csv.
        `,
      },
      groundTemperature10mBelowTopOfPile: {
        whenIsRequired: `
            Always Required.
        `,
        howToFindValues: `
            Use the built in Ground Temperature Predictor (GTP) to get a csv with all of the predicted ground temperatures for your location and time period. Simply copy the corresponding columns from that csv into your input csv.
        `,
      },
      maxGroundThermalConductivity: {
        whenIsRequired: `
                Always Required.
            `,
        howToFindValues: `
                This should be the maximum thermal conductivity you expect in the column of soil around your piles. You can use "soilconductivity.com" to estimate your ground's thermal conductivity.
            `,
      },
      minGroundThermalConductivity: {
        whenIsRequired: `
            Always Required.
        `,
        howToFindValues: `
            This should be the minimum thermal conductivity you expect in the column of soil around your piles. You can use "soilconductivity.com" to estimate your ground's thermal conductivity.
        `,
      },
      averageGroundThermalConductivity: {
        whenIsRequired: `
            Always Required.
        `,
        howToFindValues: `
            This should be the average thermal conductivity you expect in the column of soil around your piles. You can use "soilconductivity.com" to estimate your ground's thermal conductivity.
        `,
      },
      arrayPumpOutletFlowRate: {
        whenIsRequired: `
              Always Required.
          `,
        suggestedValues: `
            The GPPS machine learning models are most accurate with flow rates of around 0.0000167 - 0.000033 m3/s (1-2 Liters/minute).
          `,
      },
      temperatureChangeBeforeArray: {
        whenIsRequired: `
        Always Required
      `,
        suggestedValues: `
        In a North American climate this value should generally be negative in the winter (temperature loss) and positive in the summer (temperature gain).
      `,
        howToFindValues: `
        You can simply fill this row with 0s to test a system with no temperature losses or gains in the before-array section. 
        However real-world systems always have losses/gains and so the better your estimates here the more accurate your results will be.
        Calculating these values will most likely involve looking at the R value of your fluid piping, the temperature of the material surrounding the piping, and the total length of piping in each section of the system.
      `,
      },
      averageInterPileTemperatureChangeInflow: {
        whenIsRequired: `
        Always Required
      `,
        suggestedValues: `
        In a North American climate this value should generally be negative in the winter (temperature loss) and positive in the summer (temperature gain).
      `,
        howToFindValues: `
        You can simply fill this row with 0s to test a system with no temperature losses or gains in the before-array section. 
        However real-world systems always have losses/gains and so the better your estimates here the more accurate your results will be.
        Calculating these values will most likely involve looking at the R value of your fluid piping, the temperature of the material surrounding the piping, and the total length of piping in each section of the system.
      `,
      },
      averageInterPileTemperatureChangeOutflow: {
        whenIsRequired: `
        Always Required
      `,
        suggestedValues: `
        In a North American climate this value should generally be negative in the winter (temperature loss) and positive in the summer (temperature gain).
      `,
        howToFindValues: `
        You can simply fill this row with 0s to test a system with no temperature losses or gains in the before-array section. 
        However real-world systems always have losses/gains and so the better your estimates here the more accurate your results will be.
        Calculating these values will most likely involve looking at the R value of your fluid piping, the temperature of the material surrounding the piping, and the total length of piping in each section of the system.
      `,
      },
      temperatureChangeAfterArray: {
        whenIsRequired: `
        Always Required
      `,
        suggestedValues: `
        In a North American climate this value should generally be negative in the winter (temperature loss) and positive in the summer (temperature gain).
      `,
        howToFindValues: `
        You can simply fill this row with 0s to test a system with no temperature losses or gains in the before-array section. 
        However real-world systems always have losses/gains and so the better your estimates here the more accurate your results will be.
        Calculating these values will most likely involve looking at the R value of your fluid piping, the temperature of the material surrounding the piping, and the total length of piping in each section of the system.
      `,
      },
      heatPumpOutletFluidTemperature: {
        whenIsRequired: `
              Based on Study Configuration
              With Heat Pump + Automatic Inlet Fluid Temps: A single value, in the first row of your csv, is required.
              With Heat Pump + Manual Inlet Fluid Temps: All rows must have valid values (Always Required).
              Without Heat Pump: Must be empty.
          `,
        suggestedValues: `
              The predictions are more accurate when you select AUTOMATIC in your bulk study configuration which will have the algorithm calculate this temperature based on the results from the previous time-step and the Target load/capacity variable you input.
          `,
        howToFindValues: `
            Consult your heat pump's manual.
          `,
      },
      pileInletFluidTemperature: {
        whenIsRequired: `
          Based on Study Configuration
                Without Heat Pump + Automatic Inlet Fluid Temps: A single value, in the first row of your csv, is required.
              Without Heat Pump + Manual Inlet Fluid Temps: All rows must have valid values (Always Required).
              With Heat Pump: Must be empty.
          `,
        suggestedValues: `
          The predictions are more accurate when you select AUTOMATIC in your bulk study configuration which will have the algorithm calculate this temperature based on the results from the previous time-step and the Target load/capacity variable you input.
      `,
        howToFindValues: `
            Usually this value should be a directly-measured value from a real site.
          `,
      },
    };

    return (
      <Popup title="CSV Instructions" closeCallback={closeCallback}>
        <Container>
          {/* <InnerContainer> */}
          <BigTitle> Key Points</BigTitle>
          <KeyPointsSection>
          <ol>
            <li>
              First row (header) is ALWAYS the following variable name
              abreviations:
              <HorizFlex>
              <ul>
              {Object.keys(perRowInstructions).map((varName,i) => {
                  if (i > 5) return<></>;
                  return (
                    <li>{VariableAbreviation[varName as OfficialVariables]}</li>
                  );
                })}

              </ul>
              <ul>
              {Object.keys(perRowInstructions).map((varName,i) => {
                  if (i > 11 || i <=5) return <></>;
                  return (
                    <li>{VariableAbreviation[varName as OfficialVariables]}</li>
                  );
                })}
              </ul>
              <ul>
              {Object.keys(perRowInstructions).map((varName,i) => {
                  if ( i <=11) return <></>;
                  return (
                    <li>{VariableAbreviation[varName as OfficialVariables]}</li>
                  );
                })}
              </ul>

              </HorizFlex>
            </li>
            <li>
              The row values in each column correspond to the variable name in
              that column in the header row.
            </li>
            <br/>
            <li>
              The rows in your csv that have a value in them are considered a
              single timestep.
            </li>
            <br/>
            <li>
              Rows/timesteps should always be in chronological order starting
              from the top of the csv and progressing in time as you go down.
            </li>
            <br/>
            <li>
                All values must be entered in the unit of measurement corresponding to that variable/column, as described below.
            </li>
            <br/>
            <li>
              Make sure there is no whitespace before or after the values in
              your cells. If the last column or last row is giving validation
              errors, and you are using excel, try selecting a few whole
              rows/columns which should be empty and deleting them, as excel can
              sometimes put hidden whitespace in a row or column.
            </li>
          </ol>
          </KeyPointsSection>
          <img
          style={{width: '98%'}}
            src="whole-system-diagram.svg"
            alt="umny-innovia-gpps-geopile-system"
          />
          <img
          style={{width: '98%'}}
          src="Target Building Load INFO.jpg"
          alt="target building load umny"
          />
          <BigTitle>Per Column/Variable Instructions:</BigTitle>
        <KeyPointsSection>
          {Object.keys(perRowInstructions).map((officialVarName) => {
            const v: OfficialVariables = officialVarName as OfficialVariables;
            const i = perRowInstructions[v];
            if (!i) return <></>;
            return (
              <PerVariableSection>
                <SubTitle>{VariableAbreviation[v]}</SubTitle>
                <ShortVariableSection>
            
                <SubSubTitle>Full Name: </SubSubTitle>
                <Text>{readableVariableNoAbrev[v]}
                    </Text>
                </ShortVariableSection>
                <ShortVariableSection>
                <SubSubTitle>Required Unit of Measurement: </SubSubTitle>
                <Text>
                    {readableUnits[variableUnit[v]]}
                </Text>
                </ShortVariableSection>
                <SubSubTitle>When is Required:</SubSubTitle>
                <Text>{i.whenIsRequired}</Text>
                {i.suggestedValues ? (
                  <>
                    <SubSubTitle>Suggested Values:</SubSubTitle>
                    <Text>{i.suggestedValues}</Text>
                  </>
                ) : (
                  <></>
                )}
                {i.howToFindValues ? (
                  <>
                    <SubSubTitle>How to Get:</SubSubTitle>
                    <Text>{i.howToFindValues}</Text>
                  </>
                ) : (
                  <></>
                )}
                {i.otherComments ? (
                  <>
                    <SubSubTitle>Comments:</SubSubTitle>
                    <Text>{i.otherComments}</Text>
                  </>
                ) : (
                  <></>
                )}
              </PerVariableSection>
            );
          })}
          </KeyPointsSection>
        </Container>
      </Popup>
    );
  };
export default BulkPredCsvInstructions;
