import { ChartData } from "chart.js/auto";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import Chart from "react-chartjs-2";

interface BarLineChartProps {
  xAxisLabels: string[];
  lineData: number[];
  lineLabel: string;
  barData: number[];
  barLabel: string;
  title?: string
}
const BarLineChart: React.FunctionComponent<BarLineChartProps> = ({
  xAxisLabels,
  lineData,
  barData,
  lineLabel,
  barLabel,
  title
}) => {
  const data = {
    labels: xAxisLabels,
    datasets: [
      {
        type: "line" as const,
        label: lineLabel,
        borderColor: "white",
        borderWidth: 0.3,
        fill: "gray",
        data: lineData,
        radius: 0.5
      },
      {
        type: "bar" as const,
        label: barLabel,
        backgroundColor:"green",
        data: barData,
        borderColor: "green",
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
    {title?
      <h3>{title}</h3>
    :<></>}
      <Chart 
      // height={100}
      type="bar" 
      data={data} />
    </>
  );
};
export default BarLineChart;
