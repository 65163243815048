import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import styled from "styled-components";
import GlobalProvider, { GlobalContext } from "./Contexts/Global";
import HiddenSecurityQuestionForm from "./HiddenSecurityQuestionForm";
import MainMenu from "./MainMenu";
import MLModelCrud from "./MLModelCrud";
import HeatPumpCapacityFlow from "./OutputFlows/HeatPumpCapacity";
import NumberOfPilesFlow from "./OutputFlows/NumberOfPiles";
import PileLengthFlow from "./OutputFlows/PileLength";
import GroundTemperatureForm from "./OutputFlows/GroundTemperature";
import FormTemplate from "./OutputFlows/Template";
import LoginPage from "./LoginPage";
import PageProvider from "./Contexts/Page";
import BulkPredictions from "./OutputFlows/BulkPredictionHPCapacity/Container";
import MessageHandlerAppContainer from "./MessageHandlerComponents/AppContainer";
import BulkHeatPumpCapacityReport from "./PrintableReports/SummaryPage/BulkHeatPumpCapacity";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";

const GlobalContainer = styled.div`
  margin: auto;
  position: relative;
  /* width: 85%; */
  padding: 0;
  height: 100%;
`;


interface AppProps {}
const App: React.FunctionComponent<AppProps> = (props) => {
  const gc = useContext(GlobalContext);

  const [testBulkResultObject, setTestBulkResultObject] = useState<BulkHeatPumpCapacityResult>();

  //on mount setup event listeners to fix number inputs
  useEffect(() => {
    function handleWheelEvent(event: WheelEvent) {
      if (
        document?.activeElement?.getAttribute("type") === "number" &&
        document.activeElement instanceof HTMLElement
      ) {
        document.activeElement.blur();
      }
    }
    document.addEventListener("wheel", handleWheelEvent);
    return () => {
      document.removeEventListener("wheel", handleWheelEvent);
    };
  }, []);
  function getFormViewFromOutputVariable() {
    //console.log("getting form view", gc?.outputVariable);
    if (!gc) return <MainMenu />;
    switch (gc.outputVariable) {
      default:
        console.warn("no valid output variable selected");
        return <MainMenu />;
      case 'Bulk Heat Pump Capacity':
        return <MainMenu/>;
      case "Heat Pump Capacity":
        return <FormTemplate FormContent={HeatPumpCapacityFlow} />;
      case "Number of Piles":
        return <FormTemplate FormContent={NumberOfPilesFlow} />;
      case "Pile Length":
        return (
          <FormTemplate
            withHeatPumpOptionSelector={true}
            FormContent={PileLengthFlow}
          />
        );
      case "Ground Temperature":
        return <FormTemplate FormContent={GroundTemperatureForm} />;
    }
  }


  useEffect(()=>{
    fetch('ExampleBulkResult.json')
    .then(
      async data => {
        const asJson = await data.json()
        setTestBulkResultObject(asJson)
      })
  },[]);

  //console.log("Main APP component rerendering");
  return (
    
    <GlobalContainer>
      <Router>
        <PageProvider>
          <MessageHandlerAppContainer>
          {//gc?.errorMessage?.length ? (
            // <GlobalMessage>
            //   {gc.errorMessage}
            //   <Button onClick={() => gc.clearErrorMessages()}>Okay</Button>
            // </GlobalMessage>
          // ) : (gc?.loadingState && gc.outputVariable === OutputVariables.BulkPredictions) ?  (
            // <GlobalMessage>
                // <ProgressBar />
            // </GlobalMessage>
          // ) : gc?.loadingState ? (
          //   <GlobalMessage>
          //     <LoadingAnimation /> 
          //   </GlobalMessage>
          // )
          // : (gc?.successState && !gc.waitingActions ) ? (
          //   <GlobalMessage>Success!</GlobalMessage>
          // ) : (
          //   <></>
          // )}
          } 
          {gc?.popup ? gc.popup : <></>}
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/form">{getFormViewFromOutputVariable()}</Route>
            <Route path="/report">
              {/* <DiagramsReport  id={'divToPrint'}/> */}
              {/* <AssumptionsReport id={'divToPrint'}/> */}
              {/* <ErrorSourcesReport id={'divToPrint'}/> */}
              {/* <DisclaimerReport id={"divToPrint"} /> */}
              {/* <HeatPumpCapacityReport sessions={[]} TESTING={true} imperial={true} id={'divToPrint'}/> */}
              {
              testBulkResultObject
              ?
              <BulkHeatPumpCapacityReport
                imperial={false}
                key="mainReportPage"
                id="mainReportPage"
                sessions={testBulkResultObject}
              />
              :<></>}
            </Route>
            <Route path="/ml-models">
              <MLModelCrud />
            </Route>
            <Route path="/csq">
              <HiddenSecurityQuestionForm />
            </Route>
            <Route path="/bulk">
              <BulkPredictions />;
            </Route>
            <Route path="/">
              <MainMenu />
            </Route>
          </Switch>
          </MessageHandlerAppContainer>
        </PageProvider>
      </Router>
    </GlobalContainer>
  );
};
export default App;
