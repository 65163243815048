import { HorizFlex } from 'components/MainMenu';
import NumberInput from 'components/Reusables/NumberInput';
import React, {useState, useEffect, useContext, useCallback, useRef, SetStateAction} from 'react';
import styled, { css } from 'styled-components';
import { TextLabel, Unit } from './Template';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background: var(--c-green);
    width: var(--s-17);
    padding: 0;
`;

interface InputOutputVariableProps{
    value: number | undefined 
    setValue: React.Dispatch<SetStateAction<number|undefined|null>>
    unit?: string
    label: string
}
const InputOutputVariable: React.FunctionComponent<InputOutputVariableProps> =({
    value, setValue, unit, label
})=> {

return (
<HorizFlex>
            <TextLabel>
                {label}
            </TextLabel>
            <Container>
             <NumberInput
            value={value}
            onChange={undefined}
            setValue={(newVal: any)=> {
                if (!newVal) setValue(undefined);
                const val = newVal;
                setValue(val);
            }}
            width="100%;"
            />
            <Unit>
                {unit||'\xA0\xA0\xA0'}
            </Unit>   
            </Container>
            
        </HorizFlex>
);
}
export default InputOutputVariable;
