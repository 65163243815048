import { GlobalContext } from 'components/Contexts/Global';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    background: var(--c-green-dark);
    width: 100%;
    z-index: 10;
    margin: auto;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;
interface FooterProps{

}
const Footer: React.FunctionComponent<FooterProps> =({children})=> {
return (
<Container >
    {children}
</Container>
);
}
export default Footer;
