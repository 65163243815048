import React, {
  Dispatch,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
} from "react";
import _ from "lodash";
import {
  deleteFromApi,
  saveToApi,
  getFromApi,
  putMLModelAsActive,
  saveFileToApi,
  putToApi,
  getCalculationResult as APIGetCalculationResult,
  requestStartBulkPrediction,
  fetchBulkPrediction,
  addCsvToBulkPrediction,
  requestStartBulkGTPPredictionAndGetJobId,
  checkBulkGTPPredictionStatusAndGetProgressResultOrError,
  SingleResponseSchema,
} from "../../lib/api";
import {
  ApiBaseModel,
  ICalculators,
  IClimates,
  IHeatPumps,
  IPileArrays,
  IGrounds,
  IPileInFluids,
  IPileOutFluids,
  IPlasticPipes,
  ISteelPiles,
  ApiSingleVariableFromApi,
  IPlasticPipeNominalSizes,
  ISteelPileNominalSizes,
  ApiEndpoints,
} from "../../lib/types";
import {
  ICalculator,
  IClimate,
  IHeatPump,
  IPileArray,
  IPileInFluid,
  IPileOutFluid,
  IPlasticPipe,
  PossibleCategoryNames as Cat,
  IGround,
  ISteelPile,
  IUiInputVariableDouble,
  IUiInputVariableInt,
  IUiInputVariableString,
  OutputVariables,
  pileArrayToDto,
  heatPumpToDto,
  plasticPipeToDto,
  steelPileToDto,
  groundToDto,
  climateToDto,
  pileOutFluidToDto,
  pileInFluidToDto,
  PileInFluid,
  UiInputVariableDouble,
  UiInputVariableString,
} from "lib/models";
import {
  emptyUiInputVar,
  createEmpty,
  setThesePropertiesToUndefined,
  mapEnum,
} from "lib/helper-functions";
import { useHistory } from "react-router";
import { GlobalContext } from "./Global";
import { IPlasticPipeNominalSize } from "lib/models/plastic-pipe-nominal-size";
import { ISteelPileNominalSize } from "lib/models/steel-pipe-nominal-size";
import { IPileOutletFluidTemperatureMLModel } from "lib/models/fluid-temperature-exiting-pile-ml-model";
import { IGroundTemperatureMLModel } from "lib/models/ground-temperature-ml-model";
import { IBaseModel } from "lib/models/base-model";
import {
  getInvalidMessageBasedOnOutputType,
  getOutputTypeFromCalculator,
  isNotNullOrUndefined,
} from "lib/validation-helpers";
import ValidationErrorPopup from "components/Popups/ValidationError";
import { IPileLengthMLModel } from "lib/models/pile-length-ml-model";
import GTPInputs from "components/Popups/GTPInputs";
import {
  BulkPredictionDTO,
  timeUnitsToMilliseconds,
} from "lib/models/bulk-prediction-dto";
import { config } from "lib/CONFIG-consts";
import { MessageHandlerContext } from "./MessageHandler";
import {
  createNonFatalApplicationErrorMessage,
  createNonFatalHttpRequestErrorMessage,
  createNonFatalValidationErrorMessage,
} from "lib/ErrorAndMessageHandling/ErrorHandler";
import {
  createBlockingLoadingMessage,
  createHttpRequestSuccessMessage,
  createLoadingMessageWithProgress,
  Message,
} from "lib/ErrorAndMessageHandling/MessageHandler";
import ResultsPopup from "components/Popups/ResultsPopup";
import { generateCsvFromBulkPredictionResults } from "lib/process-bulk-prediction-results";
import {
  GTPInputs as IGTPInputs,
  GTPInputsForExternalAPI,
} from "components/Popups/GTPBulkPredictions";
import { processBulkGTPResultsAndDownloadCSV } from "lib/processBulkGTPResults";
import useInterval from "lib/useInterval";
import { getNumberOfRowsInCsv } from "lib/getNumberOfRowsInCsv";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { stat } from "fs";

type CustomSetState<T> = Dispatch<SetStateAction<T | undefined>>;
type numUn = number | undefined;

//define types here
export interface BackendAPIContextProps {
  grounds?: IGrounds;
  ground?: IGround;
  setGround: CustomSetState<IGround>;
  setGrounds: CustomSetState<IGrounds>;
  climates?: IClimates;
  climate?: IClimate;
  setClimate: CustomSetState<IClimate>;
  setClimates: CustomSetState<IClimates>;
  heatPumps?: IHeatPumps;
  heatPump?: IHeatPump;
  setHeatPumps: CustomSetState<IHeatPumps>;
  setHeatPump: CustomSetState<IHeatPump>;
  pileArrays?: IPileArrays;
  pileArray?: IPileArray;
  setPileArrays: CustomSetState<IPileArrays>;
  setPileArray: CustomSetState<IPileArray>;
  pileInFluids?: IPileInFluids;
  pileInFluid?: IPileInFluid;
  setPileInFluids: CustomSetState<IPileInFluids>;
  setPileInFluid: CustomSetState<IPileInFluid>;
  pileOutFluids?: IPileOutFluids;
  pileOutFluid?: IPileOutFluid;
  setPileOutFluids: CustomSetState<IPileOutFluids>;
  setPileOutFluid: CustomSetState<IPileOutFluid>;
  plasticPipes?: IPlasticPipes;
  plasticPipe?: IPlasticPipe;
  plasticPipeNominalSizes?: IPlasticPipeNominalSizes;
  plasticPipeNominalSize?: IPlasticPipeNominalSize;
  setPlasticPipes: CustomSetState<IPlasticPipes>;
  setPlasticPipe: CustomSetState<IPlasticPipe>;
  setPlasticPipeNominalSizes: CustomSetState<IPlasticPipeNominalSizes>;
  setPlasticPipeNominalSize: CustomSetState<IPlasticPipeNominalSize>;
  steelPiles?: ISteelPiles;
  steelPile?: ISteelPile;
  steelPileNominalSizes?: ISteelPileNominalSizes;
  steelPileNominalSize?: ISteelPileNominalSize;
  setSteelPiles: CustomSetState<ISteelPiles>;
  setSteelPile: CustomSetState<ISteelPile>;
  setSteelPileNominalSizes: CustomSetState<ISteelPileNominalSizes>;
  setSteelPileNominalSize: CustomSetState<ISteelPileNominalSize>;
  calculators?: ICalculators;
  calculator?: ICalculator;
  setCalculators: CustomSetState<ICalculators>;
  setCalculator: CustomSetState<ICalculator>;
  saveCalculator: (fresh: boolean, apiToken: string) => void;
  updateCalculator: (fresh: boolean, apiToken: string) => void;
  loadCalculator: (...args: any[]) => void;
  deleteCalculator: (name: string, apiToken: string) => void;
  getCurrentCalculator: () => ICalculator | undefined;
  saveModel: (
    endpoint: ApiEndpoints,
    obj: any,
    apiToken: string,
    existingModels: any[] | undefined
  ) => Promise<void>;
  updateModel: (
    endpoint: ApiEndpoints,

    obj: any,
    apiToken: string
  ) => Promise<void>;
  deleteModel: (
    endpoint: ApiEndpoints,
    name: string,
    apiToken: string
  ) => Promise<void>;
  getCalculationResult: <ReturnType>(
    endpoint: ApiEndpoints,
    calc: ICalculator,
    apiToken: string
  ) => Promise<ReturnType | undefined>;
  getModel: <ResponseType>(
    endpoint: ApiEndpoints,
    apiToken: string
  ) => Promise<ResponseType | undefined>;
  refreshModelsLists: (apiToken: string) => Promise<void>;
  getPlasticPipeNominalSizeNames: () => string[];
  getSteelPileNominalSizeNames: () => string[];
  pileOutletFluidTemperatureMLModels?: IPileOutletFluidTemperatureMLModel[];
  activePileOutletFluidTemperatureMLModel?: IPileOutletFluidTemperatureMLModel;
  groundTemperatureMLModels?: IGroundTemperatureMLModel[];
  activeGroundTemperatureMLModel?: IGroundTemperatureMLModel;
  pileLengthMLModels?: IPileLengthMLModel[];
  activePileLengthMLModel?: IPileLengthMLModel;
  refreshMLModelsLists: (apiToken: string) => Promise<void>;
  setMLModelAsActive: (
    endpoint: ApiEndpoints,
    id: number,
    apiToken: string
  ) => Promise<void>;
  saveFile: (endpoint: string, file: File, apiToken: string) => Promise<void>;
  getSteelPileDiametersFromName: (name: string) => {
    innerDiameter: number;
    outerDiameter: number;
  };
  getPlasticPipeDiametersFromName: (name: string) => {
    innerDiameter: number;
    outerDiameter: number;
  };
  startGTPPrediction: (e: any, depthBelowTopOfPile: 0 | 5 | 10) => void;
  gtpDay: numUn;
  setGTPDay: any;
  gtpMonth: numUn;
  setGTPMonth: any;
  gtpLat: numUn;
  setGTPLat: any;
  gtpLong: numUn;
  setGTPLong: any;
  gtpPileDepth: numUn;
  setGTPPileDepth: any;
  startBulkPrediction: (dto: BulkPredictionDTO, csv: File) => void;
  checkCurrentBulkPredictionStatusAndHandleResults: (
    stopCallback: () => void
  ) => Promise<void>;
  bulkPredictionDto: BulkPredictionDTO | undefined;
  setBulkPredictionDto: React.Dispatch<
    SetStateAction<BulkPredictionDTO | undefined>
  >;
  bulkPredictionCsv: File | undefined;
  setBulkPredictionCsv: React.Dispatch<SetStateAction<File | undefined>>;
  startBulkGTPPrediction: (gtpInputs: IGTPInputs[]) => void;
  bulkGTPQueue: IGTPInputs[] | undefined;
  setBulkGTPQueue: React.Dispatch<SetStateAction<IGTPInputs[] | undefined>>;
  listOfBulkCsvInputRowDatetimes: Date[] | undefined;
  setListOfBulkCsvInputRowDatetimes: React.Dispatch<
    SetStateAction<Date[] | undefined>
  >;
  gtpBulkPredictionDepthCategoriesToGet: number[] | undefined;
  setGTPBulkPredictionDepthCategoriesToGet: React.Dispatch<
    SetStateAction<number[] | undefined>
  >;
  runningGTPPredictionConfirmId: undefined | string;
  setRunningGTPPredictionConfirmId: React.Dispatch<
    SetStateAction<undefined | string>
  >;
  prevBulkPredictionResult: BulkHeatPumpCapacityResult | undefined;
  prevBulkPredictionResultName: string | undefined;
}

//initialize state structure here
export const BackendAPIContext =
  React.createContext<BackendAPIContextProps | null>(null);

const BackendAPIProvider: React.FunctionComponent = ({ children }) => {
  const history = useHistory();
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);

  const [grounds, setGrounds] = useState<IGrounds | undefined>();
  const [ground, setGround] = useState<IGround | undefined>();
  const [climates, setClimates] = useState<IClimates | undefined>();
  const [climate, setClimate] = useState<IClimate | undefined>();
  const [heatPumps, setHeatPumps] = useState<IHeatPumps | undefined>();
  const [heatPump, setHeatPump] = useState<IHeatPump | undefined>();
  const [pileArrays, setPileArrays] = useState<IPileArrays | undefined>();
  const [pileArray, setPileArray] = useState<IPileArray | undefined>();
  const [pileOutFluids, setPileOutFluids] = useState<
    IPileOutFluids | undefined
  >();
  const [pileOutFluid, setPileOutFluid] = useState<IPileOutFluid | undefined>();
  const [pileInFluids, setPileInFluids] = useState<IPileInFluids | undefined>();
  const [pileInFluid, setPileInFluid] = useState<IPileInFluid | undefined>();
  const [steelPiles, setSteelPiles] = useState<ISteelPiles | undefined>();
  const [steelPile, setSteelPile] = useState<ISteelPile | undefined>();
  const [plasticPipes, setPlasticPipes] = useState<IPlasticPipes | undefined>();
  const [plasticPipe, setPlasticPipe] = useState<IPlasticPipe | undefined>();
  const [plasticPipeNominalSizes, setPlasticPipeNominalSizes] =
    useState<IPlasticPipeNominalSizes>();
  const [plasticPipeNominalSize, setPlasticPipeNominalSize] =
    useState<IPlasticPipeNominalSize>();
  const [steelPileNominalSizes, setSteelPileNominalSizes] =
    useState<ISteelPileNominalSizes>();
  const [steelPileNominalSize, setSteelPileNominalSize] =
    useState<ISteelPileNominalSize>();
  const [calculators, setCalculators] = useState<ICalculators | undefined>();
  const [calculator, setCalculator] = useState<ICalculator | undefined>();
  const [groundTemperatureMLModels, setGroundTemperatureMLModels] =
    useState<IGroundTemperatureMLModel[]>();
  const [activeGroundTemperatureMLModel, setActiveGroundTemperatureMLModel] =
    useState<IGroundTemperatureMLModel>();
  const [
    pileOutletFluidTemperatureMLModels,
    setPileOutletFluidTemperatureMLModels,
  ] = useState<IPileOutletFluidTemperatureMLModel[]>();
  const [
    activePileOutletFluidTemperatureMLModel,
    setActivePileOutletFluidTemperatureMLModel,
  ] = useState<IPileOutletFluidTemperatureMLModel>();
  const [pileLengthMLModels, setPileLengthMLModels] =
    useState<IPileLengthMLModel[]>();
  const [activePileLengthMLModel, setActivePileLengthMLModel] =
    useState<IPileLengthMLModel>();
  const [plasticPipeNominalSizeNames, setPlasticPipeNominalSizeNames] =
    useState<string[]>();
  const [steelPileNominalSizeNames, setSteelPileNominalSizeNames] =
    useState<string[]>();
  //for GTP predictions
  const [gtpPileDepth, setGTPPileDepth] = useState<number>();
  const [gtpLat, setGTPLat] = useState<number>();
  const [gtpLong, setGTPLong] = useState<number>();
  const [gtpDay, setGTPDay] = useState<number>();
  const [gtpMonth, setGTPMonth] = useState<number>();
  const [bulkPredictionDto, setBulkPredictionDto] =
    useState<BulkPredictionDTO>();
  const [bulkPredictionCsv, setBulkPredictionCsv] = useState<File>();
  const [bulkGTPQueue, setBulkGTPQueue] = useState<IGTPInputs[]>();
  //TODO
  const [listOfBulkCsvInputRowDatetimes, setListOfBulkCsvInputRowDatetimes] =
    useState<Date[]>();
  const [gtpBulkDepthCategoriesToGet, setGTPBulkDepthCategoriesToGet] =
    useState<number[]>();
  const [runningGTPPredictionConfirmId, setRunningGTPPredictionConfirmId] =
    useState<string>();
  const [runningGTPPredictionFailures, setRunningGTPPredictionFailures] =
    useState<number>(0);
  const [numberOfBulkCsvRows, setNumberOfBulkCsvRows] = useState<number>();
  const [prevBulkPredictionResult, setPrevBulkPredictionResult] =
    useState<BulkHeatPumpCapacityResult>();
  const [prevBulkPredictionResultName, setPrevBulkPredictionResultName] =
    useState<string>();

  const messages = useSelector((state: RootState) => state.messages);
  //debugging
  useEffect(() => {
    //console.log('current selected SOIL object has changed: ',ground);
  }, [ground]);

  //on apiClient initiation, get all saved models from Api
  useEffect(() => {
    if (!gc || !gc.apiToken) return;
    //console.log('going to get all model data from api...');
    try {
      updateStateWithMLModelsFromApi(gc.apiToken);
      updateStateWithAllModelsFromApiExceptMLModels(gc.apiToken);
      setAllSelectedModelsToBlankModels();
      setCalculator(getCurrentCalculator());
    } catch (error) {
      //console.log(error);
      messageHandler.addMessage!(
        createNonFatalHttpRequestErrorMessage(
          "Get Saved Models From Backend",
          500,
          JSON.stringify(error)
        )
      );
      // history.push("/error");
    }
  }, [gc?.apiToken]);

  function setAllSelectedModelsToBlankModels() {
    setGround(createEmpty.ground());
    setClimate(createEmpty.climate());
    setPlasticPipe(createEmpty.plasticPipe(getPlasticPipeNominalSizeNames()));
    setHeatPump(createEmpty.heatPump());
    setSteelPile(createEmpty.steelPile(getSteelPileNominalSizeNames()));
    setPileInFluid(createEmpty.pileInFluid());
    setPileOutFluid(createEmpty.pileOutFluid());
    setPileArray(createEmpty.pileArray());
  }

  async function updateStateWithMLModelsFromApi(apiToken: string) {
    const fluidTempM = await getFromApi<IPileOutletFluidTemperatureMLModel[]>(
      "PileOutletFluidTemperatureMLModels",
      apiToken
    );
    const groundTempM = await getFromApi<IGroundTemperatureMLModel[]>(
      "GroundTemperatureMLModels",
      apiToken
    );
    const pileLengthM = await getFromApi<IPileLengthMLModel[]>(
      "PileLengthMLModels",
      apiToken
    );
    setPileOutletFluidTemperatureMLModels(fluidTempM);
    setGroundTemperatureMLModels(groundTempM);
    setPileLengthMLModels(pileLengthM);
  }

  async function updateStateWithAllModelsFromApiExceptMLModels(
    apiToken: string
  ) {
    const calc = await getFromApi<ICalculators>("calculators", apiToken);
    const s = await getFromApi<IGrounds>("grounds", apiToken);
    const c = await getFromApi<IClimates>("climates", apiToken);
    const hp = await getFromApi<IHeatPumps>("heatpumps", apiToken);
    const pa = await getFromApi<IPileArrays>("pilearrays", apiToken);
    const pof = await getFromApi<IPileOutFluids>("pileoutfluids", apiToken);
    const pif = await getFromApi<IPileInFluids>("pileinfluids", apiToken);
    const sp = await getFromApi<ISteelPiles>("steelpiles", apiToken);
    const pp = await getFromApi<IPlasticPipes>("plasticpipes", apiToken);
    const ppg = await getFromApi<IPlasticPipeNominalSizes>(
      "plasticpipenominalsizes",
      apiToken
    );
    const spg = await getFromApi<ISteelPileNominalSizes>(
      "steelpilenominalsizes",
      apiToken
    );
    //console.log('done getting all models from backend, now going to set them to state');
    setCalculators(calc);
    setGrounds(s);
    setClimates(c);
    setHeatPumps(hp);
    setPileArrays(pa);
    setPileOutFluids(pof);
    setPileInFluids(pif);
    setSteelPiles(sp);
    setPlasticPipes(pp);
    setPlasticPipeNominalSizes(ppg);
    setSteelPileNominalSizes(spg);
  }

  //whenever a new plastic pipe or steel pipe is selected, if it doesn't have a possibleValues list, get the list
  //keep the list of possible value names as its own state as well, so it doesn't have to be generated each time
  useEffect(() => {
    //console.log('going to set new possibleValuesList maybe', plasticPipe);
    if (!plasticPipe || !plasticPipeNominalSizeNames) return;
    if (
      plasticPipe.plasticPipeNominalSizeForClient?.possibleValueNames ===
      plasticPipeNominalSizeNames
    )
      return;
    //console.log('setting new possible values list for plastic pipe gauge', plasticPipeNominalSizeNames, plasticPipe);
    setPlasticPipe((prev) => {
      const clone = { ...prev };
      if (clone.plasticPipeNominalSizeForClient) {
        clone.plasticPipeNominalSizeForClient.possibleValueNames =
          plasticPipeNominalSizeNames;
      } else {
        clone.plasticPipeNominalSizeForClient = new UiInputVariableString(
          "plasticPipeNominalSize",
          plasticPipeNominalSizeNames
        );
      }
      return clone;
    });
  }, [plasticPipe, plasticPipeNominalSizeNames]);
  useEffect(() => {
    if (!steelPile || !steelPileNominalSizeNames) return;
    if (
      steelPile.steelPileNominalSizeForClient?.possibleValueNames ===
      steelPileNominalSizeNames
    )
      return;
    setSteelPile((prev) => {
      const clone = { ...prev };
      if (clone.steelPileNominalSizeForClient) {
        clone.steelPileNominalSizeForClient.possibleValueNames =
          steelPileNominalSizeNames;
      } else {
        clone.steelPileNominalSizeForClient = new UiInputVariableString(
          "steelPileNominalSize",
          steelPileNominalSizeNames
        );
      }
      return clone;
    });
  }, [steelPile, steelPileNominalSizeNames]);

  //on plastic pipe gauges change update the name list, same with steel pipes
  useEffect(() => {
    if (!plasticPipeNominalSizes) return;
    //console.log('setting new plastic pipe gauge names', plasticPipeNominalSizes);
    setPlasticPipeNominalSizeNames(
      //@ts-ignore
      plasticPipeNominalSizes.map((x) => x.name).filter((x) => x)
    );
  }, [plasticPipeNominalSizes]);
  useEffect(() => {
    if (!steelPileNominalSizes) return;
    setSteelPileNominalSizeNames(
      //@ts-ignore
      steelPileNominalSizes.map((x) => x.name).filter((x) => x)
    );
  }, [steelPileNominalSizes]);

  //whenever list of ml models changes, reset active models
  useEffect(() => {
    if (!pileOutletFluidTemperatureMLModels)
      setActivePileOutletFluidTemperatureMLModel(undefined);
    else
      setActivePileOutletFluidTemperatureMLModel(
        pileOutletFluidTemperatureMLModels.filter((x) => x.isActive)[0] ||
          undefined
      );
  }, [pileOutletFluidTemperatureMLModels]);
  useEffect(() => {
    if (!groundTemperatureMLModels)
      setActiveGroundTemperatureMLModel(undefined);
    else
      setActiveGroundTemperatureMLModel(
        groundTemperatureMLModels.filter((x) => x.isActive)[0] || undefined
      );
  }, [groundTemperatureMLModels]);
  useEffect(() => {
    if (!pileLengthMLModels) setActivePileLengthMLModel(undefined);
    else
      setActivePileLengthMLModel(
        pileLengthMLModels.filter((x) => x.isActive)[0] || undefined
      );
  }, [pileLengthMLModels]);

  const getPlasticPipeNominalSizeNames = useCallback(() => {
    return plasticPipeNominalSizes
      ? plasticPipeNominalSizes.map((x) => x.name || "no name")
      : ["not loaded yet"];
  }, [plasticPipeNominalSizes]);
  const getSteelPileNominalSizeNames = useCallback(() => {
    return steelPileNominalSizes
      ? steelPileNominalSizes.map((x) => x.name || "no name")
      : ["not loaded yet"];
  }, [steelPileNominalSizes]);

  //any models that have zero input data (including names, will not be saved)
  const getCurrentCalculator = useCallback(
    (fresh?: boolean): ICalculator => {
      //console.log('getting current calculator. Resetting the id? ',fresh);
      const newCalc: ICalculator = {
        outputVariable: gc?.outputVariable,
        id: fresh ? undefined : calculator?.id, //this will be replaced by the backend
        name: calculator?.name,
        ground: modelHasBeenEdited(ground, createEmpty.ground())
          ? ground
          : undefined,
        steelPile: modelHasBeenEdited(
          steelPile,
          createEmpty.steelPile(getSteelPileNominalSizeNames())
        )
          ? steelPile
          : undefined,
        plasticPipe: modelHasBeenEdited(
          plasticPipe,
          createEmpty.plasticPipe(getPlasticPipeNominalSizeNames())
        )
          ? plasticPipe
          : undefined,
        climate: modelHasBeenEdited(climate, createEmpty.climate())
          ? climate
          : undefined,
        pileArray: modelHasBeenEdited(pileArray, createEmpty.pileArray())
          ? pileArray
          : undefined,
        pileInFluid: modelHasBeenEdited(pileInFluid, createEmpty.pileInFluid())
          ? pileInFluid
          : undefined,
        pileOutFluid: modelHasBeenEdited(
          pileOutFluid,
          createEmpty.pileOutFluid()
        )
          ? pileOutFluid
          : undefined,
        heatPump: modelHasBeenEdited(heatPump, createEmpty.heatPump())
          ? heatPump
          : undefined,
      };
      return newCalc;
    },
    [
      calculator,
      climate,
      heatPump,
      pileArray,
      pileInFluid,
      pileOutFluid,
      plasticPipe,
      ground,
      steelPile,
      getPlasticPipeNominalSizeNames,
      getSteelPileNominalSizeNames,
      gc?.outputVariable,
    ]
  );

  function modelHasBeenEdited(model: any, emptyModel: any) {
    if (_.isEqual(model, emptyModel)) return false;
    return true;
  }

  const doAsyncActionWithUiDisplayOfState = useCallback(
    async (callback: any, title: string, summary: string) => {
      if (!gc) return;
      //TODO handle situation where multiple actions are happening at the same time....
      //TODO this have a list of pending actions, only set success or failure state when that list is empty
      //TODO AND failure messages should be added to a list, to account for multiple failures
      const messageId = messageHandler.addMessage!(
        createBlockingLoadingMessage(title, summary, "")
      );
      // gc.setLoadingState(true);
      try {
        const result = await callback();
        const allActionsCompleted =
          gc.removeWaitingActionAndReturnAllActionsCompleted();
        if (allActionsCompleted) {
          // gc.setSuccessState(true);
          ////console.log('going to resolve message 2')
          messageHandler.resolveMessage!(messageId);
        }
        return result;
      } catch (error: any) {
        try {
          ////console.log('going to resolve message 3');
          messageHandler.resolveMessage!(messageId);
        } catch (e) {}
        messageHandler.addMessage!(
          createNonFatalHttpRequestErrorMessage(
            "",
            500,
            error.response && error.response.data
              ? error.response.data.substring(0, 400)
              : JSON.stringify(error).substring(0, 400)
          )
        );
      }
    },
    [gc, messageHandler.addMessage, messageHandler.resolveMessage]
  );

  const saveCalculator = useCallback(
    async (fresh: boolean, apiToken: string): Promise<void> => {
      const currentCalc = getCurrentCalculator(fresh);
      if (calculators?.some((calc) => calc.name === currentCalc.name)) {
        const shouldContinue = window.confirm(
          "Warning: There is already an existing session with this name. Do you want to overwrite it?"
        );
        if (!shouldContinue) {
          return;
        }
      }
      setCalculator(currentCalc);
      //console.log('got this current calculator', currentCalc);
      await doAsyncActionWithUiDisplayOfState(
        () => {
          return saveToApi<ICalculator>("calculators", currentCalc, apiToken);
        },
        "Save Session",
        ""
      );
      if (gc?.apiToken) {
        updateStateWithAllModelsFromApiExceptMLModels(gc?.apiToken);
      }
    },
    [
      getCurrentCalculator,
      gc?.apiToken,
      calculators,
      doAsyncActionWithUiDisplayOfState,
    ]
  );
  const updateCalculator = useCallback(
    async (fresh: boolean, apiToken: string): Promise<void> => {
      const currentCalc = getCurrentCalculator(fresh);
      //console.log('got this current calculator', currentCalc);
      setCalculator(currentCalc);
      await doAsyncActionWithUiDisplayOfState(
        () => {
          return putToApi<ICalculator>("calculators", currentCalc, apiToken);
        },
        "Update Session",
        ""
      );
      if (gc?.apiToken) {
        updateStateWithAllModelsFromApiExceptMLModels(gc?.apiToken);
      }
    },
    [getCurrentCalculator, gc?.apiToken, doAsyncActionWithUiDisplayOfState]
  );

  const loadCalculator = useCallback(
    (newCalculator) => {
      //console.log('loading calculator', newCalculator);
      setCalculator(newCalculator);
      //set all other models based on this calculator
      setClimate(newCalculator.climate || createEmpty.climate());
      setHeatPump(newCalculator.heatPump || createEmpty.heatPump());
      setPileArray(newCalculator.pileArray || createEmpty.pileArray());
      setPileInFluid(newCalculator.pileInFluid || createEmpty.pileInFluid());
      setPileOutFluid(newCalculator.pileOutFluid || createEmpty.pileOutFluid());
      setPlasticPipe(
        newCalculator.plasticPipe ||
          createEmpty.plasticPipe(getPlasticPipeNominalSizeNames())
      );
      setSteelPile(
        newCalculator.steelPile ||
          createEmpty.steelPile(getSteelPileNominalSizeNames())
      );
      setGround(newCalculator.ground || createEmpty.ground());
    },
    [getPlasticPipeNominalSizeNames, getSteelPileNominalSizeNames]
  );

  const deleteCalculator = useCallback(
    async (name: string, apiToken: string) => {
      const shouldContinue = window.confirm(
        "Do you want to permantently delete session '" + name + "'?"
      );
      if (!shouldContinue) return;
      deleteFromApi("calculators", name, apiToken);
      const calc = await getFromApi<ICalculators>("calculators", apiToken);
      setCalculators(calc);
      setCalculator(getCurrentCalculator());
    },
    [getCurrentCalculator]
  );

  async function getModel<ResponseType>(
    endpoint: ApiEndpoints,
    apiToken: string
  ): Promise<ResponseType | undefined> {
    return await doAsyncActionWithUiDisplayOfState(
      () => getFromApi(endpoint!, apiToken),
      "Getting Model From: " + endpoint,
      ""
    );
  }
  async function saveModel(
    endpoint: ApiEndpoints,
    obj: any,
    apiToken: string,
    existingModels: any[] | undefined
  ): Promise<void> {
    // check if there is already a model with the name, and ask the user if they want to continue anyways
    //console.log('saving model, should give a warning if a model with same name exists...', obj, existingModels);
    if (existingModels && existingModels.some((x) => x.name === obj.name)) {
      const shouldContinue = window.confirm(
        "Warning: If you continue you will be overwriting an existing object with the same name. Do you wish to continue?"
      );
      if (!shouldContinue) return;
    }
    await doAsyncActionWithUiDisplayOfState(
      () => saveToApi(endpoint, obj!, apiToken),
      "Saving Model To :" + endpoint,
      ""
    );
  }
  async function updateModel(
    endpoint: ApiEndpoints,
    obj: any,
    apiToken: string
  ): Promise<void> {
    await doAsyncActionWithUiDisplayOfState(
      () => putToApi(endpoint, obj!, apiToken),
      "Updating Model At: " + endpoint,
      ""
    );
  }
  async function saveFile(
    endpoint: string,
    obj: File,
    apiToken: string
  ): Promise<void> {
    await doAsyncActionWithUiDisplayOfState(
      () => saveFileToApi(endpoint, obj, apiToken),
      "Saving File " + obj.name,
      ""
    );
  }
  async function deleteModel(
    endpoint: ApiEndpoints,
    name: string,
    apiToken: string
  ): Promise<void> {
    const shouldContinue = window.confirm(
      "Are you sure you want to permantently delete: " + name + "?"
    );
    if (!shouldContinue) return;
    await doAsyncActionWithUiDisplayOfState(
      () => deleteFromApi(endpoint, name!, apiToken),
      "Deleting Model At: " + endpoint,
      ""
    );
  }
  async function setMLModelAsActive(
    endpoint: ApiEndpoints,
    id: number,
    apiToken: string
  ): Promise<void> {
    await doAsyncActionWithUiDisplayOfState(
      () => putMLModelAsActive(endpoint, id!, apiToken),
      "Setting ML Model As Active",
      ""
    );
  }
  async function getCalculationResult<ReturnType>(
    endpoint: ApiEndpoints,
    calc: ICalculator,
    apiToken: string
  ): Promise<ReturnType | undefined> {
    //validate ICalculator based on the output type
    //console.log('getting calculation result from backend api with this calculator: ',calc);
    const outputType = await getOutputTypeFromCalculator(calc);
    //console.log('got this output type: ',outputType);
    const invalidMessage = await getInvalidMessageBasedOnOutputType(
      calc,
      outputType
    );
    //console.log('got this invalidMessage:',invalidMessage);
    if (invalidMessage) {
      gc?.setPopup(<ValidationErrorPopup message={invalidMessage} />);
      return;
    } else {
      return await doAsyncActionWithUiDisplayOfState(
        () => APIGetCalculationResult(endpoint, calc!, apiToken),
        "Retrieving Results For Session: " + calc.name,
        ""
      );
    }
  }

  function getSteelPileDiametersFromName(name: string) {
    const nomSize = steelPileNominalSizes?.filter(
      (size) => size.name === name
    )[0];
    return {
      innerDiameter: nomSize?.innerDiameter || 0,
      outerDiameter: nomSize?.outerDiameter || 0,
    };
  }
  function getPlasticPipeDiametersFromName(name: string) {
    const nomSize = plasticPipeNominalSizes?.filter(
      (size) => size.name === name
    )[0];
    return {
      innerDiameter: nomSize?.innerDiameter || 0,
      outerDiameter: nomSize?.outerDiameter || 0,
    };
  }

  function startGTPPrediction(e: any, depthBelowTopOfPile: 0 | 5 | 10) {
    //TODO

    e.preventDefault();
    doAsyncActionWithUiDisplayOfState(
      async () => {
        //TODO make sure this still works properly, ie try a gtp prediction
        gc?.setPopup(
          <GTPInputs
            closeCallback={() => gc?.setPopup(undefined)}
            depthBelowTopOfPile={depthBelowTopOfPile}
            setResult={(val: numUn) => setGTPResult(depthBelowTopOfPile, val)}
          />
        );
        //get depth TO TOP OF PILE
        //get desired time of year
        //get desired location
        //send the request and get the response
      },
      "Starting Ground Temperature Predictions",
      ""
    );
  }
  //set the response value to state
  function setGTPResult(depthBelowTopOfPile: 0 | 5 | 10, result: numUn) {
    setGround((prev) => {
      if (
        !prev ||
        !prev.groundTemperature10mBelowTopOfPile ||
        !prev.groundTemperature5mBelowTopOfPile ||
        !prev.groundTemperatureAtTopOfPile
      )
        return prev;
      const clone = { ...prev };
      switch (depthBelowTopOfPile) {
        case 0:
          clone.groundTemperatureAtTopOfPile!.inputValue = result;
          break;
        case 5:
          clone.groundTemperature5mBelowTopOfPile!.inputValue = result;
          break;
        case 10:
          clone.groundTemperature10mBelowTopOfPile!.inputValue = result;
          break;
        default:
          break;
      }
      return clone;
    });
  }
  async function startBulkPrediction(dto: BulkPredictionDTO, csv: File) {
    //console.log("starting bulk prediction, do async action ...");
    if (!gc?.apiToken) {
      throw new Error("trying to calculate without API token");
    }
    //TODO add numberOfActivePilesInTheArray
    localStorage.setItem(config.currentBulkPredNameLocalStorageKey, dto.name);
    //console.log("sending request to start bulk prediction");
    let bpInterval: any = null;
    const dtoClone: BulkPredictionDTO = {
      ...dto,
      numberOfActivePilesInTheArray:
        pileArray?.numberOfActivePilesInTheArray?.inputValue || 1,
      flowChangeBeforeArray:
        pileInFluid?.flowChangeBeforeArray?.inputValue || 0,
      averageInterPileFlowChangeInflow:
        pileInFluid?.averageInterPileFlowChangeInflow?.inputValue || 0,
    };
    const startPredictionResponse = await requestStartBulkPrediction(
      dtoClone,
      gc?.apiToken
    );
    if (
      startPredictionResponse === "Sucessfully set the study configuration."
    ) {
      const addCsvResponse = await addCsvToBulkPrediction(
        csv,
        dtoClone.name,
        gc?.apiToken
      );
      if (
        addCsvResponse ==
        "Successfully recieved the .csv file and began the bulk prediction calculation."
      ) {
        //set up loading screen
        const messageId = messageHandler.addMessage!(
          createLoadingMessageWithProgress(
            0,
            "Processing Bulk Prediction: " + dto.name,
            ""
          )
        );
        localStorage.setItem(
          config.currentBulkPredMessageIdStorageKey,
          messageId.toString()
        );
        // gc.setLoadingState(true);
        //console.log("starting bulk prediction check interval", messageId);
        bpInterval = setInterval(() => {
          //console.log("should be sending off status check soon");
          checkCurrentBulkPredictionStatusAndHandleResults(() => {
            clearInterval(bpInterval);
          });
        }, 3000);
        return;
      }
    } else {
      stopAndCleanupCurrentBulkPrediction(
        createNonFatalHttpRequestErrorMessage(
          "Start Bulk Prediction",
          500,
          "Something has gone wrong with the server, and the bulk prediction study was not able to start."
        ),
        () => clearInterval(bpInterval)
      );
    }
  }

  async function stopAndCleanupCurrentBulkPrediction(
    m: Message,
    additionalCallback: () => void
  ) {
    //console.log("Stopping and cleaning up current bulk prediction");
    localStorage.removeItem(config.currentBulkPredNameLocalStorageKey);
    const messageId = localStorage.getItem(
      config.currentBulkPredMessageIdStorageKey
    );
    localStorage.removeItem(config.currentBulkPredMessageIdStorageKey);
    //console.log('got this messageId in stopAndCleanupCurrentBulkPrediction', messageId);
    if (messageId !== undefined && messageId !== null) {
      messageHandler.resolveMessage!(messageId);
    }
    //console.log('adding message in stopAndCleanupCurrentBulkPrediction', m);
    messageHandler.addMessage!(m);
    //TODO send cancellation and cleanup request to backend
    additionalCallback();
  }

  async function checkCurrentBulkPredictionStatusAndHandleResults(
    stopProgressReportCallback: () => void
  ): Promise<void> {
    if (!gc?.apiToken) {
      console.warn("MISSING api token ");
      stopAndCleanupCurrentBulkPrediction(
        createNonFatalValidationErrorMessage(
          "Missing Api Token",
          "Api Token / Password",
          "Does not exist."
        ),
        stopProgressReportCallback
      );
      return;
    }
    const currentSessionName = localStorage.getItem(
      config.currentBulkPredNameLocalStorageKey
    );
    const currentMessageId = localStorage.getItem(
      config.currentBulkPredMessageIdStorageKey
    );
    if (!currentSessionName || currentMessageId == undefined) {
      console.warn(
        "no study name found in localStorage",
        currentSessionName,
        currentMessageId
      );
      stopAndCleanupCurrentBulkPrediction(
        createNonFatalApplicationErrorMessage(
          "Lost Session Name",
          "The saved Bulk Prediction study/session name could not be found.",
          ""
        ),
        stopProgressReportCallback
      );
      return;
    }
    const result = await fetchBulkPrediction(currentSessionName, gc?.apiToken);
    if (result.taskStatus == "Failed") {
      //console.log('bulk study task failure detected');
      stopAndCleanupCurrentBulkPrediction(
        createNonFatalHttpRequestErrorMessage(
          "Bulk Study Failed",
          500,
          result.errorMessages
            ? result.errorMessages[0]
            : "Study failed, reason unknown."
        ),
        stopProgressReportCallback
      );
    } else if (
      (result.isComplete || result.taskStatus === "Finished") &&
      isNotNullOrUndefined(
        localStorage.getItem(config.currentBulkPredNameLocalStorageKey)
      )
    ) {
      //console.log('succesful bulk study task completion detected');
      const studyName = localStorage.getItem(
        config.currentBulkPredNameLocalStorageKey
      );
      setPrevBulkPredictionResult(result);
      setPrevBulkPredictionResultName(studyName || undefined);
      stopAndCleanupCurrentBulkPrediction(
        createHttpRequestSuccessMessage(
          "Bulk Prediction Study Completed",
          "Study completed successfully."
        ),
        () => {
          gc.setPopup(<ResultsPopup bulkPredictionData={result} />);
          generateCsvFromBulkPredictionResults(
            result,
            "GPPS-BPR-" + studyName + ".csv"
          );
          stopProgressReportCallback();
        }
      );
    } else if (result.completionPercentage !== undefined) {
      ////console.log("completion percentage", result.completionPercentage);
      messageHandler.updateMessageProgress!(
        currentMessageId,
        result.completionPercentage
      );
    } else {
      stopAndCleanupCurrentBulkPrediction(
        createNonFatalHttpRequestErrorMessage(
          "Get Bulk Prediction Results",
          500,
          "Unknown Error, Invalid Response Object Received..." +
            JSON.stringify(result.errorMessages)
        ),
        stopProgressReportCallback
      );
    }
  }

  //keep numberOfCsvRows up to date
  useEffect(() => {
    if (!bulkPredictionCsv) {
      setNumberOfBulkCsvRows(undefined);
      return;
    }
    getNumberOfRowsInCsv(bulkPredictionCsv, false, (numRows: any) => {
      setNumberOfBulkCsvRows(numRows);
    });
  }, [bulkPredictionCsv]);

  //keep listOfCSVInputRowAndDatetimes up to date
  useEffect(() => {
    if (!bulkPredictionDto || !numberOfBulkCsvRows) return;
    const newList = [];
    const interval =
      bulkPredictionDto.timestepMagnitude *
      timeUnitsToMilliseconds[bulkPredictionDto.timestepUnit];
    const startTime = bulkPredictionDto.timeAndDateOfFirstRowStart.getTime();
    for (let i = 0; i < numberOfBulkCsvRows; i++) {
      newList.push(new Date(startTime + i * interval));
    }
    setListOfBulkCsvInputRowDatetimes(newList);
  }, [numberOfBulkCsvRows, bulkPredictionDto]);

  //!Delete me
  useEffect(() => {
    //console.log('bulk csv has changed', bulkPredictionCsv);
  }, [bulkPredictionCsv]);

  async function startBulkGTPPrediction(gtpInputs: IGTPInputs[]) {
    //console.log("starting bulk GTP prediction, do async action ...");
    //console.log("sending request to start bulk prediction");
    let bpInterval: any = null;
    const gtpInputsForExternalApi: GTPInputsForExternalAPI[] = gtpInputs.map(
      (x) => ({
        Day: x.day,
        Month: x.month,
        Lat: x.lat,
        Long: x.long,
        Depth: x.predictionDepth,
        Year: 2023,
      })
    );
    const startPredictionJobIdOrMessage =
      await requestStartBulkGTPPredictionAndGetJobId(gtpInputsForExternalApi);
    if (typeof startPredictionJobIdOrMessage === "string") {
      const jobId: string = startPredictionJobIdOrMessage;
      localStorage.setItem(config.currentBulkGTPNameLocalStorageKey, jobId);
      //set up loading screen
      const messageId = messageHandler.addMessage!(
        createLoadingMessageWithProgress(
          0,
          "Processing Bulk GTP Prediction: ID:" + jobId,
          ""
        )
      );
      localStorage.setItem(
        config.currentBulkGTPMessageIdStorageKey,
        messageId.toString()
      );
      //console.log("starting bulk prediction check interval", messageId);
      const secondsToCompletion = gtpInputsForExternalApi.length * 34;
      const completionPercentagePerSecond = (1 / secondsToCompletion) * 100;
      bpInterval = setInterval(() => {
        //console.log("should be sending off status check for bulk GTP soon");
        messageHandler.incrementMessageProgress!(
          messageId,
          completionPercentagePerSecond * 3 //! depends on time interval set in this setInterval
        );
        checkCurrentBulkGTPStatusAndHandleResults(() => {
          clearInterval(bpInterval);
        });
      }, 3000);
      return;
    } else {
      stopAndCleanupCurrentBulkGTP(startPredictionJobIdOrMessage, () =>
        clearInterval(bpInterval)
      );
    }
  }

  async function stopAndCleanupCurrentBulkGTP(
    m: Message,
    additionalCallback: () => void
  ) {
    //console.log("Stopping and cleaning up current bulk prediction");
    localStorage.removeItem(config.currentBulkGTPNameLocalStorageKey);
    const messageId = localStorage.getItem(
      config.currentBulkGTPMessageIdStorageKey
    );
    localStorage.removeItem(config.currentBulkGTPMessageIdStorageKey);
    //console.log('got this messageId in stopAndCleanupCurrentBulkGTP', messageId);
    if (messageId !== undefined && messageId !== null) {
      messageHandler.resolveMessage!(messageId);
    }
    ////console.log('adding message in stopAndCleanupCurrentBulkGTP', m);
    messageHandler.addMessage!(m);
    //TODO send cancellation and cleanup request to backend
    additionalCallback();
  }

  async function checkCurrentBulkGTPStatusAndHandleResults(
    stopProgressReportCallback: () => void
  ): Promise<void> {
    console.log("checking current bulk GTP status and handling results");
    //console.log('checking current GTP and handling results');
    const currentSessionName = localStorage.getItem(
      config.currentBulkGTPNameLocalStorageKey
    );
    const currentMessageId = localStorage.getItem(
      config.currentBulkGTPMessageIdStorageKey
    );
    if (!currentSessionName || currentMessageId === undefined) {
      console.warn(
        "no bulk GTP prediction ID found in localStorage",
        currentSessionName,
        currentMessageId
      );
      stopAndCleanupCurrentBulkGTP(
        createNonFatalApplicationErrorMessage(
          "Lost Bulk GTP Task ID",
          "The saved Bulk GTP Prediction ID could not be found.",
          ""
        ),
        stopProgressReportCallback
      );
      return;
    }
    if (!bulkGTPQueue || !listOfBulkCsvInputRowDatetimes) {
      stopAndCleanupCurrentBulkGTP(
        createNonFatalApplicationErrorMessage(
          "Bulk GTP Setup Variables Missing",
          "Missing bulkGTPQueue or listOfBulkCsvInputRowDatetimes.",
          "These variables are required in order to match gtp results with their corresponding inputs."
        ),
        stopProgressReportCallback
      );
      return;
    }
    const result: number | SingleResponseSchema[] | Message =
      await checkBulkGTPPredictionStatusAndGetProgressResultOrError(
        currentSessionName
      );
    if (result instanceof Message) {
      stopAndCleanupCurrentBulkGTP(result, stopProgressReportCallback);
    } else if (
      typeof result !== "number" &&
      Array.isArray(result) &&
      result.length > 0 &&
      isNotNullOrUndefined(
        localStorage.getItem(config.currentBulkGTPNameLocalStorageKey)
      )
    ) {
      //console.log('succesful bulk GTP task completion detected');
      stopAndCleanupCurrentBulkGTP(
        createHttpRequestSuccessMessage(
          "Bulk GTP Predictions Completed",
          "Ground temperature predictions completed successfully.",
          "Copy the required columns from the csv that you have just downloaded into your study's input csv."
        ),
        () => {
          processBulkGTPResultsAndDownloadCSV(
            result,
            bulkGTPQueue,
            listOfBulkCsvInputRowDatetimes
          );
          stopProgressReportCallback();
        }
      );
    } else if (typeof result === "number") {
      //do nothing because right now GTP server does not return accurate completion percentages
      ////console.log("completion percentage", result.completionPercentage);
    } else {
      throw new Error(
        "The result object retrieved from check/retrieve GTP Bulk result was not recognized"
      );
    }
  }

  //! for bulk GTP Prediction

  useInterval(
    () => {
      //console.log('running useInterval');
      if (runningGTPPredictionConfirmId === undefined) {
        setRunningGTPPredictionFailures((prev) => prev + 1);
        return;
      }
      const thisMessage = messages.map[runningGTPPredictionConfirmId];
      //console.log('bulk gtp interval running', thisMessage);
      if (runningGTPPredictionFailures > 100) {
        setRunningGTPPredictionFailures(0);
        setRunningGTPPredictionConfirmId(undefined);
      } else if (!thisMessage || !bulkGTPQueue) {
        //console.log('thisMessage or bulkGTPQueue empty', thisMessage, bulkGTPQueue)
        setRunningGTPPredictionFailures((prev) => prev + 1);
        return;
      } else if (thisMessage.messageResolved === "handledByUserCancel") {
        //dont do anything, simply return and allow user to adjust
        setRunningGTPPredictionConfirmId(undefined);
      } else if (thisMessage.messageResolved === "handledByUserCanMoveOn") {
        //console.log('bulk gtp interval running and about to send off request')
        //user confirms they are ready for GTP results, go get them
        startBulkGTPPrediction(bulkGTPQueue);
        //! everything else in the GTP process will be handled by the above function
        setRunningGTPPredictionConfirmId(undefined);
      } else {
        // do nothing, waiting for user input
      }
    },
    runningGTPPredictionConfirmId !== undefined ? 500 : null
  );

  return (
    <BackendAPIContext.Provider
      value={{
        calculator: calculator,
        calculators: calculators,
        setCalculator: setCalculator,
        setCalculators: setCalculators,
        ground: ground,
        grounds: grounds,
        setGround: setGround,
        setGrounds: setGrounds,
        climate: climate,
        climates: climates,
        setClimates: setClimates,
        setClimate: setClimate,
        heatPump: heatPump,
        heatPumps: heatPumps,
        setHeatPumps: setHeatPumps,
        setHeatPump: setHeatPump,
        pileArray: pileArray,
        pileArrays: pileArrays,
        setPileArrays: setPileArrays,
        setPileArray: setPileArray,
        pileInFluid: pileInFluid,
        pileInFluids: pileInFluids,
        setPileInFluids: setPileInFluids,
        setPileInFluid: setPileInFluid,
        pileOutFluid: pileOutFluid,
        pileOutFluids: pileOutFluids,
        setPileOutFluids: setPileOutFluids,
        setPileOutFluid: setPileOutFluid,
        steelPile: steelPile,
        steelPiles: steelPiles,
        setSteelPiles: setSteelPiles,
        setSteelPile: setSteelPile,
        plasticPipe: plasticPipe,
        plasticPipes: plasticPipes,
        setPlasticPipes: setPlasticPipes,
        setPlasticPipe: setPlasticPipe,
        saveCalculator: saveCalculator,
        updateCalculator: updateCalculator,
        deleteCalculator: deleteCalculator,
        loadCalculator: loadCalculator,
        saveModel: saveModel,
        updateModel: updateModel,
        deleteModel: deleteModel,
        getModel: getModel,
        refreshModelsLists: updateStateWithAllModelsFromApiExceptMLModels,
        refreshMLModelsLists: updateStateWithMLModelsFromApi,
        steelPileNominalSizes: steelPileNominalSizes,
        plasticPipeNominalSizes: plasticPipeNominalSizes,
        setSteelPileNominalSizes: setSteelPileNominalSizes,
        setPlasticPipeNominalSizes: setPlasticPipeNominalSizes,
        steelPileNominalSize: steelPileNominalSize,
        setSteelPileNominalSize: setSteelPileNominalSize,
        plasticPipeNominalSize: plasticPipeNominalSize,
        setPlasticPipeNominalSize: setPlasticPipeNominalSize,
        getPlasticPipeNominalSizeNames: getPlasticPipeNominalSizeNames,
        getSteelPileNominalSizeNames: getSteelPileNominalSizeNames,
        pileOutletFluidTemperatureMLModels: pileOutletFluidTemperatureMLModels,
        groundTemperatureMLModels: groundTemperatureMLModels,
        pileLengthMLModels: pileLengthMLModels,
        setMLModelAsActive: setMLModelAsActive,
        activePileOutletFluidTemperatureMLModel:
          activePileOutletFluidTemperatureMLModel,
        activePileLengthMLModel: activePileLengthMLModel,
        activeGroundTemperatureMLModel: activeGroundTemperatureMLModel,
        saveFile: saveFile,
        getCurrentCalculator: getCurrentCalculator,
        getCalculationResult: getCalculationResult,
        getPlasticPipeDiametersFromName: getPlasticPipeDiametersFromName,
        getSteelPileDiametersFromName: getSteelPileDiametersFromName,
        startGTPPrediction: startGTPPrediction,
        gtpPileDepth: gtpPileDepth,
        gtpDay: gtpDay,
        gtpLat: gtpLat,
        gtpLong: gtpLong,
        gtpMonth: gtpMonth,
        setGTPDay: setGTPDay,
        setGTPLat: setGTPLat,
        setGTPLong: setGTPLong,
        setGTPMonth: setGTPMonth,
        setGTPPileDepth: setGTPPileDepth,
        startBulkPrediction: startBulkPrediction,
        checkCurrentBulkPredictionStatusAndHandleResults:
          checkCurrentBulkPredictionStatusAndHandleResults,
        bulkPredictionDto: bulkPredictionDto,
        setBulkPredictionDto: setBulkPredictionDto,
        bulkPredictionCsv: bulkPredictionCsv,
        setBulkPredictionCsv: setBulkPredictionCsv,
        startBulkGTPPrediction: startBulkGTPPrediction,
        bulkGTPQueue: bulkGTPQueue,
        setBulkGTPQueue: setBulkGTPQueue,
        listOfBulkCsvInputRowDatetimes: listOfBulkCsvInputRowDatetimes,
        setListOfBulkCsvInputRowDatetimes: setListOfBulkCsvInputRowDatetimes,
        gtpBulkPredictionDepthCategoriesToGet: gtpBulkDepthCategoriesToGet,
        setGTPBulkPredictionDepthCategoriesToGet:
          setGTPBulkDepthCategoriesToGet,
        runningGTPPredictionConfirmId: runningGTPPredictionConfirmId,
        setRunningGTPPredictionConfirmId: setRunningGTPPredictionConfirmId,
        prevBulkPredictionResult: prevBulkPredictionResult,
        prevBulkPredictionResultName: prevBulkPredictionResultName,
      }}
    >
      {children}
    </BackendAPIContext.Provider>
  );
};
export default BackendAPIProvider;
