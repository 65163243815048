import YearlessDatePicker from "components/Reusables/YearlessDatePicker";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
// import styles from "../styles/InputSection.module.scss";

export type Months =
  | "January"
  | "February"
  | "March"
  | "April"
  | "May"
  | "June"
  | "July"
  | "August"
  | "September"
  | "October"
  | "November"
  | "December";
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const daysPerMonth: { [month in Months]: number } = {
  January: 31,
  February: 28,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};
interface DateInputProps {
  day: number | undefined;
  month: Months | undefined;
  setDay: any;
  setMonth: any;
}
const DateInput: React.FunctionComponent<DateInputProps> = ({
  day,
  month,
  setDay,
  setMonth,
}) => {
  return (
    <>
     
      <div >
        <YearlessDatePicker
          day={day}
          setDay={setDay}
          month={month}
          setMonth={setMonth}
        />
      </div>
    </>
  );
};
export default DateInput;
