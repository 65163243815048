import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import ReportTemplate, { GreenTitle } from "./Template";

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-white);
  width: 100%;
  margin: 0;
  padding: 0;
`;
const LeftTop = styled.div`
  margin: 0;
  padding: 0;
`;
const RightTop = styled.div`
  font-size: var(--ss-4);
  padding-left: var(--ss-9);
  padding-right: 0;
  padding-block: 0;
  margin: 0;
`;
// const BlackTitle = styled.h3`
//     color: var(--c-black);
//     font-size: var(--ss-6);
//     margin-block: var(--ss-4);
//     padding: 0;
// `;
// const GreenTitle = styled.h3`
//     color: var(--c-green);
//     font-size: var(--ss-6);
//     margin-block: var(--ss-4);
//     padding:0;
// `;
const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;
const DiagramArea = styled.h5`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: var(--ss-4);
  text-align: justify;
`;
const Diagram = styled.img`
  width: 100%;
`;
const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: var(--ss-5);
`;
const LegendArea = styled.div`
  margin: 0;
  padding: 0;
`;
const Legend = styled.img`
  width: 100%;
`;
const BottomDisclaimer = styled.div`
  margin: 0;
  padding: 0;
  font-size: var(--ss-4);
`;
const GreenDivider = styled.div`
  margin-block: var(--ss-4);
  margin-inline: 0;
  border-radius: 0;
  padding: 0;
  height: var(--ss-2);
  width: var(--ss-10);
  background: var(--c-green-very-light);
`;

interface DisclaimerReportProps {
  id: any;
}
const DisclaimerReport: React.FunctionComponent<DisclaimerReportProps> = ({
  id,
}) => {
  return (
    <ReportTemplate id={id} xPosition={-9999} yPosition={0}>
      <Container>
        <TopArea>
          <GreenTitle>Disclaimer</GreenTitle>
        </TopArea>
        <GreenDivider></GreenDivider>
        <DiagramArea>
          <div>
            The developers of this software are not responsible for losses or
            outcomes from the use of this software. This report is not an
            engineering document, and may not be used as an engineering document
            without the express consent, validation, and seal of a professional
            engineer licensed under the governing body of the region of use. The
            users of this software understand that the developers of this
            software are not providing engineering services through the
            development of this software.
          </div>
          <div>
            This document was produced by the Geo-Pile Prediction software and
            any use if this document also falls under the terms and conditions
            of that software (can be found in the login page).
          </div>
          <SubTitle>References </SubTitle>
          <div>
            This version of the software was created by Sarah R. Nicholson,
            Sheldon (Firth) Nicholson, and Rony Shohet for Innovia GEO Corp.
          </div>
          <div>
            This software utilizes research published in the following
            documents:
            <br/>
            <p>
              SR Nicholson, LR Kober, P Atefrad, A Mwesigye, SB Dworkin, “The
              influence of geometry on the performance of a helical steel pile
              as a geo-exchange system,” Renewable Energy, 2021.
            </p>
            <br/>
            <p>
              SR Nicholson, “Characterization of a novel in-ground heat
              exchanger for applications in sustainable building energy and
              maintaining permafrost,” Ryerson University, 2020.
            </p>
            <br/>
            <p>
              SR Nicholson, A Mwesigye, SB Dworkin, “Modelling and optimization
              of helical steel piles as in-ground heat exchangers for
              ground-source heat pumps,” IOP Conference Series: Materials
              Science and Engineering 609 (5), 2019.
            </p>
          </div>
        </DiagramArea>
      </Container>
    </ReportTemplate>
  );
};
export default DisclaimerReport;
