import React, {useState, useEffect, useCallback, useContext} from 'react';
import { useHistory } from 'react-router';
import { GlobalContext } from './Global';

//define types here
interface PageContextProps {

}

//initialize state structure here
export const PageContext = React.createContext<Partial<PageContextProps>>({});


const PageProvider: React.FunctionComponent =({children}) =>{

    const gc = useContext(GlobalContext);
    const history = useHistory();
 //whenever there is no apiToken, show the login page
 useEffect(()=>{
    if (!gc?.apiToken) {
       //console.log('not logged in, going to login page');
        history.push('/login');
        // setPopup(<Login/>);
    } if (gc?.apiToken && history.location.pathname === '/login'){
        history.push('/');
        // setPopup(undefined);
    }
},[gc?.apiToken, history]);



return (
<PageContext.Provider
value={{

}}
>
{children}
</PageContext.Provider>
);
}
export default PageProvider;
