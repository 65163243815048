import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
  } from 'react';
  import { ReusableComponentBase, StyleOverride, NumberInput, twParse, useClassNameManager, GeneralInput} from 'sheldons-components';
  import _ from 'lodash';
import { ObjectCrudBase, OfficialVariables } from 'lib/types';
import EditFormGenerator from './EditFormGenerator';
  
  const DefaultTw = {
    main: twParse``,
  };
  
  
  interface GeneralEditFormProps extends ObjectCrudBase{
    emptyObject: any
    fieldsToEmphasize?: string[]
    fieldsToDisable?: string[]
    universalDisabledMessage?: string
    imageLinkMap: {[fieldName: string]:string}
    fieldsInOrder: OfficialVariables[]
  }
  
  const GeneralEditForm: React.FunctionComponent<GeneralEditFormProps> = ({
    children,
    styles,
    object,
    instructionKeyMap,
    setObject,
    fieldsToExclude,
    fieldsToEmphasize,
    fieldsToDisable,
    emptyObject,
    universalDisabledMessage,
    imageLinkMap,
    fieldsInOrder
  }) => {
  
    return (
    <form >
        <EditFormGenerator
        fieldsToExclude={fieldsToExclude}
        object={object || emptyObject}
        setObject={setObject}
        instructionKeyMap={instructionKeyMap}
        fieldsToDisable={fieldsToDisable}
        fieldsToEmphasize={fieldsToEmphasize}
        universalDisabledMessage={universalDisabledMessage}
        imageLinkMap={imageLinkMap}
        fieldsInOrder={fieldsInOrder}
        />
    </form>
    );
  };
  export default GeneralEditForm;
  