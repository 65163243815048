import { getPercentage } from "lib/helper-functions";
import { ICalculator } from "lib/models";
import { IGroundTemperatureResults } from "lib/models/calculation-results";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import ReportTemplate, { getConvertedText, H1, H4 } from "../Template";
import { convertFlowRateToLPM } from "lib/units";
import DetailsPageTemplate, {DetailsTablePropsData} from "./Template";
import { readableVariable, readableVariableNoAbrev } from "lib/types";
import { BackendAPIContext } from "components/Contexts/BackendAPI";


interface GroundTemperatureDetailsProps {
  id: any;
  sessions: IGroundTemperatureResults[];
  imperial: boolean
}
const GroundTemperatureDetails: React.FunctionComponent<GroundTemperatureDetailsProps> = ({
  id,
  sessions,
  imperial,
}) => {
 const api = useContext(BackendAPIContext);
 

  return (
    <DetailsPageTemplate
      id={id}
      tables={[
        {
            title: 'Soil and Site Details',
            variableNames: [readableVariable.maxGroundThermalConductivity,
                readableVariable.minGroundThermalConductivity
            ],
            data: sessions.map((session): DetailsTablePropsData => {
                return {
                    sessionName: session.calculator.name||'',
                    valuesInOrder: [
                        session.calculator.ground?.maxGroundThermalConductivity?.inputValue+' W/mK',
                        session.calculator.ground?.minGroundThermalConductivity?.inputValue+' W/mK'
                    ]
                }
            })
        },
        {
            title: 'Plastic Pipe',
            variableNames: [
                readableVariable.plasticPipeNominalSize,
                readableVariable.plasticPipeInnerDiameter, 
                readableVariable.plasticPipeOuterDiameter],
            data: sessions.map(session => {
               
                const nomSizeName = session.calculator.plasticPipe?.plasticPipeNominalSizeForClient?.inputValue ||''
                const diams = api?.getPlasticPipeDiametersFromName(nomSizeName);
                return {
                    sessionName: session.calculator.name ||'',
                    valuesInOrder: [
                        nomSizeName,
                        getConvertedText(imperial,(diams?.innerDiameter||0)*100.00,'cm'),
                        getConvertedText(imperial, (diams?.outerDiameter||0)*100.00, 'cm')
                    ]
                }
            })
        },
        {
            title: 'Steel Pile',
            variableNames: [
                readableVariable.steelPileNominalSize,
                readableVariable.steelPileInnerDiameter,
                readableVariable.steelPileOuterDiameter
            ],
            data: sessions.map(session => {
                const nomSizeName = session.calculator.steelPile?.steelPileNominalSizeForClient?.inputValue ||''
                const diams = api?.getSteelPileDiametersFromName(nomSizeName);
                return {
                    sessionName: session.calculator.name ||'',
                    valuesInOrder: [
                        nomSizeName,
                        getConvertedText(imperial,(diams?.innerDiameter||0)*100.0,'cm'),
                        getConvertedText(imperial, (diams?.outerDiameter||0)*100.0, 'cm')
                    ]
                }
            })
        },
        {
            title: 'Temperature Changes',
            variableNames: [
                readableVariable.pileInletFluidTemperature,
                readableVariable.pileOutletFluidTemperature], 
            data: sessions.map(session => {
                return {
                    sessionName: session.calculator.name ||'',
                    valuesInOrder: [
                        getConvertedText(imperial,session.pileInletFluidTemperature,'degCEnv'),
                        getConvertedText(imperial, session.pileOutletFluidTemperature, 'degCEnv')
                    ]
                }
            })
        },
      ]}
    />
  );
};
export default GroundTemperatureDetails;
