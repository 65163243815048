import Papa from "papaparse";

export function getNumberOfRowsInCsv(csv: File, includeHeaderRowInCount: boolean, giveResultsCallback: (results: number)=>void){
    Papa.parse(csv, {
        header: true,
        dynamicTyping: true, //!maybe no?
        skipEmptyLines: true,
        complete: function (results: {data:{[colName: string]:string}[]}) {
            giveResultsCallback(includeHeaderRowInCount?results.data.length+1:results.data.length);
        },
      });
}