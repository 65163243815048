
import { BackendAPIContext } from 'components/Contexts/BackendAPI';
import { GlobalContext } from 'components/Contexts/Global';
import InfoPopup from 'components/Popups/InfoPopup';
import NumberInput from 'components/Reusables/NumberInput';
import ObjectCrud from 'components/Reusables/ObjectCrud';
import Select from 'components/Reusables/Select';
import { createEmpty } from 'lib/helper-functions';
import { generateObjCrudProps, getModelNameTypes, standardImageLinkMaps } from 'lib/objectCrudPropsHelpers';
import React, {useState, useEffect, useContext, useCallback, useRef, SetStateAction} from 'react';
import styled from 'styled-components';
import SectionContainer from './SectionContainer';
import { BasicCols, InfoButton, InputInstr } from './Styles';

const TopSpacingDiv = styled.div`
  margin: var(--s-10);
`;
interface StaticVariableInputSectionProps{
    withHeatPump: boolean | undefined
    maxFluidTemperature: number | undefined
    setMaxFluidTemperature: React.Dispatch<SetStateAction<number|undefined>>
    minFluidTemperature: number | undefined
    setMinFluidTemperature: React.Dispatch<SetStateAction<number|undefined>>

}
const StaticVariableInputSection: React.FunctionComponent<StaticVariableInputSectionProps> =({
    withHeatPump, maxFluidTemperature, minFluidTemperature, setMaxFluidTemperature, setMinFluidTemperature

})=> {
    const api = useContext(BackendAPIContext);
    const gc = useContext(GlobalContext);
    if (!api){
        return <></>;
    }
return (
<SectionContainer
    title="6. Define Constant Variables"
>
               
                <BasicCols>
            <InputInstr>
              Define the MAXIMUM fluid temperature of the system.
            </InputInstr>
            <NumberInput
              value={maxFluidTemperature}
              setValue={setMaxFluidTemperature}
              outputUnit={'degC'}
            />
            <InfoButton
              onClick={() =>
                gc?.setPopup(<InfoPopup imageLink={"Tin Limits INFO.jpg"} />)
              }
            ></InfoButton>
          </BasicCols> 
          <BasicCols>
            <InputInstr>
              Define the MINIMUM fluid temperature of the system.
            </InputInstr>
            <NumberInput
              value={minFluidTemperature}
              setValue={setMinFluidTemperature}
              outputUnit={'degC'}
            />
            <InfoButton
              onClick={() =>
                gc?.setPopup(<InfoPopup imageLink={"Tin Limits INFO.jpg"} />)
              }
            ></InfoButton>
          </BasicCols> 
          <TopSpacingDiv></TopSpacingDiv>

                <ObjectCrud {...generateObjCrudProps("SteelPiles", api)} />
                <ObjectCrud {...generateObjCrudProps("PlasticPipes", api)} />
                <ObjectCrud
                  title="Pile Array"
                  emptyObject={createEmpty["pileArray"]()}
                  instructionKeyMap={{}}
                  fieldsToExclude={[
                    "perPileHeatPumpCapacity",
                    "targetWholeArrayHeatPumpCapacity",
                    "targetGeoPileThermalCapacity",
                  ]}
                  modelEndpoint={"PileArrays"}
                  hideCrud={false}
                  names={getModelNameTypes("PileArrays")}
                  imageLinkMap={standardImageLinkMaps.pileArray}
                  fieldsInOrder={["numberOfActivePilesInTheArray"]}
                />
                <ObjectCrud
                  title="Pile In Fluid"
                  emptyObject={createEmpty["pileInFluid"]()}
                  instructionKeyMap={{}}
                  fieldsToExclude={[
                    "averageInterPileTemperatureChangeInflow",
                    "temperatureChangeBeforeArray",
                    "pileInletFlowRate",
                    "pileInletFluidTemperature",
                    "arrayPumpOutletFlowRate",
                  ]}
                  modelEndpoint={"PileInFluids"}
                  hideCrud={false}
                  names={getModelNameTypes("PileInFluids")}
                  imageLinkMap={standardImageLinkMaps.pileInFluid}
                  fieldsInOrder={[
                    "flowChangeBeforeArray",
                    "averageInterPileFlowChangeInflow",
                  ]}
                />
                {withHeatPump?
                <ObjectCrud {...generateObjCrudProps("HeatPumps", api)} />
                :<></>}
              </SectionContainer>
);
}
export default StaticVariableInputSection;
