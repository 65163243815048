import { GlobalContext } from 'components/Contexts/Global';
import Popup from 'components/Reusables/Popup';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* width: var(--s-23); */
    background: var(--c-white);
    overflow: auto;
    max-height: 99%;
`;
const Image = styled.img`
    /* width: 100%; */
    height:70vh;
`;
interface InfoPopupProps{
    imageLink: string
}
const InfoPopup: React.FunctionComponent<InfoPopupProps> =({imageLink})=> {
const gc = useContext(GlobalContext);
return (
<Popup
    title="More Information"
    closeCallback={()=>gc?.setPopup(undefined)}
>
    <Container>
        {imageLink?
        <Image src={imageLink} alt="infopic"/>

        :<></>}
    </Container>
</Popup>
);
}
export default InfoPopup;
