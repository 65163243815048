import { GlobalContext } from "components/Contexts/Global";
import BulkPredictionProgressBar from "components/Popups/BulkPredictionProgressBar";
import { OutputVariables } from "lib/models";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: var(--s-15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
const IMG = styled.img`
  width: var(--s-20);
  height: var(--s-20);
`;

interface LoadingAnimationProps {}
const LoadingAnimation: React.FunctionComponent<LoadingAnimationProps> =
  ({}) => {
    // const location = useLocation();
      return (
        <Container>
          <IMG src="animated-logo-icon-slow.gif" alt="loading" />
        </Container>
      );
}
export default LoadingAnimation;
