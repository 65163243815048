import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import Button from "components/Reusables/Button";
import { Message, MessageResponse } from "lib/ErrorAndMessageHandling/MessageHandler";
import { usePrevious } from "lib/usePrevious";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import styled from "styled-components";
import LoadingAnimation from "./LoadingAnimation";
import ProgressDisplayer from "./ProgressDisplayer";


const BlockingContainer = styled.div`
  margin: 0;
  padding: var(--s-14);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background: radial-gradient(var(--c-white), var(--c-light-transparent));
  backdrop-filter: var(--c-blur);
  font-size: var(--f-6);
  color: var(--c-black);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.h2<{color: string}>`
  color: ${props => props.color};
`;
const Summary = styled.div`
  font-size: var(--fs-7);
`;
const AdditionalInfo = styled.div`

`;

interface WholeAppMessageOverlayProps {
  closeCallback: (mr?: MessageResponse) => void;
  messageId: string
}
const WholeAppMessageOverlay: React.FunctionComponent<WholeAppMessageOverlayProps> =
  ({ closeCallback, messageId }) => {
      const messages = useSelector((state: RootState)=>state.messages.map);


    //double check to ensure this component closes when it is supposed to
    useEffect(()=>{
        ////console.log('updated message or close callback changed', updatedMessage)
        if(messages[messageId] && messages[messageId].messageResolved){
           //console.log('should be calling close callback', messages[messageId], );
            closeCallback();
        }
    },[closeCallback, messages, messageId]);


    function handleUserActionClick(contOrCanc: 'continue' | 'cancel'| 'restartApp'){
        if (messages[messageId] && messages[messageId].userAllowedActionType === 'continue' && contOrCanc === 'continue'){
            closeCallback('handledByUserCanMoveOn');
        }else if (contOrCanc === 'cancel'){
            closeCallback('handledByUserCancel');
        }else if (contOrCanc === 'continue'){
          closeCallback('handledByUserCanMoveOn');
        }else if (contOrCanc === 'restartApp'){
            closeCallback('handledByUserRestartApp');
        } else {
          closeCallback('failedToBeHandledByUser');
        }
    }

    if (messages[messageId] && messages[messageId].userAllowedActionType === 'none' && !messages[messageId].updateable){
        throw new Error(
          'A blocking message has been received that is unresolvable. Action type is "none", its blocking, and non updateable... '
          + JSON.stringify(messages[messageId]));
    }

    if(! messages[messageId]) {
     //console.log('messages[messageId] does not exist, cannot display blocking error UI', messages.map, messageId);
      return <></>;
    }
    return (
      <BlockingContainer>
        <Title
          color = {
            (messages[messageId].type == 'application error' || messages[messageId].type == 'user error' || messages[messageId].type == 'validation error')?
            `var(--c-red);`
            :
            `var(--c-green);`
          }
        >{messages[messageId].contentForDisplay.title}</Title>
        {messages[messageId].contentForDisplay.summary ? (
          <Summary>{messages[messageId].contentForDisplay.summary}</Summary>
        ) : (
          <></>
        )}
        {messages[messageId].contentForDisplay.additionalInfo ? (
          <AdditionalInfo>{messages[messageId].contentForDisplay.additionalInfo}</AdditionalInfo>
        ) : (
          <></>
        )}
        {messages[messageId].updateable &&
        messages[messageId].contentForDisplay.numericProgress != undefined ? (
          <ProgressDisplayer
            progress={messages[messageId].contentForDisplay.numericProgress||0}
          />
        ) : (
          <></>
        )}
        {(messages[messageId].type === 'progress report') && !messages[messageId].updateable?
        <LoadingAnimation/>  
        :<></>
        }
        { messages[messageId].userAllowedActionType === 'continue' || messages[messageId].userAllowedActionType === 'continueOrCancel' ?
            <Button  onClick={()=>handleUserActionClick('continue')}>
                Continue
                </Button>
        :<></>}
        {
          messages[messageId].userAllowedActionType === 'restartApp'?
          <Button onClick={()=>handleUserActionClick('restartApp')}>
            Restart Application
          </Button>
          :<></>
        }
        {
          messages[messageId].userAllowedActionType === 'continueOrCancel'?
          <Button onClick={()=>handleUserActionClick('cancel')}>
            Cancel
          </Button>
          :<></>
        }
      </BlockingContainer>
    );
  };
export default WholeAppMessageOverlay;
