import { getPercentage } from 'lib/helper-functions';
import { ISteelPile } from 'lib/models';
import { IGroundTemperatureResults } from 'lib/models/calculation-results';
import { IPlasticPipeNominalSize } from 'lib/models/plastic-pipe-nominal-size';
import { ISteelPileNominalSize } from 'lib/models/steel-pipe-nominal-size';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import GenerateReportForm from './Reusables/GenerateReportForm';
import ResultsViewTemplate, { IResultsVariable}from './Reusables/Template';


interface GroundTemperatureResultsProps{
    data: IGroundTemperatureResults
}
const GroundTemperatureResults: React.FunctionComponent<GroundTemperatureResultsProps> =({
    data
})=> {

const resultsVariables: IResultsVariable[] = [
    {
        label: "Ground Temperature 10m Below Top of Pile",
        value: data.groundTemperature10mBelowTopOfPile,
        type: 'static',
        unit: 'degC',
        decimalPlaces: 1,
        column: 'left',
        orderWithinColumn: 0
    },
    {
        label: "Change in Fluid Temperature Across Pile",
        value: data.changeInTemperatureAcrossPile,
        type: 'static',
        unit: 'degC',
        decimalPlaces: 3,
        column:'right',
        orderWithinColumn: 0
    },
    {
        label: "Ground Thermal Potential",
        value: data.averageGeoPileThermalCapacity,
        type: 'static',
        unit: 'W',
        decimalPlaces: 2,
        column: 'left',
        orderWithinColumn: 1
    },
];

return (
<ResultsViewTemplate
    resultsVariables={resultsVariables}
    sessions={[data]}
/>
);
}
export default GroundTemperatureResults;