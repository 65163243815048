import { GlobalContext } from "components/Contexts/Global";
import { HorizFlexMin, VertFlexMin } from "components/MainMenu";
import GTPBulkPredictions from "components/Popups/GTPBulkPredictions";
import InfoPopup from "components/Popups/InfoPopup";
import LocationInput from "components/Popups/LocationInput";
import Button from "components/Reusables/Button";
import CheckBox from "components/Reusables/CheckBox";
import NumberInput from "components/Reusables/NumberInput";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import SectionContainer from "./SectionContainer";
import {
  BasicCols,
  BottomBar,
  GTPLogo,
  InfoButton,
  InnerCols,
  InnerInput,
  InnerTitleCont,
  InnerTitleText,
  InputInstr,
  LittleDivider,
  LittleInstr,
  LittleLabel,
  LogoSection,
  SecondaryInputInstr,
} from "./Styles";

interface GTPConfigSectionProps {
  tGround0Auto: boolean | undefined;
  setTGround0Auto: any;
  tGround5Auto: boolean | undefined;
  setTGround5Auto: any;
  tGround10Auto: boolean | undefined;
  setTGround10Auto: any;
  lat: number | undefined;
  setLat: any;
  long: number | undefined;
  setLong: any;
  pileTopDepth: number | undefined;
  setPileTopDepth: any;
}
const GTPConfigSection: React.FunctionComponent<GTPConfigSectionProps> = ({
  tGround0Auto,
  tGround10Auto,
  tGround5Auto,
  setLat,
  setLong,
  setPileTopDepth,
  setTGround0Auto,
  setTGround10Auto,
  setTGround5Auto,
  lat,
  long,
  pileTopDepth,
}) => {
  const gc = useContext(GlobalContext);
  return (
    <SectionContainer title="5. Get Automatic Ground Temperature Predictions (Optional)">
      <HorizFlexMin>
        <div>
          You must first upload a valid CSV file and ensure you have correctly filled out "4. Time and Date Configuration".
        </div>
      <Button onClick={()=>{gc?.setPopup(<GTPBulkPredictions closeCallback={()=>gc.setPopup(undefined)} />)}}>
        Get Bulk GTP Predictions
      </Button>
      </HorizFlexMin>
    </SectionContainer>
  );
};
export default GTPConfigSection;
