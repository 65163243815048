import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageUpdateInstructions } from "components/Contexts/MessageHandler";
import { randomInt } from "crypto";
import { stat } from "fs";
import {
  deepCloneMessage,
  Message,
  MessageHandler,
  MessageHandlingCallback,
  MessageResponse,
} from "lib/ErrorAndMessageHandling/MessageHandler";
import { number } from "mathjs";

export interface MessagesState {
  map: { [id: string]: Message };
  currentBlockingMessageId: string;
  lastThreeMessagesIdAndTimestamp: {message: Message, timestamp: number}[];
  idToSendToMessageHandler: string;
}

const initialState: MessagesState = {
  map: {},
  currentBlockingMessageId: '',
  lastThreeMessagesIdAndTimestamp: [],
  idToSendToMessageHandler: '',
};

export const MessagesSlice = createSlice({
  name: "Messages",
  initialState,
  reducers: {
   
    add: (state, action: PayloadAction<{ message: Message; id: string }>) => {
      const message = {...action.payload.message};
      if (!message) return;
      // message.contentForDisplay = {...action.payload.message.contentForDisplay};
      const id = action.payload.id;
      if (message.messageHandlingState === 'beingHandled'){
        throw new Error('You cannot create a new message with the messageHandlingState of "beingHandled"');
      }
      // do something to prevent multiple duplicate messages being received in a short time frame
      const now = Date.now();
      let preventMessageAddition = false;
     //console.log('going to check if should add new message', JSON.stringify(state.lastThreeMessagesIdAndTimestamp));
      state.lastThreeMessagesIdAndTimestamp.forEach(mAndDate=>{
        if (!mAndDate) return;
        const milliDiff = now - mAndDate.timestamp;
        if (milliDiff < 2000){
          const recentM = mAndDate.message;
          if (recentM){
           //console.log('recent M', JSON.stringify(recentM));
            const messageBeingHandledIsTheSameAsNextMessage = (
              JSON.stringify({...message.contentForDisplay, numericProgress: 0}) 
              === JSON.stringify({...recentM.contentForDisplay,numericProgress: 0})
              );
           //console.log('message matches recent message?', 
            // messageBeingHandledIsTheSameAsNextMessage, 
            // JSON.stringify(message.contentForDisplay), 
            // JSON.stringify(recentM.contentForDisplay));
              if (messageBeingHandledIsTheSameAsNextMessage){
                preventMessageAddition = true;
              }
          }else {
            ////console.log('COULD NOT FIND recentM', JSON.stringify(state.map), idAndDate.id);
          }
        }else {
         //console.log('recent message was too old to stop addition of new message');

        }
      })
      //add this message to the last three messages state as well
      state.lastThreeMessagesIdAndTimestamp = [
        {message: message, timestamp: now},
        {...state.lastThreeMessagesIdAndTimestamp[0]},
        {...state.lastThreeMessagesIdAndTimestamp[1]}
      ];
     //console.log('new last 3 messegas = ', state.lastThreeMessagesIdAndTimestamp);
      if (preventMessageAddition){
       //console.log('preventing message addition due to recent duplicates')
        return state
      }else {
        state.map[action.payload.id] = {...action.payload.message};
        return state;
      }
    },
  
    setCurrentMessageBlockingId: (state, action: PayloadAction<string>)=>{
      state.currentBlockingMessageId = action.payload
    },
    updateProgress: (state, action: PayloadAction<{newProgress: number, id: string}>)=> {
      state.map[action.payload.id].contentForDisplay.numericProgress = action.payload.newProgress;
    },
    incrementProgress: (state, action: PayloadAction<{progressToAdd: number, id: string}>)=>{
      const id = action.payload.id;
      state.map[id].contentForDisplay.numericProgress = (state.map[id].contentForDisplay.numericProgress || 0) + action.payload.progressToAdd;
    },
    startHandling: (state, action: PayloadAction<{id:string,shouldForceCloseCurrentBlockingMessage:boolean}>)=>{
     //console.log('startHandling message in redux', action, state);
      const message = state.map[action.payload.id];
      if (!message) return;
      if (state.currentBlockingMessageId && !action.payload.shouldForceCloseCurrentBlockingMessage){
        throw new Error("Cannot start handling this message because there is already a message being handled");
      }
      state.currentBlockingMessageId = action.payload.id;
      message.messageHandlingState = 'beingHandled';
      message.messageResolved = undefined;
      state.idToSendToMessageHandler = action.payload.id;
    },
    resolve: (state, action: PayloadAction<{id:string, resolution: MessageResponse}>)=>{
     //console.log('trying to resolve message in redux',action);
      const message = state.map[action.payload.id];
      if (!message) return state;
      message.messageHandlingState = 'handled';
      message.messageResolved = action.payload.resolution;
      if (action.payload.id === state.currentBlockingMessageId){
        state.currentBlockingMessageId = '';
      }
     //console.log('successfully resolved message in redux');
      return state;
    },
    haveSentToMessageHandler: (state, action: PayloadAction<string>)=>{
      if (state.idToSendToMessageHandler === action.payload){
        state.idToSendToMessageHandler = '';
      }else {
        console.warn('Somehow the messages.idToSendToMessageHandler state is already wrong.');
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const messagesActions = MessagesSlice.actions;

export default MessagesSlice.reducer;
