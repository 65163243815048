import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";

export interface IGround extends IBaseModel {
  groundTemperatureAtTopOfPile?: IUiInputVariableDouble | null;

  groundTemperature5mBelowTopOfPile?: IUiInputVariableDouble | null;

  groundTemperature10mBelowTopOfPile?: IUiInputVariableDouble | null;

  averageGroundThermalConductivity?: IUiInputVariableDouble | null;

  maxGroundThermalConductivity?: IUiInputVariableDouble | null;

  minGroundThermalConductivity?: IUiInputVariableDouble | null;
}
export interface IGroundOut extends IBaseModel {
  groundTemperatureAtTopOfPile?: IUiInputVariableDoubleOut | null;

  groundTemperature5mBelowTopOfPile?: IUiInputVariableDoubleOut | null;

  groundTemperature10mBelowTopOfPile?: IUiInputVariableDoubleOut | null;

  averageGroundThermalConductivity?: IUiInputVariableDoubleOut | null;

  maxGroundThermalConductivity?: IUiInputVariableDoubleOut | null;

  minGroundThermalConductivity?: IUiInputVariableDoubleOut | null;
}
export class Ground extends BaseModel implements IGround{
  public groundTemperatureAtTopOfPile?: IUiInputVariableDouble = new UiInputVariableDouble('groundTemperatureAtTopOfPile');

  public groundTemperature5mBelowTopOfPile?: IUiInputVariableDouble = new UiInputVariableDouble('groundTemperature5mBelowTopOfPile');

  public groundTemperature10mBelowTopOfPile?: IUiInputVariableDouble = new UiInputVariableDouble('groundTemperature10mBelowTopOfPile');

  public averageGroundThermalConductivity?: IUiInputVariableDouble = new UiInputVariableDouble('averageGroundThermalConductivity');

  public maxGroundThermalConductivity?: IUiInputVariableDouble = new UiInputVariableDouble('maxGroundThermalConductivity');

  public minGroundThermalConductivity?: IUiInputVariableDouble = new UiInputVariableDouble('minGroundThermalConductivity');
}

export function groundToDto(base: IGround): IGroundOut{
  return {
    id: base.id,
    name: base.name,
    groundTemperatureAtTopOfPile: convertUiInputDoubleToDto(base.groundTemperatureAtTopOfPile),

  groundTemperature5mBelowTopOfPile: convertUiInputDoubleToDto(base.groundTemperature5mBelowTopOfPile),

  groundTemperature10mBelowTopOfPile: convertUiInputDoubleToDto(base.groundTemperature10mBelowTopOfPile),

  averageGroundThermalConductivity: convertUiInputDoubleToDto(base.averageGroundThermalConductivity),

  maxGroundThermalConductivity: convertUiInputDoubleToDto(base.maxGroundThermalConductivity),

  minGroundThermalConductivity: convertUiInputDoubleToDto(base.minGroundThermalConductivity)
  };
}