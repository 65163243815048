import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import {GeneralInput as Base} from 'sheldons-components';
import { GeneralInputProps } from 'sheldons-components/src/components/inputs/GeneralInput';
import { css } from 'styled-components';

export const GeneralInputStyle = {
    main: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--s-12);
    padding: 0;
    margin: 0;
    `,
    inputGroup: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* margin-inline: var(--s-10); */
    padding: 0;
    margin: 0;
    margin-left: var(--s-5);
    background: var(--c-white);
    `,
    label: css`
    margin-left: var(--s-5);
    margin-right: var(--s-5);
    `,
    input: css`
    :focus{
        outline: none;
    }
    margin: 0;
    height: var(--s-10);
    width: fit-content;
    font-size: var(--fs-4);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    border-radius: var(--light-rounding);
    ::placeholder{
        color: var(--c-green-light);
    }
    `,
    validationMessage: {
        main: css`
        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        font-size: var(--s-7);
        color: var(--c-green);
        /* DISABLED FOR NOW */
        display: none;
        width:0;
        height:0;
        `,
        text: css`
        `,
        icon: css`
        font-size: var(--s-1);
        `
    }
};

const DarkBGGeneralInputStyle = {
    ...GeneralInputStyle,
    label: css` color: var(--c-white);`
};



interface GeneralInputProps2 extends GeneralInputProps{
    darkBackground?: boolean
}
const GeneralInput: React.FunctionComponent<GeneralInputProps2> =(props)=> {
// //console.log('input value is ',props.value)
return (
<Base 
    styles={props.darkBackground?DarkBGGeneralInputStyle:GeneralInputStyle}
    {...props}

/>

);
}
export default GeneralInput;
