import { csvColsType } from "./bulk-prediction-validation";
import { exportToCsv } from "./export-to-csv";
import {
  Calculator,
  Climate,
  Ground,
  HeatPump,
  ICalculator,
  PileArray,
  PileInFluid,
  PileOutFluid,
  PlasticPipe,
  SteelPile,
  UiInputVariableDouble,
  UiInputVariableInt,
  UiInputVariableString,
} from "./models";
import { BulkHeatPumpCapacityResult } from "./models/bulk-prediction-results";
import { isNullishOrNanButNotZero } from "./validation-helpers";

type validCsvCellValue = number | string | Date;

interface CSVRowBase extends inputCols, ExpandedCalculatorData {
  INPUTS_FOR_THIS_TIMESTEP: ">>>>";
  FULL_SYSTEM_CONFIGURATION_FOR_THIS_TIMESTEP: ">>>>";
  RESULTS_FOR_THIS_TIMESTEP: ">>>>";
  datetime: string;
}
interface CSVRowWithHeatPump extends CSVRowBase, CsvOutputsWithHeatPump {}
interface CSVRowWithoutHeatPump extends CSVRowBase, CsvOutputsWithoutHeatPump {}
type inputCols = { [key in csvColsType]: validCsvCellValue };

interface CsvOutputsWithHeatPump {
  averageGeoPileThermalCapacity: number;
  changeInTemperatureAcrossPile: number;
  heatPumpCoefficientOfPerformance: number;
  isCoolingSeasonAtHeatPump: string;
  perPileHeatPumpCapacity: number;
  wholeArrayHeatPumpCapacity: number;
  pileInletFluidTemperature: number;
  pileOutletFluidTemperature: number;
  electricityUsedByHeatPumpPerPile: number;
}
interface CsvOutputsWithoutHeatPump {
  averageGeoPileThermalCapacity: number;
  changeInTemperatureAcrossPile: number;
  pileInletFluidTemperature: number;
  pileOutletFluidTemperature: number;
}

type expandedCalcDataKeys =
  | keyof Climate
  | keyof Ground
  | keyof HeatPump
  | keyof PileArray
  | keyof PileInFluid
  | keyof PileOutFluid
  | keyof PlasticPipe
  | keyof SteelPile;

type ExpandedCalculatorData = Omit<
  { [key in expandedCalcDataKeys]: validCsvCellValue },
  | "perPileHeatPumpCapacity"
  | "pileInletFluidTemperature"
  | "pileOutletFluidTemperature"
>;

//expand all calculator properties into a shallow object
function expandCalculatorIntoShallowObject(
  calc: ICalculator
): ExpandedCalculatorData {
  //@ts-expect-error
  const res: ExpandedCalculatorData = {};
  Object.keys(calc).forEach((topLvlKey) => {
    const subObj = calc[topLvlKey as keyof Calculator];
    if (!subObj) {
      return;
    }
    Object.keys(subObj!).forEach((secLvlKey: any) => {
      //@ts-expect-error
      const subSubObj = subObj[secLvlKey];
      if ( typeof subSubObj === 'object' && subSubObj !== null && subSubObj.inputValue !== undefined
        // subSubObj instanceof UiInputVariableDouble ||
        // subSubObj instanceof UiInputVariableInt ||
        // subSubObj instanceof UiInputVariableString
      ) {
        const relevantValue = subSubObj.inputValue;
        res[secLvlKey as keyof ExpandedCalculatorData]  = JSON.stringify(relevantValue);
      }
    });
  });
  return res;
}

const separatorCellVal = " -- ";
const naCellVal = "NA";

//generate a csv file
//! primary function here

export function generateCsvFromBulkPredictionResults(
  results: BulkHeatPumpCapacityResult,
  fileName: string
) {
  if (results.withHeatPump) {
    const listOfRowDicts: CSVRowWithHeatPump[] = results.perRowResults.map(
      (row, index) => {
        const flattenedCalc: ExpandedCalculatorData =
          expandCalculatorIntoShallowObject(row.calculator);
        const outputs: CsvOutputsWithHeatPump = {
          averageGeoPileThermalCapacity:
            row.withHeatPumpResults.averageGeoPileThermalCapacity,
          changeInTemperatureAcrossPile:
            row.withHeatPumpResults.changeInTemperatureAcrossPile,
          heatPumpCoefficientOfPerformance:
            row.withHeatPumpResults.heatPumpCoefficientOfPerformance,
          isCoolingSeasonAtHeatPump: row.withHeatPumpResults
            .isCoolingSeasonAtHeatPump
            ? "True"
            : "False",
          wholeArrayHeatPumpCapacity: 
            row.withHeatPumpResults.wholeArrayHeatPumpCapacity,
          perPileHeatPumpCapacity:
            row.withHeatPumpResults.perPileHeatPumpCapacity,
          pileInletFluidTemperature:
            row.withHeatPumpResults.pileInletFluidTemperature,
          pileOutletFluidTemperature:
            row.withHeatPumpResults.pileOutletFluidTemperature,
          electricityUsedByHeatPumpPerPile:
            row.withHeatPumpResults.electricityUsedByHeatPumpPerPile
        };
        const rowRes: CSVRowWithHeatPump = {
            INPUTS_FOR_THIS_TIMESTEP: ">>>>",
            FULL_SYSTEM_CONFIGURATION_FOR_THIS_TIMESTEP: ">>>>",
            RESULTS_FOR_THIS_TIMESTEP: ">>>>",
            datetime: row.dateTime,
            ...row.csvRowData,
            // ...flattenedCsvInputs as {[colName in csvColsType]: string},
            ...flattenedCalc,
            ...outputs,
        };
       //console.log('why isnt this working?', row, flattenedCalc, rowRes)
        return rowRes;
      }
    );
     //console.log('about to export to CSV');
    exportToCsv(
      fileName,
      getListOfRowListsFromListOfRowDicts({
        listOfRowDictsWithHeatPump: listOfRowDicts,
        listOfRowDictsWithoutHeatPump: undefined,
      })
    );
  } else if (!results.withHeatPump) {
    const listOfRowDicts: CSVRowWithoutHeatPump[] = results.perRowResults.map(
      (row, index) => {
        const flattenedCalc: ExpandedCalculatorData =
          expandCalculatorIntoShallowObject(row.calculator);
        const outputs: CsvOutputsWithoutHeatPump = {
          averageGeoPileThermalCapacity:
            row.withoutHeatPumpResults.averageGeoPileThermalCapacity,
          changeInTemperatureAcrossPile:
            row.withoutHeatPumpResults.changeInTemperatureAcrossPile,
          pileInletFluidTemperature:
            row.withoutHeatPumpResults.pileInletFluidTemperature,
          pileOutletFluidTemperature:
            row.withoutHeatPumpResults.pileOutletFluidTemperature,
        };
        const rowRes: CSVRowWithoutHeatPump = {
          INPUTS_FOR_THIS_TIMESTEP: ">>>>",
          FULL_SYSTEM_CONFIGURATION_FOR_THIS_TIMESTEP: ">>>>",
          RESULTS_FOR_THIS_TIMESTEP: ">>>>",
          datetime: row.dateTime,
          ...row.csvRowData,
          ...flattenedCalc,
          ...outputs,
        };
        return rowRes;
      }
    );

    exportToCsv(
      fileName,
      getListOfRowListsFromListOfRowDicts({
        listOfRowDictsWithHeatPump: undefined,
        listOfRowDictsWithoutHeatPump: listOfRowDicts,
      })
    );
  }
}

export type ListOfRowListsForCSV = (string[] | number[] | Date[])[];

function getListOfRowListsFromListOfRowDicts(paramObj: {
  listOfRowDictsWithHeatPump?: CSVRowWithHeatPump[];
  listOfRowDictsWithoutHeatPump?: CSVRowWithoutHeatPump[];
}): ListOfRowListsForCSV {
  const listOfRowDictsWithHeatPump = paramObj.listOfRowDictsWithHeatPump;
  const listOfRowDictsWithoutHeatPump = paramObj.listOfRowDictsWithoutHeatPump;
  let listOfRowLists: ListOfRowListsForCSV = [];
  let listOfRowDicts:
    | CSVRowWithHeatPump[]
    | CSVRowWithoutHeatPump[]
    | undefined = [];
    if (listOfRowDictsWithHeatPump) {
        listOfRowLists = addHeaderList(listOfRowLists, true);
        listOfRowDicts = listOfRowDictsWithHeatPump;
    } else {
        listOfRowLists = addHeaderList(listOfRowLists, false);
        listOfRowDicts = listOfRowDictsWithoutHeatPump;
    }
   //console.log('turning list of dicts into list of lists for csv', listOfRowDicts);
  if (!listOfRowDicts) {
    throw new Error(
      "This should be impossible, in getListOfRowListsFromListOfRowDicts"
    );
  }
  listOfRowDicts.forEach((rowDict) => {
    let rowList: any[] = [];
    Object.keys(rowDict).forEach((colName) => {
      const val = rowDict[colName as keyof typeof rowDict];
      let index: number | undefined = 1;
      if (listOfRowDictsWithHeatPump) {
        index =
          colIdToArrayIndexWithHeatPump[
            colName as keyof typeof colIdToArrayIndexWithHeatPump
          ];
      } else {
        index =
          colIdToArrayIndexWithoutHeatPump[
            colName as keyof typeof colIdToArrayIndexWithoutHeatPump
          ];
      }
      if (index === undefined) {
        return;
      }
      rowList[index] = val;
    });
    listOfRowLists.push(rowList);
  });
 //console.log('going to return this list of row lists',listOfRowLists)
  return listOfRowLists;
}

const colIdToArrayIndexWithHeatPump: {
  [colID in keyof Partial<CSVRowWithHeatPump>]: number;
} = {
  datetime: 0,
  RESULTS_FOR_THIS_TIMESTEP: 1,
  averageGeoPileThermalCapacity: 2,
  perPileHeatPumpCapacity: 3,
  wholeArrayHeatPumpCapacity: 4,
  changeInTemperatureAcrossPile: 5,
  pileInletFluidTemperature: 6,
  pileOutletFluidTemperature: 7,
  heatPumpCoefficientOfPerformance: 8,
  isCoolingSeasonAtHeatPump: 9,
  electricityUsedByHeatPumpPerPile: 10,
  INPUTS_FOR_THIS_TIMESTEP: 11,
  WhpT: 12,
  WgheT: 13,
  AWgheT: 14,
  Tair: 15,
  Tground0: 16,
  Tground5: 17,
  Tground10: 18,
  Kavg: 19,
  Kmax: 20,
  Kmin: 21,
  Qoap: 22,
  Tlpre: 23,
  Tohp: 24,
  Tin: 25,
  Tliin: 26,
  Tliout: 27,
  Tlpost: 28,
  FULL_SYSTEM_CONFIGURATION_FOR_THIS_TIMESTEP: 29,
  ambientAirTemperature: 30,
  maxGroundThermalConductivity: 31,
  minGroundThermalConductivity: 32,
  averageGroundThermalConductivity: 33,
  groundTemperatureAtTopOfPile: 34,
  groundTemperature5mBelowTopOfPile: 35,
  groundTemperature10mBelowTopOfPile: 36,
  heatingSeasonCoefficientA: 37,
  heatingSeasonCoefficientB: 38,
  heatingSeasonCoefficientC: 39,
  coolingSeasonCoefficientA: 40,
  coolingSeasonCoefficientB: 41,
  coolingSeasonCoefficientC: 42,
  numberOfActivePilesInTheArray: 43,
  length: 44,
  steelPileNominalSizeForClient: 45,
  plasticPipeNominalSizeForClient: 46,
  temperatureChangeBeforeArray: 47,
  averageInterPileTemperatureChangeInflow: 48,
  averageInterPileTemperatureChangeOutflow: 49,
  temperatureChangeAfterArray: 50,
  arrayPumpOutletFlowRate: 51,
  flowChangeBeforeArray: 52,
  averageInterPileFlowChangeInflow: 53,
  pileInletFlowRate: 54,
};

const colIdToArrayIndexWithoutHeatPump: {
  [colID in keyof Partial<CSVRowWithoutHeatPump>]: number;
} = {
  datetime: 0,
  RESULTS_FOR_THIS_TIMESTEP: 1,
  averageGeoPileThermalCapacity: 2,
  changeInTemperatureAcrossPile: 4,
  pileInletFluidTemperature: 5,
  pileOutletFluidTemperature: 6,
  INPUTS_FOR_THIS_TIMESTEP: 9,
  WhpT: 10,
  WgheT: 11,
  AWgheT: 12,
  Tair: 13,
  Tground0: 14,
  Tground5: 15,
  Tground10: 16,
  Kavg: 17,
  Kmax: 18,
  Kmin: 19,
  Qoap: 20,
  Tlpre: 21,
  Tohp: 22,
  Tin: 23,
  Tliin: 24,
  Tliout: 25,
  Tlpost: 26,
  FULL_SYSTEM_CONFIGURATION_FOR_THIS_TIMESTEP: 27,
  ambientAirTemperature: 28,
  maxGroundThermalConductivity: 29,
  minGroundThermalConductivity: 30,
  averageGroundThermalConductivity: 31,
  groundTemperatureAtTopOfPile: 32,
  groundTemperature5mBelowTopOfPile: 33,
  groundTemperature10mBelowTopOfPile: 34,
  numberOfActivePilesInTheArray: 41,
  length: 42,
  steelPileNominalSizeForClient: 43,
  plasticPipeNominalSizeForClient: 44,
  temperatureChangeBeforeArray: 45,
  averageInterPileTemperatureChangeInflow: 46,
  averageInterPileTemperatureChangeOutflow: 47,
  temperatureChangeAfterArray: 48,
  arrayPumpOutletFlowRate: 49,
  flowChangeBeforeArray: 50,
  averageInterPileFlowChangeInflow: 51,
  pileInletFlowRate: 52,
};

function renameSomeKeys(key: keyof typeof colIdToArrayIndexWithHeatPump) {
  switch (key) {
    case "datetime":
      return "Date_Time";
    case "plasticPipeNominalSizeForClient":
      return "plasticPipeNominalSize";
    case "steelPileNominalSizeForClient":
      return "steelPipeNominalSize";
    case "length":
      return "averageSteelPileLength";
    default:
      return key;
  }
}

function addHeaderList(list: ListOfRowListsForCSV, withHeatPump: boolean): ListOfRowListsForCSV {
    const headerRow: number[] | string[] | Date[] = [];
    const iterObj = withHeatPump? colIdToArrayIndexWithHeatPump: colIdToArrayIndexWithoutHeatPump;
  Object.keys(iterObj).forEach((colId: string) => {
    const index = withHeatPump?
      colIdToArrayIndexWithHeatPump[
        colId as keyof typeof colIdToArrayIndexWithHeatPump
      ]:
      colIdToArrayIndexWithoutHeatPump[
          colId as keyof typeof colIdToArrayIndexWithoutHeatPump
      ]
      ;
    if (index == undefined) {
      return;
    }
    headerRow[index] = renameSomeKeys(
      colId as keyof typeof colIdToArrayIndexWithHeatPump
    );
  });
  list[0] = headerRow;
 //console.log('added header list: ', headerRow, list);
  return list;
}

//turn row object into correctly ordered array

//!generate the necessary variables for the output popup

//! for the comparison-to-target graph
// per row data points
// need target value for each row?
// need actual, relative to target
// need time label
type PerRowResults = {}[];

//generate the necessary variables for the output report
