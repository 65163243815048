import { trimDecimals } from 'lib/helper-functions';
import { ICalculator, OutputVariables } from 'lib/models';
import { ConvertableUnits, convertableUnitsToReadable, convertFlowRateToLPM, convertToImperial } from 'lib/units';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import styled, { css } from 'styled-components';
import { DisclaimerText } from './SummaryPage/SummaryPageTemplate';




const Container = styled.div<{yPosition: number, xPosition:number}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* margin: 0; */
    margin: auto;
    position: absolute;
    width: 8.5in;
    height: 11.75in;
    /* left: -9999px; */
    ${props=> `
        left: ${props.xPosition}px;
        bottom: ${props.yPosition}px;
    `}
    padding: 0;
    /* padding-left: -0.2in; */
    color: var(--c-black);
    border-radius: 0;
    font-size: 0.2in;
    font-family: 'Heebo';
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: var(--c-light-gray);
    width: 105%;
    margin: 0;
    border-radius: 0;
    padding: var(--ss-2);
    padding-inline: var(--ss-6);
    
`;
const HeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin: var(--ss-3);
    padding: var(--ss-3);
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-width:100%;
    margin: 0;
    border-radius: 0;
    padding: var(--ss-3);
    padding-inline: var(--ss-6);
    background: var(--c-white);
    /* padding-bottom: var(--s-16); */
`;

const Footer = styled.div`
    background: var(--c-black);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 105%;
    border-radius:0;
    margin: 0;
    color: var(--c-white);
    padding: 0;
    /* padding-inline: var(--ss-6); */
    padding-right: 0.8in;
    padding-left: 0.5in;
    height: 0.8in;
`;
export const H2 = styled.h2`
    color: var(--c-green);
    font-size: 0.2in;
    margin: var(--ss-3);
    padding: var(--ss-3);
    font-family: 'Ubuntu';
`;
const H1Base = css`
    font-size: 0.4in;
    margin: var(--ss-3);
    padding: var(--ss-3);
    font-family: 'Ubuntu';
    text-align: left;
`;
export const H1 = styled.h1`
    color: var(--c-green);
    ${H1Base}
`;
export const H4 = styled.h4`
margin: var(--ss-3);
padding: var(--ss-3);
font-size: 0.15in;
font-family: 'Ubuntu';
`;

export const BlackTitle = styled.h1`
    ${H1Base};
  color: var(--c-black);
  /* font-size: var(--ss-6); */
  margin-block: var(--ss-4);
  padding: 0;
`;
export const GreenTitle = styled.h1`
    ${H1Base}
  color: var(--c-green);
  /* font-size: var(--ss-6); */
  margin-block: var(--ss-4);
  padding: 0;
`;

export const Logo = styled.img`
    height: var(--ss-7);
    margin-inline: var(--ss-4);
`;
export const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: var(--ss-3);
    padding: var(--ss-3);
`;

export function getConvertedText(imperial:boolean, value: number | undefined, unit: ConvertableUnits, decimals = 1 ){
    // //console.log('converting maybe...', imperial, value, unit);
    if (imperial && unit === 'W'){
        decimals = 3
    }
    if (imperial && (unit === 'm3/s' || unit === 'l/m')){
        decimals = 5
    }
    if (imperial){
        return `${trimDecimals(convertToImperial(unit,value),decimals)} ${convertableUnitsToReadable(unit,true)}`;
    }else {
        if (unit === 'm3/s'){
            return `${trimDecimals(value, decimals+5)} ${convertableUnitsToReadable(unit)}`;
        }
        return `${trimDecimals(value,decimals)} ${convertableUnitsToReadable(unit)}`;
    }
}

export function getPileInletFlowRate(session: ICalculator, imperial: boolean, numPiles?: number){
    let result = 0;
    const isDirect = (session.outputVariable = OutputVariables.GroundTemperature) || (session.outputVariable === OutputVariables.PileLength && session.pileArray?.targetGeoPileThermalCapacity)
    if (isDirect){
        result = session.pileInFluid?.pileInletFlowRate?.inputValue || 0;
    }
    else {
        const calculated = calculatePileInletFlowRate(session, numPiles)
    result = calculated;
    }
    return getConvertedText(imperial, convertFlowRateToLPM(result),'l/m');
}
function calculatePileInletFlowRate(session: ICalculator, numPiles?: number){
    const nPiles = numPiles || session.pileArray?.numberOfActivePilesInTheArray?.inputValue || 0;
    const pumpFlow = session.pileInFluid?.arrayPumpOutletFlowRate?.inputValue || 0;
    const arrayChanges = session.pileInFluid?.flowChangeBeforeArray?.inputValue || 0;
    const pileChanges = session.pileInFluid?.averageInterPileFlowChangeInflow?.inputValue || 0;
    const arrayFlow = pumpFlow + arrayChanges;
    return (arrayFlow/nPiles *1.0) + pileChanges;
}


interface ReportTemplateProps{
    id: any,
    xPosition: number,
    yPosition: number,
}
const ReportTemplate: React.FunctionComponent<ReportTemplateProps> =({
    id, children,xPosition,yPosition
})=> {

    

return (
<Container id={id} xPosition={xPosition} yPosition={yPosition} className={'EraseDefaultStylingOnChildren'}>
    <Header>
        <LogoContainer>
        <Logo src="logo.svg" alt="logo"/>
        <Logo src="simpleLogo.svg" alt="logo"/>
        </LogoContainer>
        <HeaderRight>
            <H2>
                Geo-Pile Performance Report
                </H2>
            <H4>{new Date().toLocaleDateString()}</H4>
        </HeaderRight>
    </Header>
    <Content>
        {children}
    </Content>
    <Footer>
        <DisclaimerText>*Disclaimer: This is not an engineering document.</DisclaimerText>
        Geo-Pile Predictive Software
    </Footer>
</Container>
);
}
export default ReportTemplate;
