import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import ReportTemplate, { BlackTitle, GreenTitle } from "./Template";
// import Button from "./Reusables/Button";
// import { Link, useHistory } from "react-router-dom";

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-white);
  width: 100%;
  margin: 0;
  padding: 0;
`;
const LeftTop = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
`;
const RightTop = styled.div`
  font-size: var(--ss-4);
  /* padding-left: var(--ss-9); */
  padding-right: 0;
  padding-block: 0;
  margin: 0;
`;
// const BlackTitle = styled.h3`
//   color: var(--c-black);
//   font-size: var(--ss-6);
//   margin-block: var(--ss-4);
//   padding: 0;
// `;
// const GreenTitle = styled.h3`
//   color: var(--c-green);
//   font-size: var(--ss-6);
//   margin-block: var(--ss-4);
//   padding: 0;
// `;

const DiagramArea = styled.div`
  margin: 0;
  padding: 0;
`;
const Diagram = styled.img`
  width: 100%;
`;
const SubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: var(--ss-5);
`;
const LegendArea = styled.div`
  margin: 0;
  padding: 0;
`;
const Legend = styled.img`
  width: 100%;
`;
const BottomDisclaimer = styled.div`
  margin: 0;
  padding: 0;
  font-size: var(--ss-4);
`;
const GreenDivider = styled.div`
  margin-block: var(--ss-4);
  margin-inline: 0;
  border-radius: 0;
  padding: 0;
  height: var(--ss-2);
  width: var(--ss-10);
  background: var(--c-green-very-light);
`;

const ExampleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: var(--c-gray-very-light);
  border-radius: 0;
  margin: 0;
  padding: var(--ss-5);
  margin-bottom: var(--ss-35);
`;
const ColSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 50%;
  height: 100%;
`;
const Fractal = styled.img`
  width: var(--ss-10);
`;
const ErrorSection = styled.div`
  margin: 0;
  padding: 0;
  font-size: var(--ss-375);
`;
const P = styled.p`
  margin-top: var(--ss-35);
  margin-bottom: var(--ss-35);
`;

const SubSubTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: var(--ss-4);
  color: var(--c-green);
  align-self: flex-start;
`;
const ExampleTable = styled.img`
  width: 90%;
`;

//Sarah's test to add a button to check the next report page
// const GrayButton = styled(Button)`
//  background: var(--c-gray);
//  height: 2em;
//  font-size: 1em;
//  border-radius: var(--light-rounding);
//  `;

interface ErrorSourcesReportProps {
  id: any;
}

const ErrorSourcesReport: React.FunctionComponent<ErrorSourcesReportProps> = ({
  id,
}) => {
  // const history = useHistory();
  // function goToReportView2() {
  // history.push("/report");
  // }
  return (
    <ReportTemplate id={id} xPosition={9999} yPosition={0}>

      <TopArea>
        <LeftTop>
          <BlackTitle>Appendix</BlackTitle>
          <GreenTitle>
            Potential Sources <br />
            of Error
          </GreenTitle>
        </LeftTop>
        <RightTop>
          Potential sources of error are detailed here which may have an effect
          on the software outputs.
          <br />
          <br />
          This list of potential error sources is not guaranteed to be complete.
        </RightTop>
      </TopArea>
      <GreenDivider></GreenDivider>
      <ColSection>
        <Column>
          <ErrorSection>
            <SubTitle>Machine Learning Model Errors</SubTitle>
            <P>
              The primary source of error with all machine learning based
              software is within the training dataset. For this reason, the
              dataset was analyzed and validated across experimental,
              computational, and analytical methods whenever possible. Further,
              the dataset was pre-processed to remove portions which may
              contribute to the over-training of the model, or may provide bias
              to the machine learning outputs.
            </P>
          </ErrorSection>
          <ErrorSection>
            <SubTitle>Compounding Errors</SubTitle>
            <P>
              Secondary sources of error in the software computation are the
              typical compounding of errors with multi-calculation algorithms.
              Errors in calculation at the base level of the algorithm (machine
              learning) will be compounded across the other calculations. In the
              case of the variables present here, the nature of their
              mathematics implies that the magnitude of the temperature values
              have a high effect on the magnitude of the thermal capacity
              values.
            </P>
            <ExampleSection>
              <SubSubTitle>For Example:</SubSubTitle>
              <P>
                Consider two geo-pile components with the same ground thermal
                conductivity, fluid and solid material properties, geometry, air
                temperature, flow conditions, and heat pump. The incoming water
                temperature to the first pile is 3.61°C, and the outlet water
                from that pile is 5.41°C due to the heat exchange it experiences
                within the ground domain due to ground temperature. The second
                pile’s incoming water temperature is also 3.61°C, but the outlet
                water from this pile is 5.90°C instead.
              </P>
              <ExampleTable src="ErrorSourcesExampleTable.png" />
              <P>
                Even though the absolute value of the difference in outlet water
                temperature between the first and the second pile is only
                0.49°C-, the thermodynamic calculation of heat exchange rate
                magnifies this difference by 61.40 W between the two piles, and
                a difference of 80.30 W between the thermal capacity output of
                the heat pump (with a heating COP difference of 0.06 due to the
                changes in entering water temperature to the heat pump) between
                the two piles.
              </P>
              <P>
                The discrepancy in outlet water temperature between these two
                systems could be due to a number of sources of calculation or
                measurement error (temperature sensors, or predictive algorithm
                error), and a number of physical influences that affect the
                thermodynamics of the system (pile leaks, ground/pile
                inconsistencies in temperature or conductivity, thermal
                “resting” of one pile’s fluid longer than the other, slight
                variations in flow rate within the piles). Though temperature
                differences might be common and seem negligibly small, their
                resulting effects on the thermal capacity are magnified
                mathematically, and physically.
              </P>
              <P>
                For this reason, all data collected and used with in-ground heat
                exchangers must be used with a reasonable understanding of the
                complexities and variability involved in the system. Design of
                these systems should account for this variation.
              </P>
            </ExampleSection>
          </ErrorSection>
        </Column>
        <Column>
          <ErrorSection>
            <SubTitle>Input Errors</SubTitle>
            <P>
              Finally, this software is dependent on the assumptions of the user
              input information. Ambient air temperature, soil temperature, and
              soil thermal conductivity are variables that have a high effect on
              the performance of the in-ground heat exchanger practically and
              computationally. These values are often difficult to exactly
              measure, and vary highly with time and location.
            </P>
            <ExampleSection>
              <P>
                To compensate for these errors, this software aims to both
                clearly communicate the importance of variables and their effect
                on the output - as well as aid in the reduction of these errors
                by continuously updating, validating, and optimizing the
                calculation algorithms which may contribute error. Further
                versions of this software will be focused on continuously
                reducing the errors which may affect prediction accuracy, as
                this information becomes available through technological and
                research advances.
              </P>
            </ExampleSection>
            <SubTitle>Software Methodology</SubTitle>
            <P>
              The Geo-Pile Predictive Software (GPPS) utilizes machine learning
              algorithms to predict the performance of the in-ground heat
              exchanger component in this system. A large portion of the
              training set was generated from computational fluid dynamic
              modelling, which used finite element analysis (FEA) to solve
              3-dimensional steady state and transient multiphysics
              calculations. Further validation on the dataset was performed with
              experimental and sensor data, as well as the use of widely
              accepted calculations and assumptions in the research field of
              geo-exchange, and fluid dynamics. This initial research was
              performed by Sarah R. Nicholson, and is referenced in the several
              published documents [Journal Article (x2?)],[Conference Paper],
              [Thesis].
            </P>

            <SubTitle>
              Calculating the Average Geo-Pile Thermal Capacity Error
            </SubTitle>
            <P>
              The key output variable with errors is the “Average Geo-Pile
              Thermal Capacity” error (this will be reflected in “Whole Array
              Heat Pump Capacity”, but the exact magnitude will depend on the
              specific heat pump being used, and will be multiplied by the
              number of piles in the array). The two most influential sources of
              error are the “Pile Outlet Fluid Temperature (Tout)” and the “Pile
              Inlet Fluid Flow Rate (Qin)”. Here is how to calculate potential
              error magnitude from those two sources:
            </P>
            <code>
              Average Geo-Pile Thermal Capacity Error = (Error in Tout) x (Qin /
              0.00000024)
            </code>
            <br />
            <code>
              Average Geo-Pile Thermal Capacity Error = (Tout - Tin) x 42
            </code>
            <P>
              Given the current lack of robust data on geo-pile systems, the
              machine learning model may produce errors of up to 1 \xB0Celcius,
              which for a typical flow rate and heat pump scenario can mean a
              final error of 100-200 W per Geo-Pile in the system.
            </P>
            <SubTitle>Ground Temperature</SubTitle>
            <P>
              The current ground temperature machine learning model, due to
              limitations in source data, may predict deep soil temperatures
              that are up to 4 \xB0Celcius different from reality (at its
              worst), depending on the site location and how different it is
              from the datasets used for training. However for most Southern
              Ontario locations in non-shoulder seasons (prediction error is
              much higher in shoulder seasons) the predicted deep soil
              temperature should be within 0.1 \xB0Celcius of the reality.
            </P>
          </ErrorSection>
        </Column>
      </ColSection>
    </ReportTemplate>
  );
};
export default ErrorSourcesReport;
