import { BaseModel, IBaseModel } from "./base-model";
import { convertUiInputDoubleToDto, IUiInputVariableDouble, IUiInputVariableDoubleOut, UiInputVariableDouble } from "./ui-input-variable-double";

export interface IPileInFluid extends IBaseModel{

  heatPumpOutletFluidTemperature?: IUiInputVariableDouble | null;

  averageInterPileTemperatureChangeInflow?: IUiInputVariableDouble | null;

  temperatureChangeBeforeArray?: IUiInputVariableDouble | null;

  pileInletFlowRate?: IUiInputVariableDouble | null;

  pileInletFluidTemperature?: IUiInputVariableDouble | null;

  arrayPumpOutletFlowRate?: IUiInputVariableDouble | null;

  flowChangeBeforeArray?: IUiInputVariableDouble | null;

  averageInterPileFlowChangeInflow?: IUiInputVariableDouble | null;
}
export interface IPileInFluidOut extends IBaseModel{

  
  heatPumpOutletFluidTemperature?: IUiInputVariableDoubleOut | null;
  
  averageInterPileTemperatureChangeInflow?: IUiInputVariableDoubleOut | null;
  
  temperatureChangeBeforeArray?: IUiInputVariableDoubleOut | null;
  
  pileInletFlowRate?: IUiInputVariableDoubleOut | null;

  pileInletFluidTemperature?: IUiInputVariableDoubleOut | null;

  arrayPumpOutletFlowRate?: IUiInputVariableDoubleOut | null;

  flowChangeBeforeArray?: IUiInputVariableDoubleOut | null;

  averageInterPileFlowChangeInflow?: IUiInputVariableDoubleOut | null;
}
export class PileInFluid extends BaseModel implements IPileInFluid{
  
  public heatPumpOutletFluidTemperature?: IUiInputVariableDouble = new UiInputVariableDouble('heatPumpOutletFluidTemperature');
  //the spaces in 'degC ' are there on purpose, do not remove them, the prevent the user from being able to convert those units
  public averageInterPileTemperatureChangeInflow?: IUiInputVariableDouble = new UiInputVariableDouble('averageInterPileTemperatureChangeInflow');

  public temperatureChangeBeforeArray?: IUiInputVariableDouble = new UiInputVariableDouble('temperatureChangeBeforeArray');

  
  
  public arrayPumpOutletFlowRate?: IUiInputVariableDouble = new UiInputVariableDouble('arrayPumpOutletFlowRate');
  
  public flowChangeBeforeArray?: IUiInputVariableDouble = new UiInputVariableDouble('flowChangeBeforeArray');
  
  public averageInterPileFlowChangeInflow? = new UiInputVariableDouble('averageInterPileFlowChangeInflow');
  public pileInletFluidTemperature?: IUiInputVariableDouble = new UiInputVariableDouble('pileInletFluidTemperature');
  public pileInletFlowRate?: IUiInputVariableDouble = new UiInputVariableDouble('pileInletFlowRate');
}
export function pileInFluidToDto(base: IPileInFluid):IPileInFluidOut{
  return {
    id : base.id,
    name: base.name,
    heatPumpOutletFluidTemperature: convertUiInputDoubleToDto(base.heatPumpOutletFluidTemperature),
    averageInterPileTemperatureChangeInflow: convertUiInputDoubleToDto(base.averageInterPileTemperatureChangeInflow),
    temperatureChangeBeforeArray: convertUiInputDoubleToDto(base.temperatureChangeBeforeArray),
    pileInletFlowRate: convertUiInputDoubleToDto(base.pileInletFlowRate),
    pileInletFluidTemperature: convertUiInputDoubleToDto(base.pileInletFlowRate),
    arrayPumpOutletFlowRate: convertUiInputDoubleToDto(base.arrayPumpOutletFlowRate),
    flowChangeBeforeArray: convertUiInputDoubleToDto(base.flowChangeBeforeArray),
    averageInterPileFlowChangeInflow: convertUiInputDoubleToDto(base.averageInterPileFlowChangeInflow),
  } as IPileInFluidOut;
}