import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import { TD, TDGreen, THGreen } from "../SummaryPage/SummaryPageTemplate";
import ReportTemplate, { BlackTitle, GreenTitle, H1, H4 } from "../Template";

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  margin: var(--ss-3);
  padding: var(--ss-3);
`;

const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-white);
  width: 100%;
  margin: 0;
  padding: 0;
`;
const LeftTop = styled.div`
  margin: 0;
  padding: 0;
`;
const RightTop = styled.div`
  font-size: var(--ss-4);
  padding-left: var(--ss-9);
  padding-right: 0;
  padding-block: 0;
  margin: 0;
`;

const Table = styled.table`
    width: 100%;
    background: var(--c-white-dark);
    justify-content: flex-start;
    margin-top: var(--ss-3);
    margin-bottom: var(--ss-5);
`;
const SilentDiv = styled.div`
  margin: 0;
  padding: 0;
  font-size: var(--ss-4);
  width: 100%;
`;
const SummaryLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 180%;
`;
const SummaryRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 100%;
`;
export const DisclaimerText = styled.div`
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.12in;
`;
export const InfoText = styled.div`
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.12in;
`;
const BaseTable = css`
  width: 100%;
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-size: 0.1in;
`;
const ComparisonTable = styled.table`
  ${BaseTable}
  border: solid var(--c-light-gray);
`;
const SiteAndStudyTable = styled.table`
  ${BaseTable}
  background: var(--c-light-gray);
`;
const ComponentTable = styled.table`
  ${BaseTable}
  background: var(--c-light-gray);
`;
const SummaryHighlight = styled.div`
  background: var(--c-black);
  margin: var(--ss-3);
  padding: var(--ss-3);
  width: 100%;
`;
const H2 = styled.h2`
  color: var(--c-white);
  font-size: 0.35in;
  margin: var(--ss-3);
  padding: var(--ss-3);
  font-family: "Ubuntu";
  font-weight: 400;
`;
const SummaryBigLabel = styled.div`
  color: var(--c-white);
  font-size: 0.2in;
  margin: var(--ss-3);
  padding: var(--ss-3);
`;
const SummaryDivider = styled.div`
  width: 100%;
  height: 0.01in;
  margin: 0;
  padding: 0;
  background: var(--c-green-very-light);
`;

export interface DetailsTablePropsData {
  sessionName: string;
  valuesInOrder: (number | string)[];
}
export interface DetailsTableProps {
  data: DetailsTablePropsData[];
  title: string;
  variableNames: string[];
}

interface DetailsPageTemplateProps {
  tables: DetailsTableProps[];
  id: any;
}
const DetailsPageTemplate: React.FunctionComponent<DetailsPageTemplateProps> = ({
  id,
  tables,
}) => {
  return (
    <ReportTemplate id={id} xPosition={9999} yPosition={-9999}>
    {/* <ReportTemplate id={id} xPosition={0} yPosition={0}> */}
      <TopArea>
        <LeftTop>
          <BlackTitle>Appendix</BlackTitle>
          <GreenTitle>
            Detailed Variable <br />
            Information
          </GreenTitle>
        </LeftTop>
        <RightTop>
          Values of detail variables are presented here. Depending on the
          variable, they may either be chosen as a user input, or calculated as
          a software output. <br />
          <br />
        </RightTop>
      </TopArea>
      {tables.map((table) => {
        return (
          <SilentDiv key={table.title}>
            <H4>{table.title}</H4>
            <Table>
              <tr>
                <THGreen>Session Name</THGreen>
                {table.variableNames.map((varName: string) => (
                  <th>{varName}</th>
                ))}
              </tr>
              {table.data.map((data: DetailsTablePropsData) => {
                return (
                  <tr>
                    <TDGreen>{data.sessionName}</TDGreen>
                    {data.valuesInOrder.map((value: string | number) => (
                      <TD>{value}</TD>
                    ))}
                  </tr>
                );
              })}
            </Table>
          </SilentDiv>
        );
      })}
    </ReportTemplate>
  );
};
export default DetailsPageTemplate;
