import { BaseModel, IBaseModel } from "./base-model";
import { IClimate, IClimateOut } from "./climate";
import { IHeatPump, IHeatPumpOut } from "./heat-pump";
import { OutputVariables } from "./output-variables";
import { IPileArray, IPileArrayOut } from "./pile-array";
import { IPileInFluid, IPileInFluidOut } from "./pile-in-fluid";
import { IPileOutFluid, IPileOutFluidOut } from "./pile-out-fluid";
import { IPlasticPipe, IPlasticPipeOut } from "./plastic-pipe";
import { IGround, IGroundOut } from "./ground";
import { ISteelPile, ISteelPileOut } from "./steel-pipe";
import {
  IUiInputVariableDouble,
  UiInputVariableDouble,
  IUiInputVariableDoubleOut
} from "./ui-input-variable-double";

export interface ICalculator extends IBaseModel {
 
  climate?: IClimate;

  heatPump?: IHeatPump;

  outputVariable?: OutputVariables;

  pileArray?: IPileArray;

  pileInFluid?: IPileInFluid;

  pileOutFluid?: IPileOutFluid;

  plasticPipe?: IPlasticPipe;

  ground?: IGround;

  steelPile?: ISteelPile;
}
export interface ICalculatorOut extends IBaseModel {
  

  climate?: IClimateOut;

  heatPump?: IHeatPumpOut;

  outputVariable?: OutputVariables;

  pileArray?: IPileArrayOut;

  pileInFluid?: IPileInFluidOut;

  pileOutFluid?: IPileOutFluidOut;

  plasticPipe?: IPlasticPipeOut;

  ground?: IGroundOut;

  steelPile?: ISteelPileOut;
}

export class Calculator extends BaseModel implements ICalculator {
 

  public climate?: IClimate;


  public heatPump?: IHeatPump;


  public outputVariable?: OutputVariables;

  public pileArray?: IPileArray;


  public pileInFluid?: IPileInFluid;


  public pileOutFluid?: IPileOutFluid;


  public plasticPipe?: IPlasticPipe;


  public ground?: IGround;


  public steelPile?: ISteelPile;

}
