import React, {useState, useEffect, useContext, useCallback, useRef, ButtonHTMLAttributes} from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

const Main = styled.button<{custCss?: string | FlattenSimpleInterpolation}>`
background: var(--c-green);
border: none;
color: var(--c-white);
/* box-shadow: var(--shadow-1); */
 border-radius: var(--def-rounding);
 font-size: var(--fs-4);
 margin: var(--s-4);
 :hover {
     background: var(--c-green-dark);
 }
${props=>props.custCss}
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    custCss?: string | FlattenSimpleInterpolation
    isLoading?: boolean
    isSuccessful?: boolean
    failureMessage?: string
}
const Button: React.FunctionComponent<ButtonProps> =(props)=> {

    const {
        children, 
        isLoading,
        isSuccessful,
        failureMessage,
        ...buttonProps} = {...props};
return (
<Main {...buttonProps}>
    {isLoading?
        '...Loading':
    isSuccessful?
        'Success!':
    failureMessage?
        'Failed':
    children}
</Main>
);
}
export default Button;
