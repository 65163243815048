//CREDIT FOR THIS CODE TO XAVIER JOHN at https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side

import { NonFatalError } from "./ErrorAndMessageHandling/ErrorHandler";
import { isNullishOrNanButNotZero } from "./validation-helpers";

export function exportToCsv(filename: string, rows: (string[]|number[]|Date[])[]) {
    ////console.log('exportingToCsv with ',rows)
    var processRow = function (row: string[]|number[]|Date[]) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = isNullishOrNanButNotZero(row[j]) ? '' : row[j].toString();
            ////console.log('row inner Value', innerValue, row[j]);
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            ////console.log('result =', result);
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            ////console.log('finalVal+result', finalVal, result);
            finalVal += result;
        }
        ////console.log('proccess row got ',finalVal);
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        if (rows[i]){
            csvFile += processRow(rows[i]);
        }else {
            // throw new NonFatalError('CSV file list of lists had an empty or undefined row', rows[i]);
        }

    }
   //console.log('exporting to CSV with ',csvFile);
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
   //console.log('blog csv = ',blob);
    try { // IE 10+
        //@ts-ignore
        navigator.msSaveBlob(blob, filename);
    } catch {
       //console.log('navigator.msSaveBlob did not exist')
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
           //console.log('csv url', url);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
           //console.log('file name = ',filename);
            link.style.visibility = 'hidden';
           //console.log('download link element',link);
            document.body.appendChild(link);
           //console.log('link has been appended');
            link.click();
           //console.log('link has been clicked');
            document.body.removeChild(link);
           //console.log('link has been removed')
        }
        else {
            console.warn('link.download === undefined, cannot export csv');
        }
    }
}