import { HorizFlex } from "components/MainMenu";
import Button from "components/Reusables/Button";
import Select from "components/Reusables/Select";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Option } from "sheldons-components";
import CheckBox from "components/Reusables/CheckBox";
import styled from "styled-components";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HeatPumpCapacityReport from "components/PrintableReports/SummaryPage/HeatPumpCapacity";
import { OutputVariables } from "lib/models";
import DiagramsReport from "components/PrintableReports/DiagramsReport";
import AssumptionsReport from "components/PrintableReports/AssumptionsReport";
import ErrorSourcesReport from "components/PrintableReports/ErrorSourcesReport";
import PileLengthReport from "components/PrintableReports/SummaryPage/PileLengthWHP";
import GroundTemperatureReport from "components/PrintableReports/SummaryPage/GroundTemperature";
import { ApiEndpoints, ICalculators } from "lib/types";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { gcd } from "mathjs";
import { GlobalContext } from "components/Contexts/Global";
import NumberOfPilesReport from "components/PrintableReports/SummaryPage/NumberOfPiles";
import DisclaimerReport from "components/PrintableReports/DisclaimerPage";
import HeatPumpCapacityDetails from "components/PrintableReports/DetailsPage/HeatPumpCapacity";
import NumberOfPilesDetails from "components/PrintableReports/DetailsPage/NumberOfPiles";
import PileLengthWHPDetails from "components/PrintableReports/DetailsPage/PileLengthWHP";
import PileLengthNHPDetails from "components/PrintableReports/DetailsPage/PileLengthNHP";
import GroundTemperatureDetails from "components/PrintableReports/DetailsPage/GroundTemperature";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import { createBlockingLoadingMessage } from "lib/ErrorAndMessageHandling/MessageHandler";

const Container = styled.div`
  color: var(--c-white);
  display: flex;
  background: var(--c-black);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid var(--c-gray-light);
  border-radius: var(--s-3);
`;
const JustForColor = styled.div`
  margin: 0;
  padding: 0;
  color: var(--c-green);
`;
const ExportTitle = styled.h4`
  display: flex;
  color: var(--c-white);
  font-size: var(--s-9);
  padding: var(--s-2);
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--c-gray-light);
  border-radius: var(--s-3);
`;
const IncludeQuestion = styled.h4`
  display: flex;
  color: var(--c-white);
  font-size: var(--s-9);
  text-decoration: underline;
  padding: 0;
  align-items: center;
  box-sizing: border-box;
`;
type DynamicPageNames = "summary" | "details";
type StaticPageNames =
  | "diagrams"
  | "assumptions"
  | "errorSources"
  | "disclaimer";
type PageNames = DynamicPageNames | StaticPageNames;
const pageNameToId: {[name in PageNames]: string} = {
    summary: 'mainReportPage',
    details:'detailsReportPage',
    diagrams: 'diagramsPage',
    assumptions: 'assumptionsPage',
    errorSources: 'errorSourcesPage',
    disclaimer: 'disclaimerPage'
}


type PageComponentMap = Record<PageNames, JSX.Element>;
type StaticPageComponentMap = Record<StaticPageNames, JSX.Element>;
type DynamicPageComponentMap = Record<DynamicPageNames, JSX.Element>;

interface GenerateReportFormProps {
  type: OutputVariables;
  withHeatPump?: boolean;
  sessions?: any[];
  uncalculatedSessions?: ICalculators;
}
const GenerateReportForm: React.FunctionComponent<GenerateReportFormProps> = ({
  type,
  withHeatPump,
  sessions,
  uncalculatedSessions,
}) => {
  if (sessions && uncalculatedSessions)
    throw new Error(
      'Error, cannot provide both "sessions" and "uncalculatedSessions" prop to GenerateReportForm'
    );
  const [imperialUnits, setImperialUnits] = useState<boolean>(false);
  const [includeDiagrams, setIncludeDiagrams] = useState<boolean>(false);
  const [includeAssumptions, setIncludeAssumptions] = useState<boolean>(false);
  const [includeErrorSources, setIncludeErrorSources] = useState<boolean>(
    false
  );
  const [showPrintReport, setShowPrintReport] = useState<boolean>(false);
  const [activePageNames, setActivePageNames] = useState<PageNames[]>([
    "summary",
    "details",
    "disclaimer",
  ]);
  const [calculatedSessions, setCalculatedSessions] = useState<
    any[] | undefined
  >(sessions);
  const [readyToPrint, setReadyToPrint] = useState<boolean>(false);
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);

  //debugging
  useEffect(() => {
    //console.log("imperial units?", imperialUnits);
  }, [imperialUnits]);

  //whenever readyToPrint is true AND calculatedSessions is valid, print
  useEffect(() => {
    if (readyToPrint && calculatedSessionsAreReady(calculatedSessions)) {
      const messageId = messageHandler.addMessage!(createBlockingLoadingMessage(
        'Generating Printable Reports','',''
      ));
      // gc?.addWaitingAction();
      printStep2().then(() => {
        setTimeout(async () => {
          await printStep3();
          setReadyToPrint(false);
          if (gc?.removeWaitingActionAndReturnAllActionsCompleted()){
            messageHandler.resolveMessage!(messageId);
            // gc?.setLoadingState(false);
            // gc.setSuccessState(true)
          }
        }, 500); //waiting for state changes from step2 to complete
      });
    }
  }, [readyToPrint, calculatedSessions]);

  function calculatedSessionsAreReady(calculatedSessions?: any[]) {
    const result =
      calculatedSessions &&
      calculatedSessions.length &&
      calculatedSessions[0] &&
      calculatedSessions[0].calculator;
    //console.log("calculated sessions are ready: ", result, calculatedSessions);
    return result;
  }

  async function getAllCalculationResults(
    sessions: ICalculators
  ): Promise<any[]> {
    if (!gc?.apiToken)
      throw new Error("Error: no valid token/password available");
    const results: any[] = [];
    //console.log('getting all calc results with ',sessions);
    for (let i=0; i <sessions.length; i++){
      //console.log('adding waiting action');
      gc.addWaitingAction();
    }
    for (const calc of sessions) {
      if (!calc.outputVariable) continue;
      const outVar: OutputVariables = calc.outputVariable;
      await results.push(
        await api?.getCalculationResult(
          getEndpointFromOutputVariable(outVar),
          calc,
          gc.apiToken
        )
      );
    }
    const result =  await results.filter((x) => x); //filter out results that failed
    return result;
  }
  function getEndpointFromOutputVariable(v: OutputVariables): ApiEndpoints {
    switch (v) {
      case OutputVariables.NumberOfPiles:
        return "CalculateNumberOfActivePilesInTheArray";
      case OutputVariables.PileHeatPumpCapacity:
        return "CalculatePerPileHeatPumpCapacity";
      case OutputVariables.PileLength:
        return "CalculatePileLengths";
      case OutputVariables.GroundTemperature:
        return "CalculateGroundTemperature";
      case OutputVariables.BulkPredictions:
        throw new Error('this should never happen');
    }
  }

  // //console.log('rendering GenerateReportForm');
  //change active pages whenever a checkbox changes
  useEffect(() => {
    const newPagesNames: PageNames[] = ["summary", 'details'];
    if (includeDiagrams) newPagesNames.push("diagrams");
    if (includeAssumptions) newPagesNames.push("assumptions");
    if (includeErrorSources) newPagesNames.push("errorSources");
    newPagesNames.push('disclaimer');
    setActivePageNames(newPagesNames);
  }, [includeDiagrams, includeAssumptions, includeErrorSources]);

  const UnitOptions = [
    { id: 0, content: "Metric", value: "Metric" },
    { id: 1, content: "Imperial", value: "Imperial" },
  ];


  //! Debugging
  useEffect(()=>{
    //console.log('calculated sessions changed: ');
    //console.log(calculatedSessions);
  },[calculatedSessions]);

  async function printDocument() {
    if (!calculatedSessions && uncalculatedSessions) {
      //console.log("loading sessions from backend");
      const calcResults = await getAllCalculationResults(uncalculatedSessions);
      await setCalculatedSessions(calcResults);
    }
    setReadyToPrint(true);
  }

  async function printStep2() {
    if (!calculatedSessions)
      throw new Error(
        "Error, no calculatedSessions detected, maybe set the timeout to a longer value"
      );
    const sessionsLimit = 5;
    if (calculatedSessions.length > sessionsLimit) {
      window.alert(
        "Error: You cannot print a report with more than " +
          sessionsLimit +
          " sessions."
      );
    }
    setShowPrintReport(true);
  }
  
  async function printStep3() {
    let pdf = new jsPDF();
    let pageNumber = 1;
    for (let i = 0; i < activePageNames.length; i++){
        const pageName = activePageNames[i];
        //console.log('print step 3, pageNum',pageNumber);
        //console.log(pageName);
        const element = document.getElementById(pageNameToId[pageName]);
        //console.log(element);
        if (!element) return;
        const pdf1 = await addPageToPdf(
            pdf,
            element,
            pageNumber
        );
        pageNumber ++;
        pdf = pdf1;
        }
    await pdf.save("GPPReport.pdf");
  }

  async function addPageToPdf(
    pdf: jsPDF,
    element: HTMLElement | null,
    pageNumber: number
  ) {
    if (!element) return pdf;
    const canvas = await html2canvas(element,{scale: 4});
    const img = canvas.toDataURL("image/png",1);
    if (pageNumber !== 1) pdf.addPage();
    pdf.setPage(pageNumber);
    // pdf.addImage(img, "PNG", 0, 0, 210, 300, "NONE"+pageNumber);
    pdf.addImage(img, "PNG", 0, 0, 210, 298, "NONE"+pageNumber);

    return pdf;
  }

  const getStaticPages = useCallback(
    (): StaticPageComponentMap => ({
      diagrams: <DiagramsReport key="diagramsPage" id="diagramsPage" />,
      assumptions: (
        <AssumptionsReport key="assumptionsPage" id="assumptionsPage" />
      ),
      errorSources: (
        <ErrorSourcesReport key="errorSourcesPage" id="errorSourcesPage" />
      ),
      disclaimer: <DisclaimerReport key="disclaimerPage" id="disclaimerPage" />,
    }),
    []
  );

  const HeatPumpCapacityPages = useCallback(
    (): PageComponentMap => ({
      summary: (
        <HeatPumpCapacityReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={calculatedSessions!}
        />
      ),
      details: (
        <HeatPumpCapacityDetails
          imperial={imperialUnits}
          key="detailsReportPage"
          id="detailsReportPage"
          sessions={calculatedSessions!}
        />
      ),
      ...getStaticPages(),
    }),
    [calculatedSessions, imperialUnits, getStaticPages]
  );
  const NumberOfPilesPages = useCallback(
    (): PageComponentMap => ({
      summary: (
        <NumberOfPilesReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={calculatedSessions!}
        />
      ),
      details: (
        <NumberOfPilesDetails
          imperial={imperialUnits}
          key="detailsReportPage"
          id="detailsReportPage"
          sessions={calculatedSessions!}
        />
      ),
      ...getStaticPages(),
    }),
    [calculatedSessions, imperialUnits, getStaticPages]
  );
  const PileLengthPagesWithHeatPump = useCallback(
    (): PageComponentMap => ({
      summary: (
        <PileLengthReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={calculatedSessions!}
        />
      ),
      details: (
        <PileLengthWHPDetails
          imperial={imperialUnits}
          key="detailsReportPage"
          id="detailsReportPage"
          sessions={calculatedSessions!}
        />
      ),
      ...getStaticPages(),
    }),
    [calculatedSessions, imperialUnits, getStaticPages]
  );
  const PileLengthPagesWithoutHeatPump = useCallback(
    () => ({
      summary: (
        <PileLengthReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={calculatedSessions!}
        />
      ),
      details: (
        <PileLengthNHPDetails
          imperial={imperialUnits}
          key="detailsReportPage"
          id="detailsReportPage"
          sessions={calculatedSessions!}
        />
      ),
      ...getStaticPages(),
    }),
    [calculatedSessions, imperialUnits, getStaticPages]
  );
  const GroundTemperaturePages = useCallback(
    () => ({
      summary: (
        <GroundTemperatureReport
          imperial={imperialUnits}
          key="mainReportPage"
          id="mainReportPage"
          sessions={calculatedSessions!}
        />
      ),
      details: (
        <GroundTemperatureDetails
          imperial={imperialUnits}
          key="detailsReportPage"
          id="detailsReportPage"
          sessions={calculatedSessions!}
        />
      ),
      ...getStaticPages(),
    }),
    [calculatedSessions, imperialUnits, getStaticPages]
  );

  const getActualReportComponent = useCallback(() => {
    if (!calculatedSessions) return;
    let pageComponents = HeatPumpCapacityPages();
    switch (type) {
      case OutputVariables.PileHeatPumpCapacity:
        pageComponents = HeatPumpCapacityPages();
        break;
      case OutputVariables.NumberOfPiles:
        pageComponents = NumberOfPilesPages();
        break;
      case OutputVariables.PileLength:
        if (withHeatPump) {
          pageComponents = PileLengthPagesWithHeatPump();
          break;
        } else {
          pageComponents = PileLengthPagesWithoutHeatPump();
          break;
        }
      case OutputVariables.GroundTemperature:
        pageComponents = GroundTemperaturePages();
        break;
      default:
        throw new Error(
          "invalid type given to getActualReportComponent in GenerateReportForm"
        );
    }
    //console.log('getting these pages: ',activePageNames, pageComponents);
    
    return <>{activePageNames.map((page) => pageComponents[page])}</>;
  }, [
    activePageNames,
    HeatPumpCapacityPages,
    NumberOfPilesPages,
    PileLengthPagesWithHeatPump,
    PileLengthPagesWithoutHeatPump,
    GroundTemperaturePages,
    calculatedSessions,
    type,
    withHeatPump,
  ]);

  return (
    <>
      {showPrintReport ? <>{getActualReportComponent()}</> : <></>}
      <Container>
        <ExportTitle> Export These Results </ExportTitle>
        <HorizFlex>
          Units: 
          <JustForColor>
            <Select
              type="styled"
              options={UnitOptions}
              selected={imperialUnits ? UnitOptions[1] : UnitOptions[0]}
              onChange={(opt?: Option) =>
                opt?.id !== 0 ? setImperialUnits(true) : setImperialUnits(false)
              }
            />
          </JustForColor>
        </HorizFlex>
        <IncludeQuestion> Include in report: </IncludeQuestion>
        <HorizFlex>
          <CheckBox
            checked={includeDiagrams}
            onClick={() => setIncludeDiagrams((prev) => !prev)}
          />
          Diagrams and Schematics
        </HorizFlex>
        <HorizFlex>
          <CheckBox
            checked={includeAssumptions}
            onClick={() => setIncludeAssumptions((prev) => !prev)}
          />
          List of Assumptions?
        </HorizFlex>
        <HorizFlex>
          <CheckBox
            checked={includeErrorSources}
            onClick={() => setIncludeErrorSources((prev) => !prev)}
          />
          Sources of Error
        </HorizFlex>
        <Button onClick={() => printDocument()}>Generate Report</Button>
      </Container>
    </>
  );
};
export default GenerateReportForm;
