import { BackendAPIContext } from 'components/Contexts/BackendAPI';
import { GlobalContext } from 'components/Contexts/Global';
import GenerateReportForm from 'components/ResultsViews/Reusables/GenerateReportForm';
import CheckBox from 'components/Reusables/CheckBox';
import MultiSelect from 'components/Reusables/MultiSelect';
import Popup from 'components/Reusables/Popup';
import Select from 'components/Reusables/Select';

import { ICalculator, OutputVariables } from 'lib/models';
import { ICalculators } from 'lib/types';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import { Option } from 'sheldons-components';
import styled from 'styled-components';

const Container = styled.div`
    background: var(--c-white);
    max-width: var(--s-21);
    overflow: auto;
    max-height: calc(100% - var(--s-7));
`;
const H3 = styled.h3`
    color: var(--c-black);
    text-align: center;
    margin: var(--s-10);
`;
const H4 = styled.h4`
    color: var(--c-black);

`;
const SelectSessionsForm = styled.div`
    background: var(--c-green-very-light);
`;
interface MultiSessionReportGeneratorProps{
    
}
const MultiSessionReportGenerator: React.FunctionComponent<MultiSessionReportGeneratorProps> =({})=> {
    const api = useContext(BackendAPIContext);
    const gc = useContext(GlobalContext);
    const [selectedSession1, setSelectedSession1] = useState<Option>();
    const [selectedSession2, setSelectedSession2] = useState<Option>();
    const [selectedSession3, setSelectedSession3] = useState<Option>();
    const [selectedSession4, setSelectedSession4] = useState<Option>();
    const [selectedSession5, setSelectedSession5] = useState<Option>();
    const [sessionsToPrint, setSessionsToPrint] = useState<ICalculators>();
    const [withHeatPump, setWithHeatPump] = useState<boolean>();

    //whenever selectedSessions changes update sessionsToPrint
    useEffect(()=>{
        const names = [
            selectedSession1?.content,
            selectedSession2?.content,
            selectedSession3?.content,
            selectedSession4?.content,
            selectedSession5?.content
        ].filter(x => x);
        setSessionsToPrint(getAllSessions()?.filter(calc=> names?.includes(calc.name)));
    },[selectedSession1, selectedSession2, selectedSession3, selectedSession4, selectedSession5]);

    const getAllSessions = useCallback(()=>{
        const thisOutputVariable = api?.calculators?.filter(calc=> calc.outputVariable === gc?.outputVariable);
        if ( gc?.outputVariable === OutputVariables.PileLength){
            if (withHeatPump){
                return thisOutputVariable?.filter(calc=>!calc.pileArray?.targetGeoPileThermalCapacity?.inputValue);
            }else {
                return thisOutputVariable?.filter(calc=>calc.pileArray?.targetGeoPileThermalCapacity?.inputValue);
            }   
        }else {
            return thisOutputVariable;
        }
    },[api?.calculators,gc?.outputVariable,withHeatPump]);

    const getAllSessionsAsOptions = useCallback(()=>{
        return getAllSessions()?.map((calc):Option => ({id: calc.id || 0, value: calc.name || 'no name', content: calc.name || 'no name'}));
    },[getAllSessions]);

    const withHeatPumpSelectOptions = [
        {id:0,content:'With Heat Pump',value:'With Heat Pump'},
        {id:1, content:'Without Heat Pump', value: 'Without Heat Pump'}
    ];

return (
<Popup
title="Multi-Session Report"
closeCallback={()=>gc?.setPopup(undefined)}
>
    <Container>
        <SelectSessionsForm>
    {(gc?.outputVariable === OutputVariables.PileLength)?
    <>
        <H4>Choose Session Sub-Type:</H4>
        <div>You cannot put predictions generated with a Heat Pump included together with ones that were generated with "Average Geo-Pile Thermal Capacity" input directly.</div>
        <Select
            type='styled'
            options={withHeatPumpSelectOptions}
            selected={withHeatPump?withHeatPumpSelectOptions[0]:withHeatPumpSelectOptions[1]}
            onChange={(newOption)=>{
                if (!newOption) return;
                if (newOption.id === 0){
                    setWithHeatPump(true);
                } else {
                    setWithHeatPump(false);
                }
            }}
        />
        </>
        :<></>}
        <H3>Select up to 5 sessions to include in the report:</H3>
        Session #1
        <Select 
            options={getAllSessionsAsOptions()}
            type='styled'
            selected={selectedSession1}
            onChange={(newOption: Option|undefined)=>{
                setSelectedSession1(newOption);
            }}
        />
        Session #2
        <Select 
            options={getAllSessionsAsOptions()}
            type='styled'
            selected={selectedSession2}
            onChange={(newOption: Option|undefined)=>{
                setSelectedSession2(newOption);
            }}
        />
        Session #3
        <Select 
            options={getAllSessionsAsOptions()}
            type='styled'
            selected={selectedSession3}
            onChange={(newOption: Option|undefined)=>{
                setSelectedSession3(newOption);
            }}
        />
        Session #4
        <Select 
            options={getAllSessionsAsOptions()}
            type='styled'
            selected={selectedSession4}
            onChange={(newOption: Option|undefined)=>{
                setSelectedSession4(newOption);
            }}
        />
        Session #5
        <Select 
            options={getAllSessionsAsOptions()}
            type='styled'
            selected={selectedSession5}
            onChange={(newOption: Option|undefined)=>{
                setSelectedSession5(newOption);
            }}
        />
        </SelectSessionsForm>
    <GenerateReportForm
        uncalculatedSessions={sessionsToPrint}
        type={gc?.outputVariable!}
        withHeatPump={withHeatPump}
    />
    </Container>
</Popup>
);
}
export default MultiSessionReportGenerator;
