import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import {
  HorizFlex,
  HorizFlexMin,
  MinimalDiv,
  VertFlex,
  VertFlexMin,
} from "components/MainMenu";
import Button from "components/Reusables/Button";
import CheckBox from "components/Reusables/CheckBox";
import NumberInput from "components/Reusables/NumberInput";
import Popup from "components/Reusables/Popup";
import YearlessDatePicker, {
  months,
  Months,
} from "components/Reusables/YearlessDatePicker";
import {
  createNonFatalApplicationErrorMessage,
  createNonFatalUserErrorMessage,
  createNonFatalValidationErrorMessage,
} from "lib/ErrorAndMessageHandling/ErrorHandler";
import {
  createBlockingLoadingMessage,
  createBlockingWarningContinueMessage,
} from "lib/ErrorAndMessageHandling/MessageHandler";
import { getNumberOfRowsInCsv } from "lib/getNumberOfRowsInCsv";
import { invalidInputsMessage } from "lib/gtpInputValidation";
import { timeUnitsToMilliseconds } from "lib/models/bulk-prediction-dto";
import { getAllowedUnitsFromBaseUnit } from "lib/units";
import useInterval from "lib/useInterval";
import { usePrevious } from "lib/usePrevious";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  SetStateAction,
} from "react";
import styled from "styled-components";
import DateInput from "./DateInput";
import GTPResult from "./GTPResult";
import LocationInput from "./LocationInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: var(--s-23); */
  background: var(--c-gray);
  overflow: auto;
  max-height: 99%;
  padding-top: var(--s-10);
  padding-bottom: var(--s-15);
`;
const BottomCenterButton = styled(Button)`
  font-size: var(--fs-6);
  width: 80%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background: var(--c-gray-dark);
  padding: 0;
  color: var(--c-white);
`;
const SectionTitle = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-green);
  color: var(--c-white);
  width: 100%;
  margin: 0;
  border-radius: var(--def-rounding);
  box-sizing: border-box;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: var(--s-10);
`;
const BottomBar = styled.div`
  position: absolute;
  bottom: -10%;
  left: 15%;
  /* border-radius: 0; */
  /* padding: 0; */
  /* margin: 0; */
  color: var(--c-black);
  width: 70%;
  box-sizing: border-box;
  background: var(--c-gray-very-light);
  /* left: 30%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow-1-surround);
`;
const GTPLogo = styled.img`
  width: 20%;
`;
const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;
const DirectInputSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const VertDivider = styled.div`
  border-left: solid var(--c-green) var(--s-3);
  padding: 0;
  min-height: var(--s-15);
  width: 0px;
  max-width: 0;
  border-radius: 0;
  /* margin: 0; */
`;
const InstructionText = styled.div`
  width: 100%;
`;
const InstructionPhotoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const InstructionPhoto = styled.img`
  width: 50%;
`;
const InstructionSideText = styled.div``;

const CheckboxLabel = styled.div``;
const HorizCheckboxArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export interface GTPInputs {
  pileTopDepth: number;
  predictionDepth: number;
  month: number;
  day: number;
  lat: number;
  long: number;
  predictedTemperature?: number;
  datetime: Date;
}

export interface GTPInputsForExternalAPI {
  // NUMERIC FIELDS
  Lat: number;
  Long: number;
  Depth: number;
  Day: number;
  Month: number;
  Year: number;
}

export interface GTPResultSingle {
  temperature: number;
  date: Date;
}

type numUn = number | undefined;

interface GTPBulkPredictionsProps {
  closeCallback: any;
}
const GTPBulkPredictions: React.FunctionComponent<GTPBulkPredictionsProps> = ({
  closeCallback,
}) => {
  const api = useContext(BackendAPIContext);
  const messageHandler = useContext(MessageHandlerContext);
  const [getAt0M, setGetAt0M] = useState<boolean>();
  const prevGetAt0M = usePrevious(getAt0M);
  const [queueAt0M, setQueueAt0M] = useState<GTPInputs[]>();
  const [getAt5M, setGetAt5M] = useState<boolean>();
  const prevGetAt5M = usePrevious(getAt5M);
  const [queueAt5M, setQueueAt5M] = useState<GTPInputs[]>();
  const [getAt10M, setGetAt10M] = useState<boolean>();
  const prevGetAt10M = usePrevious(getAt10M);
  const [queueAt10M, setQueueAt10M] = useState<GTPInputs[]>();
  const [queueControllingVariablesString, setQueueControllingVariablesString] =
    useState<string>("");
  const prevQueueControllingVariablesString = usePrevious(
    queueControllingVariablesString
  );
  const [
    endDateTimeControllingVariablesString,
    setEndDateTimeControllingVariablesString,
  ] = useState<string>();
  const prevEndDateTimeControllingVariablesString = usePrevious(
    endDateTimeControllingVariablesString
  );
  const [startDateTime, setStartDateTime] = useState<Date>();
  const [endDateTime, setEndDateTime] = useState<Date>();
  const [localLat, setLocalLat] = useState<any>(); //to allow for negative input
  const [localLong, setLocalLong] = useState<any>();
  const prevLocLat = usePrevious(localLat);
  const prevLocLong = usePrevious(localLong);

  const [runningPrediction, setRunningPrediction] = useState<false | number>(
    false
  );
  const [runningPredictionFailures, setRunningPredictionFailures] =
    useState<number>(0);

  //on mount, retrieve the "getAt_M" variables from saved state in api context
  useEffect(() => {
    setGetAt0M(
      api?.gtpBulkPredictionDepthCategoriesToGet?.includes(0) || false
    );
    setGetAt5M(
      api?.gtpBulkPredictionDepthCategoriesToGet?.includes(5) || false
    );
    setGetAt10M(
      api?.gtpBulkPredictionDepthCategoriesToGet?.includes(10) || false
    );
  }, []);

  //keep api.gtpBulkPredictionDepthCategoriesToGet up to date with local state
  // so that user doesn't have to re-enter whenever the popup closes, but do not pull FROM the api context, only use on mount
  useEffect(() => {
    const somethingLocalHasChanged =
      getAt0M !== prevGetAt0M ||
      getAt5M !== prevGetAt5M ||
      getAt10M !== prevGetAt10M;
    if (!somethingLocalHasChanged) return;
    const existingList = api?.gtpBulkPredictionDepthCategoriesToGet;
    const bothAreEmpty = !existingList && !getAt0M && !getAt5M && !getAt10M;
    if (bothAreEmpty) {
      return;
    }
    const listAreTheSame =
      existingList &&
      existingList?.includes(0) == getAt0M &&
      existingList.includes(5) == getAt5M &&
      existingList.includes(10) == getAt5M;
    if (listAreTheSame) {
      return;
    }
    //are already the same?
    ////console.log(
    //   "API rerendering, if you see this message a lot you need to add a condition to this useEffect"
    // );
    const newCategoriesList = [];
    if (getAt0M) {
      newCategoriesList.push(0);
    }
    if (getAt5M) {
      newCategoriesList.push(5);
    }
    if (getAt10M) {
      newCategoriesList.push(10);
    }
    api?.setGTPBulkPredictionDepthCategoriesToGet(newCategoriesList);
  }, [getAt0M, getAt5M, getAt10M, api]);

  //keep startDateTime up to date
  useEffect(() => {
    if (
      api &&
      api.bulkPredictionDto &&
      api.bulkPredictionDto.timeAndDateOfFirstRowStart &&
      api.bulkPredictionDto.timeAndDateOfFirstRowStart !== startDateTime
    ) {
      setStartDateTime(api?.bulkPredictionDto?.timeAndDateOfFirstRowStart);
    }
  }, [
    api,
    api?.bulkPredictionDto,
    api?.bulkPredictionDto?.timeAndDateOfFirstRowStart,
    startDateTime,
  ]);

  //keep endDateTimeControllingVarString up to date
  useEffect(() => {
    const newString = `${startDateTime}
  ${api?.bulkPredictionDto?.timestepUnit}
  ${api?.bulkPredictionDto?.timestepMagnitude}`;
    if (newString !== endDateTimeControllingVariablesString) {
      setEndDateTimeControllingVariablesString(newString);
    }
  }, [
    startDateTime,
    api?.bulkPredictionDto?.timestepMagnitude,
    endDateTimeControllingVariablesString,
    api?.bulkPredictionDto?.timestepUnit,
  ]);

  //keep endDateTime up to date
  useEffect(() => {
    if (
      endDateTimeControllingVariablesString !==
      prevEndDateTimeControllingVariablesString
    ) {
      if (
        !api ||
        !api.bulkPredictionCsv ||
        !startDateTime ||
        !api.bulkPredictionDto ||
        !api.bulkPredictionDto.timestepUnit ||
        !api.bulkPredictionDto.timestepMagnitude
      ) {
        return;
      }
      const start = startDateTime.getTime();
      const unit = api.bulkPredictionDto.timestepUnit;
      const mag = api.bulkPredictionDto.timestepMagnitude;
      getNumberOfRowsInCsv(
        api.bulkPredictionCsv,
        false,
        (numberOfRows: number) => {
          ////console.log("got this number of rows in csv = ", numberOfRows);
          const rowInterval = timeUnitsToMilliseconds[unit] * mag;
          const end = start + (numberOfRows - 1) * rowInterval;
          setEndDateTime(new Date(end));
        }
      );
    }
  }, [
    api,
    startDateTime,
    api?.bulkPredictionCsv,
    api?.bulkPredictionDto?.timestepUnit,
    api?.bulkPredictionDto?.timestepMagnitude,
    endDateTimeControllingVariablesString,
    prevEndDateTimeControllingVariablesString,
  ]);

  const newQueueObj = useCallback(
    (
      depthToAdd: number,
      time: Date,
      lat: number,
      long: number,
      pileTopDepth: number
    ): GTPInputs => {
      return {
        day: time.getDate(),
        lat: lat,
        long: long,
        month: time.getMonth() + 1,
        pileTopDepth: pileTopDepth,
        predictionDepth: pileTopDepth + depthToAdd,
        datetime: time,
      };
    },
    []
  );

  const createGTPQueueForOneDepthInterval = useCallback(
    (
      depthInterval: number,
      startDT: Date,
      endDT: Date,
      lat: number,
      long: number,
      pileTopDepth: number
    ) => {
      const tempGTPQueue: GTPInputs[] = [];
      tempGTPQueue.push(
        newQueueObj(depthInterval, startDT, lat, long, pileTopDepth)
      );
      tempGTPQueue.push(
        newQueueObj(depthInterval, endDT, lat, long, pileTopDepth)
      );
      const millisecondsPerDay = 8.64e7;
      const oneMonthMilliseconds = 30 * millisecondsPerDay;
      const studyDurationMilliseconds = endDT.getTime() - startDT.getTime();
      const studyIsLongerThanOneMonth =
        studyDurationMilliseconds > oneMonthMilliseconds;
      console.log(
        "time duration study in ms",
        endDT.getTime() - startDT.getTime()
      );
      console.log(
        "longer than one month?",
        studyIsLongerThanOneMonth,
        startDT,
        endDT,
        oneMonthMilliseconds
      );
      if (studyIsLongerThanOneMonth) {
        let timeIteratorPosition = startDT.getTime();
        while (timeIteratorPosition + oneMonthMilliseconds < endDT.getTime()) {
          timeIteratorPosition += oneMonthMilliseconds;
          tempGTPQueue.push(
            newQueueObj(
              depthInterval,
              new Date(timeIteratorPosition),
              lat,
              long,
              pileTopDepth
            )
          );
        }
      }
      return tempGTPQueue;
    },
    [newQueueObj]
  );

  const updateQueue = useCallback(
    (
      depthCategory: 0 | 5 | 10,
      getV: boolean | undefined,
      queueV: GTPInputs[] | undefined,
      setter: React.Dispatch<SetStateAction<GTPInputs[] | undefined>>
    ) => {
      ////console.log(
      //   "in updateQueue callback with",
      //   startDateTime,
      //   endDateTime,
      //   api?.gtpLat,
      //   api?.gtpLong,
      //   api?.gtpPileDepth
      // );
      if (
        !startDateTime ||
        !endDateTime ||
        api?.gtpLat === undefined ||
        api?.gtpLong === undefined ||
        api?.gtpPileDepth === undefined
      )
        return;
      ////console.log("progressing through updateQueue callback");
      //handle at 0 m pile
      let newQueue = undefined;
      if (getV) {
        newQueue = createGTPQueueForOneDepthInterval(
          depthCategory,
          startDateTime,
          endDateTime,
          api.gtpLat,
          api.gtpLong,
          api.gtpPileDepth
        );
      }
      if (newQueue != queueV) {
        ////console.log("setting new queue", newQueue, queueV);
        setter(newQueue);
      }
      if (!getV && queueV) {
        setter(undefined);
      }
    },
    [
      createGTPQueueForOneDepthInterval,
      startDateTime,
      endDateTime,
      api?.gtpLong,
      api?.gtpLat,
      api?.gtpPileDepth,
    ]
  );
  //keep the local GTP queues for each depth up to date
  useEffect(() => {
    let shouldUpdateQueue = false;
    if (
      queueControllingVariablesString !== prevQueueControllingVariablesString
    ) {
      shouldUpdateQueue = true;
    }
    if (getAt0M !== prevGetAt0M) {
      shouldUpdateQueue = true;
    }
    if (shouldUpdateQueue) {
      updateQueue(0, getAt0M, queueAt0M, setQueueAt0M);
    }
  }, [
    getAt0M,
    queueAt0M,
    prevGetAt0M,
    updateQueue,
    queueControllingVariablesString,
    prevQueueControllingVariablesString,
  ]);

  useEffect(() => {
    ////console.log("in 5m queue update useEffect, ", getAt5M, prevGetAt5M);
    let shouldUpdateQueue = false;
    if (
      queueControllingVariablesString !== prevQueueControllingVariablesString
    ) {
      shouldUpdateQueue = true;
    }
    if (getAt5M !== prevGetAt5M) {
      shouldUpdateQueue = true;
    }
    if (shouldUpdateQueue) {
      updateQueue(5, getAt5M, queueAt5M, setQueueAt5M);
    }
  }, [
    getAt5M,
    queueAt5M,
    prevGetAt5M,
    updateQueue,
    queueControllingVariablesString,
    prevQueueControllingVariablesString,
  ]);

  useEffect(() => {
    let shouldUpdateQueue = false;
    if (
      queueControllingVariablesString !== prevQueueControllingVariablesString
    ) {
      shouldUpdateQueue = true;
    }
    if (getAt10M !== prevGetAt10M) {
      shouldUpdateQueue = true;
    }
    if (shouldUpdateQueue) {
      updateQueue(10, getAt10M, queueAt10M, setQueueAt10M);
    }
  }, [
    getAt10M,
    queueAt10M,
    prevGetAt10M,
    updateQueue,
    queueControllingVariablesString,
    prevQueueControllingVariablesString,
  ]);

  //keep queue controlling variables up to date
  useEffect(() => {
    //variables that effect GTP queue?
    //csv file
    const newString = `${api?.bulkPredictionCsv?.size}
    ${startDateTime}
    ${endDateTime}
    ${api?.gtpPileDepth}
    ${api?.gtpLat}
    ${api?.gtpLong}`;
    if (newString !== queueControllingVariablesString) {
      setQueueControllingVariablesString(newString);
    }
  }, [api, queueControllingVariablesString, startDateTime, endDateTime]);

  async function startGTPPredictionResults() {
    if (!getAt0M && !getAt5M && !getAt10M) {
      messageHandler.addMessage!(
        createNonFatalUserErrorMessage(
          "No Depths To Get",
          "You have not selected any of the three depth categories.",
          "Select the depth category(s) (0m, 5m, or 10m) that you need temperature predictions for."
        )
      );
      return;
    }
    //TODO create the most up to date GTPQueue object
    const newQueue = [
      ...(queueAt0M || []),
      ...(queueAt5M || []),
      ...(queueAt10M || []),
    ];
    if (newQueue.length < 1) {
      if (!api?.bulkPredictionCsv) {
        messageHandler.addMessage!(
          createNonFatalUserErrorMessage(
            "Missing CSV",
            "In order to get bulk GTP predictions you need to upload a valid Bulk Prediction CSV input file.",
            "If you are sure you have done the above, wait a second and try again."
          )
        );
      } else if (
        !api?.bulkPredictionDto?.timeAndDateOfFirstRowStart ||
        !api.bulkPredictionDto?.timestepUnit ||
        !api.bulkPredictionDto.timestepMagnitude
      ) {
        messageHandler.addMessage!(
          createNonFatalUserErrorMessage(
            "Missing Date/Time information.",
            "In order to get bulk GTP predictions you need to set valid time information (Date/Time of start row, Timestep Unit, and Timestep Magnitude) to inform us about your CSV file.",
            "Ground temperatures changed based on the time of year, which is why we need this information. If you are sure you have done the above, wait a second and try again."
          )
        );
      } else if (api.gtpPileDepth === undefined) {
        messageHandler.addMessage!(
          createNonFatalUserErrorMessage(
            "Missing Pile-Top Depth",
            "You must enter some number value, which could be zero, to indicate how deep the top of your average pile is below the true ground surface, in meters.",
            ""
          )
        );
      } else {
        messageHandler.addMessage!(
          createNonFatalApplicationErrorMessage(
            "Unknown Error",
            "Missing gtpQueue information, but csv file and date/time information appear to be present.",
            "Contact Developer."
          )
        );
      }
      return;
    }
    api?.setBulkGTPQueue(newQueue);
    const mesId = messageHandler.addMessage!(
      createBlockingWarningContinueMessage(
        "Warning",
        "In order for GTP ground temperature predictions to work you must first input all TIME information about your input CSV file. ",
        "This includes the Date/Time of the first row in the csv. The Timestep Unit, and Timestep Magnitude."
      )
    );
    //console.log('1');
    api?.setRunningGTPPredictionConfirmId(mesId);
  }

  useEffect(() => {
    //detect if change is coming from outside?
    if (!api) return;
    if (localLat == prevLocLat) {
      //change coming from outside
      setLocalLat(api.gtpLat);
    } else {
      // change coming from number input
      if (parseFloat(localLat) !== api.gtpLat && parseFloat(localLat)) {
        api.setGTPLat(localLat);
      }
    }
  }, [localLat, api, prevLocLat]);
  useEffect(() => {
    if (!api) return;
    //detect if change is coming from outside?
    if (localLong == prevLocLong) {
      //change coming from outside
      setLocalLong(api.gtpLong);
    } else {
      // change coming from number input
      if (parseFloat(localLong) !== api.gtpLong && parseFloat(localLong)) {
        api.setGTPLong(localLong);
      }
    }
  }, [localLong, api, prevLocLong]);

  return (
    <Popup
      closeCallback={closeCallback}
      title="Inputs Required For Ground Temperature Prediction"
    >
      <Container>
        <Section>
          <SectionTitle>1. Which Depths Are Needed</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Select the depths (relative to the top of the average geo-pile,
              NOT relative to the actual soil surface) for which you would like
              to have GTP automatic ground temperature predictions generated.
            </InstructionText>
            <VertDivider></VertDivider>
            <HorizCheckboxArea>
              <CheckBox
                checked={getAt0M || false}
                onClick={() => setGetAt0M((prev) => !prev)}
              />
              <CheckboxLabel>0 meters</CheckboxLabel>
              <CheckBox
                checked={getAt5M || false}
                onClick={() => setGetAt5M((prev) => !prev)}
              />
              <CheckboxLabel>5 meters</CheckboxLabel>
              <CheckBox
                checked={getAt10M || false}
                onClick={() => setGetAt10M((prev) => !prev)}
              />
              <CheckboxLabel>10 meters</CheckboxLabel>
            </HorizCheckboxArea>
          </DirectInputSection>
        </Section>
        <Section>
          <SectionTitle>2. Pile Depth</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Enter the buried depth of the pile: the distance between the top
              surface of the ground and the top of the geo-heat-exchanging
              section of the GEOPile:
            </InstructionText>
            <VertDivider></VertDivider>

            <NumberInput
              label="Pile Top Depth"
              value={api?.gtpPileDepth}
              setValue={api?.setGTPPileDepth}
              withUnitConversions
              allowedUnits={getAllowedUnitsFromBaseUnit("m")}
              outputUnit="m"
              labelWidth="30%"
            />
          </DirectInputSection>
          <InstructionPhotoSection>
            <InstructionPhoto src="BuriedDepth.svg"></InstructionPhoto>
            <InstructionSideText>
              The buried depth can be thought of as the start of the vertical
              section of the pile that is in direct (uninterrupted) contact with
              the ground, without the presence of grout or insulation.
              <br />
              <br />
              If there are multiple buried depths within an array, enter the
              average distance.
            </InstructionSideText>
          </InstructionPhotoSection>
        </Section>
        <Section>
          <SectionTitle>3. Location</SectionTitle>
          <DirectInputSection>
            <InstructionText>
              Select the location of the desired ground temperature on the map,
              or manually enter the latitude and longitude:
            </InstructionText>
            <VertDivider></VertDivider>
            <VertFlex>
              <HorizFlex>
                <label>Latitude</label>
                <input
                  type="number"
                  step="0.00001"
                  min="-90"
                  max="90"
                  onChange={(e) => setLocalLat(e.target.value)}
                  value={localLat}
                ></input>
              </HorizFlex>
              <HorizFlex>
                <label>Longitude</label>
                <input
                  type="number"
                  step="0.00001"
                  min="-180"
                  max="180"
                  onChange={(e) => setLocalLong(e.target.value)}
                  value={localLong}
                ></input>
              </HorizFlex>
            </VertFlex>
          </DirectInputSection>
          <LocationInput
            lat={api?.gtpLat || 0}
            long={api?.gtpLong || 0}
            setLong={api?.setGTPLong}
            setLat={api?.setGTPLat}
          />
        </Section>
      </Container>
      <BottomBar>
        <LogoSection>
          <GTPLogo src="GTPLogo.svg" />
          <VertFlexMin>
            <div>Predicted with Umny AI through the GTP Application.</div>
            <HorizFlexMin>
              <Button
                custCss="background-color: var(--c-gray-dark);"
                onClick={() =>
                  window.open("https://groundtemperatures.com/accuracy")
                }
              >
                Accuracy
              </Button>
              <Button
                custCss="background-color: var(--c-gray-dark);"
                onClick={() =>
                  window.open(
                    "https://secureservercdn.net/104.238.68.196/c65.c15.myftpupload.com/wp-content/uploads/2021/09/GTP-Technical-Brochure-2021.pdf"
                  )
                }
              >
                Info
              </Button>
            </HorizFlexMin>
          </VertFlexMin>
        </LogoSection>
        <BottomCenterButton onClick={startGTPPredictionResults}>
          Get Predictions
        </BottomCenterButton>
      </BottomBar>
    </Popup>
  );
};
export default GTPBulkPredictions;
function createNonFatalHTTPRequestErrorMessage(
  arg0: string,
  arg1: number,
  arg2: string
): import("lib/ErrorAndMessageHandling/MessageHandler").Message {
  throw new Error("Function not implemented.");
}
