import { logMessageToDb } from "lib/api";
import { config } from "lib/CONFIG-consts";
import {
  deepCloneMessage,
  GetUpdatedMessageCallback,
  HandleMessageResponseCallback,
  Message,
  MessageContentForDisplay,
  MessageHandler,
  MessageHandlingStates,
  MessageResponse,
} from "lib/ErrorAndMessageHandling/MessageHandler";
import { randomInt } from "mathjs";
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {messagesActions} from "redux/messagesSlice";
import { RootState } from "redux/store";
import { StringDecoder } from "string_decoder";
import { v4 as uuidv4 } from 'uuid';
import { usePrevious } from "../../lib/usePrevious";
import WholeAppMessageOverlay from "../MessageHandlerComponents/WholeAppMessageOverlay";

export interface MessageUpdateInstructions {
  messageId: string;
  contentForDisplay?: MessageContentForDisplay;
  newUpdateableNumber?: number;
  newUpdateableNumberIncrement?: number;
  messageResolved?: MessageResponse | false;
  messageHandlingState?: MessageHandlingStates;
}

//define types here
interface MessageHandlerContextProps {
  wholeAppOverlay: ReactNode;
  addMessage: (m: Message) => string;
  resolveMessage: (id: string) => void;
  updateMessageProgress: (id: string, newProgress: number) => void;
  incrementMessageProgress: (id: string, progressToAdd: number) => void;
}

//initialize state structure here
export const MessageHandlerContext = React.createContext<
  Partial<MessageHandlerContextProps>
>({});

const MessageHandlerProvider: React.FunctionComponent = ({ children }) => {
  const messages = useSelector((state: RootState)=>state.messages.map);
  const currentBlockingMessageId = useSelector((state: RootState)=>state.messages.currentBlockingMessageId);
  const idToSendToMessageHandler = useSelector((state: RootState)=> state.messages.idToSendToMessageHandler);
  const dispatch = useDispatch();
  const [wholeAppOverlay, setWholeAppOverlay] = useState<ReactNode>();

  //!DEBUGGING
  useEffect(()=>{
   //console.log('messages has changed ...',messages);
  },[messages]);


  const messageHandler = useMemo(
    () =>
      new MessageHandler(
        displayBlockingMessage,
        displayNonBlockingMessage,
        sendMessageToDeveloper,
        restartApplicationCallback,
        cleanupMessageNotBeingDisplayedToUser
      ),
    []
  );

  function displayBlockingMessage(
    m: Message,
    mId: string,
    completedCallback: HandleMessageResponseCallback
  ): void {
   //console.log("displayBlockingMessage called, with ", m);
    //! customize here
    setWholeAppOverlay(
      <WholeAppMessageOverlay
        messageId={mId}
        closeCallback={(response?: MessageResponse) => {
         //console.log(
          //   "close callback on whole app overlay called",
          //   response,
          //   m
          // );
          setWholeAppOverlay(undefined);
          completedCallback(response || "handledByUserCanMoveOn", m);
         //console.log('111');
          dispatch(messagesActions.resolve({id: mId, resolution: response}));
        }}
      ></WholeAppMessageOverlay>
    );
  }
  function displayNonBlockingMessage(m: Message,id: string): void {
    //! customize here
    //TODO, in future
   //console.log('Trying to display non-blocking message, but nothing implemented yet, resolving message');
    return 
  }
  function sendMessageToDeveloper(m: Message, id: string): void {
    const apiToken = window.sessionStorage.getItem(
      config.apiTokenSessionStorageKey
    );
    if (!apiToken) return;
    logMessageToDb(m, apiToken);
   //console.log('message sent to developer',m);
  }
  function cleanupMessageNotBeingDisplayedToUser(m: Message, id: string): void{
   //console.log('calling cleanupMessageifNothingElseHasBeenDone');
    dispatch(messagesActions.resolve({id: id, resolution: 'handledByUserCanMoveOn'}));
  }
  async function restartApplicationCallback() {
    window.location.reload();
  }



  // //if any message has resolved set, make sure its not being handled still
  // useEffect(()=>{
  //   if (!messages || !messages || !Object.keys(messages))return;
  //   const wrongMessageIds = Object.keys(messages).filter((messageId: string)=>{
  //     const message = messages[messageId];
  //     return (message.messageResolved && (message.messageHandlingState === 'beingHandled'));
  //   });
  //   wrongMessageIds.forEach((mId: string)=>{
  //    //console.log('cleaning up message with resolved state that still has "beingHandled" state.')
  //     dispatch(update({messageId: mId, messageHandlingState: 'handled'}));
  //   })
  // },[messages, dispatch]);

  //treat allMessages like a queue, because I'm experiencinging issues with react state async updates
  //this handles messages, ONLY after the previous blocking message has been resolved
  useEffect(() => {
   //console.log('going to see if should handle any messages', messages.currentBlockingMessageId);
    if (!messages || !messages) return;
    if (messages.currentBlockingMessageId){
      return;
    }
    if (!Object.keys(messages).length) return;
   //console.log('going to search through messages for something needing handling');
    const messageId = Object.keys(messages).find(
      (id) => messages[id].messageHandlingState === "inQueue"
    );
    if (messageId === undefined) return;
    const nextMessageToHandle = messages[messageId];
    if (!nextMessageToHandle) return;
    if (nextMessageToHandle.isBlocking && wholeAppOverlay) {
     //console.log('found message to be handled, but is being blocked by current whole app overlay');
      //check if this message is the same as the one being currently handled in wholeAppOverlay, and delete/handle it if so
      const currentMessageBeingHandledId = Object.keys(messages).find(messageId => {
        const message = messages[messageId];
        return (message.messageHandlingState === 'beingHandled');
      });
      if (currentMessageBeingHandledId){
      const currentMessageBeingHandled = messages[currentMessageBeingHandledId];
      const messageBeingHandledIsTheSameAsNextMessage = (
        nextMessageToHandle.contentForDisplay.title === currentMessageBeingHandled.contentForDisplay.title
        &&
        nextMessageToHandle.contentForDisplay.summary === currentMessageBeingHandled.contentForDisplay.title
        && 
        nextMessageToHandle.contentForDisplay.additionalInfo === currentMessageBeingHandled.contentForDisplay.additionalInfo
        );
        if (messageBeingHandledIsTheSameAsNextMessage){
         //console.log('cleaning up a probable duplicate message, the same as the one currently blocking the application');
          dispatch(messagesActions.resolve({id: currentMessageBeingHandledId, resolution: 'failedToDisplay'}));
        }
      }
     //console.log('not handling message yet, because wholeApp overlay is alread set');
      return;
    }
   //console.log('detected a message that should be handled, going to handle it');
    try{
      dispatch(messagesActions.startHandling({id: messageId, shouldForceCloseCurrentBlockingMessage: false}));
    }catch (e: any){
      if (e.message.includes('Cannot start handling this message because there is already a message being handled')){
       //console.log('not handling message because there is already a message being handled apparently', currentBlockingMessageId);
        return;
      }
      else throw e;
    }
  }, [wholeAppOverlay,messages,dispatch, messageHandler, currentBlockingMessageId]);

  //display the current blocking message
  // useEffect(()=>{
    ////console.log('checking if should render whole app overlay', currentBlockingMessageId, wholeAppOverlay)
    // if (!idToSendToMessageHandler && wholeAppOverlay){
      // setWholeAppOverlay(undefined)
    // }
    // if (idToSendToMessageHandler && !wholeAppOverlay){
      // messageHandler.handleMessage(messages[currentBlockingMessageId], currentBlockingMessageId);
    // }
  // },[currentBlockingMessageId, wholeAppOverlay, messageHandler, messages]);
  
  const prevIdToSendToMessageHandler = usePrevious(idToSendToMessageHandler);
  //handle changes to idToSendToMessageHandler state
  useEffect(()=>{
    if (idToSendToMessageHandler && (prevIdToSendToMessageHandler !== idToSendToMessageHandler)){
      const message = messages[idToSendToMessageHandler];
      if (message){
       //console.log('going to send Message to messageHandler');
        dispatch(messagesActions.haveSentToMessageHandler(idToSendToMessageHandler));
        messageHandler.handleMessage(message,idToSendToMessageHandler);
      }
    }
  },[idToSendToMessageHandler, messages, dispatch, messageHandler, prevIdToSendToMessageHandler]);

  const addMessage = useCallback(
    (newMessage: Message): string => {
      const tempId = uuidv4();
     //console.log("in addMessage, with id", tempId);
      newMessage.messageHandlingState = "inQueue";
      dispatch(messagesActions.add({message: newMessage, id: tempId}));
      return tempId;
    },
    [dispatch]
  );

  
 
  const resolveMessage = useCallback(
    (id: string) => {
      try {
       //console.log('resolving message ',id)
        dispatch(messagesActions.resolve({id, resolution: 'handledByUserCanMoveOn'}));
      } catch (e: any) {
        throw new Error(
          "The requested message, id = " +
            id +
            ", does not exist. " +
            JSON.stringify(e)
        );
      }
    },
    [dispatch]
  );
  const updateMessageProgress = useCallback(
    (id: string, newProgress: number) => {
      dispatch(messagesActions.updateProgress({newProgress, id}))
    },
    [dispatch]
  );
  const incrementMessageProgress = useCallback(
    (id: string, progressToAdd: number) => {
     //console.log("going to increment message progress");
      dispatch(messagesActions.incrementProgress({progressToAdd, id}));
    },
    [dispatch]
  );

  return (
    <MessageHandlerContext.Provider
      value={{
        wholeAppOverlay: wholeAppOverlay,
        addMessage: addMessage,
        resolveMessage: resolveMessage,
        updateMessageProgress: updateMessageProgress,
        incrementMessageProgress: incrementMessageProgress,
      }}
    >
      {children}
    </MessageHandlerContext.Provider>
  );
};
export default MessageHandlerProvider;
