// import { fakeBulkPredictionResults } from 'lib/models/bulk-prediction-results';
import { GlobalContext } from "components/Contexts/Global";
import { VertFlex } from "components/MainMenu";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";
import {
  OfficialVariables,
  readableVariable,
  getOfficialVariableFromAbrv,
} from "lib/types";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import GenerateReportForm from "./Reusables/GenerateReportForm";
import GenerateReportFormBulk from "./Reusables/GenerateReportFormBulk";
import StaticOutputVariable from "./Reusables/StaticOutputVariable";
import ResultsViewTemplate, { IResultsVariable } from "./Reusables/Template";
import TripleLineChart, {
  TripleLineChartProps,
} from "./Reusables/TripleLineChart";

const ResultsImage = styled.img`
  height: var(--s-20);
`;
const DetailImage = styled.img`
  height: var(--s-16);
  align-items: center;
  margin: var(--s-4);
`;

export const TextLabel = styled.h4`
  font-size: var(--s-9);
  color: var(--c-gray-light);
  align-items: center;
`;

export const Unit = styled.div`
  color: var(--c-green-bright);
  font-size: var(--s-20);
  padding: 0;
  margin-block: 0;
`;

const StandardSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TopSection = styled.div`
  ${StandardSection}
`;
const MiddleResultsSection = styled.div`
  ${StandardSection}
  flex-direction: row;
  align-items: flex-start;
`;
const MiddleSection = styled.div`
  ${StandardSection}
  flex-direction: row;
  align-items: flex-start;
`;
const LeftSection = styled.div`
  ${StandardSection}
`;

const CenterSection = styled.div`
  ${StandardSection}
`;
const RightSection = styled.div`
  ${StandardSection}
`;
const BottomSection = styled.div`
  ${StandardSection}
`;
const RightActionSection = styled.div`
  ${StandardSection}
  justify-content: flex-start;
  height: 100%;
`;

interface BulkPredictionResultsProps {
  data: BulkHeatPumpCapacityResult;
}
const BulkPredictionResults: React.FunctionComponent<BulkPredictionResultsProps> =
  ({ data }) => {

    const gc = useContext(GlobalContext);

    function targetVariableProperName() {
      return getOfficialVariableFromAbrv(data.targetVariableName);
    }
    const targetProperName = targetVariableProperName();
    const targetReadableName = readableVariable[targetProperName];
    const tReadableNameNoT = readableVariable[resultsVariableNameFromTarget()];
    function resultsVariableNameFromTarget(): OfficialVariables {
      switch (data.targetVariableName) {
        case "WhpT":
          return "wholeArrayHeatPumpCapacity";
        case "WgheT":
          return "averageGeoPileThermalCapacity";
        case "AWgheT":
          return "wholeArrayGeoPileCapacity";
      }
    }
    //! functions for working with the raw data
    function getFirstGraphValues(): TripleLineChartProps {
      return {
        xAxisLabels: data.perRowResults.map((r) =>
          new Date(r.dateTime).toISOString().slice(0, 10)
        ),
        lines: [
          {
            data: data.perRowResults.map((r) => r.targetValueInput || 0),
            label: "Target",
            color: "white",
          },
          {
            data: data.perRowResults.map((r) => r.targetValuePredicted || 0),
            label: "Actual",
            color: "green",
            barOverride: true,
          },
          {
            data: data.perRowResults.map(
              (r) => r.targetValuePredictedMinusInput || 0
            ),
            label: "Difference",
            color: "orange",
          },
        ],
        title: tReadableNameNoT + " [W]",
      };
    }
    function getSecondGraphValues(): TripleLineChartProps {
      return {
        xAxisLabels: data.perRowResults.map((r) =>
          new Date(r.dateTime).toISOString().slice(0, 10)
        ),
        lines: [
          {
            data: data.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileInletFluidTemperature
                : r.withoutHeatPumpResults.pileInletFluidTemperature || 0
            ),
            label: "Inlet Temperature",
            color: "lightGreen",
          },
          {
            data: data.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileOutletFluidTemperature
                : r.withoutHeatPumpResults.pileOutletFluidTemperature || 0
            ),
            label: "Outlet Temperature",
            color: "green",
          },
          {
            data: data.perRowResults.map((r) =>
              r.withHeatPumpResults
                ? r.withHeatPumpResults.pileInletFluidTemperature -
                  r.withHeatPumpResults.pileOutletFluidTemperature
                : r.withoutHeatPumpResults.pileInletFluidTemperature -
                    r.withoutHeatPumpResults.pileOutletFluidTemperature || 0
            ),
            label: "Difference",
            color: "orange",
          },
        ],
        title: "Fluid Temperatures At Piles " + "[°C]",
      };
    }
   //console.log(data.dataSummaries);

    const firstGraphValues = getFirstGraphValues();
    const secondGraphValues = getSecondGraphValues();
    return (
      <VertFlex>
        <TopSection>
          <TripleLineChart
            title={firstGraphValues.title}
            lines={firstGraphValues.lines}
            xAxisLabels={firstGraphValues.xAxisLabels}
          />
          <TripleLineChart
            title={secondGraphValues.title}
            lines={secondGraphValues.lines}
            xAxisLabels={secondGraphValues.xAxisLabels}
          />
        </TopSection>
        <MiddleSection>
        <VertFlex>
          <h3>{tReadableNameNoT}</h3>
          <MiddleResultsSection>
            <LeftSection>
              <StaticOutputVariable
                value={data.dataSummaries.maxTargetValueActual.value || 0}
                unit={"W"}
                decimalPlaces={1}
                label={`Peak Cooling`}
              />
              <StaticOutputVariable
                decimalPlaces={1}
                label={`Total, Cooling`}
                unit={"kWh"}
                value={data.dataSummaries.sumCoolingPeriods || 0}
              />
            </LeftSection>
            <RightSection>
              <StaticOutputVariable
                label={'Peak Heating'}
                value={data.dataSummaries.minTargetValueActual.value || 0}
                unit={"W"}
                decimalPlaces={1}
              />
              <StaticOutputVariable
                label={`Total, Heating`}
                value={data.dataSummaries.sumHeatingPeriods || 0}
                unit={"kWh"}
                decimalPlaces={1}
              />
            </RightSection>
          </MiddleResultsSection>
          <MiddleResultsSection>
            <LeftSection>
              <StaticOutputVariable
                label={`Average, Whole-Time-Period`}
                value={data.dataSummaries.targetValueActualAverage || 0}
                unit={"W"}
                decimalPlaces={1}
              />
              <StaticOutputVariable
                label={`Total, Whole-Time-Period`}
                value={data.dataSummaries.targetValueActualSum || 0}
                unit={"kWh"}
                decimalPlaces={1}
              />
            </LeftSection>
            <RightSection>
              <StaticOutputVariable
                label={`Actual - Target (difference), Average, Whole-Time-Period`}
                value={data.dataSummaries.targetValueDifferenceAverage || 0}
                unit={"W"}
                decimalPlaces={1}
              />
              <StaticOutputVariable
                label={`Actual - Target (difference), Total, Whole-Time-Period`}
                value={data.dataSummaries.targetValueDifferenceSum || 0}
                unit={"kWh"}
                decimalPlaces={1}
              />
            </RightSection>
          </MiddleResultsSection>
          {data.withHeatPump ? (
            <>
              <h3>Heat Pump COP Summary</h3>
              <MiddleResultsSection>
                <LeftSection>
                  <StaticOutputVariable
                    label="Average, Cooling Period"
                    value={data.dataSummaries.averageCOPCoolingPeriods || 0}
                    unit={""}
                    decimalPlaces={1}
                  />
                  <StaticOutputVariable
                    label="At Peak Cooling"
                    value={data.dataSummaries.copAtPeakCooling?.value || 0}
                    unit={""}
                    decimalPlaces={1}
                  />
                </LeftSection>
                <CenterSection>
                  <StaticOutputVariable
                    label="Average, Whole-Time-Period"
                    value={data.dataSummaries.averageCOP || 0}
                    unit={""}
                    decimalPlaces={1}
                  />
                </CenterSection>
                <RightSection>
                  <StaticOutputVariable
                    label="Average, Heating Periods"
                    value={data.dataSummaries.averageCOPHeatingPeriods || 0}
                    unit={""}
                    decimalPlaces={1}
                  />
                  <StaticOutputVariable
                    label="At Peak Heating"
                    value={data.dataSummaries.copAtPeakHeating?.value || 0}
                    unit={""}
                    decimalPlaces={1}
                  />
                </RightSection>
              </MiddleResultsSection>
              <h3>Energy (Absolute) Summations</h3>
              <MiddleResultsSection>
                <LeftSection>
                <StaticOutputVariable
                    label="Produced, Cooling Period"
                    value={data.dataSummaries.totalEnergyProducedByHeatPumpCoolingkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={0}
                  />
                  <StaticOutputVariable
                    label="Consumed, Cooling Period"
                    value={data.dataSummaries.totalEnergyConsumedByHeatPumpCoolingkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={0}
                  />

                </LeftSection>
                <CenterSection>
                <StaticOutputVariable
                    label="Produced, Whole-Time-Period"
                    value={data.dataSummaries.totalEnergyProducedByHeatPumpkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={0}
                  />
                  <StaticOutputVariable
                    label="Consumed, Whole-Time-Period"
                    value={data.dataSummaries.totalEnergyConsumedByHeatPumpkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={0}
                  />
                </CenterSection>
                <RightSection>
                <StaticOutputVariable
                    label="Produced, Heating Period"
                    value={data.dataSummaries.totalEnergyProducedByHeatPumpHeatingkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={1}
                  />
                  <StaticOutputVariable
                    label="Consumed, Heating Period"
                    value={data.dataSummaries.totalEnergyConsumedByHeatPumpHeatingkWh || 0}
                    unit={"kWh"}
                    decimalPlaces={1}
                  />
                </RightSection>
              </MiddleResultsSection>
            </>
          ) : (
            <></>
          )}
          </VertFlex>
          <RightActionSection>
            <GenerateReportFormBulk
              withHeatPump={data.withHeatPump}
              session={data}
            />
          </RightActionSection>
        </MiddleSection>
      </VertFlex>
    );
  };
export default BulkPredictionResults;
