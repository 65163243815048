import { BackendAPIContext } from 'components/Contexts/BackendAPI';
import { getPercentage } from 'lib/helper-functions';
import { IPileLengthDynamicReturnType, IPileLengthResults, IPileLengthResultsWithHeatPump, IPileLengthResultsWithoutHeatPump } from 'lib/models/calculation-results';
import { IPlasticPipeNominalSize } from 'lib/models/plastic-pipe-nominal-size';
import { ISteelPileNominalSize } from 'lib/models/steel-pipe-nominal-size';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import GenerateReportForm from './Reusables/GenerateReportForm';
import ResultsViewTemplate, { IResultsVariable}from './Reusables/Template';


interface PileLengthResultsProps{
    data: IPileLengthDynamicReturnType
}
const PileLengthResults: React.FunctionComponent<PileLengthResultsProps> =({
    data
})=> {
    
    const api = useContext(BackendAPIContext);
    // const [heatPumpCapacity, setPerPileHeatPumpCapacity] = useState<number>(data.withHeatPump?data.withHeatPump.perPileHeatPumpCapacity:0);
    // const [wholeArrayHeatPumpCapacity, setWholeArrayHeatPumpCapacity]  = useState<number>();
    // const [localNumberOfPiles, setLocalNumberOfPiles] = useState<number|null|undefined>(null);
    // const [localTargetWholeArrayHeatPumpCapacity, setLocalTargetWholeArrayHeatPumpCapacity] = useState<number|null|undefined>(null);

    // //keep whole array heat pump output up to date
    // useEffect(()=>{
    //     const numberOfActivePilesInTheArray = api?.calculator?.pileArray?.numberOfActivePilesInTheArray?.inputValue;
    //     if (!numberOfActivePilesInTheArray) return;
    //     setWholeArrayHeatPumpCapacity(heatPumpCapacity * numberOfActivePilesInTheArray);
    // },[heatPumpCapacity, api?.calculator]);

    // //keep localNumberOfPiles in sync both ways
    // useEffect(()=>{
    //     if (!api || !api.calculator) return;
    //     if (localNumberOfPiles === api.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue) return;
    //     if (localNumberOfPiles === null) {
    //         setLocalNumberOfPiles(api.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue);
    //         return;
    //     }
    //     api.setCalculator(prev => {
    //         if (!prev || !prev.pileArray || !prev.pileArray.numberOfActivePilesInTheArray) return prev;
    //         const clone = {...prev};
    //         clone.pileArray!.numberOfActivePilesInTheArray!.inputValue = localNumberOfPiles;
    //         return clone;
    //     });

    // },[localNumberOfPiles, api?.calculator]);

    // //keep localTargetWholeArrayHeatPumpCapacity in sync both ways
    // useEffect(()=>{
    //     if (!api || !api.calculator || !api.calculator.pileArray || !api.calculator.pileArray.targetWholeArrayHeatPumpCapacity) return;
    //     if (localNumberOfPiles === api.calculator.pileArray.targetWholeArrayHeatPumpCapacity.inputValue) return;
    //     if (localTargetWholeArrayHeatPumpCapacity === null){
    //         setLocalTargetWholeArrayHeatPumpCapacity(api.calculator.pileArray.targetWholeArrayHeatPumpCapacity.inputValue);
    //         return;
    //     }
    //     api.setCalculator(prev=>{
    //         if (!prev || !prev.pileArray || !prev.pileArray.targetWholeArrayHeatPumpCapacity) return prev;
    //         const clone = {...prev};
    //         clone.pileArray!.targetWholeArrayHeatPumpCapacity!.inputValue = localTargetWholeArrayHeatPumpCapacity;
    //         return clone;
    //     });
    // },[localTargetWholeArrayHeatPumpCapacity, api?.calculator]);

    const [wholeArrayHeatPumpCapacity, setWholeArrayHeatPumpCapacity]  = useState<number>(
        data.withHeatPump?.withHeatPump?
        data.withHeatPump.perPileHeatPumpCapacity *
             (data.withHeatPump.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue || 0)
        : 0
    );

    let resultsVariables: IResultsVariable[] = [];
    if (data.withHeatPump){
        const d = data.withHeatPump as IPileLengthResultsWithHeatPump;
        resultsVariables = [
            {
                label: "Optimal Pile Length",
                value: d.steelPileLength,
                type: 'static',
                unit: 'm',
                decimalPlaces: 2,
                column: 'right',
                orderWithinColumn: 0,
            },
            {
                label: "Per-Pile Heat Pump Capacity Supplied",
                value: d.perPileHeatPumpCapacity,
                type: 'static',
                decimalPlaces: 2,
                unit: 'W',
                column: 'right',
                orderWithinColumn: 1,
            },
            {
                label: "Number of Active Piles",
                value: d.calculator.pileArray?.numberOfActivePilesInTheArray?.inputValue || 1,
                type: 'static',
                decimalPlaces: 0,
                column: 'right',
                orderWithinColumn: 2,
            },
            {
                label: "Whole-Array Heat Pump Capacity Supplied",
                value: wholeArrayHeatPumpCapacity || 0,
                type: 'static',
                unit: 'W',
                decimalPlaces: 2,
                column: 'left',
                orderWithinColumn: 0
            },
            {
                label: "Target Whole-Array Heat Pump Capacity",
                value: d.calculator.pileArray?.targetWholeArrayHeatPumpCapacity?.inputValue ||0,
                type: 'static',
                // setValue: setLocalTargetWholeArrayHeatPumpCapacity,
                unit: 'W',
                decimalPlaces:2,
                column: 'left',
                orderWithinColumn: 1
            },
            {
                label: "Percentage of Target Supplied",
                value: getPercentage(wholeArrayHeatPumpCapacity || 0,
                     d.calculator.pileArray?.targetWholeArrayHeatPumpCapacity?.inputValue || 100),
                type: 'circle',
                unit: '%',
                decimalPlaces: 0,
                column: 'left',
                orderWithinColumn: 2
            }   
        ];
    }else {
        const d2: IPileLengthResultsWithoutHeatPump = data.withoutHeatPump!;
        resultsVariables = [
            
            {
                label: "Average Geo-Pile Thermal Capacity",
                value: d2.averageGeoPileThermalCapacity!,
                type: 'static',
                unit: 'W',
                decimalPlaces: 2,
                column: 'left',
                orderWithinColumn: 0
            },
            {
                label: "Target Per Pile Thermal Capacity",
                value: d2?.calculator?.pileArray?.targetGeoPileThermalCapacity?.inputValue || 0,
                type: 'static',
                unit: 'W',
                decimalPlaces: 2,
                column: 'left',
                orderWithinColumn: 1
            },
            {
                label: "Percentage Of Target Supplied",
                value: getPercentage(
                    data.withoutHeatPump?.averageGeoPileThermalCapacity || 0
                    ,data.withoutHeatPump?.calculator.pileArray?.targetGeoPileThermalCapacity?.inputValue || 100,
                    ),
                type: 'circle',
                unit: '%',
                decimalPlaces: 1,
                column: 'left',
                orderWithinColumn: 2
            },
            {
                label: "Capacity By Length",
                value: d2.averageGeoPileThermalCapacity / d2.steelPileLength,
                type: 'static',
                unit: 'W/m',
                decimalPlaces: 2,
                column:'right',
                orderWithinColumn: 0
            },
            {
                label: "Change in Temperature Across Pile",
                value: d2.changeInTemperatureAcrossPile,
                type: 'static',
                unit: '\xB0C',
                decimalPlaces: 2,
                column:'right',
                orderWithinColumn: 1
            },
            {
                label: "Optimal Pile Length",
                value: d2.steelPileLength,
                type: 'static',
                unit: 'm',
                decimalPlaces: 2,
                column:'right',
                orderWithinColumn: 2
            },
        ];
    }


 

return (
<ResultsViewTemplate
    resultsVariables={resultsVariables}
    withHeatPump={(data.withHeatPump)?true:false}
    sessions={[data.withHeatPump?data.withHeatPump:data.withoutHeatPump]}
/>
);
}

export default PileLengthResults;