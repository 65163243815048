import React, { ReactNode, useEffect, useContext, useState, SetStateAction } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import Button from "./Button";
import { Card, Option, ReusableComponentBase } from "sheldons-components";
import Select from "./Select";
import GeneralEditForm from "components/ModelEditForms/GeneralEditForm";
import GeneralInput from "./GeneralInput";
import { createEmpty } from "lib/helper-functions";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import { GlobalContext } from "components/Contexts/Global";
import { ApiEndpoints, OfficialVariables } from "lib/types";
import { ModelNameTypes } from "lib/objectCrudPropsHelpers";

const Container = styled.div`
  background: var(--c-gray-very-dark);
  position: relative;
  padding: var(--s-7);
  margin-bottom: var(--s-15);
  color: var(--c-white);
`;
const TitleContainer = styled.div`
  background: var(--c-green-dark);
  width: 99%;
  position: absolute;
  top: -4vw;
  left:0;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
`;
const TitleText = styled.h3`
  color: var(--c-black);
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-between;
  padding: var(--s-2);
  color: var(--c-background-white);
`;
const ACRow = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--c-gray-dark);
  margin: 0;
  padding: var(--s-2);
  width: 100%;
`;
const ACRow1 = styled.div`
  ${ACRow}
  background: var(--c-gray-light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;
const SelectLabel = styled.div`
  color: var(--c-gray-dark);
  font-weight: bold;
`;
const ACRow2 = styled.div`
  ${ACRow}
  background: var(--c-gray);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
const MutedButton = css`
  background: var(--c-gray-dark);
`;


export interface CrudableObject {
  id?: string | number | null;
  name?: string | null;
}

export interface EditFormProps extends ReusableComponentBase {
  object: object;
  setObject: (newObject: object) => void;
}
export type InstructionKeyMap = {[key: string]: string | undefined};
export interface ObjectCrudProps extends ReusableComponentBase {
  title: string;
  emptyObject: object;
  instructionKeyMap: InstructionKeyMap;
  appendNodes?: ReactNode;
  fieldsToExclude?: string[]
  customEditForm?: JSX.Element;
  setCustomEditFormProps?: any;
  modelEndpoint: ApiEndpoints,
  hideCrud?: boolean;
  fieldsToEmphasize?: string[];
  fieldsToDisable?: string[];
  universalDisabledMessage?: string
  names: ModelNameTypes
  imageLinkMap: {[fieldName:string]:string}
  fieldsInOrder: OfficialVariables[]
}

const ObjectCrud: React.FunctionComponent<ObjectCrudProps> = ({
  styles,
  title,
  instructionKeyMap,
  appendNodes,
  emptyObject,
  fieldsToExclude,
  customEditForm,
  setCustomEditFormProps,
  modelEndpoint,
  hideCrud,
  fieldsToDisable,
  fieldsToEmphasize,
  universalDisabledMessage,
  names,
  imageLinkMap,
  fieldsInOrder
}) => {
  const api = useContext(BackendAPIContext);
  const gc = useContext(GlobalContext);

  //@ts-ignore
  const savedObjects: CrudableObject[]|undefined = api[names.lcp];
  //@ts-ignore
  const setSavedObjects: React.Dispatch<SetStateAction<any>> = api['set'+names.ucp];
  //@ts-ignore
  const editingObject: CrudableObject | undefined = api[names.lc];
//@ts-ignore
  const setEditingObject: React.Dispatch<SetStateAction<any>> = api['set'+names.uc];

  // //console.log('api methods and references in object crud: ', savedObjects, setSavedObjects, editingObject, setEditingObject);
  // //console.log("savedObjects in object crud = ", savedObjects);
  const [selectedObject, setSelectedObject] = useState<
    CrudableObject | undefined
  >();

  function savedObjectsToOptions(): Option[] | undefined {
    if (!savedObjects || savedObjects.length < 1) return undefined;
    // //console.log(savedObjects);
    const savedOptions = savedObjects.map((savedObject) => {
      return crudableObjectToOption(savedObject);
    });
    return savedOptions?.filter((item: Option | undefined) => item) as Option[];
  }
  function crudableObjectToOption(
    obj: CrudableObject | undefined
  ): Option | undefined {
    if (!obj || obj.id === null || obj.id === undefined) return undefined;
    return { id: obj.id, value: obj.id, content: obj.name };
  }
  function getSavedObjectFromOption(option: Option | undefined) {
    if (!savedObjects || !option) return undefined;
    return savedObjects.find((savedObject) => savedObject.id === option.id);
  }
  async function handleOverwriteClick() {
    if (!editingObject || !gc?.apiToken) return;
    if (_.isEqual(editingObject, emptyObject)) return;
    if (!editingObject.name){
      window.alert('Error: You must enter a name.');
      return;
    }
    if (editingObject.name.length > 30) {
      window.alert('Error: You cannot use a name longer than 30 characters.');
      return;
    }
    await api?.updateModel(modelEndpoint, editingObject,gc.apiToken);
    api?.refreshModelsLists(gc.apiToken);
    refreshSelectedObject();
  }
  function refreshSelectedObject(){
    //console.log('refreshing selected object');
    const id = selectedObject?.id;
    const newSelected = savedObjects?.find(object => object.id === id);
    //console.log('found this new selected object', newSelected);
    setSelectedObject(newSelected);
  }
  function handleSaveAsNewClick(){
    if (!editingObject || !gc?.apiToken ) return;
    if (_.isEqual(editingObject, emptyObject)) return;
    if (!editingObject.name){
      window.alert('Error: You must enter a name.');
      return;
    }
    if (editingObject.name.length > 60) {
      window.alert('Error: You cannot use a name longer than 60 characters.');
      return;
    }
    api?.saveModel(modelEndpoint,editingObject,gc.apiToken, savedObjects);
  }
  async function handleDeleteClick() {
    if (!editingObject ||!gc?.apiToken) return;
    const name = editingObject.name;
    if (!name) return;
    api?.deleteModel(modelEndpoint, name,gc.apiToken);
    api?.refreshModelsLists(gc.apiToken);
    setSelectedObject(undefined);
   
  }
  function handleLoadClick() {
    if (!selectedObject) return;
    //console.log('loading object: ', selectedObject);
    setEditingObject({...selectedObject});
  }
//deleteme
useEffect(()=>{
  //console.log('editing object in object crud has changed', editingObject);
},[editingObject]);
 

  return (
    <Container>
      <TitleContainer>
        <TitleText>{title}</TitleText>
      </TitleContainer>
      {hideCrud?
      <></>:
      <ActionsContainer>
        <ACRow1>
          <SelectLabel>Select from saved {(title.slice(title.length -1)==='s')?title:title+'s'}:</SelectLabel>
          <Select
            type="styled"
            options={savedObjectsToOptions()}
            selected={crudableObjectToOption(selectedObject)}
            onChange={(obj: Option | undefined) =>
              setSelectedObject(getSavedObjectFromOption(obj))
            }
            placeholder={`Select`}
          ></Select>
          <Button custCss={MutedButton} onClick={handleLoadClick}>Load</Button>
        </ACRow1>
        <ACRow2>
          <GeneralInput
            darkBackground
            label="Name"
            autoComplete="none"
            value={
              editingObject && editingObject.name !== null
                ? editingObject.name
                : undefined
            }
            onChange={(e: any) => {
              setEditingObject((prev: any) => {
                const clone = { ...prev };
                clone.name = e.target.value;
                return clone;
              });
            }}
          />
          <Button custCss={MutedButton} onClick={handleOverwriteClick}>Overwrite</Button>
          <Button custCss={MutedButton} onClick={handleSaveAsNewClick}>Save As New</Button>
          <Button custCss={MutedButton} onClick={handleDeleteClick}>Delete</Button>
        </ACRow2>
      </ActionsContainer>
      } 
      {editingObject ? 
        (
          <GeneralEditForm
            object={editingObject}
            setObject={setEditingObject}
            emptyObject={emptyObject}
            fieldsToExclude={fieldsToExclude}
            instructionKeyMap={instructionKeyMap}
            fieldsToEmphasize={fieldsToEmphasize}
            fieldsToDisable={fieldsToDisable}
            universalDisabledMessage={universalDisabledMessage}
            imageLinkMap={imageLinkMap}
            fieldsInOrder={fieldsInOrder}
          />
        ) 
      : (
        <></>
      )}
      {appendNodes}
    </Container>
  );
};
export default ObjectCrud;
