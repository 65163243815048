import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  ChangeEvent,
} from "react";
import styled, { css, keyframes } from "styled-components";
import { baseURL, GlobalContext } from "../../Contexts/Global";
import Button from "../../Reusables/Button";
import CheckBox from "../../Reusables/CheckBox";
import GeneralInput from "../../Reusables/GeneralInput";
import TOS from "../../Popups/TOS";
import FormTemplate, { FormContentProps } from "../Template";
import { BackendAPIContext } from "components/Contexts/BackendAPI";
import FormNavigation, { FormNavigationItem } from "../FormNavigation";
import { createEmpty, getIconByOutputType, vw } from "lib/helper-functions";
import PlasticPipeNominalSizeCrud from "components/Popups/PlasticPipeNominalSizeCrud";
import SteelPileNominalSizeCrud from "components/Popups/SteelPileNominalSizeCrud";
import {
  generateObjCrudProps,
  getModelNameTypes,
} from "lib/objectCrudPropsHelpers";
import ObjectCrud from "components/Reusables/ObjectCrud";
import BulkInputBox from "components/OutputFlows/BulkPredictionHPCapacity/StudyNameInput";
import { ModelNameTypes } from "lib/objectCrudPropsHelpers";
import Select from "components/Reusables/Select";
import { prettifyTempUnit } from "lib/units";
import getInitialSelectedUnit from "../../Reusables/NumberInput";
import { H1 } from "components/PrintableReports/Template";
import InfoPopup from "components/Popups/InfoPopup";
import {
  HorizFlex,
  HorizFlexMin,
  MinimalDiv,
  VertFlex,
  VertFlexMin,
} from "components/MainMenu";
import {
  BulkPredictionDTO,
  bulkPredictionsTimeUnit,
  BulkPredictionTimeUnit,
  getBulkPredictionDtoWithDefaults,
} from "lib/models/bulk-prediction-dto";
import { SteelPile } from "lib/models/steel-pipe";
import { PlasticPipe } from "lib/models/plastic-pipe";
import { HeatPump, OutputVariables, PileArray, PileInFluid } from "lib/models";
import Header from "components/Reusables/Header";
import { useHistory } from "react-router";
import SessionCrud from "components/Popups/SessionCrud";
import Footer from "components/Reusables/Footer";
import { validateBulkPredictionCsv, validateBulkPredictionDTO } from "lib/bulk-prediction-validation";
import DatetimePicker from "components/Reusables/DatetimePicker";
import Checklist from "./Checklist";
import StaticVariableInputSection from "./StaticVariableInputSection";
import GTPConfigSection from "./GTPConfigSection";
import FileUploadSection from "./FileUploadSection";
import StudyNameInput from "components/OutputFlows/BulkPredictionHPCapacity/StudyNameInput";
import BulkStudyConfigSection from "./StudyConfigSection";
import TimeInputSection from "./TimeInputSection";
import {
  FunctionImage,
  H4,
  HeaderButtons,
  LeftCol,
  LogoGroup,
  LogoImage,
  LowerBlankSection,
  MainContainerOuter,
  MidCol,
  MLModelIndicator,
  MLModelIndicatorText,
  MLModelIndicatorTitle,
  NavLink,
  OuterContainer,
  SessionButton,
  SessionButtons,
  SessionIndicator,
  SessionIndicatorText,
  SessionIndicatorTitle,
  SessionSection,
  VerticalDivider,
} from "./Styles";
import ResultsPopup from "components/Popups/ResultsPopup";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";
import { createDeveloperOnlyErrorMessage, createImpossibleOccuranceWarning, createNonFatalHttpRequestErrorMessage, createNonFatalValidationErrorMessage } from "lib/ErrorAndMessageHandling/ErrorHandler";
import { generateCsvFromBulkPredictionResults } from "lib/process-bulk-prediction-results";
import { fetchBulkPrediction } from "lib/api";
import { isNotNullOrUndefined } from "lib/validation-helpers";
import { BulkHeatPumpCapacityResult } from "lib/models/bulk-prediction-results";

interface BulkPredictionsProps {}
type testType= {}
const test: {[o: string]:any} = {

  
}

const BulkPredictions: React.FunctionComponent<BulkPredictionsProps> = (
  {
    // children,
    // styles,
    // setNavigationItems,
    // selectedNavigationItem,
    // setSelectedNavigationItem,
  }
  ) => {
  const api = useContext(BackendAPIContext);
  const dto = api?.bulkPredictionDto;
  const setDto = api?.setBulkPredictionDto;
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);
    const history = useHistory();
  //set outputvariable correctly if in Bulk Predictions
  useEffect(()=>{
          gc?.setOutputVariable(OutputVariables.BulkPredictions)
  },[gc])


  const setToDto = (propertyName: keyof BulkPredictionDTO, newVal: any)=>{
    if (!setDto) return;
    setDto((prev)=>{
      if (!prev){
        return {...getBulkPredictionDtoWithDefaults(api), [propertyName]: newVal};
      };
      const clone = {...prev, [propertyName]: newVal};
      ////console.log('successfully updated dto, going to return ',clone)
      // clone[propertyName] = newVal;
      return clone;
    })
  }

  // input values

  const nameStudyRef = useRef(null);
  const uploadFileRef = useRef(null);
  const configureStudyRef = useRef(null);
  const configureTimeRef = useRef(null);
  const configureGroundTempsRef = useRef(null);
  const constantValuesRef = useRef(null);
  const checklistRef = useRef(null);
  const [selectedScrollPosition, setSelectedScrollPosition] =
    useState<FormNavigationItem>();
  const [scrollPositionItems, setScrollPositionItems] =
    useState<FormNavigationItem[]>();

  // navigation helpers
  function onSelect(newItem: any) {
    newItem.ref.current.scrollIntoView();
    window.scrollBy(0, vw(-10));
    setSelectedScrollPosition(newItem);
  }

  function sendBulkPredictionValError(infoAboutLocation: string, infoAboutReason: string){
    messageHandler.addMessage!(createNonFatalValidationErrorMessage(
      'Bulk Prediction Validation',
      infoAboutLocation || 'User Input',
      infoAboutReason
    ));
  }

  //MAIN CALCULATE FUNCTION
  async function runCalculation() {
    // // gc?.setPopup(<ResultsPopup />)
    const dto = generateDto();
   //console.log('here');
    if (!dto) {
      messageHandler.addMessage!(createImpossibleOccuranceWarning('DTO is Nullish', 'in runCalculation in Container'));
      // error message should have already been generated in generateDTo func
      return;
    }
   //console.log('starting dto val')
    const problemsWithDto = await validateBulkPredictionDTO(dto);
    if (problemsWithDto) {
      sendBulkPredictionValError('Non-Csv User Input', problemsWithDto)     
      return;
    }
   //console.log('done validating dto')
    const problemsWithCsv = await validateBulkPredictionCsv(api?.bulkPredictionCsv);
    if (problemsWithCsv){
     //console.log('problems with csv');
      sendBulkPredictionValError('CSV File', problemsWithCsv)
      return;
    }
   //console.log('done validating csv')
    api?.startBulkPrediction(dto, api.bulkPredictionCsv!)
    // gc?.setPopup(<ResultsPopup />)
  }

  //Generate DTO object
  function generateDto(): BulkPredictionDTO | void {
    if (!dto){
      sendBulkPredictionValError('User Input', 'Input form is not defined, have you entered anything?');

      return;
    };
    const dtoC: BulkPredictionDTO = {...dto};
    const gtpWillBeUsed = dtoC?.autoGetGroundTemperature0m || dtoC?.autoGetGroundTemperature5m || dtoC?.autoGetGroundTemperature10m;

    if (!dtoC?.name) {
      sendBulkPredictionValError('Study Name', 'Is Empty');
      return;
    }
    if (dtoC.withHeatPump == undefined) {
      sendBulkPredictionValError('With or Without Heat Pump Selector', 'Nothing Selected');
      messageHandler.addMessage!(createImpossibleOccuranceWarning('nothing selected in With/Withouth Heat Pump selector','Bulk Prediction Input'));
      return;
    }
    if (!dtoC.timeAndDateOfFirstRowStart) {
      sendBulkPredictionValError('Date and Time of first CSV Row', 'Not defined.');
      return;
    }
    if (!dtoC.timestepUnit) {
      sendBulkPredictionValError('Time-Step Unit', 'Not defined');
      return;
    }
    if (!dtoC.timestepMagnitude) {
      sendBulkPredictionValError('Time-Step Magnitude', 'Not defined, or 0.');
      return;
    }
    if (!api?.steelPile) {
      sendBulkPredictionValError('Steel Pile', 'Not defined');
      messageHandler.addMessage!(createImpossibleOccuranceWarning('Steel Pile Undefined', 'Bulk Prediction Validation'));
      return;
    }
    if (!api?.plasticPipe) {
      sendBulkPredictionValError('Plastic Pipe', 'Not defined');
      messageHandler.addMessage!(createImpossibleOccuranceWarning('Plastic Pipe Undefined', 'Bulk Prediction Validation'));
      return;
    }
    if (!api?.heatPump) {
      sendBulkPredictionValError('Heat Pump', 'Not defined');
      messageHandler.addMessage!(createImpossibleOccuranceWarning('Heat Pump Undefined', 'Bulk Prediction Validation'));
      return;
    }

    // pull the standard models from their regular save location in api context
    dtoC.steelPile = api?.steelPile
    dtoC.heatPump = api?.heatPump
    dtoC.plasticPipe = api?.plasticPipe

    if (!api.bulkPredictionCsv) {
      sendBulkPredictionValError('CSV File', 'Not found.');
      return;
    }
    if (gtpWillBeUsed && (!dtoC.GTPInputs?.lat || !dtoC.GTPInputs.long)) {
      sendBulkPredictionValError('Latitude and Longitude Coordinates', 'One or both are missing, but are required because you have requested ground temperature autofilling.');
      return;
    }
    if (gtpWillBeUsed && dtoC.GTPInputs?.pileTopDepth == undefined) {
      sendBulkPredictionValError('Depth Of Top Of Pile.',
      "You must enter the average depth of the top of your piles, because you have requested ground temperature autofilling."
      )
      return;
    }
   //console.log('most basic bulk pred. dto validation passed successfully')
    return dtoC;
  }

  //whenever a ref changes, change the internal navigation items
  useEffect(() => {
    setScrollPositionItems(buildNavigationItems());
    function buildNavigationItems() {
      return [
        { text: "1. Name Study", ref: nameStudyRef },
        { text: "2. Upload File", ref: uploadFileRef },
        { text: "3. Configure Study", ref: configureStudyRef },
        { text: "4. Configure Time/Dates", ref: configureTimeRef },
        {
          text: "5. Configure Ground Temperatures",
          ref: configureGroundTempsRef,
        },
        { text: "6. Input Constant Variables", ref: constantValuesRef },
        { text: "7. Checklist", ref: checklistRef },
      ];
    }
  }, [
    uploadFileRef,
    constantValuesRef,
    nameStudyRef,
    configureGroundTempsRef,
    configureStudyRef,
    configureTimeRef,
  ]);

  const fetchPreviousResult = useCallback(async (name: string): Promise<BulkHeatPumpCapacityResult> => {
    //get name from dto
    const result = await fetchBulkPrediction(name, gc?.apiToken || '');
    if (result.taskStatus == "Failed") {
     //console.log('bulk study task failure detected');
        createNonFatalHttpRequestErrorMessage(
          "Failed to retrieve previous bulk study result with the name: "+name,
          500,
          result.errorMessages
            ? result.errorMessages[0]
            : "Study failed, reason unknown."
        );
    }
      if ((result.isComplete || result.taskStatus === "Finished")) {
      return result;
      }
      throw new Error("Bulk study result not found");
  },[]);

  //whenever internal nav items change change the outputted navigation items
  // useEffect(() => {
  // if (!navItems) return;
  // setNavigationItems(navItems);
  // }, [navItems, setNavigationItems]);

  const getUpperMostNavLink = useCallback(
    (yPosition: number) => {
      const magicNumber = vw(10);
      let result = undefined;
      // //console.log('nav items: ', navItems);
      scrollPositionItems?.forEach((item: FormNavigationItem) => {
        if (!item.ref) return;
        const itemY = item.ref.current?.offsetTop;
        // //console.log('comparing these: ',item, itemY, yPosition);
        if (!itemY) return;
        if (itemY < yPosition + magicNumber) result = item;
        return;
      });
      return result;
    },
    [scrollPositionItems]
  );
  //detect window scroll events
  const handleScroll = useCallback(() => {
    // //console.log('scroll detected');
    const currentY = window.scrollY;
    gc?.setFormScrollTop(currentY);
    const uppermostNavLink = getUpperMostNavLink(currentY);
    // //console.log('got this component: ',uppermostNavLink);
    setSelectedScrollPosition(uppermostNavLink);
  }, [getUpperMostNavLink, setSelectedScrollPosition, gc]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  if (!api || !gc) return <div>...Loading...</div>;
  return (
    <OuterContainer>
      <Header>
        <HeaderButtons>
          <Button onClick={() => history.push("/menu")}>Main Menu</Button>
          <Button
            custCss={css`
              background: var(--c-gray);
            `}
            onClick={gc?.logout}
          >
            Logout
          </Button>
        </HeaderButtons>
        <HorizFlex>
          <FunctionImage
            src={getIconByOutputType(gc?.outputVariable)}
            alt="icon"
          ></FunctionImage>
          <H4>Predict {gc?.outputVariable}</H4>
        </HorizFlex>
        <SessionSection>
          <SessionIndicator>
            <SessionIndicatorTitle>Current Session:</SessionIndicatorTitle>
            <SessionIndicatorText>
              {api?.calculator?.name || "Not Saved"}
            </SessionIndicatorText>
          </SessionIndicator>
          <SessionButtons>
            <Button
              onClick={() => gc?.setPopup(<SessionCrud />)}
              custCss={SessionButton}
            >
              Save Session
            </Button>
            <Button
              onClick={() => gc?.setPopup(<SessionCrud />)}
              custCss={SessionButton}
            >
              Load Session
            </Button>
          </SessionButtons>
        </SessionSection>
      </Header>
      <HorizFlex>
        <LeftCol>
          <FormNavigation
            items={scrollPositionItems}
            selectedItem={selectedScrollPosition}
            onSelect={onSelect}
          />
          <LogoGroup>
            <LogoImage src={"/InnoviaLogoLight.svg"} alt="logo" />
            <LogoImage src={"/simpleLogo.svg"} alt="logo" />
          </LogoGroup>
        </LeftCol>
        <MidCol>
          {gc.popup ? (
            <></>
          ) : (
            <MainContainerOuter>
              <NavLink ref={nameStudyRef}></NavLink>
              <StudyNameInput
                title="1. Study Name"
                instructions="Type your study's name here:"
                textValue={dto?.name}
                setTextValue={(v:any)=>setToDto('name',v)}
              />
              <NavLink ref={uploadFileRef}></NavLink>
              <FileUploadSection 
                csv={api.bulkPredictionCsv}
                setCsv={api.setBulkPredictionCsv}
              />
              <NavLink ref={configureStudyRef}></NavLink>
              <BulkStudyConfigSection
                withHeatPump={dto?.withHeatPump}
                setWithHeatPump={(v:any)=>setToDto('withHeatPump',v)}
                withKnownInFluidEachRow = {dto?.withKnownInletTemperature}
                setWithKnownInFluidEachRow={(v: any)=> setToDto('withKnownInletTemperature', v)}
              />
              <NavLink ref={configureTimeRef}></NavLink>
              <TimeInputSection 
                timestepMagnitude={dto?.timestepMagnitude}
                timestepUnit={dto?.timestepUnit}
                firstRowDatetime={dto?.timeAndDateOfFirstRowStart}
                setTimestepMagnitude={(val:any)=>setToDto('timestepMagnitude',val)}
                setTimestepUnit={(val: any)=> setToDto('timestepUnit', val)}
                setFirstRowDatetime={(val: any)=> setToDto('timeAndDateOfFirstRowStart',val)}
              />
              <NavLink ref={configureGroundTempsRef}></NavLink>
              <GTPConfigSection 
                tGround0Auto={dto?.autoGetGroundTemperature0m}
                tGround5Auto={dto?.autoGetGroundTemperature5m}
                tGround10Auto={dto?.autoGetGroundTemperature10m}
                setTGround0Auto={(v:any)=> setToDto('autoGetGroundTemperature0m',v)}
                setTGround5Auto={(v: any)=> setToDto('autoGetGroundTemperature5m',v)}
                setTGround10Auto={(v: any)=> setToDto('autoGetGroundTemperature10m',v)}
                lat={dto?.GTPInputs.lat}
                long={dto?.GTPInputs.long}
                pileTopDepth={dto?.GTPInputs.pileTopDepth}
                setLat={(v:any)=> setToDto('GTPInputs',{...dto?.GTPInputs,lat:v})}
                setLong={(v:any)=>setToDto('GTPInputs',{...dto?.GTPInputs, long: v})}
                setPileTopDepth={(v:any)=>setToDto('GTPInputs',{...dto?.GTPInputs, pileTopDepth: v})}
              />
              <NavLink ref={constantValuesRef}></NavLink>
              <StaticVariableInputSection 
              withHeatPump={dto?.withHeatPump} 
              maxFluidTemperature={dto?.maxInletFluidTemperature}
              minFluidTemperature={dto?.minInletFluidTemperature}
              setMaxFluidTemperature={(v: any)=> setToDto('maxInletFluidTemperature',v)}
              setMinFluidTemperature={(v: any)=> setToDto('minInletFluidTemperature', v)}
              />
              <NavLink ref={checklistRef}></NavLink>
              <Checklist />
              <LowerBlankSection src="simpleLogo.svg"></LowerBlankSection>
            </MainContainerOuter>
          )}
        </MidCol>
        {/* <RightCol>Right Column</RightCol> */}
      </HorizFlex>
      <Footer>
        <MLModelIndicator>
          <MLModelIndicatorTitle>
            Current Machine Learning Model:{" "}
          </MLModelIndicatorTitle>
          <MLModelIndicatorText>
            {api?.activePileOutletFluidTemperatureMLModel?.name}
          </MLModelIndicatorText>
        </MLModelIndicator>
        <VerticalDivider />
        <Button onClick={runCalculation}>Calculate Results</Button>
        <VerticalDivider />
        <Button onClick={async ()=>{
          //TODO make this attempt to load a result based on the name entered in the name field,
          //TODO so that we can easily access older results without having to redo them
          if (!api)return;
          if  (!api.prevBulkPredictionResult){
            let name = dto?.name;
            if (!name) return;
            let predResults:BulkHeatPumpCapacityResult = await fetchPreviousResult(name);
            gc.setPopup(<ResultsPopup bulkPredictionData={predResults} />);
            generateCsvFromBulkPredictionResults(predResults, "GPPS-BPR-"+ name +".csv");
          } else {
            let predResults = api.prevBulkPredictionResult;
            let name = api.prevBulkPredictionResultName;
            gc.setPopup(<ResultsPopup bulkPredictionData={predResults} />);
            generateCsvFromBulkPredictionResults(predResults, "GPPS-BPR-"+ name +".csv");
          }

        }}>Open Previous Results</Button>
      </Footer>
    </OuterContainer>
  );
};
export default BulkPredictions;
