import "react-datetime/css/react-datetime.css";

import "style.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "components/App";
import reportWebVitals from "reportWebVitals";
import GlobalProvider from "components/Contexts/Global";
import BackendAPIProvider from "components/Contexts/BackendAPI";
import ErrorHandlerProvider from "components/Contexts/ErrorHandler";
import MessageHandlerProvider from "components/Contexts/MessageHandler";
import {store } from './redux/store';
import {Provider as ReduxProvider} from 'react-redux';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
    <MessageHandlerProvider>
      <ErrorHandlerProvider>
        <GlobalProvider>
          <BackendAPIProvider>
            <App />
          </BackendAPIProvider>
        </GlobalProvider>
      </ErrorHandlerProvider>
    </MessageHandlerProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
