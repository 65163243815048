import { GlobalContext } from "components/Contexts/Global";
import { useOnClickOutside } from "lib/useOnClickOutside";
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { MessageHandlerContext } from "components/Contexts/MessageHandler";

const CenteringContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;
const Container = styled.div<{
  customStyles?: string | FlattenSimpleInterpolation;
}>`
  position: relative;
  padding: var(--s-5);
  background: var(--c-black);
  box-shadow: var(--shadow-1);
  max-height: 80vh;
  max-width: 90vw;
  ${(props) => props.customStyles};
`;
const BlurOverlay = styled.div`
  position: fixed;
  margin: 0;
  padding: 0;
  border-radius: 0;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background: var(--c-dark-transparent);
  backdrop-filter: var(--c-blur);
`;
const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: var(--s--10);
  top: var(--s--10);
  color: var(--c-white);
  background: var(--c-green);
  padding: var(--s-7);
  border-radius: 100%;
  display: flex;
  z-index: 9999;
`;
const TitleContainer = styled.div`
  background: var(--c-black);
  width: fit-content;
  position: absolute;
  top: -2.8vw;
  left: 1.2vw;
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
  z-index: 11;
`;
const TitleText = styled.h3`
  color: var(--c-white);
`;

interface PopupProps {
  closeCallback: () => void;
  overwriteContainerStyles?: string | FlattenSimpleInterpolation;
  title: string;
}
const Popup: React.FunctionComponent<PopupProps> = ({
  children,
  closeCallback,
  overwriteContainerStyles,
  title,
}) => {
  const gc = useContext(GlobalContext);
  const messageHandler = useContext(MessageHandlerContext);
  const ref = useRef(null);
  const handleClickOutside = useCallback(()=>{
    if (messageHandler.wholeAppOverlay){
      return;
    }
    closeCallback();
  },[messageHandler.wholeAppOverlay, closeCallback]);
  
  useOnClickOutside(ref, handleClickOutside);
  const [scrollYOnMount, setScrollYOnMount] = useState(gc?.formScrollTop);
  

  //on mount and unmount, disable or enable scroll on document body
  useEffect(() => {
    document.body.style.overflowY = "scroll";
    document.body.style.position="fixed";

    return () => {
      document.body.style.position ="static";
      document.body.style.overflowY = "auto";
      window.scrollTo({top: scrollYOnMount});
      //scroll to previous scroll position
    };
  }, [scrollYOnMount]);

  return (
    <>
      <BlurOverlay></BlurOverlay>
      <CenteringContainer>
        <Container ref={ref} customStyles={overwriteContainerStyles}>
          <TitleContainer>
            <TitleText>{title}</TitleText>
          </TitleContainer>
          <CloseButton onClick={closeCallback}>
            <CloseIcon />
          </CloseButton>
          {children}
        </Container>
      </CenteringContainer>
    </>
  );
};
export default Popup;
