import { Schema } from "ajv";
import { OfficialVariables, readableVariable, variableUnit } from "lib/types";
import { BaseModel, IBaseModel } from "./base-model";
import { PossibleCategoryNames } from "./possible-category-names";

export function UiInputVariableDoubleSchema(
  min?: number | undefined,
  max?: number | undefined
): Schema {
  let inputValue: {[key:string]:any} = { type: "number", minimum: min, maximum: max };
  if (min == undefined || max == undefined){
    inputValue = {type: "number"};
  }
  return {
    type: "object",
    properties: {
      inputValue: inputValue,
    },
    required: ["inputValue"],
    additionalProperties: true,
  };
}

export interface IUiInputVariableDouble extends IBaseModel {
  inputValue?: number;

  calculatedValue?: number;

  category?: PossibleCategoryNames;

  minAllowed?: number;

  maxAllowed?: number;

  unit?: string | null;

  canSolveFor?: boolean;

  specialButtonText?: string;

  specialButtonOnClick?: () => void;
}
export interface IUiInputVariableDoubleOut {
  inputValue?: number;
}
export class UiInputVariableDouble
  extends BaseModel
  implements IUiInputVariableDouble
{
  // public constructor(unit: string, name: string){
  // super();
  // this.name = name;
  // this.unit = unit;
  // }
  public constructor(type: OfficialVariables) {
    super();
    this.name = readableVariable[type];
    this.unit = variableUnit[type];
  }
  public inputValue?: number;

  public calculatedValue?: number;

  public category?: PossibleCategoryNames;

  public minAllowed?: number;

  public maxAllowed?: number;

  public solveFor?: boolean = false;

  public unit?: string | null;

  public canSolveFor?: boolean;
}

export function convertUiInputDoubleToDto(
  base?: IUiInputVariableDouble | null
): IUiInputVariableDoubleOut | undefined {
  return base
    ? {
        inputValue: base.inputValue,
      }
    : undefined;
}
