// this class takes errors and turns them into appropriate messages to be handled by the message handler

import { HTTP_STATUS_CODES } from "../HTTPStatusCodeDescriptions";
import { CreateMessageFunction, Message, MessageContentForDisplay } from "./MessageHandler";

type ErrorMatchingCallback = (e: any) => boolean;
type MatchCallbacks = ErrorMatchingCallback[];
type ResponseCallback = (e: any) => Message;
export type ErrorHandlers = {
  [ruleName: string]: {
    matchCallbacks: MatchCallbacks;
    response: ResponseCallback;
  };
};

export class ErrorHandler {
  private defaultHandleUnknownErrorType: ResponseCallback = (e) => {
    return new Message(createFatalErrorMessage(e));
  };
  private defaultHandlers: ErrorHandlers = {
    FatalApplicationErrors: {
      matchCallbacks: [
        (e) => e instanceof TypeError,
        (e) => e instanceof SyntaxError,
        (e) => e instanceof ReferenceError,
      ],
      response: (e) => {
        return new Message(createFatalErrorMessage(e,{
            title: e.constructor.name || 'Fatal Error: Unknown Name',
            summary: e.message || 'Error object with no "message" property.',
            additionalInfo: e.stack || JSON.stringify(e)
        }));
      },
    },
    NonFatalApplicationErrors: {
      matchCallbacks: [
        (e)=> e instanceof NonFatalError
      ],
      response: (e) => {
        return new Message(createNonFatalApplicationErrorMessage(
          'Non Fatal Application Error',
          '',JSON.stringify(e)
        ))
      }
    }
    
  };
  private errorHandlers: ErrorHandlers;
  private handleUnknownErrorType: ResponseCallback;
  private _sendMessageToMessageHandler: (m: Message) => void;
  constructor(
    sendMessageToMessageHandler: (m: Message) => string,
    customErrorHandlers?: ErrorHandlers,
    customUnknownErrorResponse?: ResponseCallback
  ) {
    this._sendMessageToMessageHandler = sendMessageToMessageHandler;
    this.handleUnknownErrorType =
      customUnknownErrorResponse || this.defaultHandleUnknownErrorType;
    this.errorHandlers = { ...this.defaultHandlers, ...customErrorHandlers };
  }
  public handleAllErrors(e: any) {
   //console.log('error received by error handler....',e);
    let message = undefined;
    Object.keys(this.errorHandlers).forEach((rule) => {
      const matchCallbacks = this.errorHandlers[rule].matchCallbacks;
      let belongsToThisRule: boolean = false;
      matchCallbacks.forEach((matchCallback) => {
        if (matchCallback(e)) {
          belongsToThisRule = true;
        }
      });
      if (belongsToThisRule) {
        message = this.errorHandlers[rule].response(e);
      }
    });
    if (!message) {
      message = this.handleUnknownErrorType(e);
    }
    this._sendMessageToMessageHandler(message);
  }
  public modifyRules(newRules: ErrorHandlers){
      this.errorHandlers = {...this.errorHandlers, ...newRules};
  }
  public modifyUnknownErrorHandler(newUnknownErrorHandler: ResponseCallback){
      this.handleUnknownErrorType = newUnknownErrorHandler
  }
}


export function createFatalErrorMessage (e: any, contentForDisplay?: MessageContentForDisplay){
    return new Message({
        contentForDisplay: contentForDisplay?{
          ...contentForDisplay, additionalInfo: contentForDisplay.additionalInfo + JSON.stringify(e)}
          :{
            title: 'Fatal Error: Unknown Type',
            summary: 'Caught by ErrorHandler and transfered to MessageHandler.',
            additionalInfo: JSON.stringify(e)
        },
        dontAlertDevInProduction: false,
        hideInProduction: false,
        isBlocking: true,
        importanceLevel: 'very important',
        messageToDeveloper: true,
        showToUser: true,
        type: 'application error',
        userAllowedActionType: 'restartApp'
    });
}
export function createNonFatalHttpRequestErrorMessage (
  requestTitle: string,
  requestStatusCode: number,
  requestResponseBody: string
){
  return new Message({
    contentForDisplay: {
      title: "HTTP Request Failed: "+requestTitle,
      summary: "Request failed with status "+HTTP_STATUS_CODES[requestStatusCode],
      additionalInfo: JSON.stringify(requestResponseBody)
    },
    dontAlertDevInProduction: false,
    hideInProduction: false,
    isBlocking: true,
    importanceLevel: 'important',
    messageToDeveloper: true,
    showToUser: true,
    type: 'application error',
    userAllowedActionType: 'continue'
  });
}

export function createUnhandledPromiseRejectionEventMessage(e: PromiseRejectionEvent){
  if (e.reason.message.includes('Request failed with status code 400')){
    //check for message from backend, this is an http error
    return createNonFatalHttpRequestErrorMessage(e.reason.config.url, 400, e.reason.response.data);
  };
  return new Message({
    'contentForDisplay': {
        'title': 'Unhandled Promise Rejection Event',
        'summary': '',
        'additionalInfo': JSON.stringify(e.reason)
    },
    'dontAlertDevInProduction': false,
    'hideInProduction': true,
    'importanceLevel': 'important',
    'isBlocking': false,
    'messageToDeveloper': true,
    'showToUser': true,
    'type': 'application error',
    'userAllowedActionType': 'continue'
});
}

export function createHandledPromiseRejectionEventMessage(e: PromiseRejectionEvent){
  return new Message({
    'contentForDisplay': {
        'title': 'Handled Promise Rejection Event',
        'summary': '',
        'additionalInfo': JSON.stringify(e.reason)
    },
    'dontAlertDevInProduction': true,
    'hideInProduction': true,
    'importanceLevel': 'not-important',
    'isBlocking': false,
    'messageToDeveloper': false,
    'showToUser': true,
    'type': 'application error',
    userAllowedActionType: 'continue',
})
}

export function createNonFatalValidationErrorMessage (
  title: string,
  nameOfIncorrectInput: string,
  reasonItIsIncorrect: string
){
  return new Message({
    contentForDisplay: {
      title: "Input Error: "+title,
      summary: 'Location of Input Error: '+nameOfIncorrectInput,
      additionalInfo: 'Reason for Input Error: '+reasonItIsIncorrect
    },
    dontAlertDevInProduction: true,
    hideInProduction: false,
    isBlocking: true,
    importanceLevel: 'important',
    messageToDeveloper: false,
    showToUser: true,
    type: 'validation error',
    userAllowedActionType: 'continue'
  });
}


export function createNonFatalApplicationErrorMessage (
  title: string,
  message: string,
  stackTrace: string
){
  return new Message({
    contentForDisplay: {
      title: "Non-Fatal Application Error: "+title,
      summary: message,
      additionalInfo: stackTrace
    },
    dontAlertDevInProduction: false,
    hideInProduction: false,
    isBlocking: true,
    importanceLevel: 'important',
    messageToDeveloper: true,
    showToUser: true,
    type: 'application error',
    userAllowedActionType: 'continue'
  });
}

export function createDeveloperOnlyErrorMessage (
  title: string,
  message: string,
  stackTrace: string
){
  return new Message({
    contentForDisplay: {
      title: "Developer Only Error Message: "+title,
      summary: message,
      additionalInfo: stackTrace
    },
    dontAlertDevInProduction: false,
    hideInProduction: true,
    isBlocking: false,
    importanceLevel: 'important',
    messageToDeveloper: true,
    showToUser: false,
    type: 'application error',
    userAllowedActionType: 'continue'
  });
}

export function createImpossibleOccuranceWarning (
  message: string,
  stackTrace: string
){
  return createDeveloperOnlyErrorMessage(
    'Impossible Occurance', message, stackTrace
  )
}


export class NonFatalError extends Error{
}


export function createNonFatalUserErrorMessage (
  title: string,
  message: string,
  stackTrace: string
){
  return new Message({
    contentForDisplay: {
      title: "User Error: "+title,
      summary: message,
      additionalInfo: stackTrace
    },
    dontAlertDevInProduction: false,
    hideInProduction: false,
    isBlocking: true,
    importanceLevel: 'important',
    messageToDeveloper: true,
    showToUser: true,
    type: 'user error',
    userAllowedActionType: 'continue'
  });
}