import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  NumberInput as Base,
  SimpleSelectProps,
} from "sheldons-components";
import { NumberInputProps } from "sheldons-components/dist/components/inputs/NumberInput";
import styled, { css } from "styled-components";
import { GeneralInputStyle } from "./GeneralInput";
import Select from 'components/Reusables/Select';
import { prettifyTempUnit } from "lib/units";
import Button from './Button'

const SingleUnit = styled.div`
  color: inherit;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  /* width: 100%; */
`;
const SpecialButton = styled(Button)`
  background: var(--c-green);
  color: var(--c-white);
`;

interface NumberInputProps2 extends NumberInputProps {
  allowedUnits?: string[] | undefined;
  width?: any
  specialButtonText?: string
  specialButtonOnClick?: (e:any)=>void
  labelWidth?: any
}
const NumberInput: React.FunctionComponent<NumberInputProps2> = (props) => {

  function getInitialSelectedUnit(){
    if (!props.allowedUnits || ! props.allowedUnits[0]) return undefined;
    return props.allowedUnits[0];
  }
  const [selectedUnit, setSelectedUnit] = useState<string | undefined>(getInitialSelectedUnit());
  //debbuging
  useEffect(() => {
    //console.log("value in number input has changed", props.value);
  }, [props.value]);
  // useEffect(()=>{
    // //console.log('selected unit has changed', selectedUnit);
  // },[selectedUnit])
  return (
    <Container>
      <Base
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>)=>{
          //console.log('detected key press with key', e.code);
          if (e.code == 'Enter'){
            e.preventDefault();
            return false;
          }
        }}
        selectedUnit={selectedUnit}
        withUnitConversions={(props.allowedUnits)?true:false}
        styles={{
          ...GeneralInputStyle,
          label: css`
          ${GeneralInputStyle.label}
          width:${props.labelWidth?props.labelWidth: 'var(--s-20)'};
          `,
          input: css`
            ${GeneralInputStyle.input}
            width: ${props.width?props.width:`var(--s-15)`};
          `,
        }}
        {...props}
        step="any"
       
      />
      {props.specialButtonText?
      <SpecialButton onClick={props.specialButtonOnClick}>{props.specialButtonText}</SpecialButton>
      :<></>}
      {props.allowedUnits ? (
        <Select
          type="styled"
          custCssMainContainer={css`
            width: var(--s-15);
          `}
          options={props.allowedUnits!.map((unit) => ({
            id: unit,
            value: unit,
            content: prettifyTempUnit(unit),
          }))}
          selected={
            selectedUnit
              ? { id: selectedUnit, value: selectedUnit, content: prettifyTempUnit(selectedUnit) }
              : undefined
          }
          onChange={(newOption) => {
            if (newOption && newOption.value && typeof newOption?.value === "string") 
              setSelectedUnit(newOption.value);
          }}
        />
      ) : (
        <></>
      )}
      {(! props.allowedUnits && props.outputUnit)?
      <SingleUnit>
        {prettifyTempUnit(props.outputUnit)}
      </SingleUnit>
      :<></>}
    </Container>
  );
};
export default NumberInput;
